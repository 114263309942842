import React, { memo, useCallback, useMemo, useState } from "react";

import { Form, LoadIndicator } from "devextreme-react";
import { RequiredRule, SimpleItem } from "devextreme-react/form";
import { FieldDataChangedEvent } from "devextreme/ui/form";

import { useDeductiblesRetrieveDeductiblesQuery } from "../../../apis/DeductiblesApi";
import { FieldType, usePoliciesUpdatePolicyVersionNoSecondaryReviewMutation } from "../../../apis/PoliciesApi";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { selectIdentity } from "../../../store/Identity";
import { selectPolicyPage, setDeductibleGuid } from "../../../store/PolicyPageState";
import { format } from "../../../utils/formatUtils";
import { Guid } from "../../../utils/Guid";
import { isPolicyComponentReadOnly, POLICY_VALIDATION_GROUP, PolicyComponentBehaviorType } from "../../../utils/PolicyUtil";
import { Translate } from "../../../utils/Translation";
import { disableAutocompleteOnForm, sortIndices } from "../../../utils/utils";
import BaseComponent from "./BaseComponent";

type Props = {
	readonly onFormChanged: () => Promise<void>;
};

const DEDUCTIBLE = "deductible";

type Deductible = {
	readonly [DEDUCTIBLE]: Guid | null;
};

type Option = {
	readonly guid: Guid;
	readonly value: string;
};

const DeductibleComponent = ({ onFormChanged }: Props): JSX.Element => {

	const { data } = useAppSelector(selectPolicyPage);
	const { username, permissions } = useAppSelector(selectIdentity);
	if (!data)
		throw new Error("No policy page data available");

	const readOnly = isPolicyComponentReadOnly(data, username, permissions, PolicyComponentBehaviorType.RequiresNoSecondReview);

	const dispatch = useAppDispatch();
	const [formData, setFormData] = useState<Deductible>({ deductible: data.policyVersion.deductibleGuid });
	const [options, setOptions] = useState<Option[]>([]);
	const [triggerPutPolicyVersion] = usePoliciesUpdatePolicyVersionNoSecondaryReviewMutation();

	const { data: deductibleOptions, error, isLoading } = useDeductiblesRetrieveDeductiblesQuery();

	if (error)
		throw new Error(`Error retrieving deductibles: ${error}`);

	const setFieldValue = useCallback(async (e: FieldDataChangedEvent): Promise<void> => {
		if (e.dataField !== DEDUCTIBLE)
			return;
		const guid = e.value as Guid;
		dispatch(setDeductibleGuid(guid));
		const update = await triggerPutPolicyVersion({
			policyGuid: data.policy.guid, updatePolicyVersionDtoNoSecondaryReviewDto: {
				...data.policyVersion,
				deductibleGuid: guid,
			}
		}).unwrap();
		setFormData({
			deductible: update.deductibleGuid,
		});
	}, [data.policy.guid, data.policyVersion, dispatch, triggerPutPolicyVersion]);

	if (options.length === 0 && deductibleOptions) {
		const sortedOptions = [...deductibleOptions].sort((a, b) => sortIndices(a.amount, b.amount));
		setOptions(sortedOptions.map((option) => ({
			guid: option.guid,
			value: format(FieldType.Currency, option.amount),
		})));
	}

	const label = useMemo(() => ({ text: Translate("policy.form.deductible") }), []);
	const requiredLabel = useMemo(() => Translate("policy.form.deductible.required"), []);

	if (isLoading || formData === null)
		return <LoadIndicator />;

	return (
		<BaseComponent id="deductible" captionKey="policy.form.title.deductible">
			<Form
				formData={formData}
				onOptionChanged={onFormChanged}
				readOnly={readOnly}
				onFieldDataChanged={setFieldValue}
				validationGroup={POLICY_VALIDATION_GROUP}
				onContentReady={disableAutocompleteOnForm}
			>
				<SimpleItem
					dataField={DEDUCTIBLE}
					editorType="dxSelectBox"
					label={label}
					editorOptions={{
						valueExpr: "guid",
						displayExpr: "value",
						dataSource: options,
					}}
				>
					<RequiredRule message={requiredLabel} />
				</SimpleItem>
			</Form>
		</BaseComponent>
	);

};

export default memo(DeductibleComponent);