import { CEA_CODE_FIELD_NAME } from "../../hooks/usePreRiskFieldRetrieval";
import { FieldValueRetrieval } from "./FieldValueRetrieval";

class CEAFilter {
	value: string;
	type: "normal" | "negation";

	constructor(value: string, type: "normal" | "negation") {
		this.value = value;
		this.type = type;
	}

	evaluate(retrieval: FieldValueRetrieval): boolean {
		return this.evaluateCode(retrieval(CEA_CODE_FIELD_NAME));
	}

	evaluateCode(ceaCode: string | null): boolean {
		if (!ceaCode) return true;

		let beginning = "";
		for (const char of this.value) {
			if (char === "*") break;
			else beginning += char;
		}

		const result = !beginning
			? true
			: ceaCode.startsWith(beginning);
		return this.type === "normal" ? result : !result;
	}
}

export default CEAFilter;
