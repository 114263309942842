import { Guid } from "../utils/Guid";
import { befurstApiClient as api } from "./BefurstApi";
export const addTagTypes = ["PreRiskAnswersOptions"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      preRiskAnswersOptionsFetchPreRiskAnswersOptions: build.query<
        PreRiskAnswersOptionsFetchPreRiskAnswersOptionsApiResponse,
        PreRiskAnswersOptionsFetchPreRiskAnswersOptionsApiArg
      >({
        query: () => ({ url: `/api/pre-risk-answers-options` }),
        providesTags: ["PreRiskAnswersOptions"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as enhancedApi };
export type PreRiskAnswersOptionsFetchPreRiskAnswersOptionsApiResponse =
  /** status 200 OK */ PreRiskAnswerOptionDto[];
export type PreRiskAnswersOptionsFetchPreRiskAnswersOptionsApiArg = void;
export type PreRiskAnswerOptionDto = {
  guid: Guid;
  key: string | null;
  category: string | null;
  value: string | null;
  index: number;
};
export const {
  usePreRiskAnswersOptionsFetchPreRiskAnswersOptionsQuery,
  useLazyPreRiskAnswersOptionsFetchPreRiskAnswersOptionsQuery,
} = injectedRtkApi;
