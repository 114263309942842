/* eslint-disable max-lines */
/* eslint-disable react/forbid-elements */
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import { DataGrid, SelectBox } from "devextreme-react";
import { Column, Editing, Lookup, RangeRule } from "devextreme-react/data-grid";
import { EditorPreparingEvent } from "devextreme/ui/data_grid";

import {
  PolicyDto, usePoliciesCreatePremiumAfterFillInspectionScoreMutation, usePoliciesCreatePremiumAfterQuoteReviewMutation,
  usePoliciesFinishStipulationsReviewMutation,
} from "../../../apis/PoliciesApi";
import {
  PremiumStipulationDto, PremiumStipulationType, usePremiumGetStipulationsNotInUseQuery,
  usePremiumStipulationsCreatePremiumStipulationMutation, usePremiumStipulationsUpdateQuoteReviewPremiumStipulationMutation,
} from "../../../apis/PremiumApi";
import {
  PremiumStipulationResponse, usePremiumStipulationsUpdatePremiumStipulationResponseMutation,
} from "../../../apis/PremiumStipulationApi";
import { useAppDispatch } from "../../../hooks/hooks";
import { setHasAnyUnacceptableStipulations } from "../../../store/StipulationsSlice";
import styles from "../../../styles/Calculation.module.scss";
import dialogStyles from "../../../styles/Dialog.module.scss";
import policyStyles from "../../../styles/Policy.module.scss";
import dialogButtonsStyles from "../../../styles/PremiumDialogButtons.module.scss";
import { Guid } from "../../../utils/Guid";
import { Translate } from "../../../utils/Translation";
import Button from "../../buttons/Button";
import Dialog from "../../dialog/Dialog";
import ComponentTitle from "../../titles/ComponentTitle";
import FishAndUboComponent from "../FishAndUboComponent";
import FreeFieldStipulationsComponent from "./FreeFieldStipulationsComponent";
import PremiumDialogButtons from "./PremiumDialogButtons";

type Props = {
	readonly stipulations: PremiumStipulationDto[];
	readonly policy: PolicyDto;
	readonly premiumGuid: Guid;
	readonly freeFieldStipulations: string | null;
	readonly premiumOutdated: boolean;
	readonly showResponseColumn?: boolean;
	readonly showIrrelevantColumn?: boolean;
	readonly showFinishStipulationReview?: boolean;
	readonly allowEditResponseColumn?: boolean;
	readonly allowQuoteReviewEdit?: boolean;
	readonly allowQuoteReviewEditFromInspection?: boolean;
};

type Row = {
	dataField: string;
	widthFactor: number;
	caption: string;
	allowEditing: boolean;
	calculateDisplayValue?: (x: PremiumStipulationDto) => string;
	children?: JSX.Element;
};

const tableWidth = 900;

const getStipulationTypeOrderIndex = (type: PremiumStipulationType): number => {
	switch (type) {
		case PremiumStipulationType.Demand:
			return 0;
		case PremiumStipulationType.Advice:
			return 1;
		default:
			return 999;
	}
};

const PremiumStipulationsComponent = ({
	stipulations,
	policy,
	premiumGuid,
	freeFieldStipulations,
	premiumOutdated,
	showResponseColumn = false,
	showIrrelevantColumn = false,
	showFinishStipulationReview = false,
	allowEditResponseColumn = false,
	allowQuoteReviewEdit = false,
	allowQuoteReviewEditFromInspection = false,
}: Props): JSX.Element => {
	const dispatch = useAppDispatch();

	const [addStipulationDialogOpen, setAddStipulationDialogOpen] = useState(false);
	const [addStipulationCode, setAddStipulationCode] = useState<string | undefined>();
	const [addStipulationType, setAddStipulationType] = useState<PremiumStipulationType | undefined>();

	const [triggerUpdatePremiumStipulationResponse] = usePremiumStipulationsUpdatePremiumStipulationResponseMutation();
	const [triggerFinishStipulationsReview] = usePoliciesFinishStipulationsReviewMutation();
	const [triggerUpdateQuoteReview] = usePremiumStipulationsUpdateQuoteReviewPremiumStipulationMutation();
	const [triggerCreatePremiumAfterQuoteReview] = usePoliciesCreatePremiumAfterQuoteReviewMutation();
	const [triggerCreatePremiumAfterInspection] = usePoliciesCreatePremiumAfterFillInspectionScoreMutation();
	const [triggerCreatePremiumStipulation] = usePremiumStipulationsCreatePremiumStipulationMutation();

	const {
		error: stipulationsNotInUseError,
		currentData: stipulationsNotInUse,
	} = usePremiumGetStipulationsNotInUseQuery({ premiumGuid }, { skip: !allowQuoteReviewEdit });
	if (stipulationsNotInUseError)
		throw new Error(`Error fetching stipulations ${stipulationsNotInUseError}`);

	const getTypeCaption = useCallback((type: PremiumStipulationType): string => {
		switch (type) {
			case PremiumStipulationType.Demand:
				return Translate("premium-stipulations.demand");
			case PremiumStipulationType.Advice:
				return Translate("premium-stipulations.advice");
			default:
				return "Uknown";
		}
	}, []);

	const typeDataSource = useMemo(() => {
		if (!getTypeCaption)
			return [];
		return [PremiumStipulationType.Demand, PremiumStipulationType.Advice].map(x => {
			return {
				display: getTypeCaption(x),
				value: x
			};
		});
	}, [getTypeCaption]);

	const responseDataSource = useMemo(() => {
		return [
			{
				display: Translate("premium-stipulations.response.will-comply"),
				value: PremiumStipulationResponse.WillComply
			},
			{
				display: Translate("premium-stipulations.response.will-not-comply"),
				value: PremiumStipulationResponse.WillNotComply
			},
			{
				display: Translate("premium-stipulations.response.already-complying"),
				value: PremiumStipulationResponse.AlreadyComplying
			},
		];
	}, []);

	const visibleColumns = useMemo(() => {
		const columns: Row[] = [
			{
				dataField: "type",
				widthFactor: 1,
				caption: Translate("premium-stipulations.type"),
				calculateDisplayValue: (x: PremiumStipulationDto): string => getTypeCaption(x.type),
				allowEditing: allowQuoteReviewEdit,
				children: <Lookup dataSource={typeDataSource as any} displayExpr="display" valueExpr="value" />
			},
			{
				dataField: "resolutionTimeMonths",
				widthFactor: 1,
				caption: Translate("premium-stipulations.resolution-time-months"),
				allowEditing: allowQuoteReviewEdit,
				children: <RangeRule min={1} message={Translate("premium-stipulations.months-too-small")} />
			},
		];
		if (showIrrelevantColumn) {
			columns.push({
				dataField: "irrelevant",
				widthFactor: 1,
				caption: Translate("premium-stipulations.irrelevant"),
				allowEditing: allowQuoteReviewEdit,
			});
		}
		columns.push({
			dataField: "description",
			widthFactor: 4,
			caption: Translate("premium-stipulations.description"),
			allowEditing: false
		});
		columns.push({
			dataField: "label",
			widthFactor: 2,
			caption: Translate("premium-stipulations.label"),
			allowEditing: false
		});
		if (showResponseColumn) {
			columns.push({
				dataField: "response",
				widthFactor: 1.5,
				caption: Translate("premium-stipulations.response"),
				allowEditing: allowEditResponseColumn,
				children: <Lookup dataSource={responseDataSource as any} displayExpr="display" valueExpr="value" />
			});
		}
		return columns;
	}, [getTypeCaption, showResponseColumn, allowEditResponseColumn, allowQuoteReviewEdit, showIrrelevantColumn,
		typeDataSource, responseDataSource]);

	const rows = useMemo(() => {
		if (!stipulations)
			return [];
		return structuredClone(stipulations).sort(function (a, b) {
			// First sort by type (demand before advice), then by code alphabetically
			return getStipulationTypeOrderIndex(a.type) - getStipulationTypeOrderIndex(b.type) || 
				(a.code ? a.code : "").localeCompare((b.code ? b.code : ""));
		});
	}, [stipulations]);

	const setUnacceptableStipulations = useCallback(() => {
		const anyUnacceptable = rows.filter(x => !x.irrelevant &&
			x.response === PremiumStipulationResponse.WillNotComply &&
			x.type === PremiumStipulationType.Demand).length > 0;
		dispatch(setHasAnyUnacceptableStipulations(anyUnacceptable));
	}, [dispatch, rows]);

	useEffect(() => {
		// Initial call
		if (setUnacceptableStipulations)
			setUnacceptableStipulations();
	}, [setUnacceptableStipulations]);

	const newStipulationDataSource = useMemo(() => {
		if (!stipulationsNotInUse)
			return [];
		return structuredClone(stipulationsNotInUse).sort(function (a, b) {
			return (a.description ? a.description : "").localeCompare((b.description ? b.description : ""));
		});
	}, [stipulationsNotInUse]);

	const handleEditorPeparing = useCallback((event: EditorPreparingEvent<PremiumStipulationDto, any>): void => {
		if (event.parentType === 'dataRow') {
			if (event.row) {
				const alreadyComplying = event.row.data.response === PremiumStipulationResponse.AlreadyComplying;
				if (event.dataField === "resolutionTimeMonths" || event.dataField === "type") {
					// The months field should not be editable if the type is advice
					const adviceMonths = event.dataField === "resolutionTimeMonths"
						&& event.row.data.type === PremiumStipulationType.Advice;
					// The type and months fields should not be editable if the row is deemed already compliant or irrelevant
					event.editorOptions.disabled = alreadyComplying || event.row.data.irrelevant || adviceMonths;
				}

				// The irrelevant field should not be editable if the row is deemed already compliant by an earlier (intermediary) review
				// Second point is purely cosmetic: If the acceptant can't edit for quote review then the checkbox will appear inactive
				if (event.dataField === "irrelevant") {
					event.editorOptions.disabled = alreadyComplying || !allowQuoteReviewEdit;
				}
			}
			const defaultValueChangeHandler = event.editorOptions.onValueChanged;
			event.editorOptions.onValueChanged = async (args: any): Promise<void> => {
				if (!event.row)
					return;
				let sendUpdate = false;
				const updatePremiumStipulationDto = { ...event.row.data };
				if (event.dataField === "resolutionTimeMonths") {
					updatePremiumStipulationDto.resolutionTimeMonths = args.value;
					sendUpdate = true;
				}
				if (event.dataField === "irrelevant") {
					updatePremiumStipulationDto.irrelevant = args.value;
					sendUpdate = true;
				}
				if (event.dataField === "type") {
					updatePremiumStipulationDto.type = args.value;
					sendUpdate = true;

					switch (args.value) {
						case PremiumStipulationType.Demand:
							event.row.data.resolutionTimeMonths = 3;
							break;
						case PremiumStipulationType.Advice:
							event.row.data.resolutionTimeMonths = null;
							break;
						default:
							throw Error("Uknown premium stipulation type");
					}
				}
				defaultValueChangeHandler(args);
				if (sendUpdate) {
					await triggerUpdateQuoteReview({
						premiumStipulationGuid: event.row.data.guid,
						updatePremiumStipulationDto
					});
				}
			};
		}
		if (event.dataField === "response" && event.parentType === 'dataRow') {
			const defaultValueChangeHandler = event.editorOptions.onValueChanged;
			event.editorOptions.onValueChanged = async (args: any): Promise<void> => {
				if (!event.row)
					return;
				await triggerUpdatePremiumStipulationResponse({
					premiumStipulationGuid: event.row.data.guid,
					response: args.value
				});
				defaultValueChangeHandler(args);
			};
		}
	}, [allowQuoteReviewEdit, triggerUpdateQuoteReview, triggerUpdatePremiumStipulationResponse]);

	const cellPrepared = useCallback((e: any): void => {
		if (e.rowType === "data") {
			if (e.data.response === PremiumStipulationResponse.AlreadyComplying || e.data.irrelevant) {
				e.cellElement.classList.add(styles["irrelevant-cell"]);
			} else if (e.data.response === PremiumStipulationResponse.WillNotComply
				&& e.data.type === PremiumStipulationType.Demand) {
				e.cellElement.classList.add(styles["invalid-cell"]);
			}
		}
	}, []);

	const handleFinishStipulationsReview = useCallback(async (): Promise<void> => {
		await triggerFinishStipulationsReview({
			policyGuid: policy.guid
		});
	}, [triggerFinishStipulationsReview, policy.guid]);

	const handleCreatePremiumStipulation = useCallback(async (): Promise<void> => {
		if (addStipulationCode === undefined || addStipulationType === undefined)
			return;
		await triggerCreatePremiumStipulation({
			createPremiumStipulationDto: {
				premiumGuid,
				stipulationCode: addStipulationCode,
				type: addStipulationType as PremiumStipulationType
			}
		}).unwrap();
	}, [addStipulationCode, addStipulationType, premiumGuid, triggerCreatePremiumStipulation]);

	// If there is nothing to show then we do not want to render the div + title
	if (rows.length === 0 && !allowQuoteReviewEdit && !freeFieldStipulations && !showFinishStipulationReview)
		return <div />;

	return (
		<div className={`${styles["stipulation-table"]} ${policyStyles["data"]}`}>
			<ComponentTitle text={Translate("premium-stipulations.title")} id={"stipulations"} />
			{allowQuoteReviewEdit ?
				<div className={dialogButtonsStyles["dialog-buttons"]}>
					<Button
						text={Translate("premium-stipulations.add-stipulation.button")}
						autoWidth
						type="normal"
						stylingMode="outlined"
						className="blue"
						onClick={(): void => setAddStipulationDialogOpen(true)}
					/>
				</div> : null}
			{/* If there are any rows to show or if the acceptant can add rows we show the table  */}
			{rows.length > 0 || allowQuoteReviewEdit ? (
				<DataGrid
					keyExpr="guid"
					dataSource={rows}
					showBorders
					columnAutoWidth
					columnHidingEnabled
					sorting={undefined}
					wordWrapEnabled
					// Experimentally set the width of this and columns so prevention text is hidden behind ellipses.
					// There is a solution possible where we set these widths based on the screen instead of using a fixed width
					width={tableWidth + 80}
					onEditorPreparing={handleEditorPeparing}
					onCellPrepared={cellPrepared}
					onRowUpdated={setUnacceptableStipulations}
				>
					<Editing
						mode="cell"
						allowUpdating
					/>
					{
						visibleColumns ? visibleColumns.map(x =>
							<Column
								{...x}
								key={x.dataField}
								hidingPriority={2}
								width={tableWidth / visibleColumns.reduce((a, b) => a + b.widthFactor, 0) * x.widthFactor}
							/>
						) : null
					}
					<Column
						dataField="preventionText"
						caption={Translate("premium-stipulations.prevention")}
						// We'd like this to be hidden behind ellipses most of the time
						hidingPriority={1}
						allowEditing={false}
					/>
				</DataGrid>) : null}

			{freeFieldStipulations || allowQuoteReviewEdit ?
				<FreeFieldStipulationsComponent
					freeFieldStipulations={freeFieldStipulations ? freeFieldStipulations : undefined}
					premiumGuid={premiumGuid}
					readonly={!allowQuoteReviewEdit}
				/> : null}
			{showFinishStipulationReview ?
				<>
					<FishAndUboComponent policy={policy} />
					<PremiumDialogButtons
						acceptAction={(): Promise<void> => handleFinishStipulationsReview()}
						useReject={false}
						premiumStage="quote-stipulations"
						customLocalizeButton
						acceptEnabled={!premiumOutdated}
						isReadonly={!policy.fishAccepted || !policy.uboAccepted}
					/>
				</> : null}
			{allowQuoteReviewEdit ?
				<div className={dialogButtonsStyles["dialog-buttons"]}>
					<Button
						text={Translate("policy.form.recalculate-quote")}
						autoWidth
						isDisabled={!premiumOutdated}
						onClick={async (): Promise<void> => {
							if (allowQuoteReviewEditFromInspection) {
								await triggerCreatePremiumAfterInspection({ policyGuid: policy.guid });
							} else {
								await triggerCreatePremiumAfterQuoteReview({ policyGuid: policy.guid });
							}
						}}
					/>
				</div> : null}
			{allowQuoteReviewEdit ?
				<Dialog
					title={Translate("premium-stipulations.add-stipulation.title")}
					subTitle={Translate("premium-stipulations.add-stipulation.subtitle")}
					buttonText={Translate("premium-stipulations.add-stipulation.button")}
					mainActionDisabled={addStipulationCode === undefined || addStipulationType === undefined}
					isVisible={addStipulationDialogOpen}
					cancelAction={(): void => setAddStipulationDialogOpen(false)}
					mainAction={async (): Promise<void> => {
						await handleCreatePremiumStipulation();
						setAddStipulationDialogOpen(false);
					}}
				>
					<>
						{!newStipulationDataSource || newStipulationDataSource.length === 0
							? <p>{Translate("premium-stipulations.add-stipulation.none-available")}</p>
							: <SelectBox
								className={dialogStyles["dialog-dropdown"]}
								items={newStipulationDataSource}
								displayExpr={"description"}
								onValueChange={(x): void => setAddStipulationCode(x.code)}
								placeholder={Translate("premium-stipulations.add-stipulation.code-placeholder")}
								readOnly={false}
								dropDownOptions={{ wrapperAttr: { class: dialogStyles["dialog-popup"] } }}
								onDisposing={(): void => setAddStipulationCode(undefined)}
							/>}
						{!typeDataSource
							? <div />
							: <SelectBox
								className={dialogStyles["dialog-dropdown"]}
								items={typeDataSource}
								displayExpr={"display"}
								onValueChange={(x): void => setAddStipulationType(x.value)}
								placeholder={Translate("premium-stipulations.add-stipulation.type-placeholder")}
								readOnly={false}
								dropDownOptions={{ wrapperAttr: { class: dialogStyles["dialog-popup"] } }}
								onDisposing={(): void => setAddStipulationType(undefined)}
							/>}

					</>
				</Dialog> : null}
		</div>);
};

export default memo(PremiumStipulationsComponent);