import { DestinationDto } from "../apis/DestinationsApi";
import { Translate } from "../utils/Translation";

type OccupancyField = "mainGroupCaption" | "clusterCaption" | "segmentCaption" | "destinationCaption";

export type Option = {
	readonly caption: string;
	readonly translation: string;
};

class BaseElement {
	option: Option;

	constructor(caption: string) {
		this.option = {
			caption,
			translation: Translate(caption),
		};
	}

	options(): Option[] {
		return [ this.option ];
	}
}

export function distinctSorted(options: Option[]): Option[] {
	const map = new Map<string, Option>();
	return options.filter(x => {
		if (map.get(x.caption))
			return false;
		map.set(x.caption, x);
		return true;
	}).sort((a, b) => a.translation.localeCompare(b.translation));
}

class Element<ChildElement extends BaseElement> extends BaseElement {
	map: Map<string, ChildElement>;
	ctr: (new (caption: string) => ChildElement);
	field: OccupancyField;

	constructor(caption: string, ctr: (new (caption: string) => ChildElement), field: OccupancyField) {
		super(caption);
		this.map = new Map();
		this.ctr = ctr;
		this.field = field;
	}

	getOrCreate(caption: string | null | undefined): ChildElement {
		const cleanedCaption = caption ? caption : "";
		let t = this.map.get(cleanedCaption);
		if (t)
			return t;
		t = new this.ctr(cleanedCaption);
		this.map.set(cleanedCaption, t);
		return t;
	}

	addChild(occupancy: DestinationDto): ChildElement {
		return this.getOrCreate(occupancy[this.field]);
	}

	get(caption: string): ChildElement {
		const child = this.map.get(caption);
		if (!child)
			throw new Error(`Key not found: ${caption}`);
		return child;
	}

	options(): Option[] {
		return Array.from(this.map.values()).map(x => x.option);
	}

	values(): ChildElement[] {
		return Array.from(this.map.values());
	}
}

export class Occupancy extends BaseElement {
	occupancy?: DestinationDto;

	setOccupancy(occupancy: DestinationDto): void {
		this.occupancy = occupancy;
	}
}

export class Segment extends Element<Occupancy> {
	constructor(caption: string) {
		super(caption, Occupancy, "destinationCaption");
	}
	add(occupancy: DestinationDto): void {
		super.addChild(occupancy).setOccupancy(occupancy);
	}
	occupancies(): Occupancy[] {
		return this.values();
	}
}

export class Cluster extends Element<Segment> {

	constructor(caption: string) {
		super(caption, Segment, "segmentCaption");
	}
	add(occupancy: DestinationDto): void {
		super.addChild(occupancy).add(occupancy);
	}
	segments(): Segment[] {
		return this.values();
	}
}

export class MainGroup extends Element<Cluster> {
	constructor(caption: string) {
		super(caption, Cluster, "clusterCaption");
	}
	add(occupancy: DestinationDto): void {
		super.addChild(occupancy).add(occupancy);
	}
	clusters(): Cluster[] {
		return this.values();
	}
}

export class OccupancyHierarchy extends Element<MainGroup> {

	constructor(occupancies: DestinationDto[]) {
		super("", MainGroup, "mainGroupCaption");
		occupancies.forEach(occupancy => {
			this.getOrCreate(occupancy.mainGroupCaption).add(occupancy);
		});
	}

	mainGroups(): MainGroup[] {
		return this.values();
	}

	clusters(): Cluster[] {
		return this.mainGroups().flatMap(x => x.clusters());
	}

	segments(): Segment[] {
		return this.clusters().flatMap(x => x.segments());
	}

	mainGroupOptions(): Option[] {
		return distinctSorted(this.options());
	}

	clusterOptions(): Option[] {
		return distinctSorted(this.mainGroups().flatMap(x => x.options()));
	}

	segmentOptions(): Option[] {
		return distinctSorted(this.clusters().flatMap(x => x.options()));
	}

	occupancyOptions(): Option[] {
		return distinctSorted(this.segments().flatMap(x => x.options()));
	}

	getCluster(caption: string): Cluster {
		return this.mainGroups().filter(x => x.map.has(caption))[0].get(caption);
	}

	getSegment(caption: string): Segment {
		return this.clusters().filter(x => x.map.has(caption))[0].get(caption);
	}
}