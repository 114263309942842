import { Guid } from "../utils/Guid";
import { befurstApiClient as api } from "./BefurstApi";
export const addTagTypes = [
  "Addresses",
  "PremiumOutdated",
  "Relations",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      addressesGetAddress: build.query<
        AddressesGetAddressApiResponse,
        AddressesGetAddressApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Addresses/${queryArg.addressGuid}`,
        }),
        providesTags: ["Addresses"],
      }),
      addressesUpdateAddress: build.mutation<
        AddressesUpdateAddressApiResponse,
        AddressesUpdateAddressApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Addresses/${queryArg.addressGuid}`,
          method: "PUT",
          body: queryArg.updateAddressDto,
        }),
        invalidatesTags: ["PremiumOutdated", "Addresses"],
      }),
      addressesDeleteAddress: build.mutation<
        AddressesDeleteAddressApiResponse,
        AddressesDeleteAddressApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Addresses/${queryArg.addressGuid}`,
          method: "DELETE",
        }),
        invalidatesTags: ["PremiumOutdated", "Addresses"],
      }),
      addressesCreateAddress: build.mutation<
        AddressesCreateAddressApiResponse,
        AddressesCreateAddressApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Addresses`,
          method: "POST",
          body: queryArg.createAddressDto,
        }),
        invalidatesTags: ["Addresses"],
      }),
      relationsGetAddresses: build.query<
        RelationsGetAddressesApiResponse,
        RelationsGetAddressesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Relations/${queryArg.relationGuid}/addresses`,
        }),
        providesTags: ["Relations"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as enhancedApi };
export type AddressesGetAddressApiResponse = /** status 200 OK */ AddressDto;
export type AddressesGetAddressApiArg = {
  addressGuid: Guid;
};
export type AddressesUpdateAddressApiResponse = /** status 200 OK */ AddressDto;
export type AddressesUpdateAddressApiArg = {
  addressGuid: Guid;
  updateAddressDto: UpdateAddressDto;
};
export type AddressesDeleteAddressApiResponse =
  /** status 204 No Content */ void;
export type AddressesDeleteAddressApiArg = {
  addressGuid: Guid;
};
export type AddressesCreateAddressApiResponse = /** status 200 OK */ AddressDto;
export type AddressesCreateAddressApiArg = {
  createAddressDto: CreateAddressDto;
};
export type RelationsGetAddressesApiResponse =
  /** status 200 OK */ AddressDto[];
export type RelationsGetAddressesApiArg = {
  relationGuid: Guid;
};
export type AddressDto = {
  guid: Guid;
  relationGuid: Guid;
  streetName: string | null;
  houseNumber: number;
  houseLetter: string | null;
  poBoxNumber: number;
  postalCode: string | null;
  city: string | null;
  country: string | null;
  latitude: number;
  longitude: number;
};
export type CustomError = {
  status: number;
  key: string | null;
  message: string | null;
};
export type UpdateAddressDto = {
  streetName: string | null;
  houseNumber: number | null;
  houseLetter: string | null;
  poBoxNumber: number | null;
  postalCode: string | null;
  city: string | null;
  country: string | null;
  searchPostalCode: boolean;
  policyVersionGuid: Guid;
};
export type CreateAddressDto = {
  streetName: string | null;
  houseNumber: number | null;
  houseLetter: string | null;
  postalCode: string | null;
  poBoxNumber: number | null;
  city: string | null;
  country: string | null;
  latitude: number | null;
  longitude: number | null;
  policyVersionGuid: Guid;
};
export const {
  useAddressesGetAddressQuery,
  useLazyAddressesGetAddressQuery,
  useAddressesUpdateAddressMutation,
  useAddressesDeleteAddressMutation,
  useAddressesCreateAddressMutation,
  useRelationsGetAddressesQuery,
  useLazyRelationsGetAddressesQuery,
} = injectedRtkApi;
