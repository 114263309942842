import React, { memo, useCallback, useMemo } from "react";

import { PolicyVersionDto, SupplierDto, usePoliciesPatchSupplierMutation } from "../../../apis/PoliciesApi";
import { Translate } from "../../../utils/Translation";
import AddressComponent, { AddressType } from "./AddressComponent";
import DamageHistoryTableComponent from "./DamageHistoryTableComponent";
import OccupancyComponent, { OccupancyChange, OccupancyState } from "./OccupancyComponent";

type Props = {
	readonly policyVersion: PolicyVersionDto;
	readonly onFormChanged: () => Promise<void>;
	readonly supplier: SupplierDto;
};

const SupplierDetailComponent = ({ policyVersion, onFormChanged, supplier }: Props): JSX.Element => {

	const [triggerPatchSupplier] = usePoliciesPatchSupplierMutation();

	const addressComponent = useMemo(() => {
		return (
			<AddressComponent
				addressType={AddressType.Supplier}
				supplier={{
					guid: supplier.guid,
					addressGuid: supplier.addressGuid,
				}}
				onFormChanged={onFormChanged}
			/>
		);
	}, [onFormChanged, supplier]);

	const fetchOccupancy = useCallback((): OccupancyState => ({
		occupancyGuid: supplier.destinationGuid,
	}), [supplier.destinationGuid]);

	const updateOccupancy = useCallback(async (change: OccupancyChange): Promise<boolean> => {
		if (change.change !== "occupancy")
			return true;
		const update = await triggerPatchSupplier({
			policyGuid: policyVersion.policyGuid,
			supplierGuid: supplier.guid,
			patchSupplierDto: {
				addressGuid: null,
				occupancyGuid: change.newOccupancyGuid,
			},
		}).unwrap();
		return update.destinationGuid === change.newOccupancyGuid;
	}, [policyVersion.policyGuid, supplier.guid, triggerPatchSupplier]);

	const occupancyComponent = useMemo(() => {
		return (
			<OccupancyComponent
				fetchOccupancy={fetchOccupancy}
				onFormChanged={onFormChanged}
				updateOccupancy={updateOccupancy}
				mode="supplier"
				readOnly={false}
			/>
		);
	}, [fetchOccupancy, onFormChanged, updateOccupancy]);

	const damageComponent = useMemo(() => {
		return (
			<DamageHistoryTableComponent supplierGuid={supplier.guid} readOnly={false} />
		);
	}, [supplier.guid]);

	return (
		<>
			<div className="master-detail-caption master-detail-caption--form">
				{Translate("policy.suppliers-table-address.title")}:
			</div>
			{addressComponent}
			<div className="master-detail-caption master-detail-caption--form">
				{Translate("policy.suppliers-table-destination.title")}:
			</div>
			<div>
				{occupancyComponent}
			</div>
			<div className="master-detail-caption master-detail-caption--form">
				{Translate("policy.suppliers-table-damages.title")}:
			</div>
			<div>
				{damageComponent}
			</div>
		</>
	);

};

export default memo(SupplierDetailComponent);