import { Guid } from "../utils/Guid";
import { befurstApiClient as api } from "./BefurstApi";
export const addTagTypes = ["ReleaseNotes"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      releaseNotesFetchReleaseNotes: build.query<
        ReleaseNotesFetchReleaseNotesApiResponse,
        ReleaseNotesFetchReleaseNotesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/ReleaseNotes`,
          params: { language: queryArg.language },
        }),
        providesTags: ["ReleaseNotes"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as enhancedApi };
export type ReleaseNotesFetchReleaseNotesApiResponse =
  /** status 200 OK */ ReleaseNotesDto;
export type ReleaseNotesFetchReleaseNotesApiArg = {
  language?: string;
};
export type ReleaseNotesDto = {
  content: string | null;
};
export const {
  useReleaseNotesFetchReleaseNotesQuery,
  useLazyReleaseNotesFetchReleaseNotesQuery,
} = injectedRtkApi;
