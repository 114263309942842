import React from "react";

import { Button } from "devextreme-react";

import { HttpException } from "../../types/ErrorMessageFromAPI";
import CustomError from "../../utils/CustomError";
import { State } from "../../utils/error-boundary";
import { Translate } from "../../utils/Translation";

const ComponentErrorBoundary = (props: State & { readonly resetError: () => void }): JSX.Element => {
	const suffix = (
		<Button
			width={120}
			text={Translate("page.reload")}
			type="default"
			stylingMode="outlined"
			onClick={props.resetError}
		/>
	);
	if (props.exception && (props.exception as CustomError).userMessage) {
		return (
			<>
				<h2>{Translate("exception.title")}</h2>
				<p>{(props.exception as CustomError).userMessage}</p>
				{suffix}
			</>
		);
	}
	if (props.exception && (props.exception as HttpException).type) {
		return (
			<>
				<h2>{(props.exception as HttpException).title}</h2>
				<p>Status: {(props.exception as HttpException).status}</p>
				<p>Trace id: {(props.exception as HttpException).traceId}</p>
				{suffix}
			</>
		);
	}

	const jsonError = JSON.stringify(props.exception);
	const errorMessage = jsonError === "{}" ? String(props.exception) : jsonError;
	return (
		<>
			<p>{errorMessage}</p>
			{suffix}
		</>
	);
};

export default ComponentErrorBoundary;