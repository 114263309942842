import { Guid } from "../utils/Guid";
import { befurstApiClient as api } from "./BefurstApi";
export const addTagTypes = ["PolicyType"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      policyTypeGetPolicyTypeFields: build.query<
        PolicyTypeGetPolicyTypeFieldsApiResponse,
        PolicyTypeGetPolicyTypeFieldsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/policy-types/${queryArg["type"]}/fields`,
        }),
        providesTags: ["PolicyType"],
      }),
      policyTypeGetAllPolicyTypeFieldOptions: build.query<
        PolicyTypeGetAllPolicyTypeFieldOptionsApiResponse,
        PolicyTypeGetAllPolicyTypeFieldOptionsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/policy-types/${queryArg["type"]}/fields/options/all`,
        }),
        providesTags: ["PolicyType"],
      }),
      policyTypeGetPolicyTypeFieldOptions: build.query<
        PolicyTypeGetPolicyTypeFieldOptionsApiResponse,
        PolicyTypeGetPolicyTypeFieldOptionsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/policy-types/${queryArg["type"]}/fields/options/${queryArg.fieldGuid}`,
        }),
        providesTags: ["PolicyType"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as enhancedApi };
export type PolicyTypeGetPolicyTypeFieldsApiResponse =
  /** status 200 OK */ PolicyFieldDto[];
export type PolicyTypeGetPolicyTypeFieldsApiArg = {
  type: PolicyType;
};
export type PolicyTypeGetAllPolicyTypeFieldOptionsApiResponse =
  /** status 200 OK */ PolicyFieldOptionDto[];
export type PolicyTypeGetAllPolicyTypeFieldOptionsApiArg = {
  type: PolicyType;
};
export type PolicyTypeGetPolicyTypeFieldOptionsApiResponse =
  /** status 200 OK */ PolicyFieldOptionDto[];
export type PolicyTypeGetPolicyTypeFieldOptionsApiArg = {
  type: PolicyType;
  fieldGuid: Guid;
};
export type PolicyFieldDto = {
  guid: Guid;
  type: FieldType;
  key: string | null;
  policyType: PolicyType;
  caption: string | null;
  tooltipCaption: string | null;
  category: string | null;
  index: number;
  visibilityCondition: string | null;
  defaultValue: string | null;
  validation: string | null;
  databaseOption: string | null;
  subHeader: string | null;
};
export type PolicyFieldOptionDto = {
  guid: Guid;
  policyFieldGuid: Guid;
  key: string | null;
  caption: string | null;
  translate: boolean;
  index: number;
  value: string | null;
};
export enum FieldType {
  Number = "Number",
  Currency = "Currency",
  Boolean = "Boolean",
  String = "String",
  Date = "Date",
  Enum = "Enum",
  Percentage = "Percentage",
  Occupancy = "Occupancy",
}
export enum PolicyType {
  Fire = "Fire",
}
export const {
  usePolicyTypeGetPolicyTypeFieldsQuery,
  useLazyPolicyTypeGetPolicyTypeFieldsQuery,
  usePolicyTypeGetAllPolicyTypeFieldOptionsQuery,
  useLazyPolicyTypeGetAllPolicyTypeFieldOptionsQuery,
  usePolicyTypeGetPolicyTypeFieldOptionsQuery,
  useLazyPolicyTypeGetPolicyTypeFieldOptionsQuery,
} = injectedRtkApi;
