import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { CoverageChoiceDto, PolicyDto, PolicyFieldValueDto } from "../apis/PoliciesApi";
import { PolicyFieldOptionDto } from "../apis/PolicyTypeApi";
import { PreRiskQuestionDto } from "../apis/PreRiskQuestionsApi";
import { RootState } from "./store";

export type PreRiskData = {
	policy: PolicyDto;
	questions: PreRiskQuestionDto[];
	categories: string[];
	ceaCode: string | null;
	fieldValues: PolicyFieldValueDto[];
	fieldOptions: PolicyFieldOptionDto[];
	coverage: CoverageChoiceDto[];
};

export type PreRiskState = {
	data: PreRiskData | null;
};

const initialState: PreRiskState = {
	data: null,
};


export const preRiskStateSlice = createSlice({
	name: 'pre-risk',
	initialState,
	reducers: {
		setData: (state, action: PayloadAction<PreRiskData | null>) => {
			state.data = action.payload;
		},
	}
});

export const {
	setData,
} = preRiskStateSlice.actions;
export const preRiskStateReducer = preRiskStateSlice.reducer;

export const selectPreRiskData = (state: RootState): PreRiskState => state.preRiskState;

export default preRiskStateReducer;
