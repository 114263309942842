import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import { DataGrid } from "devextreme-react";
import { Column, Editing } from "devextreme-react/data-grid";
import { EditorPreparingEvent } from "devextreme/ui/data_grid";

import {
	CalculationReviewRequestDto, PolicyStatus, PremiumCalcReviewRequestReason, PremiumStage,
	 usePoliciesCreatePremiumDuringIndicationReviewMutation, usePoliciesFinishPolicyReviewMutation,
} from "../../../apis/PoliciesApi";
import { useReviewRequestsUpdateReviewRequestMutation } from "../../../apis/ReviewRequestsApi";
import { useAppSelector } from "../../../hooks/hooks";
import { selectStipulations } from "../../../store/StipulationsSlice";
import dialogButtonsStyles from "../../../styles/PremiumDialogButtons.module.scss";
import { parseMessage } from "../../../utils/commonFunctions";
import { Guid } from "../../../utils/Guid";
import { Translate } from "../../../utils/Translation";
import Button from "../../buttons/Button";
import ComponentTitle from "../../titles/ComponentTitle";
import PremiumDialogButtons from "../premium/PremiumDialogButtons";

type Props = {
	readonly reviewRequestRows: CalculationReviewRequestDto[];
	readonly policyGuid: Guid;
	readonly readonly: boolean;
	readonly premiumStage: PremiumStage;
	readonly premiumOutdated: boolean;
};

type ReviewRequestRow = {
	guid: Guid,
	description: string,
	positivelyReviewed: boolean,
	reviewNote: string,
	unacceptable: boolean
};

const ReviewRequestComponent = ({ reviewRequestRows, policyGuid, premiumStage, readonly, premiumOutdated }: Props): JSX.Element => {

	const [editors, setEditors] = useState<Map<Guid, any>>(new Map());

	const [triggeReviewRequestUpdate] = useReviewRequestsUpdateReviewRequestMutation();
	const [triggerFinishReview] = usePoliciesFinishPolicyReviewMutation();
	const [triggerCreatePremium] = usePoliciesCreatePremiumDuringIndicationReviewMutation();

	const { hasAnyUnacceptableStipulations } = useAppSelector(selectStipulations);

	const stageString = useMemo(() => premiumStage === PremiumStage.Indication ? "indication" : "quote", [premiumStage]);

	const tableItems = useMemo(() => {
		return reviewRequestRows.map(x => {
			let description = "";
			switch (x.reason) {
				case PremiumCalcReviewRequestReason.NoOccupancy:
					description = Translate("premium-calculation.review-request.no-occupancy-found");
					break;
				case PremiumCalcReviewRequestReason.NoOccupancyForMajorSupplier:
					description = Translate("premium-calculation.review-request.no-occupancy-for-major-supplier");
					break;
				case PremiumCalcReviewRequestReason.PreRiskAnswer:
					description = parseMessage("${", "}", Translate("premium-calculation.review-request.pre-risk-answer"),
						x.argument ? x.argument : "", ["QuestionName"]);
					break;
				case PremiumCalcReviewRequestReason.PremiumOnDemand:
					description = Translate("premium-calculation.review-request.premium-on-demand");
					break;
				case PremiumCalcReviewRequestReason.Remarks:
					description = parseMessage("${", "}", Translate("premium-calculation.review-request.remarks"),
						x.argument ? x.argument : "");
					break;
				case PremiumCalcReviewRequestReason.DamageFrequencyExceeded:
					description = parseMessage("${", "}", Translate("premium-calculation.review-request.damage-frequency-exceeded"),
						x.argument ? x.argument : "");
					break;
				case PremiumCalcReviewRequestReason.DamageAmountExceeded:
					description = parseMessage("${", "}", Translate("premium-calculation.review-request.damage-amount-exceeded"),
						x.argument ? x.argument : "");
					break;
				default:
					description = `Unknown reason: ${x.reason}`;
			}
			return {
				guid: x.guid,
				description,
				positivelyReviewed: x.positivelyReviewd,
				reviewNote: x.reviewNote,
				unacceptable: x.unacceptable
			} as ReviewRequestRow;
		});
	}, [reviewRequestRows]);

	useEffect(() => {
		tableItems.forEach(item => {
			const editor = editors.get(item.guid);
			if (editor) {
				// If an item is not yet checked, and there is no review not, we don't allow a state change.
				// (Note that for now we do allow removing the review note after checking the item)
				editor.option("disabled", (!item.positivelyReviewed && (item.reviewNote === null || item.reviewNote.length === 0))
					// Or the item is unacceptable: Unacceptable items can never be positively reviewed
					|| item.unacceptable);
			}
		});
	}, [tableItems, editors]);

	const handleEditorPeparing = useCallback((event: EditorPreparingEvent<ReviewRequestRow, any>): void => {
		// This method with editor state works well enough for cell editing and checkboxes, but doesn't work for cell editing and editors
		// that are created on the fly, like textboxes. See PremiumStipulationsComponent for an example that does work for all cell editing
		// and is honestly simpler.
		if (event.dataField === "positivelyReviewed") {
			event.editorOptions.onInitialized = function (args: any): void {
				setEditors((prev) => {
					const newEditors = new Map(prev);
					if (!event.row)
						return newEditors;
					newEditors.set(event.row.data.guid, args.component);
					return newEditors;
				});
			};
		}
	}, []);

	const handleRowUpdate = useCallback(async (eventInfo: any): Promise<void> => {
		const row = eventInfo.data as ReviewRequestRow;
		await triggeReviewRequestUpdate({
			reviewRequestGuid: row.guid,
			updatePremiumCalcReviewRequestDto: row
		});
	}, [triggeReviewRequestUpdate]);

	const handleFinishReview = useCallback(async (newPolicyStatus: PolicyStatus): Promise<void> => {
		await triggerFinishReview({
			policyGuid,
			newPolicyStatus
		});
	}, [policyGuid, triggerFinishReview]);

	return (
		<>
			<ComponentTitle text={Translate("premium-calculation.review-requests")} id={"review-requests"} />

			<PremiumDialogButtons
				acceptAction={(): Promise<void> => handleFinishReview(premiumStage === PremiumStage.Indication ?
					PolicyStatus.PremiumIndicationProvided :
					PolicyStatus.QuoteProvided)}
				rejectAction={(): Promise<void> => handleFinishReview(PolicyStatus.Rejected)}
				premiumStage={stageString}
				isReadonly={readonly || premiumOutdated}
				useRequestInfo
				requestInfoAction={(): Promise<void> => handleFinishReview(PolicyStatus.ProvideInformation)}
				acceptEnabled={tableItems.filter(x => !x.positivelyReviewed).length === 0 && !hasAnyUnacceptableStipulations}
			/>
			{tableItems && tableItems.length > 0 ?
				<DataGrid
					keyExpr="guid"
					dataSource={tableItems}
					showBorders
					columnAutoWidth
					columnHidingEnabled
					onRowUpdated={handleRowUpdate}
					onEditorPreparing={handleEditorPeparing}
					sorting={undefined}
				>
					<Editing
						mode="cell"
						allowUpdating={!readonly}
					/>
					<Column
						dataField="description"
						caption={Translate("premium-calculation.review-request.description")}
						allowEditing={false}
					/>
					<Column
						dataField="reviewNote"
						caption={Translate("premium-calculation.review-request.review-note")}
					/>
					<Column
						dataField="positivelyReviewed"
						caption={Translate("premium-calculation.review-request.accepted")}
					/>
				</DataGrid> : null}
			{/* For now the button is only really used when manual overwrite of premiums is done, so best to hide is otherwise */}
			{!readonly && premiumStage === PremiumStage.Indication && premiumOutdated ?
				<div className={dialogButtonsStyles["dialog-buttons"]}>
					<Button
						text={Translate("policy.form.recalculate-indication")}
						autoWidth
						onClick={async (): Promise<void> => {
							await triggerCreatePremium({ policyGuid });
						}}
					/>
				</div> : null}
		</>);
};

export default memo(ReviewRequestComponent);