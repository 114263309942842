import React, { memo, useMemo, useState } from "react";

import { useNavigate } from "react-router";

import dialogButtonsStyles from "../../../styles/PremiumDialogButtons.module.scss";
import { Translate } from "../../../utils/Translation";
import Button from "../../buttons/Button";
import Dialog from "../../dialog/Dialog";

type Props = {
	readonly acceptAction: () => Promise<void>;
	readonly requestInfoAction?: () => Promise<void>;
	readonly rejectAction?: () => Promise<void>;
	readonly useRequestInfo?: boolean;
	readonly useReject?: boolean;
	readonly isReadonly?: boolean;
	readonly premiumStage: string;
	readonly acceptEnabled?: boolean;
	readonly customLocalizeButton?: boolean;
	readonly redirect?: boolean;
};

const PremiumDialogButtons = ({ acceptAction, requestInfoAction, rejectAction, useReject = true, useRequestInfo = false,
	isReadonly = false, premiumStage, acceptEnabled = true, customLocalizeButton = false, redirect = true }: Props): JSX.Element => {

	const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
	const [requestInfoDialogOpen, setRequestInfoDialogOpen] = useState(false);
	const [acceptDialogOpen, setAcceptDialogOpen] = useState(false);

	const navigate = useNavigate();
	const localizeButtonString = useMemo(() => customLocalizeButton ? `.${premiumStage}` : "", [customLocalizeButton, premiumStage]);

	return (
		<>
			<div className={dialogButtonsStyles["dialog-buttons"]}>
				<div className={dialogButtonsStyles["dialog-button"]}>
					<Button
						text={Translate(`premium.dialog.accept${localizeButtonString}.button`)}
						type="normal"
						stylingMode="outlined"
						isDisabled={isReadonly || !acceptEnabled}
						className="blue"
						onClick={(): void => setAcceptDialogOpen(true)}
						autoWidth
					/>
				</div>
				{useRequestInfo && requestInfoAction ?
					<div className={dialogButtonsStyles["dialog-button"]}>
						<Button
							text={Translate(`premium.dialog.request-addditional-info${localizeButtonString}.button`)}
							type="normal"
							stylingMode="outlined"
							isDisabled={isReadonly}
							className="blue"
							onClick={(): void => setRequestInfoDialogOpen(true)}
							autoWidth
						/>
					</div> : null}
				{useReject && rejectAction ?
					<div className={dialogButtonsStyles["dialog-button"]}>
						<Button
							text={Translate(`premium.dialog.reject${localizeButtonString}.button`)}
							type="danger"
							stylingMode="outlined"
							isDisabled={isReadonly}
							onClick={(): void => setRejectDialogOpen(true)}
							autoWidth
						/>
					</div> : null}
			</div>

			<Dialog
				title={Translate(`premium.dialog.accept.${premiumStage}.title`)}
				subTitle={Translate("premium.dialog.subtitle")}
				buttonText={Translate(`premium.dialog.accept${localizeButtonString}.button`)}
				isVisible={acceptDialogOpen}
				cancelAction={(): void => setAcceptDialogOpen(false)}
				mainAction={async (): Promise<void> => {
					await acceptAction();
					setAcceptDialogOpen(false);
					if (redirect) {
						navigate(`/`);
					}
				}}
			>
				{null}
			</Dialog>
			{useRequestInfo && requestInfoAction ?
				<Dialog
					title={Translate(`premium.dialog.request-addditional-info.${premiumStage}.title`)}
					subTitle={Translate("premium.dialog.subtitle")}
					buttonText={Translate(`premium.dialog.request-addditional-info${localizeButtonString}.button`)}
					isVisible={requestInfoDialogOpen}
					cancelAction={(): void => setRequestInfoDialogOpen(false)}
					mainAction={async (): Promise<void> => {
						await requestInfoAction();
						setRequestInfoDialogOpen(false);
						if (redirect) {
							navigate(`/`);
						}
					}}
				>
					{null}
				</Dialog> : null}
			{useReject && rejectAction ?
				<Dialog
					title={Translate(`premium.dialog.reject.${premiumStage}.title`)}
					subTitle={Translate("premium.dialog.subtitle")}
					buttonText={Translate(`premium.dialog.reject${localizeButtonString}.button`)}
					isVisible={rejectDialogOpen}
					cancelAction={(): void => setRejectDialogOpen(false)}
					mainAction={async (): Promise<void> => {
						await rejectAction();
						setRejectDialogOpen(false);
						if (redirect) {
							navigate(`/`);
						}
					}}
					dialogStyle="danger"
				>
					{null}
				</Dialog> : null}

		</ >);
};

export default memo(PremiumDialogButtons);