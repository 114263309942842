// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { ReactNode } from "react";

import styles from "../../../styles/Policy.module.scss";
import { Translate } from "../../../utils/Translation";
import ComponentTitle from "../../titles/ComponentTitle";

type Props = {
	readonly captionKey: string;
	readonly id: string;
	readonly children: ReactNode;
};

// Base component that puts a sub-title around its child-content
const BaseComponent = ({ captionKey, id, children }: Props): JSX.Element => {
	return (
		<div className={styles["data"]}>
			<ComponentTitle text={Translate(captionKey)} id={id} />
			<div className={styles["data-form"]}>
				{children}
			</div>
		</div>
	);
};

export default BaseComponent;