import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "./store";

export type SupplierResult = {
	id: string;
	address: boolean;
	riskDestination: boolean;
};

export type PolicyValidationState = {
	mainDataResult: boolean;
	termsResult: boolean;
	policyholderAddressResult: boolean;
	correspondenceAddressResult: boolean;
	insuredAddressResult: boolean;
	riskDestinationResult: boolean;
	sumsInterestsResult: boolean;
	constructionNatureResult: boolean;
	preventionInstallationsResult: boolean;
	suppliersResults: SupplierResult[];
};

const initialState: PolicyValidationState = {
	mainDataResult: false,
	termsResult: false,
	policyholderAddressResult: false,
	correspondenceAddressResult: false,
	insuredAddressResult: false,
	riskDestinationResult: false,
	sumsInterestsResult: false,
	constructionNatureResult: false,
	preventionInstallationsResult: false,
	suppliersResults: [],
};

export const policyValidationSlice = createSlice({
	name: "policyValidation",
	initialState,
	reducers: {
		setMainDataResult: (state, action: PayloadAction<boolean>) => {
			state.mainDataResult = action.payload;
		},
		setTermsResult: (state, action: PayloadAction<boolean>) => {
			state.termsResult = action.payload;
		},
		setPolicyholderAddressResult: (state, action: PayloadAction<boolean>) => {
			state.policyholderAddressResult = action.payload;
		},
		setCorrespondenceAddressResult: (state, action: PayloadAction<boolean>) => {
			state.correspondenceAddressResult = action.payload;
		},
		setInsuredAddressResult: (state, action: PayloadAction<boolean>) => {
			state.insuredAddressResult = action.payload;
		},
		setRiskDestinationResult: (state, action: PayloadAction<boolean>) => {
			state.riskDestinationResult = action.payload;
		},
		setSumsInterestsResult: (state, action: PayloadAction<boolean>) => {
			state.sumsInterestsResult = action.payload;
		},
		setConstructionNatureResult: (state, action: PayloadAction<boolean>) => {
			state.constructionNatureResult = action.payload;
		},
		setPreventionInstallationsResult: (
			state,
			action: PayloadAction<boolean>
		) => {
			state.preventionInstallationsResult = action.payload;
		},
		setSupplierResult: (
			state,
			action: PayloadAction<{
				id: string;
				address?: boolean;
				riskDestination?: boolean;
			}>
		) => {
			const { id, address, riskDestination } = action.payload;

			const i = state.suppliersResults.findIndex((r) => r.id === id);

			if (i === -1) {
				state.suppliersResults.push({
					id,
					address: address !== undefined ? address : false,
					riskDestination:
						riskDestination !== undefined ? riskDestination : false,
				});
			} else {
				state.suppliersResults[i] = {
					id,
					address:
						address !== undefined ? address : state.suppliersResults[i].address,
					riskDestination:
						riskDestination !== undefined
							? riskDestination
							: state.suppliersResults[i].riskDestination,
				};
			}
		},
		removeSupplierResult: (state, action: PayloadAction<string>) => {
			state.suppliersResults = state.suppliersResults.filter(
				(s) => s.id !== action.payload
			);
		},
	},
});

export const {
	setMainDataResult,
	setTermsResult,
	setPolicyholderAddressResult,
	setCorrespondenceAddressResult,
	setInsuredAddressResult,
	setRiskDestinationResult,
	setSumsInterestsResult,
	setConstructionNatureResult,
	setPreventionInstallationsResult,
	setSupplierResult,
	removeSupplierResult,
} = policyValidationSlice.actions;
export const policyValidationReducer = policyValidationSlice.reducer;

export const selectPolicyValidation = (
	state: RootState
): PolicyValidationState => state.policyValidation;

export default policyValidationReducer;
