import React, { createContext, ReactNode, useContext } from "react";

import { withAITracking } from "@microsoft/applicationinsights-react-js";

import ComponentErrorBoundary from "../components/error-boundary/ComponentErrorBoundary";
import FullPageErrorBoundary from "../components/error-boundary/FullPageErrorBoundary";
import { HttpException } from "../types/ErrorMessageFromAPI";
import { SecurityLevels } from "../types/SecurityLevels";
import TrackException from "../utils/TrackException";
import { reactPlugin } from "./appInsights";
import CustomError from "./CustomError";

/**
 * A simple react boundry for gracefully handling page/render errors.
 */

export type Props = {
	readonly children: ReactNode;
	readonly showFullPage?: boolean;
};

export type State = {
	hasError: boolean;
	exception: Error | HttpException | CustomError | null;
};

// https://felixgerschau.com/react-typescript-context/
type ErrorContext = {
	triggerError: (error: Error) => void;
};

const defaultState = {
	triggerError: (): void => {
		// intentionally left empty
	},
};

const ErrorBoundaryContext = createContext<ErrorContext>(defaultState);
const useErrorHandling = (): ErrorContext => useContext(ErrorBoundaryContext);

class ErrorBoundary extends React.Component<Props, State, Record<string, unknown>> {
	constructor(props: Props) {
		super(props);
		this.state = { ...props, hasError: false, exception: null };

		withAITracking(reactPlugin, ErrorBoundary);

		this.triggerError = this.triggerError.bind(this);
		this.resetError = this.resetError.bind(this);
	}

	static getDerivedStateFromError(): State {
		return { hasError: true, exception: null };
	}

	componentDidUpdate(previousProps: Props): void {
		if (previousProps.children !== this.props.children) {
			if (this.state.hasError) {
				const error = this.state.exception as Error;
				// This is a super-hacky solution, but the custom validation form in the supplier table kept crashing when adding a new row.
				// This catches the error and refreshes the control automatically when that particular exception occurs.
				// Potential clean up: have a max nr of refreshes on this so we don't get into an infinite loop of refreshes if for some
				// reason the error would keep occuring (which I haven't seen happen, so probably not required)
				if (error && error.message.includes("_updateBrokenRules")) {
					this.setState({hasError: false});
				}
			}
		}
	}

	componentDidCatch(error: Error | CustomError | HttpException): void {

		if ((error as CustomError).component) TrackException(error as CustomError);
		else {

			const customError = new CustomError(
				JSON.stringify(error),
				"An error occurred.",
				SecurityLevels.Error,
				ErrorBoundary.name,
				"componentDidCatch"
			);

			TrackException(customError);
		}

		this.setState({ hasError: true, exception: error });
	}

	triggerError(error: Error): void {
		this.componentDidCatch(error);
		this.setState({ hasError: true, exception: error });
	}

	resetError(): void {
		this.setState({ hasError: false });
	}

	errorMessage(): JSX.Element {
		if (this.props.showFullPage)
			return <FullPageErrorBoundary {...this.state} resetError={this.resetError} />;
		else
			return <ComponentErrorBoundary {...this.state} resetError={this.resetError} />;
	}

	render(): JSX.Element {
		const triggerError = this.triggerError;

		return (
			<ErrorBoundaryContext.Provider value={{ triggerError }}>
				{this.state.hasError ? this.errorMessage() : this.props.children}
			</ErrorBoundaryContext.Provider>
		);
	}
}

export { ErrorBoundary, useErrorHandling };
