/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import { CheckBox, NumberBox, Toast, Validator } from "devextreme-react";
import { RangeRule } from "devextreme-react/form";
import { ValueChangedEvent } from "devextreme/ui/number_box";
import { useDispatch } from "react-redux";

import {
	InspectionState, useInspectionsFillScoreMutation, useInspectionsFlowFromContractChoiceRequiredMutation,
	useInspectionsFlowFromFillScoreMutation, useInspectionsFlowFromInspectionToBeDeliveredMutation,
	useInspectionsFlowFromRequestInspectionMutation, useInspectionsFlowFromScheduleInspectionMutation,
	useInspectionsSetThreeYearlyContractMutation,
} from "../../../apis/InspectionsApi";

import { PhysicalInspectionType, PolicyDto, PolicyVersionDto, usePoliciesGetInspectionByPolicyQuery,
	usePoliciesHasLatestOutdatedPremiumQuery } from "../../../apis/PoliciesApi";

import { useAppSelector } from "../../../hooks/hooks";
import { selectIdentity } from "../../../store/Identity";
import { setAcceptantCanEditStipulationsFromInspection } from "../../../store/StipulationsSlice";
import styles from "../../../styles/Inspection.module.scss";
import { canWriteInspectionReview, canWritePolicy } from "../../../utils/PolicyUtil";
import { Translate } from "../../../utils/Translation";
import FishAndUboComponent from "../FishAndUboComponent";
import PremiumDialogButtons from "../premium/PremiumDialogButtons";

type Props = {
	readonly policy: PolicyDto;
	readonly policyVersion: PolicyVersionDto;
};

const MIN_RRS_SCORE = 0;
const MAX_RRS_SCORE = 10;

const PhysicalInspectionComponent = ({ policy, policyVersion }: Props): JSX.Element => {

	const { username, permissions } = useAppSelector(selectIdentity);

	const userCanWritePolicy = useMemo(() => canWritePolicy(username, permissions, policy), [policy, permissions, username]);
	const isInspectionReviewer = useMemo(() => canWriteInspectionReview(permissions), [permissions]);

	const dispatch = useDispatch();

	const [usesThreeYearlyContract, setUsesThreeYearlyContract] = useState(false);
	const [savingFailed, setSavingFailed] = useState(false);
	const [rrsScore, setRrsScore] = useState<number | undefined>();

	const [triggerFlowFromRequestInspection] = useInspectionsFlowFromRequestInspectionMutation();
	const [triggerFlowFromInspectionToBeDelivered] = useInspectionsFlowFromInspectionToBeDeliveredMutation();
	const [triggerFlowFromContractChoiceRequired] = useInspectionsFlowFromContractChoiceRequiredMutation();
	const [triggerFlowFromScheduleInspection] = useInspectionsFlowFromScheduleInspectionMutation();
	const [triggerFillScore] = useInspectionsFillScoreMutation();
	const [triggerFlowFromFillScore] = useInspectionsFlowFromFillScoreMutation();

	const [triggerSetUsesThreeYearlyContract] = useInspectionsSetThreeYearlyContractMutation();

	const {
		data: inspection,
		error: errorInspection,
	} = usePoliciesGetInspectionByPolicyQuery({ policyGuid: policy.guid });
	const { data: outdated, error: outdatedError } = usePoliciesHasLatestOutdatedPremiumQuery({ policyGuid: policy.guid });

	const inspectionString = useMemo(() => {
		if (!inspection)
			return "";
		return inspection.type === PhysicalInspectionType.FullInspection ?
			Translate("policy.inspection.full-inspection") :
			Translate("policy.inspection.quick-scan");
	}, [inspection]);

	if (errorInspection)
		throw new Error(`Error fetching inspection ${errorInspection}`);
	if (outdatedError)
		throw new Error(`Error fetching outdated premium ${outdatedError}`);

	const handleFlowFromRequestInspection = useCallback(async (newState: InspectionState): Promise<void> => {
		await triggerFlowFromRequestInspection({
			inspectionGuid: inspection!.guid,
			newState
		});
	}, [inspection, triggerFlowFromRequestInspection]);

	const handleFlowFromInspectionToBeDelivered = useCallback(async (newState: InspectionState): Promise<void> => {
		await triggerFlowFromInspectionToBeDelivered({
			inspectionGuid: inspection!.guid,
			newState
		});
	}, [inspection, triggerFlowFromInspectionToBeDelivered]);

	const handleFlowFromContractChoiceRequired = useCallback(async (newState: InspectionState): Promise<void> => {
		await triggerFlowFromContractChoiceRequired({
			inspectionGuid: inspection!.guid,
			newState
		});
	}, [inspection, triggerFlowFromContractChoiceRequired]);

	useEffect(() => {
		if (policyVersion && policyVersion.usesThreeYearlyContract !== undefined)
			setUsesThreeYearlyContract(policyVersion.usesThreeYearlyContract);
	}, [policyVersion]);

	const handleSetThreeYearlyContract = useCallback((e: boolean | null): void => {
		// This won't happen since we're not using three state mode, but typescript doesn't know
		if (e === null)
			return;
		const handleSetThreeYearlyContractAsync = async (): Promise<void> => {
			try {
				await triggerSetUsesThreeYearlyContract({
					inspectionGuid: inspection!.guid,
					usesThreeYearlyContract: e
				}).unwrap();
				setUsesThreeYearlyContract(e);
			} catch (error) {
				setSavingFailed(true);
			}
		};
		handleSetThreeYearlyContractAsync();
	}, [inspection, triggerSetUsesThreeYearlyContract]);

	const handleFlowFromScheduleInspection = useCallback(async (newState: InspectionState): Promise<void> => {
		await triggerFlowFromScheduleInspection({
			inspectionGuid: inspection!.guid,
			newState
		});
	}, [inspection, triggerFlowFromScheduleInspection]);

	const handleFlowFromFillScore = useCallback(async (newState: InspectionState): Promise<void> => {
		dispatch(setAcceptantCanEditStipulationsFromInspection(false));
		await triggerFlowFromFillScore({
			inspectionGuid: inspection!.guid,
			newState
		});
	}, [inspection, triggerFlowFromFillScore, dispatch]);

	useEffect(() => {
		if (inspection)
			setRrsScore(inspection.rrsScore);
	}, [inspection]);

	const handleFillScore = useCallback(async (e: ValueChangedEvent): Promise<void> => {
		try {
			setRrsScore(e.value);
			await triggerFillScore({
				inspectionGuid: inspection!.guid,
				score: e.value
			}).unwrap();
		} catch (error) {
			setSavingFailed(true);
		}

	}, [inspection, triggerFillScore]);

	const component = useMemo(() => {

		const defaultComponent = <p>{Translate("policy.inspection.inspection-required", inspectionString)}</p>;
		if (!inspection)
			return defaultComponent;

		if (userCanWritePolicy) {
			switch (inspection.state) {
				case InspectionState.RequestInspection:
					return (
						<>
							<FishAndUboComponent policy={policy} />
							<p>{Translate("policy.inspection.request-inspection.int", inspectionString)}</p>
							{/* Using premium dialog for inspection as well: Bit of a misnomer and somewhat confusing caption names, 
							but seems okay enough */}
							<PremiumDialogButtons
								premiumStage="request-inspection"
								acceptAction={(): Promise<void> =>
									handleFlowFromRequestInspection(InspectionState.InspectionToBeDelivered)}
								useReject={false}
								useRequestInfo
								// using requestInfo for secondary action since neither action should be registered as 'danger'
								requestInfoAction={(): Promise<void> =>
									handleFlowFromRequestInspection(InspectionState.ContractChoiceRequired)}
								customLocalizeButton
								redirect={false}
								isReadonly={!policy.fishAccepted || !policy.uboAccepted}
							/>
						</>
					);
				case InspectionState.InspectionToBeDelivered:
					return (
						<p>
							<span>{Translate("policy.inspection.inspection-to-be-delivered.int1")}</span>
							<a href={`mailto:${Translate("policy.inspection.inspection-to-be-delivered.mail")}`}>
								{Translate("policy.inspection.inspection-to-be-delivered.mail")}
							</a>
							<span>{Translate("policy.inspection.inspection-to-be-delivered.int2", policy.policyNumber || "")}</span>
						</p>);
				case InspectionState.ContractChoiceRequired:
					return (
						<>
							<p>{Translate("policy.inspection.contract-choice-required.int", inspectionString)}</p>
							<div className={styles["three-yearly-contract-wrapper"]}>
								<p>{Translate("policy.inspection.contract-choice-required.three-yearly-contract")}</p>
								<CheckBox
									value={usesThreeYearlyContract}
									onValueChange={handleSetThreeYearlyContract}
								/>
							</div>
							<PremiumDialogButtons
								premiumStage="contract-choice-required"
								acceptAction={(): Promise<void> =>
									handleFlowFromContractChoiceRequired(InspectionState.ScheduleInspection)}
								rejectAction={(): Promise<void> =>
									handleFlowFromContractChoiceRequired(InspectionState.Rejected)}
								customLocalizeButton
								redirect={false}
							/>
						</>
					);
				case InspectionState.ScheduleInspection:
					return <p>{Translate("policy.inspection.schedule-inspection.int", inspectionString)}</p>;
				case InspectionState.FillScore:
					return <p>{Translate("policy.inspection.fill-score.int")}</p>;
				default:
					return defaultComponent;
			}
		} else if (isInspectionReviewer) {
			switch (inspection.state) {
				case InspectionState.InspectionToBeDelivered:
					return (
						<>
							<p>{Translate("policy.inspection.inspection-to-be-delivered.acc", inspectionString)}</p>
							<PremiumDialogButtons
								premiumStage="inspection-to-be-delivered"
								acceptAction={(): Promise<void> =>
									handleFlowFromInspectionToBeDelivered(InspectionState.FillScore)}
								rejectAction={(): Promise<void> =>
									handleFlowFromInspectionToBeDelivered(InspectionState.Rejected)}
								useRequestInfo
								requestInfoAction={(): Promise<void> =>
									handleFlowFromInspectionToBeDelivered(InspectionState.ContractChoiceRequired)}
								customLocalizeButton
								redirect={false}
							/>
						</>
					);
				case InspectionState.ContractChoiceRequired:
					return <p>{Translate("policy.inspection.contract-choice-required.acc", inspectionString)}</p>;
				case InspectionState.ScheduleInspection:
					return (
						<>
							<p>{Translate("policy.inspection.schedule-inspection.acc", inspectionString)}
								{policyVersion.usesThreeYearlyContract ?
									Translate("policy.inspection.schedule-inspection.acc.three-yearly-contract") :
									Translate("policy.inspection.schedule-inspection.acc.no-three-yearly-contract")}
							</p>
							<PremiumDialogButtons
								// we can reuse these captions since they are the same for this state
								premiumStage="inspection-to-be-delivered"
								acceptAction={(): Promise<void> =>
									handleFlowFromScheduleInspection(InspectionState.FillScore)}
								rejectAction={(): Promise<void> =>
									handleFlowFromScheduleInspection(InspectionState.Rejected)}
								customLocalizeButton
								redirect={false}
							/>
						</>
					);
				case InspectionState.FillScore:
					dispatch(setAcceptantCanEditStipulationsFromInspection(true));
					return (
						<>
							<p>{Translate("policy.inspection.fill-score.acc")}</p>
							<NumberBox
								format="#0.00"
								step={0.1}
								onValueChanged={handleFillScore}
								value={rrsScore}
								label={Translate("policy.inspection.fill-score.rrs-score")}
							>
								<Validator validationGroup="rrs-score">
									<RangeRule
										min={MIN_RRS_SCORE}
										max={MAX_RRS_SCORE}
										message={Translate("policy.inspection.fill-score.out-of-range",
											MIN_RRS_SCORE.toString(),
											MAX_RRS_SCORE.toString())}
									/>
								</Validator>
							</NumberBox>
							<PremiumDialogButtons
								premiumStage="fill-score"
								acceptAction={(): Promise<void> =>
									handleFlowFromFillScore(InspectionState.Accepted)}
								rejectAction={(): Promise<void> =>
									handleFlowFromFillScore(InspectionState.Rejected)}
								customLocalizeButton
								redirect={false}
								acceptEnabled={!outdated}
							/>
						</>
					);
				default:
					return defaultComponent;
			}
		}
		return defaultComponent;
	}, [inspectionString, inspection, userCanWritePolicy, isInspectionReviewer, policy, handleSetThreeYearlyContract,
		handleFlowFromRequestInspection, handleFlowFromContractChoiceRequired, handleFlowFromInspectionToBeDelivered,
		usesThreeYearlyContract, handleFlowFromScheduleInspection, handleFillScore, rrsScore, dispatch, handleFlowFromFillScore,
		outdated, policyVersion]);

	return (
		<div>
			{component}
			<Toast
				visible={savingFailed}
				message={Translate("policy.form.failure.saving")}
				type="error"
				onHiding={(): void => setSavingFailed(false)}
				closeOnClick
				displayTime={10000}
			/>
		</div>
	);
};

export default memo(PhysicalInspectionComponent);