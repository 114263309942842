import { FieldValueRetrieval } from "../models/policies/FieldValueRetrieval";
import { AddNative, Evaluate as baseEval, EvaluatorInitFunc } from "./BaseExpressionEvaluator";

export class SimpleExpressionEvaluator {
    // eslint-disable-next-line class-methods-use-this
    public Evaluate(condition: string, fieldRetrieval: FieldValueRetrieval, initFunc?: EvaluatorInitFunc): boolean {
        return baseEval(condition, (interpreter: any, globalObject: any): void => {
            // Natives based on arguments
            AddNative(interpreter, globalObject, "getPolicyField", (option: string): string => {
                const value = fieldRetrieval(option);
                if (!value)
                    return "";
                return value;
            });

            // Any custom provided natives
            if (initFunc !== undefined) {
                initFunc(interpreter, globalObject);
            }
        });
    }
}
