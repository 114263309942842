import { Guid } from "../utils/Guid";
import { befurstApiClient as api } from "./BefurstApi";
export const addTagTypes = ["Translations"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      translationsGetTranslations: build.query<
        TranslationsGetTranslationsApiResponse,
        TranslationsGetTranslationsApiArg
      >({
        query: () => ({ url: `/api/Translations` }),
        providesTags: ["Translations"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as enhancedApi };
export type TranslationsGetTranslationsApiResponse =
  /** status 200 OK */ TranslationDto[];
export type TranslationsGetTranslationsApiArg = void;
export type TranslationDto = {
  language: string | null;
  code: string | null;
  value: string | null;
};
export const {
  useTranslationsGetTranslationsQuery,
  useLazyTranslationsGetTranslationsQuery,
} = injectedRtkApi;
