import React, { memo, useCallback, useState } from "react";

import { TextArea } from "devextreme-react";
import { ValueChangedEvent } from "devextreme/ui/text_area";

import { usePremiumUpdateFreeFieldStipulationsMutation } from "../../../apis/PremiumApi";
import { Guid } from "../../../utils/Guid";
import { Translate } from "../../../utils/Translation";

type Props = {
	readonly premiumGuid: Guid;
	readonly freeFieldStipulations: string | undefined;
	readonly readonly: boolean;
};

const FreeFieldStipulationsComponent = ({ premiumGuid, freeFieldStipulations, readonly }: Props): JSX.Element => {
	const [stipulations, setStipulations] = useState(freeFieldStipulations);
	const [triggerChange] = usePremiumUpdateFreeFieldStipulationsMutation();

	const handleChange = useCallback(async (e: ValueChangedEvent): Promise<void> => {
		setStipulations(e.value);
		await triggerChange({
			premiumGuid,
			freeFieldStipulations: e.value
		}).unwrap();
	}, [premiumGuid, triggerChange]);

	return <TextArea
		value={stipulations}
		label={Translate("premium-stipulations.extra-stipulations")}
		onValueChanged={handleChange}
		readOnly={readonly}
		height={300}
		width={600}
	/>;
};

export default memo(FreeFieldStipulationsComponent);