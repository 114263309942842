import React from "react";

import { EmailRule, RequiredRule, SimpleItem } from "devextreme-react/form";

import { Translate } from "../../../utils/Translation";
import FieldInfo from "./FieldInfo";

export type StyleOptions = {
	marginTop?: boolean;
};

export default class FieldItem {
	prefix: string;
	field: FieldInfo;

	constructor(
		prefix: string,
		field: FieldInfo,
	) {
		this.prefix = prefix;
		this.field = field;
	}

	public getElement(): JSX.Element {
		return (
			<SimpleItem
				key={`${this.prefix}-${this.field.name}`}
				dataField={this.field.name}
				editorOptions={{ readOnly: this.field.readOnly }}
				label={{ text: Translate(`policy.form.${this.field.name}`), alignment: "left" }}
			>
				{this.field.isRequired ? <RequiredRule message={Translate(`policy.form.${this.field.name}.required`)} /> : null}
				{this.field.isEmail ? <EmailRule message={Translate(`policy.form.${this.field.name}.invalid`)} /> : null}
			</SimpleItem>
		);
	}
}
