import React, { useCallback, useEffect, useMemo, useState } from "react";

import { LoadPanel, ValidationSummary } from "devextreme-react";
import notify from "devextreme/ui/notify";
import validationEngine from "devextreme/ui/validation_engine";
import { useParams } from "react-router";

import {
  PolicyStatus, UpdatePolicyDto, usePoliciesGetCurrentPolicyVersionQuery, usePoliciesGetPolicyQuery, usePoliciesHasLatestPremiumQuery,
  usePoliciesUpdatePolicyMutation,
} from "../../apis/PoliciesApi";
import { useRelationsGetRelationQuery } from "../../apis/RelationsApi";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { selectIdentity } from "../../store/Identity";
import { selectPolicyPage, setData, setPolicyEmail, setPolicyNameInsured } from "../../store/PolicyPageState";
import styles from "../../styles/Policy.module.scss";
import { emptyGuid, Guid } from "../../utils/Guid";
import { canReadAllFullPremiums, canTransferOwnership, POLICY_VALIDATION_GROUP, policyStatusesDesc } from "../../utils/PolicyUtil";
import { TrackWarning } from "../../utils/TrackException";
import { Translate } from "../../utils/Translation";
import PageTitle from "../titles/PageTitle";
import FullCalculation from "./FullCalculation";
import InspectionComponent from "./inspection/InspectionComponent";
import BuildingPropertiesComponent from "./policy-page-components/BuildingPropertiesComponent";
import ConstructionNatureComponent from "./policy-page-components/ConstructionNatureComponent";
import CorrespondenceAddressComponent from "./policy-page-components/CorrespondenceAddressComponent";
import CoverageComponent from "./policy-page-components/CoverageComponent";
import DamageHistoryComponent from "./policy-page-components/DamageHistoryComponent";
import DeductibleComponent from "./policy-page-components/DeductibleComponent";
import InsuredAddressComponent from "./policy-page-components/InsuredAddressComponent";
import MainDataComponent from "./policy-page-components/MainDataComponent";
import PolicyHolderAddressComponent from "./policy-page-components/PolicyHolderAddressComponent";
import PreventionInstallationsComponent from "./policy-page-components/PreventionInstallationsComponent";
import ProvisionComponent from "./policy-page-components/ProvisionComponent";
import RequestPremiumIndicationComponent from "./policy-page-components/RequestPremiumIndicationComponent";
import RiskDestinationComponent from "./policy-page-components/RiskDestinationComponent";
import SumsAndInterestsComponent from "./policy-page-components/SumsAndInterestsComponent";
import TermsComponent from "./policy-page-components/TermsComponent";
import TransferOwnershipComponent from "./policy-page-components/TransferOwnershipComponent";
import PolicyNavigation from "./PolicyNavigation";
import PremiumWidget from "./premium/PremiumWidget";

const Policy = (): JSX.Element => {

	const params = useParams<{ guid: Guid }>();
	const dispatch = useAppDispatch();
	const { username, permissions } = useAppSelector(selectIdentity);
	const { data } = useAppSelector(selectPolicyPage);
	const policyGuid = params.guid;

	const {
		data: policyData,
		error: errorPolicyData,
	} = usePoliciesGetPolicyQuery({ policyGuid: policyGuid ? policyGuid : emptyGuid() }, { skip: !policyGuid });
	const {
		data: relationData,
		error: errorRelationData,
	} = useRelationsGetRelationQuery({ relationGuid: policyData ? policyData.relationGuid : emptyGuid() }, { skip: !policyData });
	const {
		data: currentPolicyVersionData,
		error: errorCurrentPolicyVersionData,
	} = usePoliciesGetCurrentPolicyVersionQuery({ policyGuid: policyGuid ? policyGuid : emptyGuid() }, { skip: !policyGuid });

	if (errorPolicyData)
		throw new Error(`Error fetching policy data ${errorPolicyData}`);
	if (errorRelationData)
		throw new Error(`Error fetching relation data ${errorRelationData}`);
	if (errorCurrentPolicyVersionData)
		throw new Error(`Error fetching policy version data ${errorCurrentPolicyVersionData}`);

	useEffect(() => {
		if (!policyData || !currentPolicyVersionData || !relationData)
			return;

		dispatch(setData({
			policy: policyData,
			policyVersion: currentPolicyVersionData,
			relation: relationData,
		}));
	}, [dispatch, policyData, currentPolicyVersionData, relationData, policyGuid]);

	const translatedStatus = useMemo(() => {
		if (data) {
			const translationCaption = policyStatusesDesc.get(data.policy.status);
			if (translationCaption) {
				return Translate(translationCaption);
			}
		}
		return "Uknown status";
	}, [data]);

	const {
		data: hasLatestPremium,
		error: errorHasLatestPremium,
	} = usePoliciesHasLatestPremiumQuery({ policyGuid: policyGuid ? policyGuid : emptyGuid() }, { skip: !policyGuid });

	if (errorHasLatestPremium)
		throw new Error(`Error fetching has latest premium: ${errorHasLatestPremium}`);

	const [triggerPolicyUpdate] = usePoliciesUpdatePolicyMutation();
	const [sendingRequest, setSendingRequest] = useState(false);
	const [isFormValid, setIsFormValid] = useState<boolean | undefined>(undefined);

	const updatePolicyMainData = useCallback(async (update: UpdatePolicyDto): Promise<boolean> => {
		if (!policyGuid || !data)
			return false;
		if (update.nameInsured && update.nameInsured !== data.policy.nameInsured) {
			dispatch(setPolicyNameInsured(update.nameInsured));
		}
		if (update.email && update.nameInsured !== data.policy.nameInsured) {
			dispatch(setPolicyEmail(update.email));
		}
		await triggerPolicyUpdate({
			policyGuid,
			updatePolicyDto: update
		});
		return true;
	}, [dispatch, policyGuid, triggerPolicyUpdate, data]);

	const validate = useCallback((): Promise<void> => {
		try {
			const validationResult = validationEngine.validateGroup(POLICY_VALIDATION_GROUP);
			setIsFormValid(validationResult.isValid);
		} catch (error) {
			TrackWarning(`Error validating policy ${error}`, "Policy", "validate");
			notify({
				message: Translate("error.policy-validation.failed", `${error}`),
				width: 800,
				position: {
					at: "bottom",
					my: "bottom",
					of: "#premium-indication"
				}
			},
				"error",
				10000);
		}
		return Promise.resolve();
	}, []);

	const mainDataComponent = useMemo(() => {
		return (
			<MainDataComponent
				updatePolicyMainData={updatePolicyMainData}
				onFormChanged={validate}
			/>);
	}, [updatePolicyMainData, validate]);

	if (!data || data.policy.guid !== policyGuid)
		return (<div>{Translate("policy.form.loading")}</div>);

	return (
		<div className={styles["page"]}>
			<div className={styles["title-wrapper"]}>
				<div>
					<PageTitle
						text={`${Translate("policy.form.policyNumber")}: ${data.policy.policyNumber}`}
					/>
					<div className={styles["title-extra-info"]}>
						<p className={styles["title-owner"]}>{`${Translate("policy.form.owner")}: ${data.policy.ownerName}`}</p>
						<p>{`${Translate("policy.form.status")}: ${translatedStatus}`}</p>
					</div>
				</div>

				{canTransferOwnership(data, permissions, username) ?
					<TransferOwnershipComponent policy={data.policy} /> : null}
			</div>

			<div className={styles["page-content"]}>
				<PolicyNavigation />

				<div className={styles["page-data"]}>
					{mainDataComponent}
					<TermsComponent onFormChanged={validate} />
					<PolicyHolderAddressComponent onFormChanged={validate} />
					<CorrespondenceAddressComponent onFormChanged={validate} />
					<InsuredAddressComponent onFormChanged={validate} />
					<RiskDestinationComponent onFormChanged={validate} />
					<BuildingPropertiesComponent onFormChanged={validate} />
					<SumsAndInterestsComponent onFormChanged={validate} />
					<ConstructionNatureComponent onFormChanged={validate} />
					<PreventionInstallationsComponent onFormChanged={validate} />
					<CoverageComponent onFormChanged={validate} />
					<DeductibleComponent onFormChanged={validate} />
					<DamageHistoryComponent onFormChanged={validate} />
					<ProvisionComponent onFormChanged={validate} />
					<ValidationSummary validationGroup={POLICY_VALIDATION_GROUP} />
					{username === data.policy.ownerName ?
						<RequestPremiumIndicationComponent
							policy={data.policy}
							isFormValid={isFormValid}
							setSendingRequest={setSendingRequest}
							validate={validate}
						/>
						: null}

					<div className={styles["data"]}>
						{hasLatestPremium && data.policy.status !== PolicyStatus.ProvideInformation ?
							(
								<PremiumWidget policy={data.policy} />
							) : null}
					</div>
					<div className={styles["data"]}>
						<InspectionComponent policy={data.policy} policyVersion={data.policyVersion} />
					</div>
					{(canReadAllFullPremiums(permissions) && hasLatestPremium)
						?
						<div className={styles["data"]}>
							<FullCalculation policy={data.policy} />
						</div>
						: null}
				</div>
			</div>

			<LoadPanel
				visible={sendingRequest}
				position="center"
				hideOnOutsideClick={false}
				shadingColor="rgba(0,0,0,0.4)"
				message={Translate("policy.form.sending-message")}
			/>
		</div>
	);
};

export default Policy;
