import React from "react";

import { Button } from "devextreme-react";

import HeaderLogo from "../../components/header/HeaderLogo";
import HeaderProductName from "../../components/header/HeaderProductName";
import backgroundImage from "../../public/error-background.jpg";
import styles from "../../styles/FullPageErrorBoundary.module.scss";
import { HttpException } from "../../types/ErrorMessageFromAPI";
import CustomError from "../../utils/CustomError";
import { State } from "../../utils/error-boundary";
import { Translate } from "../../utils/Translation";

const FullPageErrorBoundary = (props: State & { readonly resetError: () => void }): JSX.Element => {
	const suffix = (
		<>
			<p>{Translate("reload.message")}</p>
			<p>{Translate("contact.support")}</p>
			<Button
				width={120}
				text={Translate("page.reload")}
				type="default"
				stylingMode="outlined"
				onClick={props.resetError}
			/>
		</>
	);

	let content;

	if (props.exception && (props.exception as CustomError).userMessage) {
		content = (
			<>
				<h2>{Translate("exception.title")}</h2>
				<p>{(props.exception as CustomError).userMessage}</p>
				{suffix}
			</>
		);
	} else if (props.exception && (props.exception as HttpException).type) {
		content = (
			<>
				<h2>{(props.exception as HttpException).title}</h2>
				<p>Status: {(props.exception as HttpException).status}</p>
				<p>Trace id: {(props.exception as HttpException).traceId}</p>
				{suffix}
			</>
		);
	} else {
		const jsonError = JSON.stringify(props.exception);
		const errorMessage = jsonError === "{}" ? String(props.exception) : jsonError;
		content = (
			<>
				<p>{errorMessage}</p>
				{suffix}
			</>
		);
	}

	return (
		<div
			style={{
				backgroundImage: `url(${backgroundImage})`,
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover",
				backgroundPosition: "30% 0%",
				backgroundAttachment: "fixed",
				height: "100vh",
			}}>
			<div className={styles.left}>
				<div className={styles.header}>
					<div className={styles.header_left}>
						<HeaderLogo />
					</div>
					<div className={styles.header_right}>
						<HeaderProductName />
					</div>
				</div>
				{content}
			</div>
		</div>
	);
};

export default FullPageErrorBoundary;
