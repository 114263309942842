import React, { memo, useCallback, useState } from "react";

import { SelectBox } from "devextreme-react";

import { PolicyDto, usePoliciesTransferPolicyOwnershipMutation } from "../../../apis/PoliciesApi";
import { ProfileDto, useProfilesFetchAllProfilesQuery } from "../../../apis/ProfilesApi";
import styles from "../../../styles/Dialog.module.scss";
import { Translate } from "../../../utils/Translation";
import Button from "../../buttons/Button";
import Dialog from "../../dialog/Dialog";

type Props = {
	readonly policy: PolicyDto;
};

const TransferOwnershipComponent = ({ policy }: Props): JSX.Element => {
	const [newUser, setNewUser] = useState<ProfileDto | undefined>(undefined);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [triggerTransferOwnership] = usePoliciesTransferPolicyOwnershipMutation();

	const {
		error,
		currentData: profiles,
	} = useProfilesFetchAllProfilesQuery();

	if (error) throw error;

	const handleTransferOwnership = useCallback(async (): Promise<void> => {
		if (!newUser || !newUser.userId)
			throw Error("Expected user with ID");

		await triggerTransferOwnership({
			policyGuid: policy.guid,
			newUserId: newUser.userId
		});
	}, [triggerTransferOwnership, policy, newUser]);

	return (
		<>
			<Button
				text={Translate("policy.transfer-ownership.button")}
				type="normal"
				stylingMode="outlined"
				className="blue"
				onClick={(): void => setDialogOpen(true)}
				autoWidth
			/>
			<Dialog
				title={Translate(`policy.transfer-ownership.title`)}
				subTitle={Translate("policy.transfer-ownership.subtitle")}
				buttonText={Translate("policy.transfer-ownership.button")}
				isVisible={dialogOpen}
				cancelAction={(): void => setDialogOpen(false)}
				mainAction={async (): Promise<void> => {
					if (!newUser)
						return;
					await handleTransferOwnership();
					setDialogOpen(false);
				}}
				mainActionDisabled={!newUser}
			>
				{!profiles || profiles.length === 0
					? <p>{Translate("policy.transfer-ownership.no-profiles-available")}</p>
					: <SelectBox
						items={profiles.filter(x => x.userId !== policy.ownerId)}
						displayExpr={"username"}
						onValueChange={(x): void => setNewUser(x)}
						placeholder={Translate("policy.transfer-ownership.placeholder")}
						readOnly={false}
						dropDownOptions={{ wrapperAttr: { class: styles["dialog-popup"] } }}
						onDisposing={(): void => setNewUser(undefined)}
					/>}
			</Dialog>
		</>
	);
};

export default memo(TransferOwnershipComponent);