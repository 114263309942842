/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { on } from "devextreme/events";
import { ContentReadyEvent } from "devextreme/ui/form";

export const getCustomIdObject = (name: string): any => {
  return { customid: name.replace(".", "-") };
};

export const applyClickHandler = (
  formData: any,
  dataFieldPrefix: any,
  dxClickHandler: (event: any) => boolean
): void => {
  Object.keys(formData).map((field) => {
    const element = document.querySelector(
      `[customid=${dataFieldPrefix + field.replace(".", "-")}]`
    );
    if (element !== null)
      on(
        element,
        "dxclick",
        { timeout: 1000, elemName: dataFieldPrefix + field.replace(".", "-") },
        dxClickHandler
      );
    return true;
  });
};

export const sortIndices = (a: number | undefined, b: number | undefined): number => {
  const valA = a ? a : -1;
  const valB = b ? b : -1;
  return valA - valB;
};

// Apparently, there's no 'good' way to disable auto-complete as Chrome is ignoring 'autocomplete = off' attribute.
// Therefore, we need this hack where we set autocomplete to an invalid value. This does seem to work.
// https://supportcenter.devexpress.com/ticket/details/t655124/how-to-disable-autocomplete-for-editors-in-form
// Many of the fixes described in here:
// https://stackoverflow.com/questions/55591018/autocomplete-off-in-html5
// or https://stackoverflow.com/questions/12374442/chrome-ignores-autocomplete-off
// DO NOT work. Tried many of them. The only thing I found worked was setting autocomplete to off and role to presentation:
// https://gist.github.com/niksumeiko/360164708c3b326bd1c8?permalink_comment_id=3925346#gistcomment-3925346
export const disableAutocompleteOnForm = (e: ContentReadyEvent): void => {
  const inputs = e.element.getElementsByTagName("input");
  for(const input of inputs) {
    input.setAttribute("autocomplete", "off");
    input.setAttribute("role", "presentation");
  }
};