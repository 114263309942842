import { InspectionState, PolicyDto, PolicyExtendedDto, PolicyStatus, PremiumStage } from "../apis/PoliciesApi";
import { PolicyPageData } from "../store/PolicyPageState";

export const POLICY_VALIDATION_GROUP = "policy-validation-group";

export const policyStatusesDesc = new Map<PolicyStatus, string>([
	[PolicyStatus.ProvideInformation, "policy-state.provide-information"],
	[PolicyStatus.PremiumIndicationRequested, "policy-state.premium-indication-requested"],
	[PolicyStatus.ReviewPremiumIndicationRequest, "policy-state.review-premium-indication-request"],
	[PolicyStatus.PremiumIndicationProvided, "policy-state.premium-indication-provided"],
	[PolicyStatus.ReviewStipulations, "policy-state.review-stipulations"],
	[PolicyStatus.QuoteRequested, "policy-state.quote-requested"],
	[PolicyStatus.QuoteProvided, "policy-state.quote-provided"],
	[PolicyStatus.ReviewQuote, "policy-state.review-quote"],
	[PolicyStatus.QuoteAccepted, "policy-state.quote-accepted"],
	[PolicyStatus.QuoteRejected, "policy-state.quote-rejected"],
	[PolicyStatus.Running, "policy-state.running"],
	[PolicyStatus.RequestProlongation, "policy-state.request-prolongation"],
	[PolicyStatus.Cancelled, "policy-state.cancelled"],
	[PolicyStatus.Expired, "policy-state.expired"],
	[PolicyStatus.FullInspectionRequired, "policy-state.full-inspection-required"],
	[PolicyStatus.QuickScanInspectionRequired, "policy-state.quick-scan-inspection-required"],
	[PolicyStatus.Inspected, "policy-state.inspected"],
	[PolicyStatus.Rejected, "policy-state.rejected"],
	[PolicyStatus.PreRiskRequired, "policy-state.pre-risk-required"]
]);

export const policyTypes = new Map([[0, "Fire"]]);

export const fieldTypes = new Map([
	[0, "Number"],
	[1, "Currency"],
	[2, "Boolean"],
	[3, "String"],
	[4, "Date"],
	[5, "Enum"],
	[6, "Percentage"],
	[7, "Occupancy"],
]);

export enum PolicyComponentBehaviorType {
	Default = 0,
	RequiresNoSecondReview = 1,
}

export function isPolicyComponentReadOnly(data: PolicyPageData, userName: string | null, permissions: string[],
	behaviorType: PolicyComponentBehaviorType = PolicyComponentBehaviorType.Default): boolean {

	if (permissions.find(x => x === "WriteOwnPolicies") && data.policy.ownerName === userName) {
		switch (behaviorType) {
			case PolicyComponentBehaviorType.Default:
				return data.policy.status !== PolicyStatus.ProvideInformation;
			case PolicyComponentBehaviorType.RequiresNoSecondReview:
				return data.policy.status !== PolicyStatus.ProvideInformation
					&& data.policy.status !== PolicyStatus.PremiumIndicationProvided
					&& data.policy.status !== PolicyStatus.ReviewStipulations;
			// For safety: unknown behavior type --> always read-only
			default:
				return true;
		}
	}
	return true;
}

export function canRunPremium(policyStatus: PolicyStatus): boolean {
	return policyStatus === PolicyStatus.ProvideInformation ||
		policyStatus === PolicyStatus.PremiumIndicationProvided ||
		policyStatus === PolicyStatus.ReviewStipulations;
}

export function reviewIsReadonly(policyStatus: PolicyStatus): boolean {
	return policyStatus !== PolicyStatus.ReviewPremiumIndicationRequest && policyStatus !== PolicyStatus.ReviewQuote;
}

export function canWritePolicy(userName: string | null, permissions: string[], policy: PolicyDto): boolean {
	return userName === policy.ownerName && permissions.find(x => x === "WriteOwnPolicies") !== undefined;
}

export function canTransferOwnership(data: PolicyPageData, permissions: string[],
	userName: string | null): boolean {
	// The user should of course have permissions to write policy ownership
	// Also, the user has to be able to read profiles, to populate the dropdown on who to transfer ownership to
	if (!permissions.find(x => x === "WriteOwnPolicyOwnership") || !permissions.find(x => x === "ReadProfiles"))
		return false;

	// For now ownership can only be transferred from policies owned by the user
	if (userName !== data.policy.ownerName)
		return false;
	const policyStatus = data.policy.status;
	return policyStatus === PolicyStatus.ProvideInformation ||
		policyStatus === PolicyStatus.PremiumIndicationRequested ||
		policyStatus === PolicyStatus.ReviewPremiumIndicationRequest ||
		policyStatus === PolicyStatus.PremiumIndicationProvided;
}

export function canViewExtraStipulationColumns(policyStatus: PolicyStatus): boolean {
	return policyStatus === PolicyStatus.ReviewStipulations ||
		policyStatus === PolicyStatus.ReviewQuote ||
		policyStatus === PolicyStatus.QuoteProvided ||
		policyStatus === PolicyStatus.QuoteAccepted;
}

export function getPremiumCaptionKey(policyStatus: PolicyStatus, premiumStage: PremiumStage): string {
	if (premiumStage === PremiumStage.Indication)
		return "policy.form.title.premium-indication";
	else {
		if (policyStatus === PolicyStatus.ReviewStipulations ||
			policyStatus === PolicyStatus.ReviewQuote ||
			policyStatus === PolicyStatus.QuoteRequested)
			return "policy.form.title.preliminary-quote";
		return "policy.form.title.quote";
	}
}

export function hasPolicyAccess(permissions: string[]): boolean {
	return permissions.find(x => x === "PolicyAccess") !== undefined;
}

export function canReadAllPolicies(permissions: string[]): boolean {
	return permissions.find(x => x === "ReadAllPolicies") !== undefined;
}

export function canWritePolicies(permissions: string[]): boolean {
	return permissions.find(x => x === "WriteOwnPolicies") !== undefined;
}

export function canReadAllFullPremiums(permissions: string[]): boolean {
	return permissions.find(x => x === "ReadAllFullPremiums") !== undefined;
}

export function canWriteInspectionReview(permissions: string[]): boolean {
	return permissions.find(x => x === "WriteInspectionReview") !== undefined;
}

export function canWritePremiumReviews(permissions: string[]): boolean {
	return permissions.find(x => x === "WritePremiumReview") !== undefined;
}

export function canWriteRejectedQuote(permissions: string[]): boolean {
	return permissions.find(x => x === "WriteRejectedQuote") !== undefined;
}

export function canWriteStipulations(permissions: string[]): boolean {
	return permissions.find(x => x === "WriteStipulations") !== undefined;
}

export function canReadFullReleaseNotes(permissions: string[]): boolean {
	return permissions.find(x => x === "ReadFullReleaseNotes") !== undefined;
}

export function canWriteStaticData(permissions: string[]): boolean {
	return permissions.find(x => x === "WriteStaticData") !== undefined;
}

export function isAcceptant(role: string | null): boolean {
	return role === "Acceptant";
}

function isPhysicalInspectionStatus(status: PolicyStatus): boolean {
	return status === PolicyStatus.QuickScanInspectionRequired || status === PolicyStatus.FullInspectionRequired;
}

function isInPolicyWriterStatus(policy: PolicyExtendedDto): boolean {
	if (policy.status === PolicyStatus.ProvideInformation ||
		policy.status === PolicyStatus.PremiumIndicationProvided ||
		policy.status === PolicyStatus.PreRiskRequired ||
		policy.status === PolicyStatus.ReviewStipulations ||
		policy.status === PolicyStatus.QuoteProvided)
		return true;

	if (isPhysicalInspectionStatus(policy.status)) {
		if (policy.inspectionState === InspectionState.RequestInspection ||
			// Inspection reviewer has actual action in tool, but writer needs to be aware of sending email
			policy.inspectionState === InspectionState.InspectionToBeDelivered ||
			policy.inspectionState === InspectionState.ContractChoiceRequired
		) {
			return true;
		}
	}
	return false;
}

function isInPremiumReviewStatus(policy: PolicyExtendedDto): boolean {
	return (policy.status === PolicyStatus.ReviewPremiumIndicationRequest ||
		policy.status === PolicyStatus.ReviewQuote);
}

function isInInspectionReviewStatus(policy: PolicyExtendedDto): boolean {
	if (isPhysicalInspectionStatus(policy.status)) {
		if (policy.inspectionState === InspectionState.InspectionToBeDelivered ||
			policy.inspectionState === InspectionState.ScheduleInspection ||
			policy.inspectionState === InspectionState.FillScore
		) {
			return true;
		}
	}
	return false;
}

export function todoListFilter(policy: PolicyExtendedDto, userName: string | null, permissions: string[]): boolean {
	if (userName === policy.ownerName && permissions.find(x => x === "WriteOwnPolicies")
		&& isInPolicyWriterStatus(policy)) {
		return true;
	}
	if (canWritePremiumReviews(permissions) && isInPremiumReviewStatus(policy)) {
		return true;
	}
	if (canWriteInspectionReview(permissions) && isInInspectionReviewStatus(policy)) {
		return true;
	}
	if (canWriteRejectedQuote(permissions) && policy.status === PolicyStatus.QuoteRejected) {
		return true;
	}
	return false;
}