import React from "react";

import { PremiumWarningDto, PremiumWarningType } from "../../../apis/PremiumApi";
import { Translate } from "../../../utils/Translation";
import ComponentSubTitle from "../../titles/ComponentSubTitle";

type WarningProps = {
	readonly warning: PremiumWarningDto;
};

const Warning = ({ warning }: WarningProps): JSX.Element => {
	if (warning.type === PremiumWarningType.OccupancyZero)
		return (<ComponentSubTitle text={Translate("premium-indication.warning.occupancy-zero")} />);
	if (warning.type === PremiumWarningType.UnknownOccupancy)
		return (<ComponentSubTitle text={Translate("premium-indication.warning.unknown-occupancy")} />);
	if (warning.type === PremiumWarningType.ConstructionNature)
		return (<ComponentSubTitle text={Translate("premium-indication.warning.construction-nature")} />);
	if (warning.type === PremiumWarningType.NoOccupancyForMajorSupplier)
		return (<ComponentSubTitle text={Translate("premium-indication.warning.unknown-occupancy-supplier")} />);
	if (warning.type === PremiumWarningType.UnknownError)
		return (<ComponentSubTitle text={`${Translate("premium-indication.warning.unknown-error")}`} />);
	if (warning.type === PremiumWarningType.RiskClassTooHigh)
		return (<ComponentSubTitle text={`${Translate("premium-indication.warning.class-too-high")}`} />);
	if (warning.type === PremiumWarningType.HasRemarksForReview)
		return (<ComponentSubTitle text={`${Translate("premium-indication.warning.remarks")}`} />);
	if (warning.type === PremiumWarningType.InsufficientCapacity)
		return (<ComponentSubTitle text={`${Translate("premium-indication.warning.insufficient-capacity")}`} />);
	if (warning.type === PremiumWarningType.DamageHistoryTooHigh)
		return (<ComponentSubTitle text={`${Translate("premium-indication.warning.damage-history-too-high")}`} />);
	if (warning.type === PremiumWarningType.DamageFrequencyTooHigh)
		return (<ComponentSubTitle text={`${Translate("premium-indication.warning.damage-frequency-too-high")}`} />);
	if (warning.type === PremiumWarningType.PreRiskAnswer)
		return (<ComponentSubTitle text={`${Translate("premium-indication.warning.pre-risk-answer")}`} />);
	return <div>Unknown warning: {warning.type}</div>;
};

export default Warning;