import { PolicyFieldValueDto } from "../apis/PoliciesApi";
import { FieldType, PolicyFieldDto } from "../apis/PolicyTypeApi";
import { emptyGuid, Guid } from "./Guid";

export type EnumTypeOption = {
	key: string;
	caption: string;
};

export type FieldGroup = {
	readonly id: number;
	readonly header: string | null;
	readonly fields: PolicyFieldDto[];
};


// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const serialize = (type: FieldType | undefined, value: any): string | null => {
	switch (type) {
		case FieldType.Boolean:
			return value ? "true" : "false";
		case FieldType.Number:
		case FieldType.Currency:
			return value ? (value as number).toString() : null;
		case FieldType.Occupancy:
		case FieldType.String:
			return value as string;
		case FieldType.Date: {
			if (value)
				return value instanceof Date ? (value as Date).toISOString() : new Date(value).toISOString();
			return null;
		}
		case FieldType.Enum:
			return value as string;
		case FieldType.Percentage:
			return value ? (value as number).toString() : null;
		default:
			throw new Error(`Not implemented serialization ${type} for value ${value}`);
	}
};

export const deserialize = (type: FieldType | undefined, value: string | null | undefined): any => {
	switch (type) {
		case FieldType.Boolean:
			return value ? value.toLowerCase() === "true" : null;
		case FieldType.Number:
		case FieldType.Currency:
		case FieldType.Percentage:
			return value ? parseFloat(value) : null;
		case FieldType.String:
			return value;
		case FieldType.Date:
			return value ? new Date(value) : null;
		case FieldType.Enum:
			return value;
		case FieldType.Occupancy:
			return value as Guid;
		default:
			throw new Error(`Not implemented parsing ${type} for value ${value}`);
	}
};

export const dbOption = (value: PolicyFieldValueDto | undefined): Guid | null => value ? value.value as Guid : emptyGuid();