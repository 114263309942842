import React, { memo, useCallback, useMemo, useState } from "react";

import { NumberBox, Toast, Validator } from "devextreme-react";
import { RangeRule } from "devextreme-react/form";
import { ValueChangedEvent } from "devextreme/ui/number_box";

import { usePoliciesUpdatePolicyVersionNoSecondaryReviewMutation } from "../../../apis/PoliciesApi";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { selectIdentity } from "../../../store/Identity";
import { selectPolicyPage, setProvisionPercentage } from "../../../store/PolicyPageState";
import { isPolicyComponentReadOnly, POLICY_VALIDATION_GROUP, PolicyComponentBehaviorType } from "../../../utils/PolicyUtil";
import { Translate } from "../../../utils/Translation";
import BaseComponent from "./BaseComponent";

type Props = {
	readonly onFormChanged: () => Promise<void>;
};

const percentFormatLabel = { 'aria-label': 'Percent Format' };
const MAXIMUM_PROVISION = 0.15;

const ProvisionComponent = ({ onFormChanged }: Props): JSX.Element => {

	const dispatch = useAppDispatch();
	const { data } = useAppSelector(selectPolicyPage);
	const { username, permissions } = useAppSelector(selectIdentity);

	if (!data)
		throw new Error('No policy page data available');

	const readOnly = isPolicyComponentReadOnly(data, username, permissions, PolicyComponentBehaviorType.RequiresNoSecondReview);

	const [triggerPutPolicyVersion] = usePoliciesUpdatePolicyVersionNoSecondaryReviewMutation();
	const [savingFailed, setSavingFailed] = useState(false);

	const provisionChanged = useCallback(async (e: ValueChangedEvent): Promise<void> => {
		const newProvisionPercentage = e.value * 100.0;
		try {
			dispatch(setProvisionPercentage(newProvisionPercentage));
			await triggerPutPolicyVersion({
				policyGuid: data.policy.guid,
				updatePolicyVersionDtoNoSecondaryReviewDto: {
					...data.policyVersion,
					provisionPercentage: newProvisionPercentage,
				}
			}).unwrap();
			onFormChanged();
		} catch (error) {
			setSavingFailed(true);
		}

	}, [data.policy.guid, data.policyVersion, dispatch, onFormChanged, triggerPutPolicyVersion]);

	const numberBox = useMemo(() => {
		return (
			<NumberBox
				key="provision"
				format="#0.0%"
				inputAttr={percentFormatLabel}
				defaultValue={MAXIMUM_PROVISION}
				readOnly={readOnly}
				step={0.01}
				onValueChanged={provisionChanged}
				value={data.policyVersion.provisionPercentage / 100.0}
			>
				<Validator validationGroup={POLICY_VALIDATION_GROUP}>
					<RangeRule min={0} max={MAXIMUM_PROVISION} message={Translate("policy.form.provision.out-of-range")} />
				</Validator>
			</NumberBox>
		);
	}, [provisionChanged, readOnly, data.policyVersion]);

	return (
		<BaseComponent id="provision" captionKey="policy.form.title.provision">
			{numberBox}
			<Toast
				visible={savingFailed}
				message={Translate("policy.form.failure.saving")}
				type="error"
				onHiding={(): void => setSavingFailed(false)}
				closeOnClick
				displayTime={10000}
			/>
		</BaseComponent>
	);
};

export default memo(ProvisionComponent);