import { Guid } from "../utils/Guid";
import { befurstApiClient as api } from "./BefurstApi";
export const addTagTypes = ["ReviewRequests"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      reviewRequestsUpdateReviewRequest: build.mutation<
        ReviewRequestsUpdateReviewRequestApiResponse,
        ReviewRequestsUpdateReviewRequestApiArg
      >({
        query: (queryArg) => ({
          url: `/api/ReviewRequests/${queryArg.reviewRequestGuid}`,
          method: "PUT",
          body: queryArg.updatePremiumCalcReviewRequestDto,
        }),
        invalidatesTags: ["ReviewRequests"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as enhancedApi };
export type ReviewRequestsUpdateReviewRequestApiResponse =
  /** status 200 OK */ CalculationReviewRequestDto;
export type ReviewRequestsUpdateReviewRequestApiArg = {
  reviewRequestGuid: Guid;
  updatePremiumCalcReviewRequestDto: UpdatePremiumCalcReviewRequestDto;
};
export type CalculationReviewRequestDto = {
  guid: Guid;
  reason: PremiumCalcReviewRequestReason;
  message: string | null;
  argument: string | null;
  positivelyReviewd: boolean;
  reviewNote: string | null;
  unacceptable: boolean;
};
export type CustomError = {
  status: number;
  key: string | null;
  message: string | null;
};
export type UpdatePremiumCalcReviewRequestDto = {
  reviewNote: string | null;
  positivelyReviewed: boolean;
};
export enum PremiumCalcReviewRequestReason {
  NoOccupancy = "NoOccupancy",
  PremiumOnDemand = "PremiumOnDemand",
  NoOccupancyForMajorSupplier = "NoOccupancyForMajorSupplier",
  Remarks = "Remarks",
  PreRiskAnswer = "PreRiskAnswer",
  DamageFrequencyExceeded = "DamageFrequencyExceeded",
  DamageAmountExceeded = "DamageAmountExceeded",
}
export const { useReviewRequestsUpdateReviewRequestMutation } = injectedRtkApi;
