import { Guid } from "../utils/Guid";
import { befurstApiClient as api } from "./BefurstApi";
export const addTagTypes = ["Policies", "PremiumOutdated", "Damages"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      policiesGetCurrentPolicyDamages: build.query<
        PoliciesGetCurrentPolicyDamagesApiResponse,
        PoliciesGetCurrentPolicyDamagesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/current-version/damages`,
        }),
        providesTags: ["Policies"],
      }),
      policiesGetCurrentPolicyDamagesForSupplier: build.query<
        PoliciesGetCurrentPolicyDamagesForSupplierApiResponse,
        PoliciesGetCurrentPolicyDamagesForSupplierApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/current-version/damages/${queryArg.supplierGuid}`,
        }),
        providesTags: ["Policies"],
      }),
      damagesCreateDamage: build.mutation<
        DamagesCreateDamageApiResponse,
        DamagesCreateDamageApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Damages`,
          method: "POST",
          body: queryArg.createDamageDto,
        }),
        invalidatesTags: ["PremiumOutdated", "Damages"],
      }),
      damagesUpdateDamage: build.mutation<
        DamagesUpdateDamageApiResponse,
        DamagesUpdateDamageApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Damages/${queryArg.damageGuid}`,
          method: "PUT",
          body: queryArg.updateDamageDto,
        }),
        invalidatesTags: ["PremiumOutdated", "Damages"],
      }),
      damagesDeleteDamage: build.mutation<
        DamagesDeleteDamageApiResponse,
        DamagesDeleteDamageApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Damages/${queryArg.damageGuid}`,
          method: "DELETE",
        }),
        invalidatesTags: ["PremiumOutdated", "Damages"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as enhancedApi };
export type PoliciesGetCurrentPolicyDamagesApiResponse =
  /** status 200 OK */ DamageDto[];
export type PoliciesGetCurrentPolicyDamagesApiArg = {
  policyGuid: Guid;
};
export type PoliciesGetCurrentPolicyDamagesForSupplierApiResponse =
  /** status 200 OK */ DamageDto[];
export type PoliciesGetCurrentPolicyDamagesForSupplierApiArg = {
  policyGuid: Guid;
  supplierGuid: Guid;
};
export type DamagesCreateDamageApiResponse =
  /** status 201 Created */ DamageDto;
export type DamagesCreateDamageApiArg = {
  createDamageDto: CreateDamageDto;
};
export type DamagesUpdateDamageApiResponse = /** status 200 OK */ DamageDto;
export type DamagesUpdateDamageApiArg = {
  damageGuid: Guid;
  updateDamageDto: UpdateDamageDto;
};
export type DamagesDeleteDamageApiResponse = /** status 204 No Content */ void;
export type DamagesDeleteDamageApiArg = {
  damageGuid: Guid;
};
export type DamageDto = {
  guid: Guid;
  policyVersionGuid: Guid;
  date: string;
  amountPaidByInsurance: number;
  ownRisk: number;
  cause: DamageCause;
  description: string | null;
  supplierGuid: Guid;
};
export type CustomError = {
  status: number;
  key: string | null;
  message: string | null;
};
export type CreateDamageDto = {
  guid: Guid;
  policyVersionGuid: Guid;
  date: string;
  amountPaidByInsurance: number;
  ownRisk: number;
  cause: DamageCause;
  description: string | null;
  supplierGuid: Guid;
};
export type UpdateDamageDto = {
  date: string;
  amountPaidByInsurance: number;
  ownRisk: number;
  cause: DamageCause;
  description: string | null;
};
export enum DamageCause {
  Fire = "Fire",
  Burglary = "Burglary",
  Collision = "Collision",
  Frost = "Frost",
  Cooling = "Cooling",
  Other = "Other",
}
export const {
  usePoliciesGetCurrentPolicyDamagesQuery,
  useLazyPoliciesGetCurrentPolicyDamagesQuery,
  usePoliciesGetCurrentPolicyDamagesForSupplierQuery,
  useLazyPoliciesGetCurrentPolicyDamagesForSupplierQuery,
  useDamagesCreateDamageMutation,
  useDamagesUpdateDamageMutation,
  useDamagesDeleteDamageMutation,
} = injectedRtkApi;
