import React, { useState } from "react";

import Drawer from "devextreme-react/drawer";

import { useAppSelector } from "../../hooks/hooks";
import { selectIdentity } from "../../store/Identity";
import { canReadFullReleaseNotes } from "../../utils/PolicyUtil";
import { Translate } from "../../utils/Translation";
import Header from "../header/Header";
import DrawerContent from "./DrawerContent";
import Settings from "./Settings";

type Props = {
	readonly locale: string;
	readonly children: JSX.Element;
};

const HeaderWithDrawer = ({ locale, children }: Props): JSX.Element => {
	const { permissions } = useAppSelector(selectIdentity);
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [activeDrawer, setActiveDrawer] = useState("notifications");

	// we always have an active drawer, even if it is not shown
	// otherwise the drawer component from DevExtreme doesn't work
	const toggleDrawer = (newDrawer: string): void => {
		if (activeDrawer === newDrawer) {
			setDrawerOpen((previousState) => !previousState);
		} else {
			setActiveDrawer(newDrawer);
			setDrawerOpen(true);
		}
	};

	const closeDrawer = (): void => {
		setDrawerOpen(false);
	};

	const getDrawerContent = (): JSX.Element => {
		let title = "Help";
		// ToDo: get the version number from an ASP.NET call or somewhere
		let drawerContent = (
			<>
				<p>This is the help page.</p>
				{(canReadFullReleaseNotes(permissions))
					? <a href="/release-notes">Release notes</a>
					: null
				}
			</>
		);

		if (activeDrawer === "notifications") {
			title = "Notifications";
			drawerContent = <p>This is the notifications page.</p>;
		}
		if (activeDrawer === "settings") {
			title = Translate("sidepanel.settings.title");
			drawerContent = <Settings locale={locale} />;
		}
		return (
			<DrawerContent title={title} closeDrawer={closeDrawer}>
				{drawerContent}
			</DrawerContent>
		);
	};

	return (
		<>
			<Header showDrawerHandler={toggleDrawer} />
			<Drawer
				opened={drawerOpen}
				openedStateMode={"shrink"}
				position={"right"}
				revealMode={"slide"}
				render={getDrawerContent}
			>
				{children}
			</Drawer>
		</>
	);
};

export default HeaderWithDrawer;
