import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import { Translate } from "../../utils/Translation";

type Props = {
	readonly navigationItem: {
		path: string;
		localeId: string;
		text: string;
		icon: string;
	};
	readonly isActive: (key: string) => boolean;
	readonly setActive: (key: string) => void;
};

const NavBarButton = ({ navigationItem, isActive, setActive }: Props): JSX.Element => {
	const [isHovering, setIsHovering] = useState(false);
	const [isClicked, setIsClicked] = useState(false);

	const isHoveringClasses = isHovering ? "dx-state-focused dx-state-hover" : "";
	const isClickedClasses = isClicked ? "dx-state-focused dx-state-hover" : "";
	const classes = `dx-widget dx-button dx-button-mode-text dx-button-normal 
    dx-button-has-text ${isHoveringClasses} ${isClickedClasses}`;

	// when refreshing the navbar, show the selected button
	useEffect(() => {
		const isActiveButton = isActive(navigationItem.path);
		setIsClicked(isActiveButton);
	}, [isActive, navigationItem.path]);

	const handleMouseOver = (): void => {
		setIsHovering(true);
	};

	const handleMouseOut = (): void => {
		setIsHovering(false);
	};

	// if I click a button in the navbar, we tell the parent, this is active now
	const addSelection = (): void => {
		setActive(navigationItem.path);
	};

	return (
		<Link to={navigationItem.path} key={navigationItem.localeId}>
			<div
				className={classes}
				role="button"
				aria-label="Text"
				style={{ width: 100, height: 100, margin: "auto", textAlign: "center" }}
				onMouseOver={handleMouseOver}
				onMouseOut={handleMouseOut}
				onClick={addSelection}>
				<div className="dx-button-content" style={{ padding: 0 }}>
					<i
						className={`dx-icon dx-icon-${navigationItem.icon}`}
						style={{ width: 32, fontSize: 32, height: 32, lineHeight: 1 }}
					/>
					<p>
						<span className="dx-button-text">{Translate(navigationItem.localeId)}</span>
					</p>
				</div>
			</div>
		</Link>
	);
};

export default NavBarButton;
