import { formatNumber } from "devextreme/localization";

import { PremiumCalcFactorsDto, PremiumCalcVectorDto, PremiumCalcVectorType } from "../../../apis/PoliciesApi";
import { formatPercentage } from "../../../utils/formatUtils";
import { Translate } from "../../../utils/Translation";

type ProcessedVector = {
	name: string | null;
	index: number;
	factor: string | null;
	buildings: number | null;
	inventory: number | null;
	machines: number | null;
	goods: number | null;
	corporateDamage: number | null;
	reconstructionCosts: number | null;
	extraCosts: number | null;
	coolingDamage: number | null;
	supplier: number | null;
	premiumCalcVectorType: PremiumCalcVectorType;
	isDiscount: boolean;
	isImportant: boolean;
	isCustom: boolean;
	isFinal: boolean;
};

function divideBy100IfNotNull(value: number | null): number | null {
	return value !== null ? value / 100 : null;
}

export function getProcessedVectors(vectors: PremiumCalcVectorDto[], factors: PremiumCalcFactorsDto): ProcessedVector[] {
	const result: ProcessedVector[] = [];
	vectors.forEach(vector => {
		let factor: string | null = null;
		let name: string | null = null;
		let isDiscount = false;
		let isImportant = false;
		let isCustom = false;
		let isFinal = false;
		switch (vector.premiumCalcVectorType) {
			case PremiumCalcVectorType.StandardGrossPremium: {
				name = "premium-calculation.standard-gross-premium";
				factor = formatPercentage(0);
				break;
			}
			case PremiumCalcVectorType.StandardNetPremium: {
				name = "premium-calculation.standard-net-premium";
				factor = formatPercentage(factors.standardNetPremium);
				break;
			}
			case PremiumCalcVectorType.CommercialFactorOverall: {
				name = "premium-calculation.commercial-factor-overall";
				factor = formatPercentage(factors.commercialOverallFactor);
				isDiscount = true;
				break;
			}
			case PremiumCalcVectorType.CommercialFactorCea: {
				name = "premium-calculation.commercial-factor-cea";
				factor = formatPercentage(factors.commercialCeaFactor);
				isDiscount = true;
				break;
			}
			case PremiumCalcVectorType.CommercialFactorTotal: {
				name = "premium-calculation.commercial-factor-total";
				factor = formatPercentage(factors.totalCommercialFactor);
				isDiscount = true;
				isImportant = true;
				break;
			}
			case PremiumCalcVectorType.NetPremiumSubtotal1: {
				name = "premium-calculation.net-premium-subtotal1";
				break;
			}
			case PremiumCalcVectorType.CoverageBonus: {
				name = "premium-calculation.coverage-bonus";
				factor = formatPercentage(factors.coverageBonus);
				isDiscount = true;
				break;
			}
			case PremiumCalcVectorType.ConstructionNatureBonus: {
				name = "premium-calculation.construction-nature-bonus";
				factor = formatPercentage(factors.constructionNatureBonus);
				isDiscount = true;
				break;
			}
			case PremiumCalcVectorType.SolarPanelBonus: {
				name = "premium-calculation.solar.panel-bonus";
				factor = formatPercentage(factors.solarPanelBonus);
				isDiscount = true;
				break;
			}
			case PremiumCalcVectorType.SprinklerBonus: {
				name = "premium-calculation.sprinkler-bonus";
				factor = formatPercentage(factors.sprinklerBonus);
				isDiscount = true;
				break;
			}
			case PremiumCalcVectorType.BmiBonus: {
				name = "premium-calculation.bmi-bonus";
				factor = formatPercentage(factors.bmiBonus);
				isDiscount = true;
				break;
			}
			case PremiumCalcVectorType.BonusTotal: {
				name = "premium-calculation.bonus-total";
				factor = formatPercentage(factors.totalBonusFactor);
				isDiscount = true;
				isImportant = true;
				break;
			}
			case PremiumCalcVectorType.NetPremiumSubtotal2: {
				name = "premium-calculation.net-premium-subtotal2";
				break;
			}
			case PremiumCalcVectorType.RrsScore: {
				name = "premium-calculation.rrs-score";
				factor = formatNumber(factors.rrsScore, "#0.##");
				isDiscount = true;
				break;
			}
			case PremiumCalcVectorType.CorporateDamageConversion: {
				name = "premium-calculation.corporate-damage-conversion";
				factor = formatPercentage(factors.corporateDamageConversion);
				isDiscount = true;
				break;
			}
			case PremiumCalcVectorType.ExtraCostsConversion: {
				name = "premium-calculation.extra-costs-conversion";
				factor = formatPercentage(factors.extraCostsConversion);
				isDiscount = true;
				break;
			}
			case PremiumCalcVectorType.OwnRiskDiscount: {
				name = "premium-calculation.own-risk-discount";
				factor = formatPercentage(factors.ownRiskDiscount);
				isDiscount = true;
				break;
			}
			case PremiumCalcVectorType.RevenueDiscount: {
				name = "premium-calculation.revenue-discount";
				factor = formatPercentage(factors.revenueDiscount);
				isDiscount = true;
				break;
			}
			case PremiumCalcVectorType.PaymentTermSurcharge: {
				name = "premium-calculation.payment-term-surcharge";
				factor = formatPercentage(factors.paymentTermSurcharge);
				isDiscount = true;
				break;
			}
			case PremiumCalcVectorType.NetPremiumSubtotal3: {
				name = "premium-calculation.net-premium-subtotal3";
				break;
			}
			case PremiumCalcVectorType.CommercialDiscount: {
				name = "premium-calculation.commercial-discount";
				factor = formatPercentage(factors.commercialDiscount);
				isCustom = true;
				break;
			}
			case PremiumCalcVectorType.DesiredCommission: {
				name = "premium-calculation.desired-commission";
				factor = formatPercentage(factors.desiredCommission);
				isImportant = true;
				break;
			}
			case PremiumCalcVectorType.TotalDiscount: {
				name = "premium-calculation.total-discount";
				break;
			}
			case PremiumCalcVectorType.FinalPremiums: {
				name = "premium-calculation.final-premiums";
				factor = formatPercentage(factors.desiredCommission);
				isImportant = true;
				isFinal = true;
				break;
			}
		}
		name = Translate(name);

		result.push({
			name,
			index: vector.index,
			factor,
			buildings: divideBy100IfNotNull(vector.buildings),
			inventory: divideBy100IfNotNull(vector.inventory),
			machines: divideBy100IfNotNull(vector.machines),
			goods: divideBy100IfNotNull(vector.goods),
			corporateDamage: divideBy100IfNotNull(vector.corporateDamage),
			reconstructionCosts: divideBy100IfNotNull(vector.reconstructionCosts),
			extraCosts: divideBy100IfNotNull(vector.extraCosts),
			coolingDamage: divideBy100IfNotNull(vector.coolingDamage),
			supplier: divideBy100IfNotNull(vector.supplier),
			premiumCalcVectorType: vector.premiumCalcVectorType,
			isDiscount,
			isImportant,
			isCustom,
			isFinal
		});
	});

	// Sort by index if different, or otherwise (mostly for backcomp) on enum type
	result.sort((a, b) => a.index - b.index);
	return result;
}