/* eslint-disable max-lines */
import React, { memo, ReactNode, useCallback, useEffect, useState } from "react";

import { add, startOfToday } from "date-fns";
import { LoadIndicator, Tooltip } from "devextreme-react";
import Form, { CustomRule, GroupItem, RangeRule, RequiredRule, SimpleItem } from "devextreme-react/form";
import { FieldDataChangedEvent } from "devextreme/ui/form";

import {
  FieldType, PolicyFieldValueDto, usePoliciesGetCurrentPolicyFieldsValuesQuery, usePoliciesUpdatePolicyFieldValueMutation,
} from "../../../apis/PoliciesApi";
import {
  PolicyFieldDto, PolicyFieldOptionDto, useLazyPolicyTypeGetPolicyTypeFieldOptionsQuery, usePolicyTypeGetPolicyTypeFieldsQuery,
} from "../../../apis/PolicyTypeApi";
import { useAsyncEffect } from "../../../hooks/AsyncEffectHook";
import { useAppSelector } from "../../../hooks/hooks";
import { selectIdentity } from "../../../store/Identity";
import { selectPolicyPage } from "../../../store/PolicyPageState";
import styles from "../../../styles/GenericData.module.scss";
import { formatSerialized } from "../../../utils/formatUtils";
import { dbOption, deserialize, EnumTypeOption, FieldGroup, serialize } from "../../../utils/GenericDataUtil";
import { Guid } from "../../../utils/Guid";
import { isPolicyComponentReadOnly, POLICY_VALIDATION_GROUP, PolicyComponentBehaviorType } from "../../../utils/PolicyUtil";
import { Translate } from "../../../utils/Translation";
import { disableAutocompleteOnForm } from "../../../utils/utils";
import { PreRiskQuestionMatcher } from "../../../vm/PreRiskQuestionMatcher";
import SuppliersTableComponent from "./SuppliersTableComponent";

const INDEXATION_PREFIX = "indexation-";
const INSURE_GOODS = "insure-goods", SUM_GOODS = "insured-sum-goods", GOODS_BY_DECLARATION = "goods-by-declaration",
	GOODS_FIXED = "goods-declaration-fixed-value", GOODS_ADJUSTABLE = "goods-declaration-adjustable-value";
const GOODS_FIELDS = [INSURE_GOODS, SUM_GOODS, GOODS_BY_DECLARATION, GOODS_FIXED, GOODS_ADJUSTABLE];

type Props = {
	readonly onFormChanged: () => Promise<void>;
	readonly category: string;
};

type FieldUpdate = {
	readonly fieldGuid: Guid;
	readonly serializedValue: string | null;
	readonly deserializedValue: any;
	readonly type: FieldType;
	readonly key: string;
};

// We are currently using the PreRiskQuestionMatcher to do the policy field visibility checks.
// This doesn't require any pre-risk data or answers, therefore null and empty array.
// The goal is to create a separate class to perform policy field visibility with some reuse.
const matcher = new PreRiskQuestionMatcher(null, []);

const calculateSumGoods = (lookupValues: Map<string, any>): number => {
	if (lookupValues.get(INSURE_GOODS)) {
		if (lookupValues.get(GOODS_BY_DECLARATION))
			return parseFloat(lookupValues.get(GOODS_FIXED)) + parseFloat(lookupValues.get(GOODS_ADJUSTABLE));
		else
			return parseFloat(lookupValues.get(SUM_GOODS));
	} else
		return 0.0;
};

const isValidationLookupField = (key: string): boolean => {
	return key.startsWith(INDEXATION_PREFIX) || GOODS_FIELDS.includes(key);
};

const GenericDataComponent = ({ onFormChanged, category }: Props): JSX.Element => {

	const { data } = useAppSelector(selectPolicyPage);
	const { username, permissions } = useAppSelector(selectIdentity);
	const [policyFieldGroups, setPolicyFieldGroups] = useState<FieldGroup[] | null>(null);
	const [formFields, setFormFields] = useState<Map<string, JSX.Element>>(new Map());
	const [formData, setFormData] = useState<any>(null);
	const [fieldsToReset, setFieldsToReset] = useState<FieldUpdate[]>([]);
	const [fieldsByKey, setFieldsByKey] = useState<Map<string, PolicyFieldDto>>(new Map());
	const [visibilityMap, setVisibilityMap] = useState<Map<string, boolean>>(new Map());
	const [triggerPolicyFieldUpdate] = usePoliciesUpdatePolicyFieldValueMutation();
	const [triggerGetPolicyFieldTypeOptions] = useLazyPolicyTypeGetPolicyTypeFieldOptionsQuery();
	const [validationLookupValues, setValidationLookupValues] = useState(new Map<string, any>());

	if (!data)
		throw new Error("No policy page data available");

	const readOnly = isPolicyComponentReadOnly(data, username, permissions, PolicyComponentBehaviorType.RequiresNoSecondReview);

	const { data: allPolicyFields, isLoading: isLoadingFields, error: fieldsError } = usePolicyTypeGetPolicyTypeFieldsQuery({
		type: data.policy.type,
	});

	const { data: policyFieldValues, isLoading: isLoadingValues, error: valuesError } = usePoliciesGetCurrentPolicyFieldsValuesQuery({
		policyGuid: data.policy.guid
	});

	const isLoading = isLoadingFields || isLoadingValues;
	const error = fieldsError ? fieldsError : valuesError;

	const createOptionField = useCallback((
		field: PolicyFieldDto,
		fieldOptionsByGuid: Map<Guid, PolicyFieldOptionDto[]>,
		validationRule: ReactNode
	): JSX.Element => {
		const options = fieldOptionsByGuid.get(field.guid);
		if (!options)
			return <div>{Translate("error.field.options.missing", field.key ? field.key : "-")}</div>;
		const sortedOptions = [...options].sort((a, b) => (a.index ? a.index : -1) - (b.index ? b.index : -1));
		const dataSource = sortedOptions.map((option): EnumTypeOption => {
			const captionValue = option.caption && option.translate ? Translate(option.caption) : option.caption;
			return {
				key: option.key !== null ? option.key : option.guid,
				caption: formatSerialized(field.type, captionValue),
			};
		});

		return (
			<SimpleItem
				dataField={field.key ?? undefined}
				label={{ text: Translate(field.caption ? field.caption : "") }}
				cssClass={`${field.key} ${styles["wide-editor"]}`}
				editorType="dxSelectBox"
				editorOptions={{
					valueExpr: "key",
					displayExpr: "caption",
					dataSource,
					searchEnabled: true,
					format: formatSerialized,
				}}
			>
				{validationRule}
			</SimpleItem>);
	}, []);

	/* This is very specific validation rule: if the user is ‘indexing’ the element for which they have a taxation,
		then the taxation date can be up to 5 years old otherwise 3. So, this one was pretty tricky because it depends
		on the value of another field (indexed yes/no) and the logic is different based on it. I ended up creating two
		validation rules that are only triggering when relevant. */
	const validateTaxationDate = useCallback((indexationField: string, checkIndexed: boolean) =>
		({ value }: { value: any }): boolean => {
			if (!(value instanceof Date)) {
				throw new Error('Expected to get a date, but did not (needs any type because of devextreme wrong typing)');
			}
			const fieldValue = Boolean(validationLookupValues.get(indexationField));
			const indexed = Boolean(fieldValue ? fieldValue : false);

			// The method should only check when the indexed value matches: If the value is indexed, but the rule only holds
			// when not-indexed (or vice versa), the validation rule always returns true (i.e. valid).
			if (checkIndexed !== indexed)
				return true;
			const maxDate = startOfToday();
			const minDate = add(maxDate, { years: indexed ? -5 : -3 });
			return value ? value >= minDate && value <= maxDate : false;
		}, [validationLookupValues]);
	
	const validateCoolingDamage = useCallback(({ value }: { value: string | number }): boolean => {
		if (typeof value === "string")
			return false;
		const sumGoods = calculateSumGoods(validationLookupValues);
		return value > 0 && value <= sumGoods;

	}, [validationLookupValues]);

	const createValidationRule = useCallback((
		dataField: string | null | undefined,
		validationRule: string | null | undefined,
	): ReactNode[] | ReactNode => {
		if (!validationRule || validationRule === "")
			return null;

		switch (validationRule) {
			case ">0":
				return (<RangeRule message={Translate("policy.form.validation.greater-than-zero")} min={0.001} />);
			case ">=0":
				return (<RangeRule message={Translate("policy.form.validation.greater-than-or-equal-to-zero")} min={0} />);
			case "Not empty":
				return (<RequiredRule message={Translate("policy.form.validation.not-empty")} />);
			case "Cooling damage":
				return (<CustomRule
					key="cooling-damage"
					message={Translate("policy.form.validation.cooling-damage")}
					validationCallback={validateCoolingDamage}
					reevaluate
				/>);
			case "Taxation date": {
				const insured = dataField ? dataField.split("-")[2] : false;

				// We have this hard-coded behavior to find out what is the name of the ‘indexed’ policy field.
				// So, the field taxation-buildings will be dependent on the field ‘indexation-buildings’.
				const indexationFieldKey = `${INDEXATION_PREFIX}${insured}`;

				return [(<CustomRule
					key="indexed-rule"
					message={Translate("policy.form.validation.taxation-date-indexed")}
					validationCallback={validateTaxationDate(indexationFieldKey, true)}
					reevaluate
				/>),
				(<CustomRule
					key="not-indexed-rule"
					message={Translate("policy.form.validation.taxation-date-not-indexed")}
					validationCallback={validateTaxationDate(indexationFieldKey, false)}
					reevaluate
				/>)];
			}
			default:
				throw new Error(`Not implemented validation rule ${validationRule}`);
		}
	}, [validateCoolingDamage, validateTaxationDate]);

	const createField = useCallback((
		field: PolicyFieldDto,
		fieldOptionsByGuid: Map<Guid, PolicyFieldOptionDto[]>,
	): JSX.Element => {

		const validationRule = createValidationRule(field.key, field.validation);

		if (field.databaseOption)
			return createOptionField(field, fieldOptionsByGuid, validationRule);

		switch (field.type) {
			case FieldType.Boolean:
				return (
					<SimpleItem
						cssClass={`${field.key} ${styles["wide-editor"]}`}
						dataField={field.key ?? undefined}
						label={{ text: Translate(field.caption ? field.caption : "") }}
						editorType="dxCheckBox"
					>
						{validationRule}
					</SimpleItem>);
			case FieldType.Currency:
				return (
					<SimpleItem
						dataField={field.key ?? undefined}
						cssClass={field.key ?? undefined}
						label={{ text: Translate(field.caption ? field.caption : "") }}
						editorType="dxNumberBox"
						editorOptions={{
							format: { type: 'currency', currency: 'EUR', precision: 2, useCurrencyAccountingStyle: false }
						}}
					>
						{validationRule}
					</SimpleItem>);
			case FieldType.String:
				return (
					<SimpleItem
						cssClass={`${field.key} ${styles["wide-editor"]}`}
						dataField={field.key ?? undefined}
						label={{ text: Translate(field.caption ? field.caption : "") }}
						editorType="dxTextArea"
					>
						{validationRule}
					</SimpleItem>);
			case FieldType.Date:
				return (
					<SimpleItem
						dataField={field.key ?? undefined}
						cssClass={field.key ?? undefined}
						label={{ text: Translate(field.caption ? field.caption : "") }}
						editorType="dxDateBox"
						editorOptions={{ displayFormat: "dd/MM/yyyy" }}
					>
						{validationRule}
					</SimpleItem>);
			case FieldType.Enum:
				return createOptionField(field, fieldOptionsByGuid, validationRule);
			default:
				throw new Error(`Not implemented field type ${field.type} for field ${field.key}`);
		}

	}, [createOptionField, createValidationRule]);

	const formDataEmpty = formData === null;

	useAsyncEffect(async (): Promise<void> => {
		if (!formDataEmpty)
			return;
		// Data preprocessing method: once allPolicyFields and policyFieldValues are finished loading, the preprocessing can start
		if (!policyFieldGroups && allPolicyFields && policyFieldValues) {
			const valuesByKey = new Map<Guid, PolicyFieldValueDto>(policyFieldValues.map(v => [v.policyFieldGuid, v]));

			// We filter the data fields from the given category and order them by index
			const categoryPolicyFields = allPolicyFields
				.filter(x => x.category === category)
				.sort((a, b) => {
					if (a.index === undefined || b.index === undefined) return 0;
					else return a.index - b.index;
				});
			const initialFormData: any = {};

			let currentGroup: FieldGroup | null = null;
			const groups: FieldGroup[] = [];
			const formFieldsByKey = new Map<string, JSX.Element>();

			const fieldOptionsByGuid = new Map<Guid, PolicyFieldOptionDto[]>();
			const awaitPolicyFieldOptions = categoryPolicyFields
				.filter(x => x.guid && (x.type === FieldType.Enum || x.databaseOption))
				.map(async (f): Promise<void> => {
					const options = await triggerGetPolicyFieldTypeOptions({
						fieldGuid: f.guid,
						type: data.policy.type
					}).unwrap();
					fieldOptionsByGuid.set(f.guid, options);
				});
			await Promise.all(awaitPolicyFieldOptions);

			categoryPolicyFields.forEach(f => {
				if (!f.key || !f.guid)
					return;
				const serializedValue = valuesByKey.get(f.guid);
				const value = f.databaseOption
					? dbOption(serializedValue)
					: deserialize(f.type, serializedValue && serializedValue.value ? serializedValue.value : f.defaultValue);
				if (isValidationLookupField(f.key))
					validationLookupValues.set(f.key, value);
				initialFormData[f.key] = value;
				formFieldsByKey.set(f.key, createField(f, fieldOptionsByGuid));
				const header = f.subHeader ? f.subHeader : null;
				if (!currentGroup || currentGroup.header !== header) {
					currentGroup = { id: groups.length, header, fields: [] };
					groups.push(currentGroup);
				}
				currentGroup.fields.push(f);
			});

			// After preprocessing we add all of it to state:
			//  . A dictionary with the fields by key
			//  . The different groups of fields
			//  . The actual field components by key
			//  . Indexation values (see taxation date validation above)
			//  . The initial form data
			setFieldsByKey(new Map(categoryPolicyFields.map(f => [f.key as string, f])));
			setPolicyFieldGroups(groups);
			setFormFields(formFieldsByKey);
			setValidationLookupValues(validationLookupValues);
			setFormData(initialFormData);
		}

	}, [fieldsByKey, allPolicyFields, category, createField, data, policyFieldGroups, policyFieldValues,
		triggerGetPolicyFieldTypeOptions, validationLookupValues, formDataEmpty]);

	const isVisible = useCallback((visibilityConditions: string | null | undefined): boolean => {
		if (!visibilityConditions)
			return true;

		return matcher.Evaluate(visibilityConditions, (f: string) => formData[f]);
	}, [formData]);

	useEffect(() => {
		// We need to make sure all queued fields are reset before we overwrite the set
		if (fieldsToReset.length > 0)
			return;
		if (!policyFieldGroups || !formData)
			return;

		const map = new Map<string, boolean>();

		policyFieldGroups.forEach(x => {
			x.fields.forEach(field => {
				if (field.visibilityCondition && !map.has(field.visibilityCondition)) {
					map.set(field.visibilityCondition, isVisible(field.visibilityCondition));
				}
			});
		});

		// We update the visibility map. While we do this, we note any fields that became invisible since last time
		// and which have a nondefault value: These will be reset later
		setVisibilityMap(previousMap => {
			const fToReset: FieldUpdate[] = [];
			policyFieldGroups.forEach(x => {
				x.fields.forEach(field => {
					if (field.visibilityCondition && field.key) {
						// If the field is now invisible, while it was before visible, it is hidden
						if (!map.get(field.visibilityCondition) && previousMap.get(field.visibilityCondition)) {
							const newValue = deserialize(field.type, field.defaultValue);
							// We only reset the value back to default if it is not already default
							if (formData[field.key] !== newValue) {
								fToReset.push({
									fieldGuid: field.guid,
									serializedValue: field.defaultValue,
									deserializedValue: newValue,
									type: field.type,
									key: field.key
								});
							}
						}
					}
				});
			});
			if (fToReset.length > 0 || fieldsToReset.length > 0)
				setFieldsToReset(fToReset);
			return map;
		});
	}, [policyFieldGroups, formData, isVisible, triggerPolicyFieldUpdate, fieldsToReset]);

	useAsyncEffect(async () => {
		if (fieldsToReset.length > 0) {
			// We update the front-end data. Note that this will trigger the effect above, which
			// will trigger this effect... This is desired:
			// Changing the value of field A might reset B to default in the first pass,
			// field B to default might set C to default in the second pass, etc.
			setFormData((prev: any): any => {
				const newData = {
					...prev,
				};
				fieldsToReset.forEach(x => {
					newData[x.key] = x.deserializedValue;
				});
				return newData;
			});
			// We call the server
			const fieldUpdatePromises = fieldsToReset.map(f => {
				const args = {
					fieldGuid: f.fieldGuid,
					policyGuid: data.policy.guid,
					updatePolicyFieldValueDto: {
						value: f.serializedValue,
					}
				};
				return triggerPolicyFieldUpdate(args);
			});
			await Promise.all(fieldUpdatePromises);
			setFieldsToReset([]);
		}
	}, [fieldsToReset, setFieldsToReset]);

	const setFieldValue = useCallback(async (e: FieldDataChangedEvent): Promise<void> => {

		if (!e.dataField)
			return;
		const field = e.dataField ? fieldsByKey.get(e.dataField) : null;
		if (!field)
			throw new Error(`Guid for key ${e.dataField} not found`);

		setFormData((prev: any): any => {
			if (!e.dataField)
				return prev;
			const newData = {
				...prev,
				[e.dataField]: e.value,
			};
			return newData;
		});

		// If the changed value is an indexation value, we set the indexation value in the state object for indexation values.
		if (isValidationLookupField(e.dataField))
			setValidationLookupValues(validationLookupValues.set(e.dataField, e.value));

		// We now have to send update requests for all changed values.
		const args = {
			fieldGuid: field.guid,
			policyGuid: data.policy.guid,
			updatePolicyFieldValueDto: {
				value: serialize(field.type, e.value),
			}
		};
		await triggerPolicyFieldUpdate(args);

		onFormChanged();
	}, [data, fieldsByKey, triggerPolicyFieldUpdate, validationLookupValues, onFormChanged]);

	if (error)
		throw new Error(`Error retrieving policy fields: ${error}`);

	if (isLoading || !policyFieldGroups)
		return <LoadIndicator />;

	return (
		<div>
			<Form
				formData={formData}
				readOnly={readOnly}
				onOptionChanged={onFormChanged}
				onFieldDataChanged={setFieldValue}
				validationGroup={POLICY_VALIDATION_GROUP}
				onContentReady={disableAutocompleteOnForm}
			>
				{policyFieldGroups.map(group => (
					<GroupItem key={group.id} caption={group.header ? Translate(group.header) : undefined}>
						{group.fields.map((field) => {
							const visible = !field.visibilityCondition ||
								(visibilityMap && visibilityMap.get(field.visibilityCondition));
							return (
								<GroupItem key={field.key} cssClass={visible ? "" : styles["hidden-item"]}>
									<GroupItem cssClass={visible ? "" : styles["hidden-item"]} visible={visible}>
										{field.key ? formFields.get(field.key) : null}
									</GroupItem>
								</GroupItem>
							);
						})}
					</GroupItem>
				))}
			</Form>
			{[...fieldsByKey.values()].filter(f => f.tooltipCaption).map((f) => (
				<Tooltip
					key={`tooltip-${f.key}`}
					target={`.${f.key} > label > span > span`}
					showEvent="dxhoverstart"
					hideEvent="dxhoverend"
				>
					{Translate(f.tooltipCaption ? f.tooltipCaption : "")}
				</Tooltip>
			))}
			{formData["suppliers-insured"] ? (
				<SuppliersTableComponent onFormChanged={onFormChanged} />
			) : undefined}
		</div>
	);
};

export default memo(GenericDataComponent);