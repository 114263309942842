import React, { memo, useCallback, useMemo } from "react";

import { DataGrid } from "devextreme-react";
import { Column } from "devextreme-react/data-grid";

import { PremiumCalcFactorsDto, PremiumCalcVectorDto } from "../../../apis/PoliciesApi";
import styles from "../../../styles/Calculation.module.scss";
import { Translate } from "../../../utils/Translation";
import ComponentTitle from "../../titles/ComponentTitle";
import { getProcessedVectors } from "./CalculationUtils";

type Props = {
	readonly vectors: PremiumCalcVectorDto[],
	readonly factors: PremiumCalcFactorsDto
};

const promileFormat = "#0.####‰";

const CalculationComponent = ({ vectors, factors }: Props): JSX.Element => {
	const tableData = useMemo(() => {
		return getProcessedVectors(vectors, factors);
	}, [vectors, factors]);

	const cellPrepared = useCallback((e: any): void => {
		if (e.rowType === "data") {
			if (e.data.isDiscount) {
				e.cellElement.classList.add(styles["discount-cell"]);
			} else {
				e.cellElement.classList.add(styles["default-cell"]);
			}

			if (e.data.isImportant) {
				e.cellElement.classList.add(styles["important-cell"]);
			}
			if (e.data.isCustom) {
				e.cellElement.classList.add(styles["custom-cell"]);
			}
			if (e.data.isFinal) {
				e.cellElement.classList.add(styles["final-cell"]);
			}
		}
	}, []);

	return (
		<>
			<ComponentTitle text={Translate("premium-calculation.calculation")} id={"calculation"} />
			<div className={styles["calculation-table"]}>

				<DataGrid
					dataSource={tableData}
					keyExpr="name"
					showBorders
					columnAutoWidth
					columnHidingEnabled
					paging={{ enabled: false }}
					onCellPrepared={cellPrepared}
					sorting={undefined}
					cellHintEnabled
				>
					<Column
						dataField="name"
						caption={Translate("premium-calculation.name")}
					/>
					<Column
						dataField="factor"
						caption={Translate("premium-calculation.factor")}
					/>
					<Column
						dataField="buildings"
						caption={Translate("premium-calculation.buildings")}
						format={promileFormat}
					/>
					<Column
						dataField="inventory"
						caption={Translate("premium-calculation.inventory")}
						format={promileFormat}
					/>
					<Column
						dataField="machines"
						caption={Translate("premium-calculation.machines")}
						format={promileFormat}
					/>
					<Column
						dataField="goods"
						caption={Translate("premium-calculation.goods")}
						format={promileFormat}
					/>
					<Column
						dataField="corporateDamage"
						caption={Translate("premium-calculation.corporate-damage")}
						format={promileFormat}
					/>
					<Column
						dataField="reconstructionCosts"
						caption={Translate("premium-calculation.reconstruction-costs")}
						format={promileFormat}
					/>
					<Column
						dataField="extraCosts"
						caption={Translate("premium-calculation.extra-costs")}
						format={promileFormat}
					/>
					<Column
						dataField="coolingDamage"
						caption={Translate("premium-calculation.cooling-damage")}
						format={promileFormat}
					/>
					<Column
						dataField="supplier"
						caption={Translate("premium-calculation.supplier")}
						format={promileFormat}
					/>
				</DataGrid>
				<div className={styles["warning"]}>
					<span className={styles["asterisk"]}>*</span>{Translate("premium-calculation.warning")}
				</div>
			</div>
		</>);
};

export default memo(CalculationComponent);