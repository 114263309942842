export default class CustomError extends Error {

    userMessage: string;
    severityLevel: number;
    component: string;
    method: string;

    constructor(message: string, userMessage: string, severityLevel: number, component: string, method: string) {
        super(message);
        
        this.name = CustomError.name;
        this.userMessage = userMessage;
        this.severityLevel = severityLevel;
        this.component = component;
        this.method = method;
    }
}
