import React, { memo, useCallback } from "react";

import { usePoliciesRespondToQuoteMutation } from "../../../apis/PoliciesApi";
import { Guid } from "../../../utils/Guid";
import PremiumDialogButtons from "./PremiumDialogButtons";

type Props = {
	readonly policyGuid: Guid;
};

const RespondToQuoteWidget = ({ policyGuid }: Props): JSX.Element => {

	const [triggerRespondToQuote] = usePoliciesRespondToQuoteMutation();


	const handleRespondToQuote = useCallback(async (acceptQuote: boolean): Promise<void> => {
		await triggerRespondToQuote({
			policyGuid,
			acceptQuote
		});
	}, [policyGuid, triggerRespondToQuote]);

	return (
			<PremiumDialogButtons
				acceptAction={(): Promise<void> => handleRespondToQuote(true)}
				rejectAction={(): Promise<void> => handleRespondToQuote(false)}
				premiumStage={"quote"}
			/>
		);
};

export default memo(RespondToQuoteWidget);