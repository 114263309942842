import React from "react";

import AddressComponent, { AddressType } from "../utility-components/AddressComponent";
import BaseComponent from "./BaseComponent";

type Props = {
	readonly onFormChanged: () => Promise<void>;
};

const PolicyHolderAddressComponent = ({ onFormChanged }: Props): JSX.Element => {

	return (
		<BaseComponent id="address-location" captionKey="policy.policyholder-address.title">
			<AddressComponent onFormChanged={onFormChanged} addressType={AddressType.PolicyHolder} />
		</BaseComponent>
	);
};

export default React.memo(PolicyHolderAddressComponent);