import { Translate } from "./Translation";

/*
 * Returns the substring contained in text, between the start and the end
 */
export const getFromBetween = (start: string, end: string, text: string): string => {
	if (!text.includes(start) || !text.includes(end)) return "";

	const first = text.indexOf(start) + start.length;
	const string1 = text.substring(0, first);
	const string2 = text.substring(first);
	const second = string1.length + string2.indexOf(end);
	return text.substring(first, second);
};

/*
 * This function takes the textToParse string,
 * looks for all substrings included in (startSymbol, endSymbol),
 * and translates the substrings with values in the dataObject,
 * using the substrings as keys.
 * Example:
 * textToParse: In my message I want ${a} values from the list ${c.c2}.
 * dataObject: {"a":"15", "c":{"c2":["A","B","C"]}}
 * result: In my message I want 15 values from the list A, B, C.
 * There two ways to show only few elements from an array:
 * - ellipses: ${c.c2:ellipses@2} -> In my message I want 15 values from the list A, B....
 * - cut: ${c.c2:cut@2} -> In my message I want 15 values from the list A, B and 1 others.
 */
export const parseMessage = (
	startSymbol: string,
	endSymbol: string,
	textToParse: string,
	dataObject: string,
	argumentsToTranslate: string[] = []
): string => {
	// first check to see if we do have both substrings
	if (!textToParse.includes(startSymbol) || !textToParse.includes(endSymbol))
		return textToParse;

	// find one result
	const result = getFromBetween(startSymbol, endSymbol, textToParse);
	const jsonDescription = JSON.parse(dataObject);
	let value = [];
	let isArray = false;
	let action = "";
	let elemToShow = 0;
	let fields = result;

	if (fields.includes(":")) {
		const parts = fields.split(":");
		fields = parts[0];
		const actionParts = parts[1];
		const actionPartsArray = actionParts.split("@");
		action = actionPartsArray[0];
		elemToShow = Number(actionPartsArray[1]);
		isArray = true;
	}

	if (fields.includes(".")) {
		const parts = fields.split(".");

		const count = parts.length;
		let index = 0;
		const findValue = jsonDescription[parts[index]];

		while (index < count - 1) {
			index += 1;
			value = findValue[parts[index]];
		}
	} else value = jsonDescription[fields];

	if (isArray && action !== "") {
		const total = value.length;
		const rest = total - elemToShow;
		value = value.slice(0, elemToShow);

		if (rest > 0) {
			switch (action) {
				case "ellipses":
					value += "...";
					break;
				case "cut":
					value += Translate("parse.message.others", rest.toString());
					break;
			}
		}
	}

	const stringToReplace = startSymbol + result + endSymbol;
	const res = textToParse.replace(stringToReplace, argumentsToTranslate.find((x) => x === result) ? Translate(value) : value);

	// recursive
	if (res.includes(startSymbol) && res.includes(endSymbol)) {
		return parseMessage(startSymbol, endSymbol, res, dataObject);
	} else return res;
};

// Interpret a UTC date as if it were a local time
// e.g. the date 14-03-2022 0:00 UTC in GMT+2 will be interpreted as 14-03-2022 0:00 GMT+2 / 13-03-2022 22:00 UTC
export function utcAsLocal(utcDate: Date): Date {
	const localDate = new Date(utcDate);
	// Note that setMinutes can accept values like -120 or 180, it does not need to be between 0 and 60
	localDate.setMinutes(localDate.getMinutes() + utcDate.getTimezoneOffset());
	return localDate;
}

export function localAsUtc(localDate: Date): Date {
	const utcDate = new Date(localDate);
	utcDate.setMinutes(utcDate.getMinutes() - localDate.getTimezoneOffset());
	return utcDate;
}