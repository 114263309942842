import React from "react";

import Toolbar, { Item } from "devextreme-react/toolbar";

import styles from "../../styles/drawer.module.scss";

type Props = {
  readonly title: string;
  readonly children: JSX.Element;
  readonly closeDrawer: () => void;
};

const DrawerContent = ({ title, children, closeDrawer }: Props): JSX.Element => {
  const DrawerTitle = (): JSX.Element => {
    return <h1 className={`dx-theme-generic-typography ${styles.title}`}>{title}</h1>;
  };

  const closeOptions = {
    width: 30,
    icon: "close",
    stylingMode: "text",
    displayExpr: "text",
    onClick: (): void => {
      closeDrawer();
    },
  };
  return (
  <div className="drawer">
  <Toolbar className={styles.drawerToolbar}>
  <Item location="before" locateInMenu="never" render={DrawerTitle} />
  <Item location="after" locateInMenu="never" widget="dxButton" options={closeOptions} />
  </Toolbar>
  {children}
  </div>
  );
};

export default DrawerContent;
