import { PreRiskAnswerDto } from "../apis/PoliciesApi";
import { PolicyFieldOptionDto } from "../apis/PolicyTypeApi";
import { PreRiskQuestionDto } from "../apis/PreRiskQuestionsApi";
import { FieldValueRetrieval } from "../models/policies/FieldValueRetrieval";
import { PreRiskData } from "../store/PreRiskState";
import { Guid, parseGuid } from "../utils/Guid";
import { AddNative, Evaluate as baseEval, EvaluatorInitFunc } from "./BaseExpressionEvaluator";

class QuestionWrapper {
    question: PreRiskQuestionDto;
    answer: PreRiskAnswerDto;

    constructor(question: PreRiskQuestionDto, answer: PreRiskAnswerDto) {
        this.question = question;
        this.answer = answer;
    }
}

function HasCoverage(option: string, coverages: Set<string>): boolean {
    return coverages.has(option);
}

function GetAnswer(question: string, questionsByName: Map<string, QuestionWrapper>): string {
    const wrapper = questionsByName.get(question);
    if (wrapper && wrapper.answer && wrapper.answer.value) {
        return wrapper.answer.value;
    }
    return "";
}

export class PreRiskQuestionMatcher {

    questionsByName: Map<string, QuestionWrapper>;
    fieldOptionsByGuid: Map<Guid, PolicyFieldOptionDto>;
    coverages: Set<string>;

    constructor(data: PreRiskData | null, answers: PreRiskAnswerDto[]) {
        this.questionsByName = new Map<string, QuestionWrapper>();
        this.fieldOptionsByGuid = new Map<Guid, PolicyFieldOptionDto>();
        this.coverages = new Set<string>();

        if (data === null)
            return;

        // Store questions
        for (const question of data.questions) {
            if (question.name === null)
                continue;
            const answer = answers.find(x => x.questionName === question.name);
            if (!answer)
                continue;
            this.questionsByName.set(question.name, new QuestionWrapper(question, answer));
        }

        // Store field options
        for (const fieldOption of data.fieldOptions) {
            this.fieldOptionsByGuid.set(fieldOption.guid, fieldOption);
        }

        // Store coverages
        for (const coverage of data.coverage.filter(x => x.value)) {
            if (coverage.code) {
                this.coverages.add(coverage.code);
            }
        }
    }

    public Evaluate(condition: string, fieldRetrieval: FieldValueRetrieval, initFunc: EvaluatorInitFunc | null = null): boolean {
        return baseEval(condition, (interpreter: any, globalObject: any): void => {
            // Natives based on arguments
            AddNative(interpreter, globalObject, "getPolicyField", (option: string): string => {
                const value = fieldRetrieval(option);
                if (!value)
                    return "";
                return value;
            });

            AddNative(interpreter, globalObject, "getDbOptionValue", (field: string): string => {
                const fieldValue = fieldRetrieval(field);
                const guid = parseGuid(fieldValue);
                const fieldOption = this.fieldOptionsByGuid.get(guid);
                if (!fieldOption || !fieldOption.value)
                    return "";
                return fieldOption.value;
            });

            // Natives based on object state
            this.LocalInit(interpreter, globalObject);

            // Any custom provided natives
            if (initFunc !== null) {
                initFunc(interpreter, globalObject);
            }
        });
    }

    private LocalInit(interpreter: any, globalObject: any): void {
        AddNative(interpreter, globalObject, "getAnswer", (x => GetAnswer(x, this.questionsByName)));
        AddNative(interpreter, globalObject, "hasCoverage", (x => HasCoverage(x, this.coverages)));
    }
}