import { useEffect, useMemo, useRef, useState } from "react";

/**
 * Hook to run an async effect on mount and another on unmount.
 * Credit: https://marmelab.com/blog/2023/01/11/use-async-effect-react.html
 */
export const useAsyncEffectWithUnmount = (
	mountCallback: () => Promise<any>,
	unmountCallback: () => Promise<any>,
	deps: any[] = [],
): UseAsyncEffectResult => {
	const isMounted = useRef(false);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<unknown>(undefined);
	const [result, setResult] = useState<any>();

	useEffect(() => {
		isMounted.current = true;
		return (): void => {
			isMounted.current = false;
		};
	}, []);

	useEffect(() => {
		let ignore = false;
		let mountSucceeded = false;

		(async (): Promise<void> => {
			// wait for the initial cleanup in Strict mode - avoids double mutation
			await Promise.resolve(); 
			if (!isMounted.current || ignore) {
				return;
			}
			setIsLoading(true);
			try {
				const mountResult = await mountCallback();
				mountSucceeded = true;
				if (isMounted.current && !ignore) {
					setError(undefined);
					setResult(mountResult);
					setIsLoading(false);
				} else {
					// Component was unmounted before the mount callback returned, cancel it
					unmountCallback();
				}
			} catch (mountError) {
				if (!isMounted.current) return;
				setError(mountError);
				setIsLoading(false);
			}
		})();

		return (): void => {
			ignore = true;
			if (mountSucceeded) {
				unmountCallback()
					.then(() => {
						if (!isMounted.current) return;
						setResult(undefined);
					})
					.catch((unmountError: unknown) => {
						if (!isMounted.current) return;
						setError(unmountError);
					});
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, deps);

	return useMemo(() => ({ result, error, isLoading }), [
		result,
		error,
		isLoading,
	]);
};

export const useAsyncEffect = (
	mountCallback: () => Promise<any>,
	deps: any[] = []
): UseAsyncEffectResult => {
	return useAsyncEffectWithUnmount(mountCallback, 
		async (): Promise<void> => {
			await Promise.resolve(); 
		}, deps
	);
};

export type UseAsyncEffectResult = {
	result: any;
	error: any;
	isLoading: boolean;
};