import "./custom.css";

import React, { useEffect, useState } from "react";

import { LoadIndicator } from "devextreme-react";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";

import { useAppSettingsFetchAuthenticationProviderSettingsQuery } from "./apis/AppSettingsApi";
import { useLazyPermissionsGetPermissionsQuery } from "./apis/PermissionsApi";
import { useLazyTranslationsGetTranslationsQuery } from "./apis/TranslationsApi";
import StaticDataValidation from "./components/admin/StaticDataValidation";
import ReleaseNotes from "./components/help/ReleaseNotes";
import Main from "./components/Main";
import NavBar from "./components/navBar/NavBar";
import PoliciesWrapper from "./components/policies/PoliciesWrapper";
import Policy from "./components/policies/Policy";
import PreRiskQuestionnaire from "./components/policies/pre-risk-components/PreRiskQuestionnaire";
import ScrollRestoration from "./components/scroll/ScrollRestoration";
import HeaderWithDrawer from "./components/sidePanel/HeaderWithDrawer";
import { useAsyncEffect } from "./hooks/AsyncEffectHook";
import { useAppDispatch } from "./hooks/hooks";
import { setPermissions, setRole, setToken, setUsername } from "./store/Identity";
import { store } from "./store/store";
import { ErrorBoundary, useErrorHandling } from "./utils/error-boundary";
import { AddLanguages, GetUserLanguageOrDefault, LoadLanguages } from "./utils/Translation";
import config from 'devextreme/core/config';

const licenseKey = process.env.REACT_APP_DEVEXTREME_LICENSE_KEY;
config({licenseKey});

type MyAppProps = {
	readonly audience: string;
};

const MyApp = ({ audience }: MyAppProps): JSX.Element => {
	const dispatch = useAppDispatch();

	const [userIsSet, setUserIsSet] = useState<boolean>(false);
	const [tokenAcquired, setTokenAcquired] = useState(false);
	const [translationsInitialized, setTranslationsInitialized] = useState(false);
	const [triggerGetTranslations] = useLazyTranslationsGetTranslationsQuery();
	const [triggerGetPermissions] = useLazyPermissionsGetPermissionsQuery();

	const {
		user,
		isAuthenticated,
		loginWithRedirect,
		isLoading,
		getAccessTokenSilently,
	} = useAuth0();
	const throwError = useErrorHandling().triggerError;

	const currentLocale = GetUserLanguageOrDefault();

	if (typeof window !== "undefined") {
		if (user && user.name && !userIsSet) {
			dispatch(setUsername(user.name));

			const roles = user[`${audience}/roles`];
			if (roles)
				dispatch(setRole(roles.length > 0 ? roles[0] : "Unauthorized"));

			getAccessTokenSilently({
				authorizationParams: { audience },
			}).then((token) => {
				dispatch(setToken(token));
				setTokenAcquired(true);
			});

			setUserIsSet(true);
		}
	}

	useEffect(() => {
		if (!isAuthenticated && !isLoading) loginWithRedirect();
	}, [isAuthenticated, isLoading, loginWithRedirect, throwError]);

	useAsyncEffect(async (): Promise<void> => {

		if (translationsInitialized || !isAuthenticated || !tokenAcquired)
			return;
		const translations = await triggerGetTranslations().unwrap();
		const translationsEn = translations.filter((t) => t.language === "en");
		const translationsNl = translations.filter((t) => t.language === "nl");

		const enObject: any = { en: {} };
		const nlObject: any = { nl: {} };

		translationsEn.forEach((t): void => {
			if (t.code)
				enObject.en[t.code] = t.value;
		});

		translationsNl.forEach((t): void => {
			if (t.code)
				nlObject.nl[t.code] = t.value;
		});

		AddLanguages(enObject, nlObject);
		LoadLanguages();
		setTranslationsInitialized(true);
	}, [isAuthenticated, translationsInitialized, triggerGetTranslations, tokenAcquired]);

	useAsyncEffect(async (): Promise<void> => {
		if (!isAuthenticated || !tokenAcquired)
			return;

		const permissions = await triggerGetPermissions().unwrap();
		dispatch(setPermissions(permissions));
	}, [isAuthenticated, tokenAcquired]);

	if (isAuthenticated && translationsInitialized) {
		return (
			<BrowserRouter>
				<ScrollRestoration>
					<HeaderWithDrawer locale={currentLocale}>
						<div id="content" className="dx-theme-background-color">
							<div className="flex-container">
								<div className="flex-item-left">
									<NavBar />
								</div>
								{
									<div className="flex-item-right">
										<main className="padding-1-rem">
											{!isLoading && (
												<ErrorBoundary>
													<Routes>
														<Route path="/" element={<PoliciesWrapper applyTodoListFilter />} />
														<Route path="/policies" element={<PoliciesWrapper />} />
														<Route path="/policy/:guid" element={<Policy />} />
														<Route
															path="/policy/:guid/prerisk"
															element={<PreRiskQuestionnaire />}
														/>
														<Route
															path="/inspection-schedule"
															element={
																<Main title="Inspection bureau">
																	Inspection bureau placeholder
																</Main>
															}
														/>
														<Route
															path="/inspections"
															element={
																<Main title="Inspections">
																	Inspections placeholder
																</Main>
															}
														/>
														<Route
															path="/dashboard"
															element={
																<Main title="Dashboards">
																	Dashboard placeholder
																</Main>
															}
														/>
														<Route
															path="/admin"
															element={
																<StaticDataValidation />
															}
														/>
														<Route
															path="/release-notes"
															element={<ReleaseNotes />}
														/>
													</Routes>
												</ErrorBoundary>
											)}
										</main>
									</div>
								}
							</div>
						</div>
					</HeaderWithDrawer>
				</ScrollRestoration>
			</BrowserRouter>
		);
	}

	return <> </>;
};

const AuthenticatedApp = (): JSX.Element => {
	const { data: authenticationProvider, error } = useAppSettingsFetchAuthenticationProviderSettingsQuery();

	if (error)
		return <div>Fetching authentication provider failed. Please contact support.</div>;
	if (!authenticationProvider)
		return <LoadIndicator />;
	if (!authenticationProvider.domain || !authenticationProvider.audience || !authenticationProvider.clientId)
		return <div>Authentication provider not defined. Please contact support.</div>;

	return (
		<Auth0Provider
			domain={authenticationProvider.domain}
			clientId={authenticationProvider.clientId}
			// eslint-disable-next-line camelcase
			authorizationParams={{audience: authenticationProvider.audience, redirect_uri: window.location.origin}}
		>
			<MyApp audience={authenticationProvider.audience} />
		</Auth0Provider>		
	);
};

const App = (): JSX.Element => {
	return (
		<ErrorBoundary showFullPage>
			<Provider store={store}>
				<AuthenticatedApp />
			</Provider>
		</ErrorBoundary>
	);
};

export default App;
