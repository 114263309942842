import React, { useCallback, useState } from "react";

import { useLazyStaticDataValidatePendingDataQuery, useStaticDataValidateAndInsertPendingDataMutation } from "../../apis/StaticDataApi";
import { useAppSelector } from "../../hooks/hooks";
import { selectIdentity } from "../../store/Identity";
import { canWriteStaticData } from "../../utils/PolicyUtil";
import { Translate } from "../../utils/Translation";
import Button from "../buttons/Button";

const StaticDataValidation = (): JSX.Element => {

	const { permissions } = useAppSelector(selectIdentity);

	const [validationFeedback, setValidationFeedback] = useState<string[]>([]);
	const [validationPending, setValidationPending] = useState<boolean>(false);
	const [isValid, setIsValid] = useState<boolean | null>(null);

	const [triggerValidatePendingData] = useLazyStaticDataValidatePendingDataQuery();
	const [triggerValidateAndInsertPendingData] = useStaticDataValidateAndInsertPendingDataMutation();
	const validatePendingData = useCallback(async (): Promise<void> => {
		setIsValid(null);
		setValidationPending(true);
		const result = await triggerValidatePendingData().unwrap();
		setValidationPending(false);
		setIsValid(result.isValid);
		if (result.messages !== null)
			setValidationFeedback(result.messages);
	}, [triggerValidatePendingData]);

	const validateAndInsertPendingData = useCallback(async (): Promise<void> => {
		setIsValid(null);
		setValidationPending(true);
		const result = await triggerValidateAndInsertPendingData().unwrap();
		setValidationPending(false);
		setIsValid(result.isValid);
		if (result.messages !== null)
			setValidationFeedback(result.messages);
	}, [triggerValidateAndInsertPendingData]);

	if (!canWriteStaticData(permissions))
		return <div />;
	const validationItems = validationFeedback.map(x => <li key={x}>{x}</li>);

	let validationFeedbackPart;

	if (validationPending) {
		validationFeedbackPart = <div><h4>{Translate("admin.validate-static-data.validating")}</h4></div>;
	} else if (isValid === true) {
		validationFeedbackPart = <div><h4>{Translate("admin.validate-static-data.success")}</h4></div>;
	} else if (isValid === false) {
		validationFeedbackPart =
			<div>
				<h4>{Translate("admin.validate-static-data.failure")}</h4>
				<h4>{Translate("admin.validate-static-data.feedback")}</h4>
				<ul>{validationItems}</ul>
			</div>;
	} else {
		validationFeedbackPart = null;
	}

	return (
		<div>
			<Button text={Translate("admin.validate-static-data")} onClick={validatePendingData} autoWidth isDisabled={validationPending} />
			<Button
				text={Translate("admin.validate-static-data.and-replace")}
				onClick={validateAndInsertPendingData}
				autoWidth
				isDisabled={validationPending}
			/>
			{validationFeedbackPart}
		</div>
	);
};

export default StaticDataValidation;