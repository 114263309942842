import React, { memo, useMemo } from "react";

import { useNavigate } from "react-router";

import { InspectionState, PolicyDto, PolicyStatus, PolicyVersionDto } from "../../../apis/PoliciesApi";
import { useAppSelector } from "../../../hooks/hooks";
import { selectIdentity } from "../../../store/Identity";
import styles from "../../../styles/Policy.module.scss";
import { canWritePolicy } from "../../../utils/PolicyUtil";
import { Translate } from "../../../utils/Translation";
import Button from "../../buttons/Button";
import ComponentTitle from "../../titles/ComponentTitle";
import PhysicalInspectionComponent from "./PhysicalInspectionComponent";

type Props = {
	readonly policy: PolicyDto;
	readonly policyVersion: PolicyVersionDto;
};

const InspectionComponent = ({ policy, policyVersion }: Props): JSX.Element => {

	const navigate = useNavigate();
	const { username, permissions } = useAppSelector(selectIdentity);
	const userCanWritePolicy = canWritePolicy(username, permissions, policy);

	const inspectionComponent = useMemo(() => {
		switch (policy.status) {
			case PolicyStatus.FullInspectionRequired:
			case PolicyStatus.QuickScanInspectionRequired:
				return policy.inspectionState !== InspectionState.NoInspection ?
					<PhysicalInspectionComponent policy={policy} policyVersion={policyVersion} /> :
					<div />;
			case PolicyStatus.PreRiskRequired:
				return (
					<div>
						{Translate("policy.inspection.pre-risk")}
						{userCanWritePolicy ?
							<div
								className={styles["actions"]}
								key="pre-risk"
								id="pre-risk"
							>
								<Button
									autoWidth
									text={Translate("policy.inspection.fill-pre-risk")}
									onClick={(): void => navigate('prerisk')}
								/>
							</div>
							: null}
					</div>);
			default:
				return null;
		}
	}, [userCanWritePolicy, navigate, policy, policyVersion]);

	if (policy.status !== PolicyStatus.FullInspectionRequired
		&& policy.status !== PolicyStatus.QuickScanInspectionRequired
		&& policy.status !== PolicyStatus.PreRiskRequired) {
		return <div />;
	}

	return (
		<>
			<ComponentTitle text={Translate("policy.form.title.inspection")} id={"inspection"} />
			{inspectionComponent}
		</>
	);
};

export default memo(InspectionComponent);