import React, { memo, useCallback } from "react";

import notify from "devextreme/ui/notify";
import validationEngine from "devextreme/ui/validation_engine";

import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";

import {
  CustomError, PolicyDto, usePoliciesCreatePremiumMutation, usePoliciesHasLatestOutdatedPremiumQuery, usePoliciesHasLatestPremiumQuery,
} from "../../../apis/PoliciesApi";
import styles from "../../../styles/Policy.module.scss";
import { canRunPremium, POLICY_VALIDATION_GROUP } from "../../../utils/PolicyUtil";
import { Translate } from "../../../utils/Translation";
import Button from "../../buttons/Button";

type Props = {
	readonly policy: PolicyDto;
	readonly isFormValid: boolean | undefined;
	readonly setSendingRequest: (sendingRequest: boolean) => void
	readonly validate: () => Promise<void>;
};

const RequestPremiumIndicationComponent = ({ policy, isFormValid, setSendingRequest, validate }: Props): JSX.Element => {

	const [triggerCreatePremium] = usePoliciesCreatePremiumMutation();

	const { data: hasPremium, error: hasPremiumError } = usePoliciesHasLatestPremiumQuery({ policyGuid: policy.guid });
	const { data: outdated, error: outdatedError } = usePoliciesHasLatestOutdatedPremiumQuery({ policyGuid: policy.guid });
	if (hasPremiumError)
		throw new Error(`Error getting has premium: ${hasPremiumError}`);
	if (outdatedError)
		throw new Error(`Error getting has outdated premium: ${outdatedError}`);


	const createPremium = useCallback(async (): Promise<void> => {
		try {
			await triggerCreatePremium({
				policyGuid: policy.guid,
			}).unwrap();
		} catch (error) {
			const fetchError = error as FetchBaseQueryError;
			let fullError = Translate("error.premium-indication.request", `(${JSON.stringify(error)})`);
			if (fetchError) {

				const customError = fetchError.data as CustomError;
				if (customError) {
					fullError = `${Translate(customError.key === null ? "" : customError.key)} ` 
						+ `(${customError.status}: ${customError.message})`;
				} else {
					const errorData = fetchError.data as any;
					const message = errorData && errorData.message ? errorData.message : JSON.stringify(fetchError.data);
					fullError = Translate("error.premium-indication.request", `(${fetchError.status}: ${message})`);
				}
			}
			notify({
				message: fullError,
				width: 800,
				position: {
					at: "bottom",
					my: "top",
					offset: 20,
					of: "#premium-indication"
				}
			}, "error", 10000);
		}
	}, [policy, triggerCreatePremium]);

	const validateDataAndCreatePremium = useCallback(async (): Promise<void> => {
		setSendingRequest(true);
		try {
			validate();
			const validationResult = validationEngine.validateGroup(POLICY_VALIDATION_GROUP);
			if (validationResult.status === "pending" && validationResult.complete) {
				const r = await validationResult.complete;
				if (r.status === "valid")
					await createPremium();
				setSendingRequest(false);
			} else {
				if (validationResult.status === "valid")
					await createPremium();
				setSendingRequest(false);
			}
		} catch {
			setSendingRequest(false);
		}
	}, [validate, createPremium, setSendingRequest]);

	return (
		<div
			className={styles["actions"]}
			key="premium-indication"
			id="premium-indication"
		>
			<Button
				text={Translate("policy.form.request-premium-indication")}
				autoWidth
				isDisabled={!isFormValid || !canRunPremium(policy.status) || (hasPremium && outdated !== undefined && !outdated)}
				onClick={validateDataAndCreatePremium}
			/>
		</div>);
};

export default memo(RequestPremiumIndicationComponent);