import { Guid } from "../utils/Guid";
import { befurstApiClient as api } from "./BefurstApi";
export const addTagTypes = [
  "Inspections",
  "Policies",
  "PremiumOutdated",
  "Premium",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      policiesPolicyHasInspection: build.query<
        PoliciesPolicyHasInspectionApiResponse,
        PoliciesPolicyHasInspectionApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/has-inspection`,
        }),
        providesTags: ["Inspections", "Policies"],
      }),
      policiesGetInspectionByPolicy: build.query<
        PoliciesGetInspectionByPolicyApiResponse,
        PoliciesGetInspectionByPolicyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/inspection`,
        }),
        providesTags: ["Inspections", "Policies"],
      }),
      policiesFetchPolicies: build.query<
        PoliciesFetchPoliciesApiResponse,
        PoliciesFetchPoliciesApiArg
      >({
        query: () => ({ url: `/api/Policies` }),
        providesTags: ["Policies"],
      }),
      policiesCreatePolicy: build.mutation<
        PoliciesCreatePolicyApiResponse,
        PoliciesCreatePolicyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies`,
          method: "POST",
          params: { kvkNumber: queryArg.kvkNumber },
        }),
        invalidatesTags: ["Policies"],
      }),
      policiesGetPolicy: build.query<
        PoliciesGetPolicyApiResponse,
        PoliciesGetPolicyApiArg
      >({
        query: (queryArg) => ({ url: `/api/Policies/${queryArg.policyGuid}` }),
        providesTags: ["Policies"],
      }),
      policiesUpdatePolicy: build.mutation<
        PoliciesUpdatePolicyApiResponse,
        PoliciesUpdatePolicyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}`,
          method: "PUT",
          body: queryArg.updatePolicyDto,
        }),
        invalidatesTags: ["PremiumOutdated", "Policies"],
      }),
      policiesUpdateFishAndUbo: build.mutation<
        PoliciesUpdateFishAndUboApiResponse,
        PoliciesUpdateFishAndUboApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/fish-and-ubo`,
          method: "PUT",
          body: queryArg.updateFishAndUboDto,
        }),
        invalidatesTags: ["Policies"],
      }),
      policiesTransferPolicyOwnership: build.mutation<
        PoliciesTransferPolicyOwnershipApiResponse,
        PoliciesTransferPolicyOwnershipApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/transfer-owner/${queryArg.newUserId}`,
          method: "PUT",
        }),
        invalidatesTags: ["Policies"],
      }),
      policiesFetchPreRiskAnswers: build.query<
        PoliciesFetchPreRiskAnswersApiResponse,
        PoliciesFetchPreRiskAnswersApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/pre-risk-answers`,
        }),
        providesTags: ["Policies"],
      }),
      policiesCreatePreRiskAnswer: build.mutation<
        PoliciesCreatePreRiskAnswerApiResponse,
        PoliciesCreatePreRiskAnswerApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/pre-risk-answers`,
          method: "POST",
          body: queryArg.createPreRiskAnswerDto,
        }),
        invalidatesTags: ["Policies"],
      }),
      policiesUpdatePreRiskAnswer: build.mutation<
        PoliciesUpdatePreRiskAnswerApiResponse,
        PoliciesUpdatePreRiskAnswerApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/pre-risk-answers/${queryArg.answerGuid}`,
          method: "PUT",
          body: queryArg.updatePreRiskAnswerDto,
        }),
        invalidatesTags: ["Policies"],
      }),
      policiesGetCurrentPolicyFieldsValues: build.query<
        PoliciesGetCurrentPolicyFieldsValuesApiResponse,
        PoliciesGetCurrentPolicyFieldsValuesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/current-version/fields`,
        }),
        providesTags: ["Policies"],
      }),
      policiesGetCurrentPolicyFieldValue: build.query<
        PoliciesGetCurrentPolicyFieldValueApiResponse,
        PoliciesGetCurrentPolicyFieldValueApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/current-version/fields/${queryArg.fieldGuid}`,
        }),
        providesTags: ["Policies"],
      }),
      policiesUpdatePolicyFieldValue: build.mutation<
        PoliciesUpdatePolicyFieldValueApiResponse,
        PoliciesUpdatePolicyFieldValueApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/current-version/fields/${queryArg.fieldGuid}`,
          method: "PUT",
          body: queryArg.updatePolicyFieldValueDto,
        }),
        invalidatesTags: ["PremiumOutdated", "Policies"],
      }),
      policiesRespondToQuote: build.mutation<
        PoliciesRespondToQuoteApiResponse,
        PoliciesRespondToQuoteApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/respond-to-quote`,
          method: "PUT",
          params: { acceptQuote: queryArg.acceptQuote },
        }),
        invalidatesTags: ["Policies"],
      }),
      policiesFlowFromQuoteRejection: build.mutation<
        PoliciesFlowFromQuoteRejectionApiResponse,
        PoliciesFlowFromQuoteRejectionApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/flow-from-quote-rejection`,
          method: "PUT",
          params: { newStatus: queryArg.newStatus },
        }),
        invalidatesTags: ["Policies"],
      }),
      policiesRejectPremiumIndication: build.mutation<
        PoliciesRejectPremiumIndicationApiResponse,
        PoliciesRejectPremiumIndicationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/reject-premium-indication`,
          method: "PUT",
        }),
        invalidatesTags: ["Policies"],
      }),
      policiesGetPolicyVersions: build.query<
        PoliciesGetPolicyVersionsApiResponse,
        PoliciesGetPolicyVersionsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/versions`,
        }),
        providesTags: ["Policies"],
      }),
      policiesGetCurrentPolicyVersion: build.query<
        PoliciesGetCurrentPolicyVersionApiResponse,
        PoliciesGetCurrentPolicyVersionApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/current-version`,
        }),
        providesTags: ["Policies"],
      }),
      policiesUpdatePolicyVersion: build.mutation<
        PoliciesUpdatePolicyVersionApiResponse,
        PoliciesUpdatePolicyVersionApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/current-version`,
          method: "PUT",
          body: queryArg.updatePolicyVersionDto,
        }),
        invalidatesTags: ["PremiumOutdated", "Policies"],
      }),
      policiesGetCurrentPolicyDamages: build.query<
        PoliciesGetCurrentPolicyDamagesApiResponse,
        PoliciesGetCurrentPolicyDamagesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/current-version/damages`,
        }),
        providesTags: ["Policies"],
      }),
      policiesGetCurrentPolicyDamagesForSupplier: build.query<
        PoliciesGetCurrentPolicyDamagesForSupplierApiResponse,
        PoliciesGetCurrentPolicyDamagesForSupplierApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/current-version/damages/${queryArg.supplierGuid}`,
        }),
        providesTags: ["Policies"],
      }),
      policiesGetCurrentPolicyCoveragesChoices: build.query<
        PoliciesGetCurrentPolicyCoveragesChoicesApiResponse,
        PoliciesGetCurrentPolicyCoveragesChoicesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/current-version/coverages-choices`,
        }),
        providesTags: ["Policies"],
      }),
      policiesUpdateCoverageChoice: build.mutation<
        PoliciesUpdateCoverageChoiceApiResponse,
        PoliciesUpdateCoverageChoiceApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/current-version/coverages-choices`,
          method: "PUT",
          body: queryArg.updateCoverageChoiceDto,
        }),
        invalidatesTags: ["PremiumOutdated", "Policies"],
      }),
      policiesCreateSupplier: build.mutation<
        PoliciesCreateSupplierApiResponse,
        PoliciesCreateSupplierApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/current-version/suppliers`,
          method: "POST",
          body: queryArg.createSupplierDto,
        }),
        invalidatesTags: ["Policies"],
      }),
      policiesGetCurrentPolicySuppliers: build.query<
        PoliciesGetCurrentPolicySuppliersApiResponse,
        PoliciesGetCurrentPolicySuppliersApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/current-version/suppliers`,
        }),
        providesTags: ["Policies"],
      }),
      policiesUpdateSupplier: build.mutation<
        PoliciesUpdateSupplierApiResponse,
        PoliciesUpdateSupplierApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/current-version/suppliers/${queryArg.supplierGuid}`,
          method: "PUT",
          body: queryArg.updateSupplierDto,
        }),
        invalidatesTags: ["Policies"],
      }),
      policiesPatchSupplier: build.mutation<
        PoliciesPatchSupplierApiResponse,
        PoliciesPatchSupplierApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/current-version/suppliers/${queryArg.supplierGuid}`,
          method: "PATCH",
          body: queryArg.patchSupplierDto,
        }),
        invalidatesTags: ["Policies"],
      }),
      policiesDeleteSupplier: build.mutation<
        PoliciesDeleteSupplierApiResponse,
        PoliciesDeleteSupplierApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/current-version/suppliers/${queryArg.supplierGuid}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Policies"],
      }),
      policiesValidateCurrentPolicySuppliers: build.query<
        PoliciesValidateCurrentPolicySuppliersApiResponse,
        PoliciesValidateCurrentPolicySuppliersApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/current-version/suppliers/validation`,
        }),
        providesTags: ["Policies"],
      }),
      policiesRequestQuote: build.mutation<
        PoliciesRequestQuoteApiResponse,
        PoliciesRequestQuoteApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/request-quote`,
          method: "PUT",
          body: queryArg.requestQuoteDto,
        }),
        invalidatesTags: ["Policies"],
      }),
      policiesFinishPreRiskQuestionnaire: build.mutation<
        PoliciesFinishPreRiskQuestionnaireApiResponse,
        PoliciesFinishPreRiskQuestionnaireApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/finish-pre-risk-questionnaire`,
          method: "PUT",
        }),
        invalidatesTags: ["Premium", "PremiumOutdated", "Policies"],
      }),
      policiesGetLatestPremium: build.query<
        PoliciesGetLatestPremiumApiResponse,
        PoliciesGetLatestPremiumApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/current-version/premium`,
        }),
        providesTags: ["Premium"],
      }),
      policiesCreatePremium: build.mutation<
        PoliciesCreatePremiumApiResponse,
        PoliciesCreatePremiumApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/current-version/premium`,
          method: "POST",
        }),
        invalidatesTags: ["Premium", "Policies"],
      }),
      policiesHasLatestPremium: build.query<
        PoliciesHasLatestPremiumApiResponse,
        PoliciesHasLatestPremiumApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/current-version/has-premium`,
        }),
        providesTags: ["Premium"],
      }),
      policiesHasLatestOutdatedPremium: build.query<
        PoliciesHasLatestOutdatedPremiumApiResponse,
        PoliciesHasLatestOutdatedPremiumApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/current-version/has-outdated-premium`,
        }),
        providesTags: ["Premium", "PremiumOutdated"],
      }),
      policiesCreatePremiumAfterRejectedQuote: build.mutation<
        PoliciesCreatePremiumAfterRejectedQuoteApiResponse,
        PoliciesCreatePremiumAfterRejectedQuoteApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/current-version/premium-after-quote-rejection`,
          method: "POST",
        }),
        invalidatesTags: ["Premium", "Policies"],
      }),
      policiesCreatePremiumAfterQuoteReview: build.mutation<
        PoliciesCreatePremiumAfterQuoteReviewApiResponse,
        PoliciesCreatePremiumAfterQuoteReviewApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/current-version/premium-after-quote-review`,
          method: "POST",
        }),
        invalidatesTags: ["Premium", "Policies"],
      }),
      policiesCreatePremiumAfterFillInspectionScore: build.mutation<
        PoliciesCreatePremiumAfterFillInspectionScoreApiResponse,
        PoliciesCreatePremiumAfterFillInspectionScoreApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/current-version/premium-after-fill-inspection-score`,
          method: "POST",
        }),
        invalidatesTags: ["Premium", "Policies"],
      }),
      policiesCreatePremiumDuringIndicationReview: build.mutation<
        PoliciesCreatePremiumDuringIndicationReviewApiResponse,
        PoliciesCreatePremiumDuringIndicationReviewApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/current-version/premium-during-indication-review`,
          method: "POST",
        }),
        invalidatesTags: ["Premium", "Policies"],
      }),
      policiesGetCalculationResults: build.query<
        PoliciesGetCalculationResultsApiResponse,
        PoliciesGetCalculationResultsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/current-version/calculation-results`,
        }),
        providesTags: ["Premium"],
      }),
      policiesFinishPolicyReview: build.mutation<
        PoliciesFinishPolicyReviewApiResponse,
        PoliciesFinishPolicyReviewApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/finish-review`,
          method: "PUT",
          params: { newPolicyStatus: queryArg.newPolicyStatus },
        }),
        invalidatesTags: ["Premium", "Policies"],
      }),
      policiesFinishStipulationsReview: build.mutation<
        PoliciesFinishStipulationsReviewApiResponse,
        PoliciesFinishStipulationsReviewApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/finish-stipulations-review`,
          method: "PUT",
        }),
        invalidatesTags: ["Premium", "Policies"],
      }),
      policiesUpdatePolicyVersionNoSecondaryReview: build.mutation<
        PoliciesUpdatePolicyVersionNoSecondaryReviewApiResponse,
        PoliciesUpdatePolicyVersionNoSecondaryReviewApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/current-version/no-secondary-review`,
          method: "PUT",
          body: queryArg.updatePolicyVersionDtoNoSecondaryReviewDto,
        }),
        invalidatesTags: ["PremiumOutdated", "Policies"],
      }),
      policiesUpdateCommercialDiscount: build.mutation<
        PoliciesUpdateCommercialDiscountApiResponse,
        PoliciesUpdateCommercialDiscountApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/current-version/commercial-discount`,
          method: "PUT",
          params: { commercialDiscount: queryArg.commercialDiscount },
        }),
        invalidatesTags: ["PremiumOutdated", "Policies"],
      }),
      policiesResetCoverageChoices: build.mutation<
        PoliciesResetCoverageChoicesApiResponse,
        PoliciesResetCoverageChoicesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/current-version/coverages-choices/reset`,
          method: "PUT",
        }),
        invalidatesTags: ["PremiumOutdated", "Policies"],
      }),
      policiesUpdatePolicyDestination: build.mutation<
        PoliciesUpdatePolicyDestinationApiResponse,
        PoliciesUpdatePolicyDestinationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/current-version/destination`,
          method: "PUT",
          body: queryArg.updatePolicyVersionDestinationDto,
        }),
        invalidatesTags: ["PremiumOutdated", "Policies"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as enhancedApi };
export type PoliciesPolicyHasInspectionApiResponse =
  /** status 200 OK */ boolean;
export type PoliciesPolicyHasInspectionApiArg = {
  policyGuid: Guid;
};
export type PoliciesGetInspectionByPolicyApiResponse =
  /** status 200 OK */ InspectionDto;
export type PoliciesGetInspectionByPolicyApiArg = {
  policyGuid: Guid;
};
export type PoliciesFetchPoliciesApiResponse =
  /** status 200 OK */ PolicyExtendedDto[];
export type PoliciesFetchPoliciesApiArg = void;
export type PoliciesCreatePolicyApiResponse =
  /** status 201 Created */ PolicyDto;
export type PoliciesCreatePolicyApiArg = {
  kvkNumber?: string;
};
export type PoliciesGetPolicyApiResponse = /** status 200 OK */ PolicyDto;
export type PoliciesGetPolicyApiArg = {
  policyGuid: Guid;
};
export type PoliciesUpdatePolicyApiResponse = /** status 200 OK */ PolicyDto;
export type PoliciesUpdatePolicyApiArg = {
  policyGuid: Guid;
  updatePolicyDto: UpdatePolicyDto;
};
export type PoliciesUpdateFishAndUboApiResponse =
  /** status 200 OK */ PolicyDto;
export type PoliciesUpdateFishAndUboApiArg = {
  policyGuid: Guid;
  updateFishAndUboDto: UpdateFishAndUboDto;
};
export type PoliciesTransferPolicyOwnershipApiResponse =
  /** status 200 OK */ PolicyDto;
export type PoliciesTransferPolicyOwnershipApiArg = {
  policyGuid: Guid;
  newUserId: string;
};
export type PoliciesFetchPreRiskAnswersApiResponse =
  /** status 200 OK */ PreRiskAnswerDto[];
export type PoliciesFetchPreRiskAnswersApiArg = {
  policyGuid: Guid;
};
export type PoliciesCreatePreRiskAnswerApiResponse =
  /** status 201 Created */ PreRiskAnswerDto;
export type PoliciesCreatePreRiskAnswerApiArg = {
  policyGuid: Guid;
  createPreRiskAnswerDto: CreatePreRiskAnswerDto;
};
export type PoliciesUpdatePreRiskAnswerApiResponse =
  /** status 200 OK */ PreRiskAnswerDto;
export type PoliciesUpdatePreRiskAnswerApiArg = {
  policyGuid: Guid;
  answerGuid: Guid;
  updatePreRiskAnswerDto: UpdatePreRiskAnswerDto;
};
export type PoliciesGetCurrentPolicyFieldsValuesApiResponse =
  /** status 200 OK */ PolicyFieldValueDto[];
export type PoliciesGetCurrentPolicyFieldsValuesApiArg = {
  policyGuid: Guid;
};
export type PoliciesGetCurrentPolicyFieldValueApiResponse =
  /** status 200 OK */ PolicyFieldValueDto;
export type PoliciesGetCurrentPolicyFieldValueApiArg = {
  policyGuid: Guid;
  fieldGuid: Guid;
};
export type PoliciesUpdatePolicyFieldValueApiResponse =
  /** status 200 OK */ PolicyFieldValueDto;
export type PoliciesUpdatePolicyFieldValueApiArg = {
  policyGuid: Guid;
  fieldGuid: Guid;
  updatePolicyFieldValueDto: UpdatePolicyFieldValueDto;
};
export type PoliciesRespondToQuoteApiResponse = /** status 200 OK */ PolicyDto;
export type PoliciesRespondToQuoteApiArg = {
  policyGuid: Guid;
  acceptQuote?: boolean;
};
export type PoliciesFlowFromQuoteRejectionApiResponse =
  /** status 200 OK */ PolicyDto;
export type PoliciesFlowFromQuoteRejectionApiArg = {
  policyGuid: Guid;
  newStatus?: PolicyStatus;
};
export type PoliciesRejectPremiumIndicationApiResponse =
  /** status 200 OK */ PolicyDto;
export type PoliciesRejectPremiumIndicationApiArg = {
  policyGuid: Guid;
};
export type PoliciesGetPolicyVersionsApiResponse =
  /** status 200 OK */ PolicyVersionDto[];
export type PoliciesGetPolicyVersionsApiArg = {
  policyGuid: Guid;
};
export type PoliciesGetCurrentPolicyVersionApiResponse =
  /** status 200 OK */ PolicyVersionDto;
export type PoliciesGetCurrentPolicyVersionApiArg = {
  policyGuid: Guid;
};
export type PoliciesUpdatePolicyVersionApiResponse =
  /** status 200 OK */ PolicyVersionDto;
export type PoliciesUpdatePolicyVersionApiArg = {
  policyGuid: Guid;
  updatePolicyVersionDto: UpdatePolicyVersionDto;
};
export type PoliciesGetCurrentPolicyDamagesApiResponse =
  /** status 200 OK */ DamageDto[];
export type PoliciesGetCurrentPolicyDamagesApiArg = {
  policyGuid: Guid;
};
export type PoliciesGetCurrentPolicyDamagesForSupplierApiResponse =
  /** status 200 OK */ DamageDto[];
export type PoliciesGetCurrentPolicyDamagesForSupplierApiArg = {
  policyGuid: Guid;
  supplierGuid: Guid;
};
export type PoliciesGetCurrentPolicyCoveragesChoicesApiResponse =
  /** status 200 OK */ CoverageChoiceDto[];
export type PoliciesGetCurrentPolicyCoveragesChoicesApiArg = {
  policyGuid: Guid;
};
export type PoliciesUpdateCoverageChoiceApiResponse =
  /** status 200 OK */ CoverageChoiceDto;
export type PoliciesUpdateCoverageChoiceApiArg = {
  policyGuid: Guid;
  updateCoverageChoiceDto: UpdateCoverageChoiceDto;
};
export type PoliciesCreateSupplierApiResponse =
  /** status 201 Created */ SupplierDto;
export type PoliciesCreateSupplierApiArg = {
  policyGuid: Guid;
  createSupplierDto: CreateSupplierDto;
};
export type PoliciesGetCurrentPolicySuppliersApiResponse =
  /** status 200 OK */ SupplierDto[];
export type PoliciesGetCurrentPolicySuppliersApiArg = {
  policyGuid: Guid;
};
export type PoliciesUpdateSupplierApiResponse =
  /** status 200 OK */ SupplierDto;
export type PoliciesUpdateSupplierApiArg = {
  policyGuid: Guid;
  supplierGuid: Guid;
  updateSupplierDto: UpdateSupplierDto;
};
export type PoliciesPatchSupplierApiResponse = /** status 200 OK */ SupplierDto;
export type PoliciesPatchSupplierApiArg = {
  policyGuid: Guid;
  supplierGuid: Guid;
  patchSupplierDto: PatchSupplierDto;
};
export type PoliciesDeleteSupplierApiResponse =
  /** status 204 No Content */ void;
export type PoliciesDeleteSupplierApiArg = {
  policyGuid: Guid;
  supplierGuid: Guid;
};
export type PoliciesValidateCurrentPolicySuppliersApiResponse =
  /** status 200 OK */ SupplierValidationDto;
export type PoliciesValidateCurrentPolicySuppliersApiArg = {
  policyGuid: Guid;
};
export type PoliciesRequestQuoteApiResponse =
  /** status 200 OK */ RequestQuoteResultDto;
export type PoliciesRequestQuoteApiArg = {
  policyGuid: Guid;
  requestQuoteDto: RequestQuoteDto;
};
export type PoliciesFinishPreRiskQuestionnaireApiResponse =
  /** status 200 OK */ PolicyDto;
export type PoliciesFinishPreRiskQuestionnaireApiArg = {
  policyGuid: Guid;
};
export type PoliciesGetLatestPremiumApiResponse =
  /** status 200 OK */ PremiumDto;
export type PoliciesGetLatestPremiumApiArg = {
  policyGuid: Guid;
};
export type PoliciesCreatePremiumApiResponse =
  /** status 201 Created */ PremiumDto;
export type PoliciesCreatePremiumApiArg = {
  policyGuid: Guid;
};
export type PoliciesHasLatestPremiumApiResponse = /** status 200 OK */ boolean;
export type PoliciesHasLatestPremiumApiArg = {
  policyGuid: Guid;
};
export type PoliciesHasLatestOutdatedPremiumApiResponse =
  /** status 200 OK */ boolean;
export type PoliciesHasLatestOutdatedPremiumApiArg = {
  policyGuid: Guid;
};
export type PoliciesCreatePremiumAfterRejectedQuoteApiResponse =
  /** status 201 Created */ PremiumDto;
export type PoliciesCreatePremiumAfterRejectedQuoteApiArg = {
  policyGuid: Guid;
};
export type PoliciesCreatePremiumAfterQuoteReviewApiResponse =
  /** status 201 Created */ PremiumDto;
export type PoliciesCreatePremiumAfterQuoteReviewApiArg = {
  policyGuid: Guid;
};
export type PoliciesCreatePremiumAfterFillInspectionScoreApiResponse =
  /** status 201 Created */ PremiumDto;
export type PoliciesCreatePremiumAfterFillInspectionScoreApiArg = {
  policyGuid: Guid;
};
export type PoliciesCreatePremiumDuringIndicationReviewApiResponse =
  /** status 201 Created */ PremiumDto;
export type PoliciesCreatePremiumDuringIndicationReviewApiArg = {
  policyGuid: Guid;
};
export type PoliciesGetCalculationResultsApiResponse =
  /** status 200 OK */ PremiumCalculationDto;
export type PoliciesGetCalculationResultsApiArg = {
  policyGuid: Guid;
};
export type PoliciesFinishPolicyReviewApiResponse =
  /** status 200 OK */ PolicyDto;
export type PoliciesFinishPolicyReviewApiArg = {
  policyGuid: Guid;
  newPolicyStatus?: PolicyStatus;
};
export type PoliciesFinishStipulationsReviewApiResponse =
  /** status 200 OK */ PolicyDto;
export type PoliciesFinishStipulationsReviewApiArg = {
  policyGuid: Guid;
};
export type PoliciesUpdatePolicyVersionNoSecondaryReviewApiResponse =
  /** status 200 OK */ PolicyVersionDto;
export type PoliciesUpdatePolicyVersionNoSecondaryReviewApiArg = {
  policyGuid: Guid;
  updatePolicyVersionDtoNoSecondaryReviewDto: UpdatePolicyVersionDtoNoSecondaryReviewDto;
};
export type PoliciesUpdateCommercialDiscountApiResponse =
  /** status 200 OK */ PolicyVersionDto;
export type PoliciesUpdateCommercialDiscountApiArg = {
  policyGuid: Guid;
  commercialDiscount?: number;
};
export type PoliciesResetCoverageChoicesApiResponse =
  /** status 200 OK */ CoverageChoiceDto[];
export type PoliciesResetCoverageChoicesApiArg = {
  policyGuid: Guid;
};
export type PoliciesUpdatePolicyDestinationApiResponse =
  /** status 200 OK */ PolicyVersionDto;
export type PoliciesUpdatePolicyDestinationApiArg = {
  policyGuid: Guid;
  updatePolicyVersionDestinationDto: UpdatePolicyVersionDestinationDto;
};
export type CustomError = {
  status: number;
  key: string | null;
  message: string | null;
};
export type InspectionDto = {
  guid: Guid;
  policyGuid: Guid;
  type: PhysicalInspectionType;
  state: InspectionState;
  rrsScore: number;
};
export type RelationDto = {
  kvkNumber: string | null;
  mainAddressGuid: Guid;
  legalStructure: string | null;
  companyName: string | null;
  email: string | null;
  sbiActivityCodes: string | null;
};
export type PolicyVersionDto = {
  guid: Guid;
  policyGuid: Guid;
  index: number;
  locked: boolean;
  effectiveDate: string | null;
  contractExpirationDate: string | null;
  premiumPaymentTerm: number;
  insuredAddressGuid: Guid;
  policyholderAddressGuid: Guid;
  correspondenceAddressGuid: Guid;
  destinationGuid: Guid;
  destinationDescription: string | null;
  destinationRemark: string | null;
  damageOption: DamageOption;
  deductibleGuid: Guid;
  provisionPercentage: number;
  commercialDiscount: number;
  usesThreeYearlyContract: boolean;
};
export type PolicyExtendedDto = {
  policyNumber: string | null;
  ownerId: string | null;
  ownerName: string | null;
  guid: Guid;
  relation: RelationDto;
  creationDate: string;
  status: PolicyStatus;
  nameInsured: string | null;
  version: PolicyVersionDto;
  email: string | null;
  fishAccepted: boolean;
  uboAccepted: boolean;
  inspectionState: InspectionState;
  insuredAddress: string | null;
};
export type PolicyDto = {
  policyNumber: string | null;
  ownerId: string | null;
  ownerName: string | null;
  guid: Guid;
  relationGuid: Guid;
  creationDate: string;
  status: PolicyStatus;
  nameInsured: string | null;
  currentVersionGuid: Guid;
  type: PolicyType;
  email: string | null;
  fishAccepted: boolean;
  uboAccepted: boolean;
  inspectionState: InspectionState;
};
export type UpdatePolicyDto = {
  nameInsured: string | null;
  email: string | null;
};
export type UpdateFishAndUboDto = {
  fishAccepted: boolean;
  uboAccepted: boolean;
};
export type PreRiskAnswerDto = {
  guid: Guid;
  policyGuid: Guid;
  questionName: string | null;
  value: string | null;
};
export type CreatePreRiskAnswerDto = {
  questionName: string | null;
  value: string | null;
};
export type UpdatePreRiskAnswerDto = {
  value: string | null;
};
export type PolicyFieldValueDto = {
  guid: Guid;
  policyVersionGuid: Guid;
  policyFieldGuid: Guid;
  fieldKey: string | null;
  value: string | null;
};
export type UpdatePolicyFieldValueDto = {
  value: string | null;
};
export type UpdatePolicyVersionDto = {
  effectiveDate: string | null;
  contractExpirationDate: string | null;
  premiumPaymentTerm: number;
  insuredAddressGuid: Guid;
  policyholderAddressGuid: Guid;
  correspondenceAddressGuid: Guid;
  damageOption: DamageOption;
};
export type DamageDto = {
  guid: Guid;
  policyVersionGuid: Guid;
  date: string;
  amountPaidByInsurance: number;
  ownRisk: number;
  cause: DamageCause;
  description: string | null;
  supplierGuid: Guid;
};
export type CoverageChoiceDto = {
  guid: Guid;
  policyVersionGuid: Guid;
  code: string | null;
  value: boolean;
};
export type UpdateCoverageChoiceDto = {
  code: string | null;
  value: boolean;
};
export type SupplierDto = {
  guid: Guid;
  policyVersionGuid: Guid;
  companyName: string | null;
  dependencyRatio: number;
  type: RiskType;
  addressGuid: Guid;
  destinationGuid: Guid;
};
export type CreateSupplierDto = {
  guid: Guid;
  companyName: string | null;
  dependencyRatio: number;
  type: RiskType;
};
export type UpdateSupplierDto = {
  companyName: string | null;
  dependencyRatio: number;
  type: RiskType;
  destinationGuid: Guid | null;
};
export type PatchSupplierDto = {
  addressGuid: Guid | null;
  occupancyGuid: Guid | null;
};
export type SupplierValidationDto = {
  isValid: boolean;
  warnings: SupplierValidationWarning[] | null;
};
export type RequestQuoteResultDto = {
  inspection: InspectionType;
};
export type RequestQuoteDto = {
  premiumGuid: Guid;
};
export type PremiumDto = {
  guid: Guid;
  policyVersionGuid: Guid;
  stage: PremiumStage;
};
export type CalculationRejectionDto = {
  type: PremiumCalcRejectionType;
  message: string | null;
  guid: Guid;
  argument: string | null;
};
export type CalculationReviewRequestDto = {
  guid: Guid;
  reason: PremiumCalcReviewRequestReason;
  message: string | null;
  argument: string | null;
  positivelyReviewd: boolean;
  reviewNote: string | null;
  unacceptable: boolean;
};
export type PremiumCalcPreRiskAnswerFeedbackDto = {
  questionName: string | null;
  answer: string | null;
  state: PremiumCalcPreRiskAnswerState;
  requiresInspection: boolean;
  requiresReview: boolean;
  score: number | null;
  weight: number | null;
  demands: string | null;
  advice: string | null;
  resolutionTerm: number | null;
  fieldType: FieldType;
};
export type PremiumCalcPreRiskFeedbackDto = {
  answerFeedbacks: PremiumCalcPreRiskAnswerFeedbackDto[] | null;
  score: number;
};
export type PremiumCalcCapacityItemDto = {
  participant: string | null;
  capacity: number;
};
export type PremiumCalcCapacityFeedbackDto = {
  class: number;
  totalCapacity: number;
  requiredCapacity: number;
  totalExposure: number;
  mpl: number;
  items: PremiumCalcCapacityItemDto[] | null;
};
export type CalculationFeedbackDto = {
  state: CalculationState;
  failures: string[] | null;
  rejections: CalculationRejectionDto[] | null;
  reviewRequests: CalculationReviewRequestDto[] | null;
  preRiskFeedback: PremiumCalcPreRiskFeedbackDto;
  capacityFeedback: PremiumCalcCapacityFeedbackDto;
};
export type PremiumCalcVectorDto = {
  premiumCalcVectorType: PremiumCalcVectorType;
  buildings: number | null;
  inventory: number | null;
  machines: number | null;
  goods: number | null;
  corporateDamage: number | null;
  reconstructionCosts: number | null;
  extraCosts: number | null;
  coolingDamage: number | null;
  supplier: number | null;
  index: number;
};
export type PremiumCalcFactorsDto = {
  standardNetPremium: number;
  commercialOverallFactor: number;
  commercialCeaFactor: number;
  totalCommercialFactor: number;
  coverageBonus: number;
  constructionNatureBonus: number;
  solarPanelBonus: number;
  sprinklerBonus: number;
  bmiBonus: number;
  totalBonusFactor: number;
  rrsScore: number;
  rrsFactor: number;
  corporateDamageConversion: number;
  extraCostsConversion: number;
  ownRiskDiscount: number;
  revenueDiscount: number;
  paymentTermSurcharge: number;
  commercialDiscount: number;
  desiredCommission: number;
  firePremium: number;
};
export type PremiumCalculationDto = {
  feedback: CalculationFeedbackDto;
  calculationVectors: PremiumCalcVectorDto[] | null;
  calculationFactors: PremiumCalcFactorsDto;
};
export type UpdatePolicyVersionDtoNoSecondaryReviewDto = {
  deductibleGuid: Guid;
  provisionPercentage: number;
};
export type UpdatePolicyVersionDestinationDto = {
  destinationGuid: Guid;
  destinationDescription: string | null;
  destinationRemark: string | null;
};
export enum PhysicalInspectionType {
  QuickScan = "QuickScan",
  FullInspection = "FullInspection",
}
export enum InspectionState {
  RequestInspection = "RequestInspection",
  InspectionToBeDelivered = "InspectionToBeDelivered",
  ContractChoiceRequired = "ContractChoiceRequired",
  FillScore = "FillScore",
  ScheduleInspection = "ScheduleInspection",
  Accepted = "Accepted",
  Rejected = "Rejected",
  NoInspection = "NoInspection",
}
export enum PolicyStatus {
  ProvideInformation = "ProvideInformation",
  PremiumIndicationRequested = "PremiumIndicationRequested",
  ReviewPremiumIndicationRequest = "ReviewPremiumIndicationRequest",
  PremiumIndicationProvided = "PremiumIndicationProvided",
  ReviewStipulations = "ReviewStipulations",
  QuoteRequested = "QuoteRequested",
  QuoteProvided = "QuoteProvided",
  ReviewQuote = "ReviewQuote",
  QuoteAccepted = "QuoteAccepted",
  QuoteRejected = "QuoteRejected",
  Running = "Running",
  RequestProlongation = "RequestProlongation",
  Cancelled = "Cancelled",
  Expired = "Expired",
  FullInspectionRequired = "FullInspectionRequired",
  Inspected = "Inspected",
  Rejected = "Rejected",
  PreRiskRequired = "PreRiskRequired",
  QuickScanInspectionRequired = "QuickScanInspectionRequired",
}
export enum DamageOption {
  Unknown = "Unknown",
  Not = "Not",
  Yes = "Yes",
}
export enum PolicyType {
  Fire = "Fire",
}
export enum DamageCause {
  Fire = "Fire",
  Burglary = "Burglary",
  Collision = "Collision",
  Frost = "Frost",
  Cooling = "Cooling",
  Other = "Other",
}
export enum RiskType {
  Supplier = "Supplier",
  Customer = "Customer",
}
export enum SupplierValidationWarning {
  MissingOrInvalidAddress = "MissingOrInvalidAddress",
  MissingOccupancy = "MissingOccupancy",
}
export enum InspectionType {
  PreRisk = "PreRisk",
  QuickScan = "QuickScan",
  FullInspection = "FullInspection",
}
export enum PremiumStage {
  Indication = "Indication",
  Quote = "Quote",
}
export enum CalculationState {
  Success = "Success",
  RequiresFeedback = "RequiresFeedback",
  Rejected = "Rejected",
  Failed = "Failed",
  Pending = "Pending",
}
export enum PremiumCalcRejectionType {
  RiskClass = "RiskClass",
  ConstructionNature = "ConstructionNature",
  InsufficientCapacity = "InsufficientCapacity",
}
export enum PremiumCalcReviewRequestReason {
  NoOccupancy = "NoOccupancy",
  PremiumOnDemand = "PremiumOnDemand",
  NoOccupancyForMajorSupplier = "NoOccupancyForMajorSupplier",
  Remarks = "Remarks",
  PreRiskAnswer = "PreRiskAnswer",
  DamageFrequencyExceeded = "DamageFrequencyExceeded",
  DamageAmountExceeded = "DamageAmountExceeded",
}
export enum PremiumCalcPreRiskAnswerState {
  Valid = "Valid",
  NoScoreFound = "NoScoreFound",
  NoMatchingScoreFound = "NoMatchingScoreFound",
  Invisible = "Invisible",
}
export enum FieldType {
  Number = "Number",
  Currency = "Currency",
  Boolean = "Boolean",
  String = "String",
  Date = "Date",
  Enum = "Enum",
  Percentage = "Percentage",
  Occupancy = "Occupancy",
}
export enum PremiumCalcVectorType {
  StandardGrossPremium = "StandardGrossPremium",
  StandardNetPremium = "StandardNetPremium",
  CommercialFactorOverall = "CommercialFactorOverall",
  CommercialFactorCea = "CommercialFactorCea",
  CommercialFactorTotal = "CommercialFactorTotal",
  NetPremiumSubtotal1 = "NetPremiumSubtotal1",
  CoverageBonus = "CoverageBonus",
  ConstructionNatureBonus = "ConstructionNatureBonus",
  SolarPanelBonus = "SolarPanelBonus",
  SprinklerBonus = "SprinklerBonus",
  BmiBonus = "BmiBonus",
  BonusTotal = "BonusTotal",
  NetPremiumSubtotal2 = "NetPremiumSubtotal2",
  RrsScore = "RrsScore",
  CorporateDamageConversion = "CorporateDamageConversion",
  ExtraCostsConversion = "ExtraCostsConversion",
  OwnRiskDiscount = "OwnRiskDiscount",
  RevenueDiscount = "RevenueDiscount",
  PaymentTermSurcharge = "PaymentTermSurcharge",
  CommercialDiscount = "CommercialDiscount",
  DesiredCommission = "DesiredCommission",
  TotalDiscount = "TotalDiscount",
  FinalPremiums = "FinalPremiums",
  NetPremiumSubtotal3 = "NetPremiumSubtotal3",
}
export const {
  usePoliciesPolicyHasInspectionQuery,
  useLazyPoliciesPolicyHasInspectionQuery,
  usePoliciesGetInspectionByPolicyQuery,
  useLazyPoliciesGetInspectionByPolicyQuery,
  usePoliciesFetchPoliciesQuery,
  useLazyPoliciesFetchPoliciesQuery,
  usePoliciesCreatePolicyMutation,
  usePoliciesGetPolicyQuery,
  useLazyPoliciesGetPolicyQuery,
  usePoliciesUpdatePolicyMutation,
  usePoliciesUpdateFishAndUboMutation,
  usePoliciesTransferPolicyOwnershipMutation,
  usePoliciesFetchPreRiskAnswersQuery,
  useLazyPoliciesFetchPreRiskAnswersQuery,
  usePoliciesCreatePreRiskAnswerMutation,
  usePoliciesUpdatePreRiskAnswerMutation,
  usePoliciesGetCurrentPolicyFieldsValuesQuery,
  useLazyPoliciesGetCurrentPolicyFieldsValuesQuery,
  usePoliciesGetCurrentPolicyFieldValueQuery,
  useLazyPoliciesGetCurrentPolicyFieldValueQuery,
  usePoliciesUpdatePolicyFieldValueMutation,
  usePoliciesRespondToQuoteMutation,
  usePoliciesFlowFromQuoteRejectionMutation,
  usePoliciesRejectPremiumIndicationMutation,
  usePoliciesGetPolicyVersionsQuery,
  useLazyPoliciesGetPolicyVersionsQuery,
  usePoliciesGetCurrentPolicyVersionQuery,
  useLazyPoliciesGetCurrentPolicyVersionQuery,
  usePoliciesUpdatePolicyVersionMutation,
  usePoliciesGetCurrentPolicyDamagesQuery,
  useLazyPoliciesGetCurrentPolicyDamagesQuery,
  usePoliciesGetCurrentPolicyDamagesForSupplierQuery,
  useLazyPoliciesGetCurrentPolicyDamagesForSupplierQuery,
  usePoliciesGetCurrentPolicyCoveragesChoicesQuery,
  useLazyPoliciesGetCurrentPolicyCoveragesChoicesQuery,
  usePoliciesUpdateCoverageChoiceMutation,
  usePoliciesCreateSupplierMutation,
  usePoliciesGetCurrentPolicySuppliersQuery,
  useLazyPoliciesGetCurrentPolicySuppliersQuery,
  usePoliciesUpdateSupplierMutation,
  usePoliciesPatchSupplierMutation,
  usePoliciesDeleteSupplierMutation,
  usePoliciesValidateCurrentPolicySuppliersQuery,
  useLazyPoliciesValidateCurrentPolicySuppliersQuery,
  usePoliciesRequestQuoteMutation,
  usePoliciesFinishPreRiskQuestionnaireMutation,
  usePoliciesGetLatestPremiumQuery,
  useLazyPoliciesGetLatestPremiumQuery,
  usePoliciesCreatePremiumMutation,
  usePoliciesHasLatestPremiumQuery,
  useLazyPoliciesHasLatestPremiumQuery,
  usePoliciesHasLatestOutdatedPremiumQuery,
  useLazyPoliciesHasLatestOutdatedPremiumQuery,
  usePoliciesCreatePremiumAfterRejectedQuoteMutation,
  usePoliciesCreatePremiumAfterQuoteReviewMutation,
  usePoliciesCreatePremiumAfterFillInspectionScoreMutation,
  usePoliciesCreatePremiumDuringIndicationReviewMutation,
  usePoliciesGetCalculationResultsQuery,
  useLazyPoliciesGetCalculationResultsQuery,
  usePoliciesFinishPolicyReviewMutation,
  usePoliciesFinishStipulationsReviewMutation,
  usePoliciesUpdatePolicyVersionNoSecondaryReviewMutation,
  usePoliciesUpdateCommercialDiscountMutation,
  usePoliciesResetCoverageChoicesMutation,
  usePoliciesUpdatePolicyDestinationMutation,
} = injectedRtkApi;
