import React from "react";

import styles from "../../styles/ComponentSubTitle.module.scss";

type Props = {
  readonly text: string;
};

const ComponentSubTitle = ({ text }: Props): JSX.Element => {
  return <p className={styles["component-sub-title"]}>{text}</p>;
};

export default ComponentSubTitle;
