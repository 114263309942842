import React, { memo } from "react";

import styles from "../../../styles/Calculation.module.scss";
import { Translate } from "../../../utils/Translation";

type Props = {
	readonly itemKey: string,
	readonly itemValue: string
};

const CapacityFeedbackItem = ({ itemKey, itemValue }: Props): JSX.Element => {
	return (
		<p>
			<span className={styles["item-key"]}>{Translate(itemKey)}: </span>
			<span className={styles["item-value"]}>{itemValue}</span>
		</p>
	);
};

export default memo(CapacityFeedbackItem);