import React from "react";

import { locale } from "devextreme/localization";
import { Markup } from "interweave";

import { useReleaseNotesFetchReleaseNotesQuery } from "../../apis/ReleaseNotesApi";
import { Translate } from "../../utils/Translation";
import PageTitle from "../titles/PageTitle";

const ReleaseNotes = (): JSX.Element => {
	const currentLocale = locale();
	const {
		isLoading,
		error,
		data: releaseNotes,
	} = useReleaseNotesFetchReleaseNotesQuery({ language: currentLocale });

	return (
		<>
			<PageTitle text={Translate("release-notes.title")} />
			{isLoading ? <div>{Translate("release-notes.loading")}</div> : null}
			{error ? <div>{Translate("release-notes.error")}</div> : null}
			{releaseNotes ? (<Markup content={releaseNotes.content} />) : null}
			{!isLoading && !releaseNotes ? (<div>{Translate("release-notes.empty")}</div>) : null}
		</>
	);
};

export default ReleaseNotes;
