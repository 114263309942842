import React from "react";

import { useAppSelector } from "../hooks/hooks";
import { selectIdentity } from "../store/Identity";

type Props = {
	readonly title: string;
	readonly children?: React.ReactNode;
};

const Main = ({ title, children }: Props): JSX.Element => {

	const { role } = useAppSelector(selectIdentity);

	if (role === 'Unauthorized') {
		return (
			<div>You are not authorized to see this page. Please contact your system administrator if you think this is a mistake!</div>
		);
	}

	return (
		<div>
			<h1>{title}</h1>
			{children}
		</div>
	);
};

export default Main;