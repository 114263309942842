import "devextreme/ui/select_box";
import "devextreme/ui/menu";
import "devextreme/ui/tabs";
import "devextreme/ui/drop_down_button";

import React from "react";

import { Toast } from "devextreme-react";
import Toolbar, { Item } from "devextreme-react/toolbar";

import { useAuth0 } from "@auth0/auth0-react";

import { useProfilesSynchProfileMutation } from "../../apis/ProfilesApi";
import { useAsyncEffect } from "../../hooks/AsyncEffectHook";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { selectIdentity, setRole } from "../../store/Identity";
import styles from "../../styles/Header.module.scss";
import { roles } from "../../types/Roles";
import { Translate } from "../../utils/Translation";
import HeaderLogo from "./HeaderLogo";
import HeaderProductName from "./HeaderProductName";

const SynchedBeFurstProfile = "synched-befurst-profile";

type Props = {
	readonly showDrawerHandler: (newDrawer: string) => void;
};

const Header = ({ showDrawerHandler }: Props): JSX.Element => {
	const { username, role } = useAppSelector(selectIdentity);
	const dispatch = useAppDispatch();

	const { user, logout } = useAuth0();
	const userRoles = user ? user["https://api.befurst.macomi.nl/roles"] : undefined;
	const isAdmin = userRoles && userRoles.includes('Admin');
	const [triggerSynchProfile, { error: synchProfileError }] = useProfilesSynchProfileMutation();
	const isProfileSynched = sessionStorage.getItem(SynchedBeFurstProfile);

	useAsyncEffect(async (): Promise<void> => {
		if (!user || isProfileSynched)
			return;
		await triggerSynchProfile({
			profileDto: {
				nickname: user.nickname ? user.nickname : null,
				picture: user.picture ? user.picture : null,
				username: user.name ? user.name : null,
				// This is set in the back-end
				userId: null
			},
		}).unwrap();
		sessionStorage.setItem(SynchedBeFurstProfile, "synched");
	}, [user, isProfileSynched, triggerSynchProfile]);

	function getUserName(): string {
		if (username) {
			return username.includes(" ") ?
				username
					.split(" ")
					.map((initial: string) => initial[0])
					.join("")
					.toUpperCase()
				:
				username.substring(0, 2);
		}
		return "";
	}

	const userActionsList = [
		{
			id: 1,
			text: "Logout",
		},
	];

	const userOptions = {
		text: getUserName(),
		width: 100,
		height: 50,
		icon: user && user.picture ? user.picture : null,
		items: userActionsList,
		valueExpr: "id",
		selectedItemKey: 0,
		stylingMode: "text",
		displayExpr: "text",
		onSelectionChanged: (): void => {
			sessionStorage.removeItem(SynchedBeFurstProfile);
			logout({ logoutParams: { returnTo: window.location.origin } });
		},
	};

	// const notificationOptions = {
	// 	width: 50,
	// 	height: 50,
	// 	icon: "/notification.png",
	// 	stylingMode: "text",
	// 	displayExpr: "text",
	// 	onClick: (): void => {
	// 		showDrawerHandler("notifications");
	// 	},
	// };

	// const settingOptions = {
	// 	width: 50,
	// 	height: 50,
	// 	icon: "/settings.png",
	// 	stylingMode: "text",
	// 	displayExpr: "text",
	// 	onClick: (): void => {
	// 		showDrawerHandler("settings");
	// 	},
	// };

	const helpOptions = {
		width: 50,
		height: 50,
		icon: "/help.png",
		stylingMode: "text",
		displayExpr: "text",
		onClick: (): void => {
			showDrawerHandler("help");
		},
	};

	const selectRoleBoxOptions = {
		width: 140,
		items: roles,
		valueExpr: 'value',
		displayExpr: 'caption',
		value: role,
		onValueChanged: (args: any): void => {
			if (args.value) {
				dispatch(setRole(args.value));
			}
		},
	};

	// Old code: cssClass={photo ? styles.profileImageStyle : styles.userIconStyle}
	return (
		<>
			<Toolbar className={styles.toolbarstyle}>
				<Item location="before" locateInMenu="never" render={HeaderLogo} />
				<Item location="before" locateInMenu="never" render={HeaderProductName} />
				{/* Not in version 1.0 yet
				<Item location="after" locateInMenu="never" widget="dxButton" options={notificationOptions} />
				<Item location="after" locateInMenu="never" widget="dxButton" options={settingOptions} /> */}
				<Item location="after" locateInMenu="never" widget="dxButton" options={helpOptions} />
				{isAdmin ? <Item location="after" locateInMenu="auto" render={(): JSX.Element => <div>Select role:</div>} /> : null}
				{isAdmin ? <Item location="after" locateInMenu="auto" widget="dxSelectBox" options={selectRoleBoxOptions} /> : null}
				<Item
					location="after"
					widget="dxDropDownButton"
					options={userOptions}
					cssClass={styles.userIconStyle}
				/>
			</Toolbar>
			<Toast
				visible={Boolean(synchProfileError)}
				message={Translate("error.synch.profile")}
				type="error"
			/>
		</>
	);
};

export default Header;
