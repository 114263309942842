import { IExceptionTelemetry, SeverityLevel } from "@microsoft/applicationinsights-web";

import { appInsights } from "./appInsights";
import CustomError from "./CustomError";

const TrackException = ({ message, severityLevel, component, method }: CustomError): void => {
  // const errorType = errorItem as Error | IAutoExceptionTelemetry | undefined;

  const info: IExceptionTelemetry = {
    exception: new Error(message),
    severityLevel,
    properties: { component, method },
  };

  appInsights.trackException(info);
};

export const TrackWarning = (message: string, component: string, method: string): void => {
  return TrackException({
    message,
    method,
    component,
    userMessage: message,
    severityLevel: SeverityLevel.Warning,
    name: '-',
  });
};

export default TrackException;
