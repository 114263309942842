import React, { memo, useCallback, useMemo } from "react";

import { add, startOfToday } from "date-fns";
import { DataGrid, LoadIndicator } from "devextreme-react";
import { Column, CustomRule, Editing, FormItem, Item, Lookup, RequiredRule, Sorting, Toolbar } from "devextreme-react/data-grid";

import { useDamagesCreateDamageMutation, useDamagesDeleteDamageMutation, useDamagesUpdateDamageMutation } from "../../../apis/DamagesApi";
import {
  DamageCause, DamageDto, DamageOption, usePoliciesGetCurrentPolicyDamagesForSupplierQuery, usePoliciesGetCurrentPolicyDamagesQuery,
} from "../../../apis/PoliciesApi";
import { useAppSelector } from "../../../hooks/hooks";
import { selectPolicyPage } from "../../../store/PolicyPageState";
import styles from "../../../styles/DamageHistoryTable.module.scss";
import { localAsUtc, utcAsLocal } from "../../../utils/commonFunctions";
import { euroFormat } from "../../../utils/formatUtils";
import { emptyGuid, Guid, newGuid } from "../../../utils/Guid";
import { Translate } from "../../../utils/Translation";

type Props = {
	readonly supplierGuid: Guid;
	readonly readOnly: boolean | undefined;
};

type DamageCauseWithTranslation = {
	readonly value: DamageCause;
	readonly text: string;
};

const DamageHistoryTableComponent = ({ supplierGuid, readOnly }: Props): JSX.Element => {

	const { data } = useAppSelector(selectPolicyPage);
	if (!data)
		throw new Error("Policy page data is unavailable");

	const {
		data: mainDamageData,
		isLoading: mainIsLoading,
		error: mainError,
		refetch: mainRefetch
	} = usePoliciesGetCurrentPolicyDamagesQuery({ policyGuid: data.policy.guid }, { skip: supplierGuid !== emptyGuid() });
	const {
		data: supplierDamageData,
		isLoading: supplierIsLoading,
		error: supplierError,
		refetch: supplierRefetch,
	} = usePoliciesGetCurrentPolicyDamagesForSupplierQuery({
		policyGuid: data.policy.guid,
		supplierGuid,
	}, { skip: supplierGuid === emptyGuid() });
	const [triggerCreateDamage] = useDamagesCreateDamageMutation();
	const [triggerUpdateDamage] = useDamagesUpdateDamageMutation();
	const [triggerDeleteDamage] = useDamagesDeleteDamageMutation();

	if (mainError)
		throw new Error(`Error retrieving damages ${mainError}`);
	if (supplierError)
		throw new Error(`Error retrieving supplier damages ${supplierError}`);

	const damageData = supplierGuid === emptyGuid() ? mainDamageData : supplierDamageData;
	const isLoading = supplierGuid === emptyGuid() ? mainIsLoading : supplierIsLoading;
	const refetch = supplierGuid === emptyGuid() ? mainRefetch : supplierRefetch;
	const dataSource = damageData
		? damageData.map((d: DamageDto): DamageDto => ({
			...d,
			// See TermsComponent for why we do this conversion
			date: utcAsLocal(new Date(d.date)).toISOString()
		})).sort((a: DamageDto, b: DamageDto) => (a.date ? new Date(a.date).getDate() : -1) - (b.date ? new Date(b.date).getDate() : -1))
		: undefined;

	// Labels
	const addRowLabel = useMemo(() => ({ text: Translate("policy.damage.add") }), []);
	const dateLabel = useMemo(() => Translate("policy.damage.date"), []);
	const invalidDateLabel = useMemo(() => Translate("policy.form.date.invalid"), []);
	const invalidDateRule = useMemo(() => Translate("policy.damage.table.date-range"), []);
	const payedByInsuranceLabel = useMemo(() => Translate("policy.damage.amount-payed"), []);
	const deductibleLabel = useMemo(() => Translate("policy.damage.deductible"), []);
	const causeLabel = useMemo(() => Translate("policy.damage.cause"), []);
	const requiredCauseRule = useMemo(() => Translate("policy.damage.table.cause-required"), []);
	const requiredDateRule = useMemo(() => Translate("policy.damage.table.date-required"), []);
	const descriptionLabel = useMemo(() => Translate("policy.damage.description"), []);
	const requiredDescriptionRule = useMemo(() => Translate("policy.damage.table.description-required"), []);

	const damageCauses: DamageCauseWithTranslation[] = useMemo((): DamageCauseWithTranslation[] => {
		return [
			{
				value: DamageCause.Fire,
				text: Translate("policy.damage-cause.fire"),
			},
			{
				value: DamageCause.Burglary,
				text: Translate("policy.damage-cause.burglary"),
			},
			{
				value: DamageCause.Collision,
				text: Translate("policy.damage-cause.collision"),
			},
			{
				value: DamageCause.Frost,
				text: Translate("policy.damage-cause.frost"),
			},
			{
				value: DamageCause.Cooling,
				text: Translate("policy.damage-cause.cooling"),
			},
			{
				value: DamageCause.Other,
				text: Translate("policy.damage-cause.other"),
			},
		];
	}, []);

	const initNewRow = useCallback((e: any) => {
		e.data.id = newGuid();
	}, []);

	const newRow = useCallback(async ({ data: rowData }: { data: DamageDto }): Promise<void> => {
		await triggerCreateDamage({
			createDamageDto: {
				guid: rowData.guid,
				supplierGuid,
				date: localAsUtc(new Date(rowData.date)).toISOString(),
				amountPaidByInsurance: rowData.amountPaidByInsurance,
				ownRisk: rowData.ownRisk,
				cause: rowData.cause,
				description: rowData.description,
				policyVersionGuid: data.policyVersion.guid,
			},
		});
		refetch();
	}, [data, refetch, supplierGuid, triggerCreateDamage]);

	const updateRow = useCallback(async ({ data: rowData }: { data: DamageDto }): Promise<void> => {
		await triggerUpdateDamage({
			damageGuid: rowData.guid,
			updateDamageDto: {
				date: localAsUtc(new Date(rowData.date)).toISOString(),
				amountPaidByInsurance: rowData.amountPaidByInsurance,
				ownRisk: rowData.ownRisk,
				cause: rowData.cause,
				description: rowData.description,
			},
		});
		refetch();
	}, [refetch, triggerUpdateDamage]);

	const deleteRow = useCallback(async ({ data: rowData }: { data: DamageDto }): Promise<void> => {
		await triggerDeleteDamage({
			damageGuid: rowData.guid,
		});
		refetch();
	}, [refetch, triggerDeleteDamage]);
	const prepareEditor = useCallback((e: any) => {
		if (e.parentType === "dataRow" && e.dataField === "description")
			e.editorOptions.disabled = e.row.data.cause !== DamageCause.Other;
	}, []);
	const validateDate = useCallback(({ value }: { value: any }): boolean => {
		if (!(value instanceof Date)) {
			throw new Error('Expected to get a date, but did not (needs any type because of devextreme wrong typing)');
		}
		const maxDate = startOfToday();
		const minDate = add(maxDate, { years: -5 });
		return value >= minDate && value <= maxDate;
	}, []);
	const validateDescription = useCallback(({ value, data: rowData }:
		 { value?: string | number, data: Record<string, any> | DamageDto }): boolean => {
		if (typeof value === "number") 
			return false;
		
		const currentCause = rowData.cause as DamageCause;

		if (!currentCause || currentCause !== DamageCause.Other) return true;
		return value ? value.trim().length > 0 : false;
	}, []);

	const setCellValue = function (newData: any, value: DamageOption, currentRowData: DamageDto): void {
		if (currentRowData.cause !== DamageCause.Other) currentRowData.description = null;
		newData.cause = value;
	};

	if (isLoading)
		return <LoadIndicator />;

	return (
		<div className={styles["damage-history-table"]}>
			<DataGrid
				dataSource={dataSource}
				onRowInserted={newRow}
				onRowUpdated={updateRow}
				onRowRemoved={deleteRow}
				onInitNewRow={initNewRow}
				onEditorPreparing={prepareEditor}
				className={supplierGuid !== emptyGuid() ? "master-detail-table" : undefined}
				keyExpr="guid"
			>
				{readOnly
					? null
					: <Editing mode="form" allowAdding allowUpdating allowDeleting useIcons />
				}
				<Sorting mode="multiple" />

				<Toolbar>
					<Item
						name="addRowButton"
						showText="always"
						options={addRowLabel}
						cssClass="data-grid-add-row-button"
					/>
				</Toolbar>

				<Column
					dataField="date"
					caption={dateLabel}
					dataType="date"
					format="dd/MM/yyyy"
					sortOrder="asc"
				>
					<FormItem
						editorType="dxDateBox"
						editorOptions={{
							invalidDateMessage: { invalidDateLabel },
							displayFormat: "dd/MM/yyyy",
						}}
					/>
					<RequiredRule
						message={requiredDateRule}
					/>
					<CustomRule
						message={invalidDateRule}
						validationCallback={validateDate}
					/>
				</Column>
				<Column
					dataField="amountPaidByInsurance"
					caption={payedByInsuranceLabel}
					dataType="number"
					format={euroFormat}
					alignment="left"
				>
					<FormItem
						editorType="dxNumberBox"
						editorOptions={{
							format: euroFormat,
						}}
					/>
				</Column>
				<Column
					dataField="ownRisk"
					caption={deductibleLabel}
					dataType="number"
					format={euroFormat}
					alignment="left"
				>
					<FormItem
						editorType="dxNumberBox"
						editorOptions={{
							format: euroFormat,
						}}
					/>
				</Column>
				<Column
					dataField="cause"
					caption={causeLabel}
					setCellValue={setCellValue}
				>
					<Lookup
						dataSource={damageCauses}
						valueExpr="value"
						displayExpr="text"
					/>
					<RequiredRule
						message={requiredCauseRule}
					/>
				</Column>
				<Column
					dataField="description"
					caption={descriptionLabel}
					visible={false}
				>
					<FormItem editorType="dxTextArea" colSpan={2} />
					<CustomRule
						message={requiredDescriptionRule}
						validationCallback={validateDescription}
						reevaluate
					/>
				</Column>
			</DataGrid>
		</div>
	);
};

export default memo(DamageHistoryTableComponent);