import React from "react";

import styles from "../../styles/Header.module.scss";

const HeaderLogo = (): JSX.Element => {

	const src = "/furst-logo.png";

	return (
		<div className={styles.cn}>
			<div className={styles.inner}>
				<img className={styles.furstLogo} src={src} alt={"BeFurst logo"} />
			</div>
		</div>
	);
};

export default HeaderLogo;
