import React, { useEffect } from "react";

import { useDispatch } from "react-redux";

import { addNavigationItem, removeNavigationItem } from "../../store/PolicyPageState";
import styles from "../../styles/ComponentTitle.module.scss";

type Props = {
	readonly text: string;
	readonly textColor?: "blue" | "red";
	readonly className?: string;
	readonly id: string;
	readonly useNavigation?: boolean;
};

const ComponentTitle = ({
	text,
	textColor,
	id,
	className = undefined,
	useNavigation = true
}: Props): JSX.Element => {

	const dispatch = useDispatch();
	useEffect(() => {
		if (useNavigation) {
			// Component title can create a navigation link
			dispatch(addNavigationItem({
				hash: `#${id}`,
				text,
				visible: true
			}));
		}
		// On unmount, make sure the link is removed again
		return (): void => {
			if (useNavigation) {
				dispatch(removeNavigationItem(`#${id}`));
			}
		};
	}, [dispatch, id, text, useNavigation]);

	let finalClassName = `${styles["component-title"]}`;
	if (textColor) {
		const derivedClass = `component-title--${textColor}`;
		finalClassName += ` ${styles[derivedClass]}`;
	}
	finalClassName += className ? ` ${className}` : "";

	return (
		<h3
			// We only add the id when using navigation so we can jump to it. Otherwise we don't need to ensure it is unique
			id={useNavigation ? id : undefined}
			className={finalClassName}
		>
			{text}
		</h3>);
};

export default ComponentTitle;
