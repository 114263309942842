import { Guid } from "../utils/Guid";
import { befurstApiClient as api } from "./BefurstApi";
export const addTagTypes = ["Profiles"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      profilesFetchAllProfiles: build.query<
        ProfilesFetchAllProfilesApiResponse,
        ProfilesFetchAllProfilesApiArg
      >({
        query: () => ({ url: `/api/Profiles/all` }),
        providesTags: ["Profiles"],
      }),
      profilesFetchProfile: build.query<
        ProfilesFetchProfileApiResponse,
        ProfilesFetchProfileApiArg
      >({
        query: () => ({ url: `/api/Profiles` }),
        providesTags: ["Profiles"],
      }),
      profilesSynchProfile: build.mutation<
        ProfilesSynchProfileApiResponse,
        ProfilesSynchProfileApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Profiles`,
          method: "POST",
          body: queryArg.profileDto,
        }),
        invalidatesTags: ["Profiles"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as enhancedApi };
export type ProfilesFetchAllProfilesApiResponse =
  /** status 200 OK */ ProfileDto[];
export type ProfilesFetchAllProfilesApiArg = void;
export type ProfilesFetchProfileApiResponse = /** status 200 OK */ ProfileDto;
export type ProfilesFetchProfileApiArg = void;
export type ProfilesSynchProfileApiResponse = /** status 200 OK */
  | ProfileDto
  | /** status 201 Created */ ProfileDto;
export type ProfilesSynchProfileApiArg = {
  profileDto: ProfileDto;
};
export type ProfileDto = {
  userId: string | null;
  username: string | null;
  nickname: string | null;
  picture: string | null;
};
export type ProblemDetails = {
  type: string | null;
  title: string | null;
  status: number | null;
  detail: string | null;
  instance: string | null;
  [key: string]: any;
};
export type CustomError = {
  status: number;
  key: string | null;
  message: string | null;
};
export const {
  useProfilesFetchAllProfilesQuery,
  useLazyProfilesFetchAllProfilesQuery,
  useProfilesFetchProfileQuery,
  useLazyProfilesFetchProfileQuery,
  useProfilesSynchProfileMutation,
} = injectedRtkApi;
