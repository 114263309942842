import React, { useMemo } from "react";

import { useLocation } from "react-router";
import { HashLink as Link } from "react-router-hash-link";

import { useAppSelector } from "../../hooks/hooks";
import { selectPolicyPage } from "../../store/PolicyPageState";
import styles from "../../styles/PolicyNavigation.module.scss";

const PolicyNavigation = (): JSX.Element => {
	const location = useLocation();
	const hash = location.hash;

	const { navigationItems } = useAppSelector(selectPolicyPage);

	const sortedItems = useMemo(() => {
		const copy = [...navigationItems];
		return copy.filter(x => x.visible).sort((a, b) => {
			const itemA = document.getElementById(a.hash.substring(1));
			const itemB = document.getElementById(b.hash.substring(1));
			if (!itemA || !itemB) {
				return 0;
			}
			const position = itemA.compareDocumentPosition(itemB);
			// We want to do a bitwise here, since the document position returns a bitmask
			// eslint-disable-next-line no-bitwise
			const aBeforeB = position & Node.DOCUMENT_POSITION_FOLLOWING;
			return aBeforeB ? -1 : 1;
		});
	}, [navigationItems]);

	return (
		<div className={styles["navigation-wrapper"]}>
			<div className={styles["navigation"]}>
				{sortedItems.map(item => (
					<Link
						key={item.hash}
						to={item.hash}
						className={`${styles["link-wrapper"]} ${hash === item.hash ? styles["selected"] : ""}`}
					>
						{item.text}
					</Link>
				))}
			</div>
		</div>
	);
};

export default PolicyNavigation;
