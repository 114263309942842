/* eslint-disable react/forbid-elements */
import React, { memo, useCallback, useMemo } from "react";

import { DataGrid } from "devextreme-react";
import { Column } from "devextreme-react/data-grid";
import { formatNumber } from "devextreme/localization";

import { FieldType, PremiumCalcPreRiskAnswerState, PremiumCalcPreRiskFeedbackDto } from "../../../apis/PoliciesApi";
import styles from "../../../styles/Calculation.module.scss";
import { Translate } from "../../../utils/Translation";
import ComponentTitle from "../../titles/ComponentTitle";

type Props = {
	readonly feedback: PremiumCalcPreRiskFeedbackDto;
};

const RowStyle = {
	Valid: 0,
	Warning: 1,
	Invisible: 2,
	Invalid: 3,
	Total: 4
};

type PreRiskAnswerTableRow = {
	keyValue: string;
	questionName: string | null;
	originalQuestionName: string | null;
	answer: string | null | undefined;
	state: string | null;
	rowStyle: number;
	requiresInspection: boolean;
	requiresReview: boolean;
	score: number | null;
	totalScore: number | null;
	weight: number | null;
	demands: string | null;
	advice: string | null;
	resolutionTerm: number | null;
};

const str = (value: string | null): string => value ? value : "";

const getState = (state: PremiumCalcPreRiskAnswerState): string => {
	switch (state) {
		case PremiumCalcPreRiskAnswerState.Valid:
			return "premium-calculation.prerisk.valid";
		case PremiumCalcPreRiskAnswerState.NoScoreFound:
			return "premium-calculation.prerisk.no-score-found";
		case PremiumCalcPreRiskAnswerState.NoMatchingScoreFound:
			return "premium-calculation.prerisk.no-matching-score-found";
		case PremiumCalcPreRiskAnswerState.Invisible:
			return "premium-calculation.prerisk.invisible";
		default:
			return "premium-calculation.prerisk.unkown";
	}
};

const getRowStyle = (state: PremiumCalcPreRiskAnswerState, requiresReview: boolean, requiresInspection: boolean): number => {
	switch (state) {
		case PremiumCalcPreRiskAnswerState.Valid:
			return requiresReview || requiresInspection ? RowStyle.Warning : RowStyle.Valid;
		case PremiumCalcPreRiskAnswerState.Invisible:
			return RowStyle.Invisible;
		default:
			return RowStyle.Invalid;
	}
};

const PreRiskFeedbackComponent = ({ feedback }: Props): JSX.Element => {
	const tableData = useMemo(() => {
		const data: PreRiskAnswerTableRow[] = feedback.answerFeedbacks ? feedback.answerFeedbacks.map(x => ({
			keyValue: str(x.questionName),
			questionName: Translate(str(x.questionName)),
			originalQuestionName: x.questionName,
			answer: x.fieldType === FieldType.Occupancy && x.answer ? Translate(x.answer) : x.answer,
			state: Translate(getState(x.state)),
			requiresInspection: x.requiresInspection,
			requiresReview: x.requiresReview,
			score: x.score,
			totalScore: (x.score && x.weight) ? x.score * x.weight : null,
			weight: x.weight,
			demands: x.demands,
			advice: x.advice,
			resolutionTerm: x.resolutionTerm,
			rowStyle: getRowStyle(x.state, x.requiresReview, x.requiresInspection)
		})) : [];
		data.sort((a, b) => str(a.originalQuestionName).localeCompare(str(b.originalQuestionName),
			navigator.language, { numeric: true }));

		let totalScore = 0.0;
		let totalWeight = 0.0;
		let totalTotalWeight = 0.0;
		data.forEach(x => {
			if (x.score)
				totalScore += x.score;
			if (x.weight)
				totalWeight += x.weight;
			if (x.score && x.weight)
				totalTotalWeight += (x.score * x.weight);
		});

		data.push({
			keyValue: "total",
			score: totalScore,
			weight: totalWeight,
			totalScore: totalTotalWeight,
			questionName: null,
			originalQuestionName: null,
			answer: null,
			state: null,
			requiresInspection: false,
			requiresReview: false,
			demands: null,
			advice: null,
			resolutionTerm: null,
			rowStyle: RowStyle.Total
		});

		return data;
	}, [feedback]);

	const cellPrepared = useCallback((e: any): void => {
		if (e.rowType === "data") {
			let style = "";
			switch (e.data.rowStyle) {
				case RowStyle.Valid:
					style = "prerisk-valid-cell";
					break;
				case RowStyle.Warning:
					style = "warning-cell";
					break;
				case RowStyle.Invisible:
					style = "irrelevant-cell";
					break;
				case RowStyle.Total:
					style = "prerisk-total-cell";
					break;
				case RowStyle.Invalid:
				default:
					style = "invalid-cell";
			}
			e.cellElement.classList.add(styles[style]);
		}
	}, []);

	return (
		<>
			<ComponentTitle text={Translate("premium-calculation.prerisk-calculation-feedback")} id={"prerisk-feedback"} />
			<p>
				Score: <b>{formatNumber(feedback.score, "#0.00")}</b>
			</p>

			<div className={styles["calculation-table"]}>
				<DataGrid
					keyExpr="keyValue"
					dataSource={tableData}
					showBorders
					sorting={undefined}
					paging={{ enabled: false }}
					onCellPrepared={cellPrepared}
					cellHintEnabled
				>
					<Column
						dataField="questionName"
						caption={Translate("premium-calculation.prerisk.question-name")}
						minWidth={500}
					/>
					<Column
						dataField="state"
						caption={Translate("premium-calculation.prerisk.state")}
					/>
					<Column
						dataField="answer"
						caption={Translate("premium-calculation.prerisk.answer")}
					/>
					<Column
						dataField="score"
						caption={Translate("premium-calculation.prerisk.score")}
						format={"#0.00"}
					/>
					<Column
						dataField="weight"
						caption={Translate("premium-calculation.prerisk.weight")}
						format={"#0.00"}
					/>
					<Column
						dataField="totalScore"
						caption={Translate("premium-calculation.prerisk.totalScore")}
						format={"#0.00"}
					/>
					<Column
						dataField="requiresInspection"
						caption={Translate("premium-calculation.prerisk.requires-inspection")}
					/>
					<Column
						dataField="requiresReview"
						caption={Translate("premium-calculation.prerisk.requires-review")}
					/>
					<Column
						dataField="demands"
						caption={Translate("premium-calculation.prerisk.demands")}
					/>
					<Column
						dataField="advice"
						caption={Translate("premium-calculation.prerisk.advice")}
					/>
					<Column
						dataField="resolutionTerm"
						caption={Translate("premium-calculation.prerisk.resolution-term")}
						format={"#0"}
					/>
				</DataGrid>
			</div>
		</>);
};

export default memo(PreRiskFeedbackComponent);