import { formatMessage, loadMessages, locale } from "devextreme/localization";
import enMessages from "devextreme/localization/messages/en.json";
import nlMessages from "devextreme/localization/messages/nl.json";

import enDx from "../public/translations/en-devextreme.json";
import enApp from "../public/translations/en-webapplication.json";
// DevExtreme component translations
import nlDx from "../public/translations/nl-devextreme.json";
// Our own translations
import nlApp from "../public/translations/nl-webapplication.json";
import { ErrorMessageFromAPI } from "../types/ErrorMessageFromAPI";

const locales = [
  // {
  //   Name: "",
  //   NameCode: "language.settings.english",
  //   Value: "en",
  // },
  {
    Name: "",
    NameCode: "language.settings.dutch",
    Value: "nl",
  },
];

const languageList = [enMessages, nlMessages, nlDx, nlApp, enDx, enApp];

const AddLanguages = (...newLanguages: { en: any }[] | { nl: any }[]): void => {
  newLanguages.forEach((lang) => {
    languageList.push(lang);
  });
};

/**
 *
 * @returns void
 */
const LoadLanguages = (): void[] => {
  // Temporary code: For v1.0 we disallow English
  if (locale() === "en") {
    locale("nl");
  }
  return languageList.map((languageItem) => loadMessages(languageItem));
};


/*
 * Read the language the first time from the local storage
 * or from the browser or get the default language
 * and save it to the locale storage.
 */
const GetUserLanguageOrDefault = (): string => {
  let currentLanguage = "nl";

  if (typeof window !== "undefined") {
    const storageLocale = localStorage.getItem("locale");
    if (storageLocale) currentLanguage = storageLocale;

    localStorage.setItem("locale", currentLanguage);
    locale(currentLanguage);
  }

  return currentLanguage;
};

const ChangeLanguage = (language: string): void => {
  if (typeof window !== "undefined") {
    localStorage.setItem("locale", language);
    window.location.reload();
  }
};

/* const LogProblems = (message: string, isWarning: boolean): void => {
  const customError = new CustomError(
    message,
    message,
    isWarning ? SecurityLevels.Warning : SecurityLevels.Error,
    "Translation",
    LogProblems.name
  );

  TrackException(customError);
};*/

/**
 * Use `Translate` or `_` to translate a given key in the curently active `locale` language.
 * @param key The `key` to be translated
 * @returns string
 */
const Translate = (key: string, ...values: string[]): string => {
  const currentLocale = locale();

  let translated = formatMessage(key, ...values);
  if (translated !== "") return translated;

  // If we can't find the translation in the desired locale, we try Dutch
  locale("nl");
  translated = formatMessage(key, ...values);

  if (translated !== "") return translated;

  locale(currentLocale);

  return key;
};

/*
 *    var response = await fetch("http://localhost:3000/test");
 *
 *    if (response.ok) {
 *      [...]
 *
 *    } else {
 *
 *      let data = await response.json();
 *      TranslateMessageFromAPI(data);
 *    }
 */
const TranslateMessageFromAPI = (data: ErrorMessageFromAPI): string => {
  const parameters = Object.keys(data.Parameters).map(
    (key) => data.Parameters[key]
  );
  return Translate(data.MessageErrorKey, ...parameters);
};

export {
  AddLanguages, ChangeLanguage, GetUserLanguageOrDefault, LoadLanguages, locales, Translate, Translate as _, TranslateMessageFromAPI,
};
