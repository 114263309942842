import { Guid } from "../utils/Guid";
import { befurstApiClient as api } from "./BefurstApi";
export const addTagTypes = [
  "Premium",
  "PremiumOutdated",
  "PremiumStipulations",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      premiumStipulationsCreatePremiumStipulation: build.mutation<
        PremiumStipulationsCreatePremiumStipulationApiResponse,
        PremiumStipulationsCreatePremiumStipulationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/PremiumStipulations`,
          method: "POST",
          body: queryArg.createPremiumStipulationDto,
        }),
        invalidatesTags: ["Premium", "PremiumOutdated", "PremiumStipulations"],
      }),
      premiumStipulationsUpdatePremiumStipulationResponse: build.mutation<
        PremiumStipulationsUpdatePremiumStipulationResponseApiResponse,
        PremiumStipulationsUpdatePremiumStipulationResponseApiArg
      >({
        query: (queryArg) => ({
          url: `/api/PremiumStipulations/${queryArg.premiumStipulationGuid}`,
          method: "PUT",
          params: { response: queryArg.response },
        }),
        invalidatesTags: ["PremiumOutdated", "PremiumStipulations"],
      }),
      premiumStipulationsUpdateQuoteReviewPremiumStipulation: build.mutation<
        PremiumStipulationsUpdateQuoteReviewPremiumStipulationApiResponse,
        PremiumStipulationsUpdateQuoteReviewPremiumStipulationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/PremiumStipulations/${queryArg.premiumStipulationGuid}/quote-review`,
          method: "PUT",
          body: queryArg.updatePremiumStipulationDto,
        }),
        invalidatesTags: ["PremiumOutdated", "PremiumStipulations"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as enhancedApi };
export type PremiumStipulationsCreatePremiumStipulationApiResponse =
  /** status 200 OK */ PremiumStipulationDto;
export type PremiumStipulationsCreatePremiumStipulationApiArg = {
  createPremiumStipulationDto: CreatePremiumStipulationDto;
};
export type PremiumStipulationsUpdatePremiumStipulationResponseApiResponse =
  /** status 200 OK */ PremiumStipulationDto;
export type PremiumStipulationsUpdatePremiumStipulationResponseApiArg = {
  premiumStipulationGuid: Guid;
  response?: PremiumStipulationResponse;
};
export type PremiumStipulationsUpdateQuoteReviewPremiumStipulationApiResponse =
  /** status 200 OK */ PremiumStipulationDto;
export type PremiumStipulationsUpdateQuoteReviewPremiumStipulationApiArg = {
  premiumStipulationGuid: Guid;
  updatePremiumStipulationDto: UpdatePremiumStipulationDto;
};
export type PremiumStipulationDto = {
  guid: Guid;
  code: string | null;
  description: string | null;
  preventionText: string | null;
  label: string | null;
  type: PremiumStipulationType;
  resolutionTimeMonths: number | null;
  response: PremiumStipulationResponse;
  irrelevant: boolean;
};
export type CustomError = {
  status: number;
  key: string | null;
  message: string | null;
};
export type CreatePremiumStipulationDto = {
  premiumGuid: Guid;
  stipulationCode: string | null;
  type: PremiumStipulationType;
};
export type UpdatePremiumStipulationDto = {
  type: PremiumStipulationType;
  irrelevant: boolean;
  resolutionTimeMonths: number | null;
};
export enum PremiumStipulationType {
  Demand = "Demand",
  Advice = "Advice",
}
export enum PremiumStipulationResponse {
  WillComply = "WillComply",
  WillNotComply = "WillNotComply",
  AlreadyComplying = "AlreadyComplying",
}
export const {
  usePremiumStipulationsCreatePremiumStipulationMutation,
  usePremiumStipulationsUpdatePremiumStipulationResponseMutation,
  usePremiumStipulationsUpdateQuoteReviewPremiumStipulationMutation,
} = injectedRtkApi;
