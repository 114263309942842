import { Guid } from "../utils/Guid";
import { befurstApiClient as api } from "./BefurstApi";
export const addTagTypes = ["Deductibles"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      deductiblesRetrieveDeductibles: build.query<
        DeductiblesRetrieveDeductiblesApiResponse,
        DeductiblesRetrieveDeductiblesApiArg
      >({
        query: () => ({ url: `/api/Deductibles` }),
        providesTags: ["Deductibles"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as enhancedApi };
export type DeductiblesRetrieveDeductiblesApiResponse =
  /** status 200 OK */ DeductibleDto[];
export type DeductiblesRetrieveDeductiblesApiArg = void;
export type DeductibleDto = {
  guid: Guid;
  amount: number;
};
export const {
  useDeductiblesRetrieveDeductiblesQuery,
  useLazyDeductiblesRetrieveDeductiblesQuery,
} = injectedRtkApi;
