import { Guid } from "../utils/Guid";
import { befurstApiClient as api } from "./BefurstApi";
export const addTagTypes = ["Coverages", "Policies"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      coveragesRetrieveCoverages: build.query<
        CoveragesRetrieveCoveragesApiResponse,
        CoveragesRetrieveCoveragesApiArg
      >({
        query: () => ({ url: `/api/Coverages` }),
        providesTags: ["Coverages"],
      }),
      policiesGetCurrentPolicyCoveragesChoices: build.query<
        PoliciesGetCurrentPolicyCoveragesChoicesApiResponse,
        PoliciesGetCurrentPolicyCoveragesChoicesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/current-version/coverages-choices`,
        }),
        providesTags: ["Policies"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as enhancedApi };
export type CoveragesRetrieveCoveragesApiResponse =
  /** status 200 OK */ CoverageDto[];
export type CoveragesRetrieveCoveragesApiArg = void;
export type PoliciesGetCurrentPolicyCoveragesChoicesApiResponse =
  /** status 200 OK */ CoverageChoiceDto[];
export type PoliciesGetCurrentPolicyCoveragesChoicesApiArg = {
  policyGuid: Guid;
};
export type CoverageDto = {
  guid: Guid;
  code: string | null;
  mandatory: boolean;
  translation: string | null;
  index: number;
};
export type CoverageChoiceDto = {
  guid: Guid;
  policyVersionGuid: Guid;
  code: string | null;
  value: boolean;
};
export type CustomError = {
  status: number;
  key: string | null;
  message: string | null;
};
export const {
  useCoveragesRetrieveCoveragesQuery,
  useLazyCoveragesRetrieveCoveragesQuery,
  usePoliciesGetCurrentPolicyCoveragesChoicesQuery,
  useLazyPoliciesGetCurrentPolicyCoveragesChoicesQuery,
} = injectedRtkApi;
