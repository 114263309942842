import { Guid } from "../utils/Guid";
import { befurstApiClient as api } from "./BefurstApi";
export const addTagTypes = ["Relations"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      relationsGetRelation: build.query<
        RelationsGetRelationApiResponse,
        RelationsGetRelationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Relations/${queryArg.relationGuid}`,
        }),
        providesTags: ["Relations"],
      }),
      relationsGetAddresses: build.query<
        RelationsGetAddressesApiResponse,
        RelationsGetAddressesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Relations/${queryArg.relationGuid}/addresses`,
        }),
        providesTags: ["Relations"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as enhancedApi };
export type RelationsGetRelationApiResponse = /** status 200 OK */ RelationDto;
export type RelationsGetRelationApiArg = {
  relationGuid: Guid;
};
export type RelationsGetAddressesApiResponse =
  /** status 200 OK */ AddressDto[];
export type RelationsGetAddressesApiArg = {
  relationGuid: Guid;
};
export type RelationDto = {
  kvkNumber: string | null;
  mainAddressGuid: Guid;
  legalStructure: string | null;
  companyName: string | null;
  email: string | null;
  sbiActivityCodes: string | null;
};
export type AddressDto = {
  guid: Guid;
  relationGuid: Guid;
  streetName: string | null;
  houseNumber: number;
  houseLetter: string | null;
  poBoxNumber: number;
  postalCode: string | null;
  city: string | null;
  country: string | null;
  latitude: number;
  longitude: number;
};
export type CustomError = {
  status: number;
  key: string | null;
  message: string | null;
};
export const {
  useRelationsGetRelationQuery,
  useLazyRelationsGetRelationQuery,
  useRelationsGetAddressesQuery,
  useLazyRelationsGetAddressesQuery,
} = injectedRtkApi;
