import { Guid } from "../utils/Guid";
import { befurstApiClient as api } from "./BefurstApi";
export const addTagTypes = [
  "Inspections",
  "Policies",
  "Premium",
  "PremiumOutdated",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      inspectionsGetInspection: build.query<
        InspectionsGetInspectionApiResponse,
        InspectionsGetInspectionApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Inspections/${queryArg.inspectionGuid}`,
        }),
        providesTags: ["Inspections"],
      }),
      inspectionsFlowFromRequestInspection: build.mutation<
        InspectionsFlowFromRequestInspectionApiResponse,
        InspectionsFlowFromRequestInspectionApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Inspections/${queryArg.inspectionGuid}/flow-from-request-inspection`,
          method: "PUT",
          params: { newState: queryArg.newState },
        }),
        invalidatesTags: ["Inspections"],
      }),
      inspectionsFlowFromInspectionToBeDelivered: build.mutation<
        InspectionsFlowFromInspectionToBeDeliveredApiResponse,
        InspectionsFlowFromInspectionToBeDeliveredApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Inspections/${queryArg.inspectionGuid}/flow-from-inspection-to-be-delivered`,
          method: "PUT",
          params: { newState: queryArg.newState },
        }),
        invalidatesTags: ["Policies", "Inspections"],
      }),
      inspectionsFlowFromContractChoiceRequired: build.mutation<
        InspectionsFlowFromContractChoiceRequiredApiResponse,
        InspectionsFlowFromContractChoiceRequiredApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Inspections/${queryArg.inspectionGuid}/flow-from-contract-choice-required`,
          method: "PUT",
          params: { newState: queryArg.newState },
        }),
        invalidatesTags: ["Policies", "Inspections"],
      }),
      inspectionsSetThreeYearlyContract: build.mutation<
        InspectionsSetThreeYearlyContractApiResponse,
        InspectionsSetThreeYearlyContractApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Inspections/${queryArg.inspectionGuid}/set-three-yearly-contract`,
          method: "PUT",
          params: { usesThreeYearlyContract: queryArg.usesThreeYearlyContract },
        }),
        invalidatesTags: ["Policies", "Inspections"],
      }),
      inspectionsFlowFromScheduleInspection: build.mutation<
        InspectionsFlowFromScheduleInspectionApiResponse,
        InspectionsFlowFromScheduleInspectionApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Inspections/${queryArg.inspectionGuid}/flow-from-schedule-inspection`,
          method: "PUT",
          params: { newState: queryArg.newState },
        }),
        invalidatesTags: ["Policies", "Inspections"],
      }),
      inspectionsFlowFromFillScore: build.mutation<
        InspectionsFlowFromFillScoreApiResponse,
        InspectionsFlowFromFillScoreApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Inspections/${queryArg.inspectionGuid}/flow-from-fill-score`,
          method: "PUT",
          params: { newState: queryArg.newState },
        }),
        invalidatesTags: ["Policies", "Inspections"],
      }),
      policiesCreatePremiumAfterFillInspectionScore: build.mutation<
        PoliciesCreatePremiumAfterFillInspectionScoreApiResponse,
        PoliciesCreatePremiumAfterFillInspectionScoreApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/current-version/premium-after-fill-inspection-score`,
          method: "POST",
        }),
        invalidatesTags: ["Premium", "Policies"],
      }),
      inspectionsFillScore: build.mutation<
        InspectionsFillScoreApiResponse,
        InspectionsFillScoreApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Inspections/${queryArg.inspectionGuid}/fill-score`,
          method: "PUT",
          params: { score: queryArg.score },
        }),
        invalidatesTags: ["PremiumOutdated", "Inspections"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as enhancedApi };
export type InspectionsGetInspectionApiResponse =
  /** status 200 OK */ InspectionDto;
export type InspectionsGetInspectionApiArg = {
  inspectionGuid: Guid;
};
export type InspectionsFlowFromRequestInspectionApiResponse =
  /** status 200 OK */ InspectionDto;
export type InspectionsFlowFromRequestInspectionApiArg = {
  inspectionGuid: Guid;
  newState?: InspectionState;
};
export type InspectionsFlowFromInspectionToBeDeliveredApiResponse =
  /** status 200 OK */ InspectionDto;
export type InspectionsFlowFromInspectionToBeDeliveredApiArg = {
  inspectionGuid: Guid;
  newState?: InspectionState;
};
export type InspectionsFlowFromContractChoiceRequiredApiResponse =
  /** status 200 OK */ InspectionDto;
export type InspectionsFlowFromContractChoiceRequiredApiArg = {
  inspectionGuid: Guid;
  newState?: InspectionState;
};
export type InspectionsSetThreeYearlyContractApiResponse =
  /** status 200 OK */ InspectionDto;
export type InspectionsSetThreeYearlyContractApiArg = {
  inspectionGuid: Guid;
  usesThreeYearlyContract?: boolean;
};
export type InspectionsFlowFromScheduleInspectionApiResponse =
  /** status 200 OK */ InspectionDto;
export type InspectionsFlowFromScheduleInspectionApiArg = {
  inspectionGuid: Guid;
  newState?: InspectionState;
};
export type InspectionsFlowFromFillScoreApiResponse =
  /** status 200 OK */ InspectionDto;
export type InspectionsFlowFromFillScoreApiArg = {
  inspectionGuid: Guid;
  newState?: InspectionState;
};
export type PoliciesCreatePremiumAfterFillInspectionScoreApiResponse =
  /** status 201 Created */ PremiumDto;
export type PoliciesCreatePremiumAfterFillInspectionScoreApiArg = {
  policyGuid: Guid;
};
export type InspectionsFillScoreApiResponse =
  /** status 200 OK */ InspectionDto;
export type InspectionsFillScoreApiArg = {
  inspectionGuid: Guid;
  score?: number;
};
export type InspectionDto = {
  guid: Guid;
  policyGuid: Guid;
  type: PhysicalInspectionType;
  state: InspectionState;
  rrsScore: number;
};
export type CustomError = {
  status: number;
  key: string | null;
  message: string | null;
};
export type PremiumDto = {
  guid: Guid;
  policyVersionGuid: Guid;
  stage: PremiumStage;
};
export enum PhysicalInspectionType {
  QuickScan = "QuickScan",
  FullInspection = "FullInspection",
}
export enum InspectionState {
  RequestInspection = "RequestInspection",
  InspectionToBeDelivered = "InspectionToBeDelivered",
  ContractChoiceRequired = "ContractChoiceRequired",
  FillScore = "FillScore",
  ScheduleInspection = "ScheduleInspection",
  Accepted = "Accepted",
  Rejected = "Rejected",
  NoInspection = "NoInspection",
}
export enum PremiumStage {
  Indication = "Indication",
  Quote = "Quote",
}
export const {
  useInspectionsGetInspectionQuery,
  useLazyInspectionsGetInspectionQuery,
  useInspectionsFlowFromRequestInspectionMutation,
  useInspectionsFlowFromInspectionToBeDeliveredMutation,
  useInspectionsFlowFromContractChoiceRequiredMutation,
  useInspectionsSetThreeYearlyContractMutation,
  useInspectionsFlowFromScheduleInspectionMutation,
  useInspectionsFlowFromFillScoreMutation,
  usePoliciesCreatePremiumAfterFillInspectionScoreMutation,
  useInspectionsFillScoreMutation,
} = injectedRtkApi;
