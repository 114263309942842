import React, { memo, useCallback, useMemo, useState } from "react";

import { NumberBox, Toast, ValidationSummary, Validator } from "devextreme-react";
import { RangeRule } from "devextreme-react/form";
import { ValueChangedEvent } from "devextreme/ui/number_box";

import { usePoliciesCreatePremiumAfterRejectedQuoteMutation, usePoliciesUpdateCommercialDiscountMutation } from "../../../apis/PoliciesApi";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { selectPolicyPage, setCommercialDiscount } from "../../../store/PolicyPageState";
import dialogButtonsStyles from "../../../styles/PremiumDialogButtons.module.scss";
import { Translate } from "../../../utils/Translation";
import Button from "../../buttons/Button";
import BaseComponent from "../policy-page-components/BaseComponent";

const percentFormatLabel = { 'aria-label': 'Percent Format' };

type Props = {
	readonly outdated: boolean;
};


const RecalculateRejectedQuoteComponent = ({ outdated }: Props): JSX.Element => {

	const dispatch = useAppDispatch();
	const { data } = useAppSelector(selectPolicyPage);

	if (!data)
		throw new Error('No policy page data available');

	const [triggerPutCommercialDiscount] = usePoliciesUpdateCommercialDiscountMutation();
	const [triggerCreatePremiumAfterRejectedQuote] = usePoliciesCreatePremiumAfterRejectedQuoteMutation();
	const [savingFailed, setSavingFailed] = useState(false);

	const onCommercialDiscountChanged = useCallback(async (e: ValueChangedEvent): Promise<void> => {
		try {
			dispatch(setCommercialDiscount(e.value));
			await triggerPutCommercialDiscount({
				policyGuid: data.policy.guid,
				commercialDiscount: e.value
			}).unwrap();
		} catch (error) {
			setSavingFailed(true);
		}

	}, [data.policy.guid, triggerPutCommercialDiscount, dispatch]);

	const numberBox = useMemo(() => {
		if (!data.policyVersion)
			return null;
		return (
			<>
				<NumberBox
					key="commercialDiscount"
					format="#0.0%"
					inputAttr={percentFormatLabel}
					step={0.01}
					onValueChanged={onCommercialDiscountChanged}
					value={data.policyVersion.commercialDiscount}
				>
					<Validator validationGroup="commercial-discount">
						<RangeRule min={0} max={0.1} message={Translate("premium.commercial-discount.out-of-range")} />
					</Validator>
				</NumberBox>
				<ValidationSummary validationGroup={"commercial-discount"} />
			</>
		);
	}, [onCommercialDiscountChanged, data.policyVersion]);

	return (
		<div>
			<BaseComponent id="commercialDiscount" captionKey="policy.form.title.commercial-discount">
				{numberBox}
				<Toast
					visible={savingFailed}
					message={Translate("policy.form.failure.saving")}
					type="error"
					onHiding={(): void => setSavingFailed(false)}
					closeOnClick
					displayTime={10000}
				/>
			</BaseComponent>
			{/* We use the dialog buttons style here so the button uses the same margins as the reoffer quote button */}
			<div className={dialogButtonsStyles["dialog-buttons"]}>
				<div className={dialogButtonsStyles["dialog-button"]}>
					<Button
						text={Translate("policy.form.recalculate-quote")}
						autoWidth
						isDisabled={!outdated}
						onClick={async (): Promise<void> => {
							await triggerCreatePremiumAfterRejectedQuote({
								policyGuid: data.policy.guid,
							});
						}}
					/>
				</div>
			</div>

		</div>
	);
};

export default memo(RecalculateRejectedQuoteComponent);