import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { RootState } from "../store/store";

export const befurstApiClient = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_IDENTITY_API,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = (getState() as RootState).identity.token;
      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
});

befurstApiClient.enhanceEndpoints({
  addTagTypes: [],
  endpoints: {
  },
});
