import React, { useCallback, useState } from "react";

import Box, { Item } from "devextreme-react/box";

import { useAppSelector } from "../../hooks/hooks";
import { Navigation, NavigationList } from "../../settings/NavigationPaths";
import { selectIdentity } from "../../store/Identity";
import NavBarButton from "./NavBarButton";

const NavBar = (): JSX.Element => {

	const { role, permissions } = useAppSelector(selectIdentity);

	// select the button related to the current page or none
	const [activeButton, setActiveButton] = useState("/");

	const isActive = useCallback((key: string): boolean => {
		return key === activeButton;
	}, [activeButton]);

	const getAllowedNavigation = useCallback((): Navigation[] => {
		return NavigationList.filter((item) => {
			// If an allowed permission is set and the user doesn't have that permission: Return false
			if (item.allowedPermission) {
				if (!permissions.find(x => x === item.allowedPermission)) {
					return false;
				}
			}
			// If allowed roles are set and the user doesn't have a role or the role is not included in the list: Return false
			if (item.allowedRoles) {
				if (role === null || item.allowedRoles.find(x => x === role) === undefined) {
					return false;
				}
			}
			return true;
		});
	}, [permissions, role]);

	return (
		<div style={{ width: 100 }}>
			<Box direction="col" width="100%" align="center" crossAlign="stretch" height={"auto"}>
				{getAllowedNavigation().map((navigationItem) => (
					<Item baseSize="100%" key={navigationItem.localeId}>
						<NavBarButton navigationItem={navigationItem} isActive={isActive} setActive={setActiveButton} />
					</Item>
				))}
			</Box>
		</div>
	);
};

export default NavBar;

