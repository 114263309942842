import React, { memo, useCallback, useMemo, useState } from "react";

import { Form, LoadIndicator } from "devextreme-react";
import { GroupItem, SimpleItem } from "devextreme-react/form";
import { FieldDataChangedEvent } from "devextreme/ui/form";
import notify from "devextreme/ui/notify";

import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";

import {
  CoverageDto, useCoveragesRetrieveCoveragesQuery, usePoliciesGetCurrentPolicyCoveragesChoicesQuery,
} from "../../../apis/CoveragesApi";
import {
  CoverageChoiceDto, usePoliciesResetCoverageChoicesMutation, usePoliciesUpdateCoverageChoiceMutation,
} from "../../../apis/PoliciesApi";
import { useAppSelector } from "../../../hooks/hooks";
import { selectIdentity } from "../../../store/Identity";
import { selectPolicyPage } from "../../../store/PolicyPageState";
import { isPolicyComponentReadOnly, POLICY_VALIDATION_GROUP, PolicyComponentBehaviorType } from "../../../utils/PolicyUtil";
import { Translate } from "../../../utils/Translation";
import { disableAutocompleteOnForm, sortIndices } from "../../../utils/utils";
import ComponentSubTitle from "../../titles/ComponentSubTitle";
import BaseComponent from "./BaseComponent";

const LIMIT_COVERAGE = "limitCoverage";

type Props = {
	readonly onFormChanged: () => Promise<void>;
};

const CoverageComponent = ({ onFormChanged }: Props): JSX.Element => {

	const { data } = useAppSelector(selectPolicyPage);
	const { username, permissions } = useAppSelector(selectIdentity);
	const [formData, setFormData] = useState<any>(null);
	const [triggerUpdateChoice] = usePoliciesUpdateCoverageChoiceMutation();
	const [triggerResetChoices] = usePoliciesResetCoverageChoicesMutation();

	if (!data)
		throw new Error("Policy page data not available");

	const readOnly = isPolicyComponentReadOnly(data, username, permissions, PolicyComponentBehaviorType.RequiresNoSecondReview);

	const { data: coverageOptions, error: coverageError, isLoading: coverageIsLoading } = useCoveragesRetrieveCoveragesQuery();
	const { data: coverageChoices, error: choicesError, isLoading: choicesIsLoading } =
		usePoliciesGetCurrentPolicyCoveragesChoicesQuery({
			policyGuid: data.policy.guid,
		});

	if (coverageError)
		throw new Error('Error retrieving coverages');
	if (choicesError)
		throw new Error('Error retrieving coverage choices');

	const setFieldValue = useCallback(async (e: FieldDataChangedEvent): Promise<void> => {
		try {

			if (!e.dataField || !coverageOptions)
				return;

			if (e.dataField === LIMIT_COVERAGE) {
				if (e.value === false) {
					setFormData((prev: any) => {
						coverageOptions.forEach(({ code }: { code: string | null }) => {
							if (code !== null)
								prev[code] = true;
						});
						prev[LIMIT_COVERAGE] = false;
						return prev;
					});
					await triggerResetChoices({ policyGuid: data.policy.guid }).unwrap();
				} else {
					setFormData((prev: any) => {
						return {
							...prev,
							LIMIT_COVERAGE: true,
						};
					});
				}
				return;
			}

			await triggerUpdateChoice({
				policyGuid: data.policy.guid,
				updateCoverageChoiceDto: {
					code: e.dataField,
					value: e.value,
				},
			}).unwrap();
		} catch (error) {
			const fetchError = error as FetchBaseQueryError;
			let fullError = Translate("error.premium-indication.request", `(${JSON.stringify(error)})`);
			if (fetchError) {
				const errorData = fetchError.data as any;
				const message = errorData && errorData.message ? errorData.message : JSON.stringify(fetchError.data);
				fullError = Translate("error.premium-indication.request", `(${fetchError.status}: ${message})`);
			}
			notify({
				message: fullError,
				width: 800,
				position: {
					at: "bottom",
					my: "top",
					offset: 20,
					of: "#coverage"
				}
			}, "error", 10000);
		}

	}, [coverageOptions, data.policy.guid, triggerResetChoices, triggerUpdateChoice]);

	const limitCheckbox = useMemo(() => {
		return <SimpleItem
			editorType="dxCheckBox"
			label={{ text: Translate("coverage.limit.checkbox") }}
			dataField={LIMIT_COVERAGE}
		/>;
	}, []);

	const optionFields = useMemo(() => {
		if (!coverageOptions)
			return null;

		return [...coverageOptions].sort((a, b) => sortIndices(a.index, b.index)).map((option) => {
			return (<SimpleItem
				dataField={option.code ?? undefined}
				editorType="dxCheckBox" key={option.guid}
				editorOptions={{
					disabled: option.mandatory
				}}
			/>);
		});
	}, [coverageOptions]);

	if (coverageIsLoading || choicesIsLoading || !coverageOptions || !coverageChoices)
		return <LoadIndicator />;

	if (formData === null) {
		const initialFormData: any = {};

		coverageOptions.forEach((option: CoverageDto) => {
			if (option.mandatory && option.code !== null)
				initialFormData[option.code] = true;
		});

		coverageChoices.forEach((choice: CoverageChoiceDto) => {
			if (choice.code !== null)
				initialFormData[choice.code] = choice.value;
		});
		initialFormData[LIMIT_COVERAGE] = [...coverageOptions].some(option => option.code && !initialFormData[option.code]);
		setFormData(initialFormData);
	}

	if (!formData)
		return <LoadIndicator />;

	return (
		<BaseComponent id="coverage" captionKey="policy.form.title.coverage">
			<ComponentSubTitle text={Translate("coverage.limit.question")} />
			<Form
				formData={formData}
				onOptionChanged={onFormChanged}
				readOnly={readOnly}
				onFieldDataChanged={setFieldValue}
				validationGroup={POLICY_VALIDATION_GROUP}
				labelLocation={"left"}
				onContentReady={disableAutocompleteOnForm}
			>
				<GroupItem>
					<GroupItem>
						{limitCheckbox}
					</GroupItem>
				</GroupItem>
				<GroupItem>
					<GroupItem visible={formData[LIMIT_COVERAGE]}>
						{optionFields}
					</GroupItem>
				</GroupItem>
			</Form>
		</BaseComponent>
	);
};

export default memo(CoverageComponent);