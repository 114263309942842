import React, { memo, useMemo } from "react";

import { useAppSelector } from "../../../hooks/hooks";
import { selectPreRiskData } from "../../../store/PreRiskState";
import styles from "../../../styles/PreRiskQuestionnaire.module.scss";
import { Translate } from "../../../utils/Translation";
import ComponentTitle from "../../titles/ComponentTitle";
import PreRiskHeaderComponent from "./PreRiskHeaderComponent";

export type Props = {
	readonly category: string;
};

const PreRiskCategoryComponent = ({ category }: Props): JSX.Element => {

	const { data } = useAppSelector(selectPreRiskData);

	if (!data)
		throw new Error('No pre-risk data available');

	const filteredQuestions = useMemo(() => data.questions.filter(x => x.category === category), [data.questions, category]);
	const headers = useMemo(() => Array.from(new Set(filteredQuestions.map(x => x.subHeader))), [filteredQuestions]);

	const title = useMemo(() => (
		<ComponentTitle
			text={Translate(category)}
			className={styles["category-text"]}
			id={"prerisk"}
		/>
	), [category]);

	const headerComponents = useMemo(() => {
		if (!headers)
			return null;
		return headers.map(header => {
			if (header)
				return <PreRiskHeaderComponent key={header} category={category} header={header} />;
			return null;
		});
	}, [category, headers]);

	return (
		<div key={category} className={styles["category"]}>
			{title}
			{headerComponents}
		</div>
	);
};

export default memo(PreRiskCategoryComponent);