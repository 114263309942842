import React from "react";

import AddressComponent, { AddressType } from "../utility-components/AddressComponent";
import BaseComponent from "./BaseComponent";

type Props = {
	readonly onFormChanged: () => Promise<void>;
};

const InsuredAddressComponent = ({ onFormChanged }: Props): JSX.Element => {

	return (
		<BaseComponent id="insured-address" captionKey="policy.insured-address.title">
			<AddressComponent onFormChanged={onFormChanged} addressType={AddressType.Insured} />
		</BaseComponent>
	);
};

export default React.memo(InsuredAddressComponent);