import React from "react";

import { Button as DxButton } from "devextreme-react/button";
import { ButtonStyle, ButtonType } from "devextreme/common";
import { ClickEvent } from "devextreme/ui/button";

import styles from "../../styles/Button.module.scss";

type Props = {
	readonly text: string;
	readonly type?: ButtonType;
	readonly autoWidth?: boolean;
	readonly stylingMode?: ButtonStyle;
	readonly isDisabled?: boolean;
	readonly className?: string;
	readonly onClick?: ((e: ClickEvent) => void) | ((e: ClickEvent) => Promise<void>) | undefined;
};

const Button = ({
	text,
	type = "default",
	autoWidth = false,
	stylingMode = "contained",
	isDisabled = false,
	className,
	onClick,
}: Props): JSX.Element => {
	let finalClassName = `${styles["common"]}`;
	if (className) {
		const derivedClass = `${className}-${stylingMode}`;
		finalClassName += ` ${styles[derivedClass]}`;
	}

	return (
		<DxButton
			text={text}
			type={type}
			width={autoWidth ? "auto" : 200}
			stylingMode={stylingMode}
			disabled={isDisabled}
			className={finalClassName}
			onClick={onClick}
		/>
	);
};

export default Button;
