import React from "react";

import { LoadIndicator } from "devextreme-react";

import { useProfilesFetchProfileQuery } from "../../apis/ProfilesApi";
import { Translate } from "../../utils/Translation";
import Main from "../Main";

const Policies = (): JSX.Element => {
	const { data: profile } = useProfilesFetchProfileQuery();

	if (!profile)
		return <LoadIndicator />;

	return (
		<Main title={Translate("title.home")}>
			Welcome{profile ? `, ${profile.nickname}` : ""}
		</Main>
	);
};

export default Policies;
