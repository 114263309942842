import { formatNumber } from "devextreme/localization";

import { FieldType } from "../apis/PolicyTypeApi";
import { deserialize } from "./GenericDataUtil";

export const euroFormat = {
	style: "currency",
	currency: "EUR",
	useGrouping: true,
	precision: 2
};

export function formatEuros(euros: number): string {
	return formatNumber(euros, euroFormat);
}

export const percentageFormat = "#0.##%";

export function formatPercentage(percentage: number): string {
	return formatNumber(percentage, percentageFormat);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const format = (type: FieldType | undefined, value: any): string => {
	if (!value)
		return "";
	if (!type)
		return value;
	switch (type) {
		case FieldType.Currency:
			return formatEuros(deserialize(type, value) as number);
		default:
			return value;
	}
};

export const formatSerialized = (type: FieldType | undefined, value: string | null | undefined): string => {
	return format(type, deserialize(type, value));
};