import { createBrowserHistory } from "history";

import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

let browserHistory = null;

if (typeof window !== "undefined") browserHistory = typeof document === "undefined" ? null : createBrowserHistory();

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: "f2d76af1-711b-4188-9253-fbd12cc0dfcc",
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});
appInsights.loadAppInsights();
appInsights.trackPageView();
export { appInsights, reactPlugin };
