import React, { memo, useCallback, useMemo, useState } from "react";

import { Form, LoadIndicator } from "devextreme-react";
import { GroupItem, RangeRule, RequiredRule, SimpleItem } from "devextreme-react/form";
import { ContentReadyEvent, FieldDataChangedEvent } from "devextreme/ui/form";

import { FieldType, PreRiskAnswerDto, usePoliciesFetchPreRiskAnswersQuery } from "../../../apis/PoliciesApi";
import { PreRiskAnswerOptionDto, usePreRiskAnswersOptionsFetchPreRiskAnswersOptionsQuery } from "../../../apis/PreRiskAnswersOptionsApi";
import { PreRiskQuestionDto } from "../../../apis/PreRiskQuestionsApi";
import { useAppSelector } from "../../../hooks/hooks";
import { useCreateOrUpdatePreRiskAnswer } from "../../../hooks/useCreateOrUpdatePreRiskAnswer";
import usePreRiskFieldRetrieval from "../../../hooks/usePreRiskFieldRetrieval";
import { selectPreRiskData } from "../../../store/PreRiskState";
import { isPreRiskQuestionVisible } from "../../../utils/fieldsVisibility";
import { deserialize, serialize } from "../../../utils/GenericDataUtil";
import { emptyGuid, Guid } from "../../../utils/Guid";
import { PRE_RISK_VALIDATION_GROUP } from "../../../utils/PreRiskUtil";
import { Translate } from "../../../utils/Translation";
import { disableAutocompleteOnForm } from "../../../utils/utils";
import PolicyFormSubHeader from "../../titles/PolicyFormSubHeader";
import OccupancyComponent, { OccupancyChange, OccupancyState } from "../utility-components/OccupancyComponent";

export type Props = {
	readonly category: string;
	readonly header: string;
};

const OCC_VISIBILITY_PREFIX = "occ-visibility-";
const getOccupancyVisibilityField = (questionName: string): string => {
	return `${OCC_VISIBILITY_PREFIX}${questionName}`;
};
const getQuestionFromOccupancyVisibilityField = (visibilityField: string): string => visibilityField.slice(OCC_VISIBILITY_PREFIX.length);
const isOccupancyVisibilityField = (fieldName: string): boolean => fieldName.startsWith(OCC_VISIBILITY_PREFIX);

const PreRiskHeaderComponent = ({ category, header }: Props): JSX.Element => {

	const { data } = useAppSelector(selectPreRiskData);

	if (!data)
		throw new Error('No pre-risk data available');

	const [formData, setFormData] = useState<any>(null);
	const [preRiskAnswerOptions, setPreRiskAnswerOptions] = useState<Map<string, PreRiskAnswerOptionDto[]> | null>(null);
	const [answersByQuestionName, setAnswersByQuestionName] = useState<Map<string, PreRiskAnswerDto>>(new Map());
	const { data: answers, isLoading, error } = usePoliciesFetchPreRiskAnswersQuery({ policyGuid: data.policy.guid });
	const { data: answerOptions, isLoading: loadingAnswerOptions, error: answerOptionsError } =
		usePreRiskAnswersOptionsFetchPreRiskAnswersOptionsQuery();
	const fieldRetrieval = usePreRiskFieldRetrieval(answers);
	const createOrUpdateAnswer = useCreateOrUpdatePreRiskAnswer(answersByQuestionName, setAnswersByQuestionName);

	const occupancyField = useCallback((question: PreRiskQuestionDto): JSX.Element => {
		const questionName = question.name ? question.name : "";
		const fetchOccupancy = (): OccupancyState => {
			const answer = answersByQuestionName.get(questionName);
			return {
				occupancyGuid: answer ? answer.value as Guid : emptyGuid(),
			};
		};

		const updateOccupancy = async (change: OccupancyChange): Promise<boolean> => {
			if (change.change !== "occupancy")
				return false;
			try {
				await createOrUpdateAnswer(questionName, change.newOccupancyGuid);
				return true;
			} catch {
				return false;
			}
		};

		return (
			<SimpleItem>
				<div>
					<OccupancyComponent
						mode="prerisk"
						readOnly={false}
						fetchOccupancy={fetchOccupancy}
						onFormChanged={(): Promise<void> => Promise.resolve()}
						updateOccupancy={updateOccupancy}
					/>
				</div>
			</SimpleItem>
		);
	}, [answersByQuestionName, createOrUpdateAnswer]);
	const createField = useCallback((question: PreRiskQuestionDto, options: PreRiskAnswerOptionDto[] | null = null): JSX.Element => {

		const questionName = question.name ? question.name : "";
		const text = question.text ? Translate(question.text) : "NO TRANSLATION";
		if (question.type === FieldType.Enum) {
			const dataSource = options
				? options.sort((a, b) => a.index - b.index).map(x => {
					return { key: x.key ? Translate(x.key) : "UNKNOWN OPTION", value: x.value, };
				})
				: [];
			return (
				<SimpleItem
					key={questionName}
					dataField={questionName}
					label={{ text }}
					editorType="dxSelectBox"
					editorOptions={{ valueExpr: "value", displayExpr: "key", dataSource, }}
				>
					<RequiredRule message={Translate("pre-risk-question.missing.message")} />
				</SimpleItem>);
		} else if (question.type === FieldType.Currency) {
			return (
				<SimpleItem
					key={questionName}
					dataField={questionName}
					label={{ text }}
					editorType="dxNumberBox"
					editorOptions={{ format: { type: 'currency', currency: 'EUR', precision: 2, useCurrencyAccountingStyle: false } }}
				>
					<RangeRule min={0.00001} message={Translate("pre-risk-question.positive-number.message")} />
				</SimpleItem>);
		} else if (question.type === FieldType.Number) {
			return (
				<SimpleItem
					key={questionName}
					dataField={questionName}
					label={{ text }}
					editorType="dxNumberBox"
				>
					<RangeRule min={0.00001} message={Translate("pre-risk-question.positive-number.message")} />
				</SimpleItem>);
		} else if (question.type === FieldType.Percentage) {
			return (
				<SimpleItem
					key={questionName}
					dataField={questionName}
					label={{ text }}
					editorType="dxNumberBox"
					editorOptions={{ format: "percent", min: 0, max: 1, step: 0.01, }}
				>
					<RangeRule min={0.00001} message={Translate("pre-risk-question.positive-number.message")} />
				</SimpleItem>);
		} else if (question.type === FieldType.Occupancy) {
			return occupancyField(question);
		} else {
			throw new Error(`Unknown question type: ${question.type}`);
		}
	}, [occupancyField]);

	const updateData = useCallback(async (e: FieldDataChangedEvent): Promise<void> => {
		if (!e.dataField)
			return;
		const questionName = e.dataField;
		if (isOccupancyVisibilityField(questionName)) {
			setFormData((prev: any): any => {
				const result = { ...prev, questionName: e.value };
				if (!e.value)
					result[getQuestionFromOccupancyVisibilityField(questionName)] = emptyGuid();
				return result;
			});
			if (!e.value) {
				// Unchecked --> set occupancy to empty
				createOrUpdateAnswer(getQuestionFromOccupancyVisibilityField(questionName), emptyGuid());
				answersByQuestionName.delete(getQuestionFromOccupancyVisibilityField(questionName));
			}
			return;
		}
		const question = data.questions.find(x => x.name === questionName);
		const value = question ? serialize(question.type, e.value) : e.value;

		await createOrUpdateAnswer(questionName, value);

	}, [answersByQuestionName, createOrUpdateAnswer, data.questions]);

	const filteredQuestions = useMemo(
		() => data.questions.filter(x => x.category === category && x.subHeader === header),
		[data.questions, category, header]);

	if (answers && !formData) {
		const initialFormData: any = {};
		// First we initialize the occupancy visibility fields, to make sure that they are always set
		for (const filteredQuestion of filteredQuestions.filter(x => x.type === FieldType.Occupancy)) {
			initialFormData[getOccupancyVisibilityField(filteredQuestion.name ? filteredQuestion.name : "")] = false;
		}
		// Then we fill all fields that have answers defined already
		for (const answer of answers) {
			const questionName = answer.questionName ? answer.questionName : "";
			const question = filteredQuestions.find(x => x.name === questionName);
			const value = question ? deserialize(question.type, answer.value) : answer.value;
			initialFormData[questionName] = value;
			if (question && question.type === FieldType.Occupancy) {
				initialFormData[getOccupancyVisibilityField(questionName)] = value ? value !== emptyGuid() : false;
			}
			answersByQuestionName.set(questionName, answer);
		}
		setFormData(initialFormData);
		setAnswersByQuestionName(answersByQuestionName);
	}

	const formFields = useMemo(() => {
		const map = new Map<string, JSX.Element>();
		if (!preRiskAnswerOptions)
			return map;

		filteredQuestions.forEach((question) => {
			const options = question.type === FieldType.Enum && question.answerCategory
				? preRiskAnswerOptions.get(question.answerCategory) : null;
			const questionName = question.name ? question.name : "";
			if (question.type === FieldType.Occupancy) {
				const visibilityField = getOccupancyVisibilityField(questionName);
				map.set(visibilityField, <SimpleItem
					key={visibilityField}
					dataField={visibilityField}
					label={{ text: Translate(question.text ? question.text : "") }}
					editorType="dxCheckBox"
				/>);
			}
			map.set(questionName, createField(question, options));
		});
		return map;
	}, [createField, filteredQuestions, preRiskAnswerOptions]);

	const validateForm = useCallback((e: ContentReadyEvent) => {
		disableAutocompleteOnForm(e);
		e.component.validate();
	}, []);

	if (!preRiskAnswerOptions && answerOptions) {
		const answerOptionsByQuestion = new Map<string, PreRiskAnswerOptionDto[]>();
		for (const option of answerOptions) {
			let array = answerOptionsByQuestion.get(option.category as string);
			if (!array) {
				array = [];
				answerOptionsByQuestion.set(option.category as string, array);
			}
			array.push(option);
		}
		setPreRiskAnswerOptions(answerOptionsByQuestion);
	}

	const title = useMemo(() => <PolicyFormSubHeader text={Translate(header)} />, [header]);

	if (isLoading || loadingAnswerOptions || !preRiskAnswerOptions || !fieldRetrieval || !formData)
		return (
			<div key={header}>
				{title}
				<LoadIndicator />
			</div>
		);

	if (error || answerOptionsError)
		return <div>{Translate("pre-risk-questionnaire.failure.message")}</div>;

	return (
		<div key={header}>
			{title}
			<Form
				formData={formData}
				onFieldDataChanged={updateData}
				validationGroup={PRE_RISK_VALIDATION_GROUP}
				onContentReady={validateForm}
			>
				{filteredQuestions.map((question: PreRiskQuestionDto): JSX.Element => {
					const questionName = question.name ? question.name : "";
					const questionVisible = isPreRiskQuestionVisible(question, fieldRetrieval, data, answers ? answers : []);

					if (question.type === FieldType.Occupancy) {
						const visibilityField = getOccupancyVisibilityField(questionName);
						const isOccupancyEditorVisible = formData[visibilityField] as boolean;
						return (
							<GroupItem key={questionName}>
								<GroupItem key={`${questionName}-question`} visible={questionVisible}>
									<GroupItem key={`${questionName}-visibility`}>
										<GroupItem key={`${questionName}-visibilityField`}>
											{formFields.get(visibilityField)}
										</GroupItem>
									</GroupItem>
									<GroupItem key={`${questionName}-occupancy`}>
										<GroupItem key={`${questionName}-occupancy-item`} visible={isOccupancyEditorVisible}>
											{formFields.get(questionName)}
										</GroupItem>
									</GroupItem>
								</GroupItem>
							</GroupItem>);
					} else {
						return (
							<GroupItem key={questionName}>
								<GroupItem key={`${questionName}-question`} visible={questionVisible}>
									{formFields.get(questionName)}
								</GroupItem>
							</GroupItem>
						);
					}
				})}
			</Form>
		</div>
	);
};

export default memo(PreRiskHeaderComponent);