import { configureStore } from "@reduxjs/toolkit";

import { befurstApiClient } from "../apis/BefurstApi";
import focusedFieldsReducer from "./FormFocusedField";
import identityReducer from "./Identity";
import policyPageStateReducer from "./PolicyPageState";
import policyValidationReducer from "./PolicyValidation";
import preRiskStateReducer from "./PreRiskState";
import { stipulationsReducer } from "./StipulationsSlice";

export const store = configureStore({
	reducer: {
		identity: identityReducer,
		policyValidation: policyValidationReducer,
		focusedFields: focusedFieldsReducer,
		policyPageState: policyPageStateReducer,
		preRiskState: preRiskStateReducer,
		stipulationState: stipulationsReducer,
		[befurstApiClient.reducerPath]: befurstApiClient.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(befurstApiClient.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
