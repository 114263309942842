import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store/store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const useAppDispatch = () => useDispatch<AppDispatch>();
/* eslint-enable */
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
