/* eslint-disable max-lines */
import React, { memo, useCallback, useMemo, useState } from "react";

import { LoadIndicator, Toast } from "devextreme-react";
import Form, { GroupItem, RequiredRule, SimpleItem } from "devextreme-react/form";
import DataSource from "devextreme/data/data_source";
import { ContentReadyEvent, FieldDataChangedEvent } from "devextreme/ui/form";

import { DestinationDto, useLazyDestinationsGetDestinationsQuery } from "../../../apis/DestinationsApi";
import { useAsyncEffect } from "../../../hooks/AsyncEffectHook";
import styles from "../../../styles/PolicyInput.module.scss";
import { OccupancyHierarchy, Option } from "../../../types/OccupancyHierarchy";
import { emptyGuid, Guid } from "../../../utils/Guid";
import { POLICY_VALIDATION_GROUP } from "../../../utils/PolicyUtil";
import { Translate } from "../../../utils/Translation";
import { disableAutocompleteOnForm } from "../../../utils/utils";

const SELECTED_OCCUPANCY = "selectedOccupancyTranslation";
const OCCUPANCY_NOT_FOUND = "occupancyNotFound";
const SEARCH_OCCUPANCY = "searchForOccupancy";
const MAIN_GROUP = "mainGroup";
const CLUSTER = "cluster";
const SEGMENT = "segment";
const OCCUPANCY = "occupancy";
const DESCRIPTION = "description";
const REMARKS = "remarks";

export type OccupancyState = {
	occupancyGuid: Guid;
	description?: string | null;
	remarks?: string | null;
};

export type ChangeOccupancyGuid = {
	change: "occupancy",
	newOccupancyGuid: Guid;
};

export type ChangeOccupancyDescription = {
	change: "description",
	newDescription: string | null;
};

export type ChangeOccupancyRemarks = {
	change: "remarks",
	newRemarks: string | null;
};

export type OccupancyChange = ChangeOccupancyGuid | ChangeOccupancyDescription | ChangeOccupancyRemarks;

type OccupancyData = {
	occupanciesByGuid: Map<Guid, DestinationDto>,
	occupancyTranslations: Array<string>,
	occupanciesByTranslation: Map<string, DestinationDto>,
	hierarchy: OccupancyHierarchy,
	mainGroups: DataSource,
	clusters: DataSource,
	segments: DataSource,
	occupancies: DataSource,
};

type FormData = {
	[SELECTED_OCCUPANCY]: string | null;
	[SEARCH_OCCUPANCY]: boolean;
	[MAIN_GROUP]: string | null;
	[CLUSTER]: string | null;
	[SEGMENT]: string | null;
	[OCCUPANCY]: string | null;
	[OCCUPANCY_NOT_FOUND]: boolean;
	[DESCRIPTION]?: string | null;
	[REMARKS]?: string | null;
};

type FormDataUpdate = {
	[SELECTED_OCCUPANCY]?: string | null,
	[SEARCH_OCCUPANCY]?: boolean,
	[MAIN_GROUP]?: string | null;
	[CLUSTER]?: string | null;
	[SEGMENT]?: string | null;
	[OCCUPANCY]?: string | null;
	[OCCUPANCY_NOT_FOUND]?: boolean;
	[DESCRIPTION]?: string | null;
	[REMARKS]?: string | null;
};

type Props = {
	readonly fetchOccupancy: () => OccupancyState;
	readonly updateOccupancy: (change: OccupancyChange) => Promise<boolean>;
	readonly onFormChanged: () => Promise<void>;
	readonly mode: "default" | "supplier" | "prerisk";
	readonly readOnly: boolean | undefined;
};

const caption = (cap: string | null | undefined): string => cap ? cap : "";
const captionOrNull = (cap: string | null | undefined): string | null => cap === null || cap === undefined ? null : cap;
const translate = (cap: string | null | undefined): string => Translate(caption(cap));

const OccupancyComponent = ({ fetchOccupancy, updateOccupancy, onFormChanged, mode, readOnly }: Props): JSX.Element => {

	const [formData, setFormData] = useState<FormData | null>(null);
	const [occupancyData, setOccupancyData] = useState<OccupancyData | null>(null);
	const [savingFailed, setSavingFailed] = useState(false);

	const [triggerGetOccupancies] = useLazyDestinationsGetDestinationsQuery();
	const validateForm = useCallback((e: ContentReadyEvent) => {
		disableAutocompleteOnForm(e);
		e.component.validate();
		onFormChanged();
	}, [onFormChanged]);

	useAsyncEffect(async (): Promise<void> => {

		const occupancyList = await triggerGetOccupancies().unwrap();
		const hierarchy = new OccupancyHierarchy(occupancyList);
		const mainGroups = new DataSource(hierarchy.mainGroupOptions());
		const clusters = new DataSource(hierarchy.clusterOptions());
		const segments = new DataSource(hierarchy.segmentOptions());
		const occupancies = new DataSource(hierarchy.occupancyOptions());
		setOccupancyData({
			occupanciesByGuid: new Map(occupancyList.map((x) => [x.guid, x])),
			occupancyTranslations: occupancyList.map((x) => translate(x.destinationCaption)),
			occupanciesByTranslation:
				new Map(occupancyList.map((x) => [translate(x.destinationCaption), x])),
			hierarchy,
			mainGroups,
			clusters,
			segments,
			occupancies,
		});

	}, [triggerGetOccupancies]);

	const occupancyNotFound = formData ? formData[OCCUPANCY_NOT_FOUND] as boolean : false;
	const showNotFoundOptions = mode === "default";

	const occupancySelectionField = useMemo(() => {
		if (!occupancyData)
			return null;
		return (
			<SimpleItem
				dataField={SELECTED_OCCUPANCY}
				cssClass={styles["wide-editor"]}
				editorType="dxAutocomplete"
				label={{ text: Translate("policy.form.destinationCaption") }}
				editorOptions={{
					dataSource: occupancyData.occupancyTranslations,
					valueChangeEvent: "blur",
					maxItemCount: 0,
					minSearchLength: 2,
					readOnly: occupancyNotFound || readOnly,
				}}
			>
				{occupancyNotFound ? null : <RequiredRule message={Translate("policy.form.caption.required")} />}
			</SimpleItem>
		);
	}, [occupancyData, occupancyNotFound, readOnly]);

	const searchOccupancyCheckboxField = useMemo(() => {
		return (
			<SimpleItem
				cssClass={styles["wide-editor"]}
				dataField={SEARCH_OCCUPANCY}
				editorType="dxCheckBox"
				label={{ text: Translate("policy.form.searchForDestination") }}
			/>);
	}, []);

	const occupancyNotFoundCheckboxField = useMemo(() => {
		return (
			<SimpleItem
				dataField={OCCUPANCY_NOT_FOUND}
				editorType="dxCheckBox"
				label={{ text: Translate("policy.form.destinationNotFound") }}
			/>
		);
	}, []);

	const hasMainGroup = formData ? Boolean(formData[MAIN_GROUP]) : false;
	const hasCluster = formData ? Boolean(formData[CLUSTER]) : false;
	const hasSegment = formData ? Boolean(formData[SEGMENT]) : false;

	const mainGroupField = useMemo(() => {
		if (!occupancyData)
			return null;
		return (
			<SimpleItem
				key="select-main-group"
				dataField={MAIN_GROUP}
				editorType="dxSelectBox"
				label={{ text: Translate("policy.form.mainGroup") }}
				editorOptions={{
					valueExpr: "caption",
					displayExpr: "translation",
					dataSource: occupancyData.mainGroups,
					readOnly: occupancyNotFound,
				}}
			>
				{occupancyNotFound ? null : <RequiredRule message={Translate("policy.form.mainGroup.required")} />}
			</SimpleItem>
		);
	}, [occupancyData, occupancyNotFound]);
	const clusterField = useMemo(() => {
		if (!occupancyData)
			return null;
		return (
			<SimpleItem
				key="select-cluster"
				dataField={CLUSTER}
				editorType="dxSelectBox"
				label={{ text: Translate("policy.form.cluster") }}
				editorOptions={{
					valueExpr: "caption",
					displayExpr: "translation",
					dataSource: occupancyData.clusters,
					readOnly: !hasMainGroup || occupancyNotFound,
				}}
			>
				{occupancyNotFound ? null : <RequiredRule message={Translate("policy.form.cluster.required")} />}
			</SimpleItem>
		);
	}, [occupancyData, hasMainGroup, occupancyNotFound]);
	const segmentField = useMemo(() => {
		if (!occupancyData)
			return null;
		return (
			<SimpleItem
				key="select-segment"
				dataField={SEGMENT}
				editorType="dxSelectBox"
				label={{ text: Translate("policy.form.segment") }}
				editorOptions={{
					valueExpr: "caption",
					displayExpr: "translation",
					dataSource: occupancyData.segments,
					readOnly: !hasCluster || occupancyNotFound
				}}
			>
				{occupancyNotFound ? null : <RequiredRule message={Translate("policy.form.segment.required")} />}
			</SimpleItem>
		);
	}, [occupancyData, hasCluster, occupancyNotFound]);
	const occupancyField = useMemo(() => {
		if (!occupancyData)
			return null;
		return (
			<SimpleItem
				key="select-occupancy"
				dataField={OCCUPANCY}
				editorType="dxSelectBox"
				label={{ text: Translate("policy.form.destinationCaption") }}
				editorOptions={{
					valueExpr: "caption",
					displayExpr: "translation",
					dataSource: occupancyData.occupancies,
					readOnly: !hasSegment || occupancyNotFound,
				}}
			>
				{occupancyNotFound ? null : <RequiredRule message={Translate("policy.form.caption.required")} />}
			</SimpleItem>
		);
	}, [occupancyData, hasSegment, occupancyNotFound]);
	const occupancyDescriptionField = useMemo(() => {
		return (
			<SimpleItem
				dataField={DESCRIPTION}
				key="occupancy-description"
				editorType="dxTextArea"
				label={{ text: Translate("policy.form.destinationDescription") }}
			>
				{occupancyNotFound ? <RequiredRule message={Translate("policy.form.destinationDescription.required")} /> : null}
			</SimpleItem>
		);
	}, [occupancyNotFound]);
	const occupancyRemarksField = useMemo(() => {
		return (
			<SimpleItem
				dataField={REMARKS}
				key="occupancy-remarks"
				editorType="dxTextArea"
				label={{ text: Translate("policy.form.destinationRemark") }}
			/>
		);
	}, []);

	const updateFormData = useCallback((previousValue: FormData | null, update: FormDataUpdate): FormData => {
		if (previousValue) {
			return {
				...previousValue,
				...update,
			};
		} else {
			return {
				[SELECTED_OCCUPANCY]: null,
				[MAIN_GROUP]: null,
				[CLUSTER]: null,
				[SEGMENT]: null,
				[OCCUPANCY]: null,
				[OCCUPANCY_NOT_FOUND]: false,
				[SEARCH_OCCUPANCY]: false,
				...update,
			};
		}
	}, []);

	const setFilter = useCallback((datasource: DataSource, allowedOptions: Option[]): void => {
		const filter: any[] = [];
		allowedOptions.forEach(allowedOption => {
			if (filter.length > 0)
				filter.push("or");
			filter.push(["caption", "=", allowedOption.caption]);
		});
		datasource.filter(filter);
		datasource.reload();
	}, []);

	const fetchOccupancyData = useCallback(() => {
		if (!occupancyData)
			return;

		const { occupancyGuid, description, remarks } = fetchOccupancy();
		if (occupancyGuid === emptyGuid()) {
			setFormData({
				[SELECTED_OCCUPANCY]: null,
				[MAIN_GROUP]: null,
				[CLUSTER]: null,
				[SEGMENT]: null,
				[OCCUPANCY]: null,
				[OCCUPANCY_NOT_FOUND]: Boolean(description),
				[DESCRIPTION]: description,
				[REMARKS]: remarks,
				[SEARCH_OCCUPANCY]: false,
			});
		} else {
			const occupancy = occupancyData.occupanciesByGuid.get(occupancyGuid);
			if (!occupancy)
				throw new Error("Occupancy not found");
			setFormData({
				[SELECTED_OCCUPANCY]: translate(occupancy.destinationCaption),
				[OCCUPANCY_NOT_FOUND]: Boolean(description),
				[DESCRIPTION]: description,
				[REMARKS]: remarks,
				[MAIN_GROUP]: captionOrNull(occupancy.mainGroupCaption),
				[CLUSTER]: captionOrNull(occupancy.clusterCaption),
				[SEGMENT]: captionOrNull(occupancy.segmentCaption),
				[OCCUPANCY]: captionOrNull(occupancy.destinationCaption),
				[SEARCH_OCCUPANCY]: false,
			});
			setFilter(occupancyData.clusters,
				occupancyData.hierarchy.get(caption(occupancy.mainGroupCaption)).options());
			setFilter(occupancyData.segments,
				occupancyData.hierarchy.getCluster(caption(occupancy.clusterCaption)).options());
			setFilter(occupancyData.occupancies,
				occupancyData.hierarchy.getSegment(caption(occupancy.segmentCaption)).options());
		}

	}, [fetchOccupancy, occupancyData, setFilter]);

	const setFieldValue = useCallback(async (e: FieldDataChangedEvent): Promise<void> => {
		if (!occupancyData)
			return;
		if (!e.dataField)
			return;
		try {
			switch (e.dataField) {
				case SELECTED_OCCUPANCY: {
					const selectedText = e.value as string;
					const selectedOccupancy = selectedText ? occupancyData.occupanciesByTranslation.get(selectedText) : null;
					if (selectedOccupancy) {
						setFormData((prev: FormData | null): FormData => {
							return updateFormData(prev, {
								[SELECTED_OCCUPANCY]: translate(selectedOccupancy.destinationCaption),
								[MAIN_GROUP]: selectedOccupancy.mainGroupCaption,
								[CLUSTER]: selectedOccupancy.clusterCaption,
								[SEGMENT]: selectedOccupancy.segmentCaption,
								[OCCUPANCY]: selectedOccupancy.destinationCaption,
							});
						});
						setFilter(occupancyData.clusters,
							occupancyData.hierarchy.get(caption(selectedOccupancy.mainGroupCaption)).options());
						setFilter(occupancyData.segments,
							occupancyData.hierarchy.getCluster(caption(selectedOccupancy.clusterCaption)).options());
						setFilter(occupancyData.occupancies,
							occupancyData.hierarchy.getSegment(caption(selectedOccupancy.segmentCaption)).options());
						await updateOccupancy({ change: "occupancy", newOccupancyGuid: selectedOccupancy.guid });
					} else {
						setFormData((prev: FormData | null): FormData => {
							return updateFormData(prev, {
								[SELECTED_OCCUPANCY]: null,
								[MAIN_GROUP]: null,
								[CLUSTER]: null,
								[SEGMENT]: null,
								[OCCUPANCY]: null,
							});
						});
						await updateOccupancy({ change: "occupancy", newOccupancyGuid: emptyGuid() });
					}
					break;
				}
				case SEARCH_OCCUPANCY:
					setFormData((prev: FormData | null): FormData => updateFormData(prev, { [SEARCH_OCCUPANCY]: e.value as boolean }));
					break;
				case MAIN_GROUP: {
					const newMainGroup = e.value as string;
					setFormData((prev: FormData | null): FormData => updateFormData(prev, {
						[SELECTED_OCCUPANCY]: null,
						[MAIN_GROUP]: newMainGroup,
						[CLUSTER]: null,
						[SEGMENT]: null,
						[OCCUPANCY]: null,
					}));
					setFilter(occupancyData.clusters, occupancyData.hierarchy.get(newMainGroup).options());
					break;
				}
				case CLUSTER: {
					const newCluster = e.value as string;
					setFormData((prev: FormData | null): FormData => updateFormData(prev, {
						[SELECTED_OCCUPANCY]: null,
						[CLUSTER]: newCluster,
						[SEGMENT]: null,
						[OCCUPANCY]: null,
					}));
					setFilter(occupancyData.segments, occupancyData.hierarchy.getCluster(newCluster).options());
					break;
				}
				case SEGMENT: {
					const newSegment = e.value as string;
					setFormData((prev: FormData | null): FormData => updateFormData(prev, {
						[SELECTED_OCCUPANCY]: null,
						[SEGMENT]: newSegment,
						[OCCUPANCY]: null,
					}));
					setFilter(occupancyData.occupancies, occupancyData.hierarchy.getSegment(newSegment).options());
					break;
				}
				case OCCUPANCY: {
					const newOccupancy = e.value as string;
					const occupancy = occupancyData.occupanciesByTranslation.get(Translate(newOccupancy));
					if (!occupancy)
						return;
					setFormData((prev: FormData | null): FormData => updateFormData(prev, {
						[SELECTED_OCCUPANCY]: Translate(newOccupancy),
						[OCCUPANCY]: newOccupancy,
					}));
					await updateOccupancy({ change: "occupancy", newOccupancyGuid: occupancy.guid });
					break;
				}
				case OCCUPANCY_NOT_FOUND: {
					const newValue = e.value as boolean;
					setFormData((prev: FormData | null): FormData => updateFormData(prev, newValue ? {
						[OCCUPANCY_NOT_FOUND]: newValue,
						[SELECTED_OCCUPANCY]: null,
						[MAIN_GROUP]: null,
						[CLUSTER]: null,
						[SEGMENT]: null,
						[OCCUPANCY]: null,
					} : {
						[OCCUPANCY_NOT_FOUND]: newValue,
						[DESCRIPTION]: null,
					}));
					if (newValue)
						updateOccupancy({ change: "occupancy", newOccupancyGuid: emptyGuid() });
					else
						updateOccupancy({ change: "description", newDescription: null });
					break;
				}
				case DESCRIPTION:
					setFormData((prev: FormData | null): FormData => updateFormData(prev, {
						[DESCRIPTION]: e.value as string,
					}));
					await updateOccupancy({ change: "description", newDescription: e.value as string });
					break;
				case REMARKS:
					setFormData((prev: FormData | null): FormData => updateFormData(prev, {
						[REMARKS]: e.value as string,
					}));
					await updateOccupancy({ change: "remarks", newRemarks: e.value as string });
					break;
			}
			onFormChanged();
		} catch (err) {
			setSavingFailed(true);
		}
	}, [occupancyData, setFilter, updateFormData, updateOccupancy, onFormChanged]);

	if (!occupancyData)
		return (<LoadIndicator />);

	if (!formData) {
		fetchOccupancyData();
		return (<LoadIndicator />);
	}

	const searchOccupancy = Boolean(formData[SEARCH_OCCUPANCY]);

	return (
		<>
			<Form
				formData={formData}
				readOnly={readOnly}
				onFieldDataChanged={setFieldValue}
				onContentReady={validateForm}
				onOptionChanged={onFormChanged}
				validationGroup={(mode === "prerisk" ? undefined : POLICY_VALIDATION_GROUP)}>
				<GroupItem>
					<GroupItem>
						<GroupItem visible={!searchOccupancy}>
							{occupancySelectionField}
						</GroupItem>
					</GroupItem>
				</GroupItem>
				<GroupItem>
					<GroupItem>
						<GroupItem>
							{searchOccupancyCheckboxField}
						</GroupItem>
					</GroupItem>
				</GroupItem>
				<GroupItem>
					<GroupItem visible={searchOccupancy}>
						<GroupItem>{mainGroupField}</GroupItem>
						<GroupItem>{clusterField}</GroupItem>
						<GroupItem>{segmentField}</GroupItem>
						<GroupItem>{occupancyField}</GroupItem>
					</GroupItem>
				</GroupItem>
				<GroupItem visible={showNotFoundOptions}>
					<GroupItem>
						<GroupItem>
							{occupancyNotFoundCheckboxField}
						</GroupItem>
					</GroupItem>
				</GroupItem>
				<GroupItem visible={showNotFoundOptions}>
					<GroupItem visible={occupancyNotFound}>
						<GroupItem>
							{occupancyDescriptionField}
						</GroupItem>
					</GroupItem>
				</GroupItem>
				<GroupItem visible={showNotFoundOptions}>
					<GroupItem>
						<GroupItem>
							{occupancyRemarksField}
						</GroupItem>
					</GroupItem>
				</GroupItem>
			</Form>
			<Toast
				visible={savingFailed}
				message={Translate("policy.form.failure.saving")}
				type="error"
				onHiding={(): void => setSavingFailed(false)}
				closeOnClick
				displayTime={10000}
			/>
		</>
	);
};

export default memo(OccupancyComponent);