import { useCallback, useMemo } from "react";

import { PreRiskAnswerDto } from "../apis/PoliciesApi";
import { selectPreRiskData } from "../store/PreRiskState";
import { deserialize } from "../utils/GenericDataUtil";
import { useAppSelector } from "./hooks";

export const CEA_CODE_FIELD_NAME = "ceaCode";

const usePreRiskFieldRetrieval = (answers: PreRiskAnswerDto[] | undefined): ((fieldName: string) => any) | null => {

	const { data } = useAppSelector(selectPreRiskData);

	const ceaCode = data ? data.ceaCode : null;
	const questions = useMemo(() => data ? data.questions : [], [data]);
	const fieldValues = useMemo(() => data ? data.fieldValues : [], [data]);
	const coverages = useMemo(() => data ? data.coverage : [], [data]);

	const retrieval = useCallback((fieldName: string): any => {
		if (fieldName === CEA_CODE_FIELD_NAME)
			return ceaCode;
		const question = questions.find(x => x.name === fieldName);
		if (question) {
			if (!answers)
				return null;
			const answer = answers.find(x => x.questionName === question.name);
			return answer ? deserialize(question.type, answer.value) : null;
		}
		const policyField = fieldValues.find(x => x.fieldKey === fieldName);
		if (policyField) {
			return policyField.value;
		}
		const coverage = coverages.find(x => x.code === fieldName);
		if (coverage) {
			return coverage.value;
		}
		throw new Error(`Unknown field ${fieldName} in pre-risk visibility conditions`);
	}, [answers, ceaCode, coverages, fieldValues, questions]);

	return data ? retrieval : null;

};

export default usePreRiskFieldRetrieval;

