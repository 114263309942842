import React from "react";
import { createPortal } from "react-dom";

import { CSSTransition } from "react-transition-group";

import styles from "../../styles/Dialog.module.scss";
import { Translate } from "../../utils/Translation";
import Button from "../buttons/Button";
import ComponentSubTitle from "../titles/ComponentSubTitle";
import ComponentTitle from "../titles/ComponentTitle";

type Props = {
	title: string;
	subTitle: string;
	buttonText: string;
	isVisible: boolean;
	cancelAction: () => void;
	mainAction: () => Promise<void>;
	cancelActionDisabled?: boolean;
	mainActionDisabled?: boolean;
	children: JSX.Element | null;
	readonly dialogStyle?: "normal" | "danger"
};

const Dialog = ({
	title,
	subTitle,
	buttonText,
	isVisible,
	cancelAction,
	mainAction,
	cancelActionDisabled = false,
	mainActionDisabled = false,
	children,
	dialogStyle = "normal",
}: Props): JSX.Element => {

	const dialogCssClasses = `${styles["dialog"]} ${styles["show-border-" + dialogStyle]}`;
	const mainColor = dialogStyle === "normal" ? "blue" : "red";
	const content = (
		<>
			<CSSTransition
				in={isVisible}
				mountOnEnter
				unmountOnExit
				timeout={300}
				classNames={{
					enter: styles["overlay-enter"],
					enterActive: styles["overlay-enter-active"],
					exit: styles["overlay-exit"],
					exitActive: styles["overlay-exit-active"],
				}}
			>
				<div className={styles["overlay"]} />
			</CSSTransition>
			<CSSTransition
				in={isVisible}
				mountOnEnter
				unmountOnExit
				timeout={300}
				classNames={{
					enter: styles["dialog-enter"],
					enterActive: styles["dialog-enter-active"],
					exit: styles["dialog-exit"],
					exitActive: styles["dialog-exit-active"],
				}}
			>
				<div className={dialogCssClasses}>
					<div className={styles["dialog-title"]}>
						<ComponentTitle text={title} textColor={mainColor} id={title} useNavigation={false} />
					</div>
					<div className={styles["dialog-subtitle"]}>
						<ComponentSubTitle text={subTitle} />
					</div>
					<div className={styles["dialog-content"]}>{children}</div>
					<div className={styles["dialog-buttons"]}>
						<Button
							text={Translate("dialog.button.cancel")}
							type={dialogStyle}
							stylingMode="outlined"
							isDisabled={cancelActionDisabled}
							className={mainColor}
							onClick={(): void => cancelAction()}
						/>
						<Button
							text={buttonText}
							type={dialogStyle}
							isDisabled={mainActionDisabled}
							className={mainColor}
							autoWidth
							onClick={async (): Promise<boolean> => {
								await mainAction();
								return true;
							}}
						/>
					</div>
				</div>
			</CSSTransition>
		</>
	);

	return createPortal(
		content,
		document.getElementById("root") as HTMLElement | DocumentFragment
	);
};

export default Dialog;
