import React from "react";

import GenericDataComponent from "../utility-components/GenericDataComponent";
import BaseComponent from "./BaseComponent";

type Props = {
	readonly onFormChanged: () => Promise<void>;
};

const SumsAndInterestsComponent = ({ onFormChanged }: Props): JSX.Element => {

	return (
		<BaseComponent id="sums-interests" captionKey="policy.form.title.sums-interests">
			<GenericDataComponent onFormChanged={onFormChanged} category="sums-interests" />
		</BaseComponent>
	);
};

export default React.memo(SumsAndInterestsComponent);