import "devextreme-react/text-area";

import React, { useCallback, useMemo, useState } from "react";

import { add, startOfDay, startOfToday } from "date-fns";
import { Toast } from "devextreme-react";
import Form, { CustomRule, RangeRule, RequiredRule, SimpleItem } from "devextreme-react/form";
import { FieldDataChangedEvent } from "devextreme/ui/form";

import { usePoliciesUpdatePolicyVersionMutation } from "../../../apis/PoliciesApi";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { selectIdentity } from "../../../store/Identity";
import { selectPolicyPage, setContractExpirationDate, setEffectiveDate, setPremiumPaymentTerm } from "../../../store/PolicyPageState";
import { localAsUtc, utcAsLocal } from "../../../utils/commonFunctions";
import { isPolicyComponentReadOnly, POLICY_VALIDATION_GROUP } from "../../../utils/PolicyUtil";
import { Translate } from "../../../utils/Translation";
import { disableAutocompleteOnForm } from "../../../utils/utils";
import BaseComponent from "./BaseComponent";

const EFFECTIVE_DATE = "effectiveDate";
const HAS_EXPIRATION_DATA = "hasExpirationDate";
const EXPIRATION_DATE = "expirationDate";
const PREMIUM_PAYMENT_TERM = "premiumPaymentTerm";

type FormData = {
	[EFFECTIVE_DATE]: Date | null;
	[HAS_EXPIRATION_DATA]: boolean;
	[EXPIRATION_DATE]: Date | null;
	[PREMIUM_PAYMENT_TERM]: number;
};

type Props = {
	readonly onFormChanged: () => Promise<void>;
};

const TermsComponent = ({ onFormChanged }: Props): JSX.Element => {

	const dispatch = useAppDispatch();
	const { data } = useAppSelector(selectPolicyPage);
	const { username, permissions } = useAppSelector(selectIdentity);
	const [triggerPutPolicyVersion] = usePoliciesUpdatePolicyVersionMutation();
	const [savingFailed, setSavingFailed] = useState(false);

	if (!data)
		throw new Error("Policy data not set");

	const readOnly = isPolicyComponentReadOnly(data, username, permissions);
	const yearOffset = useMemo(() => data.policyVersion.usesThreeYearlyContract ? 2 : 0, [data.policyVersion.usesThreeYearlyContract]);

	// One year from now with a one yearly contract, three years from now with a three yearly contract
	const defaultExpirationDate = useMemo(() => data.policyVersion.effectiveDate ?
		add(data.policyVersion.effectiveDate, { years: 1 + yearOffset }) :
		null, [data.policyVersion.effectiveDate, yearOffset]);

	const expirationDate = useMemo(() => data.policyVersion.contractExpirationDate
		? data.policyVersion.contractExpirationDate
		: defaultExpirationDate, [data.policyVersion.contractExpirationDate, defaultExpirationDate]);

	const formData: FormData = {
		// Since an uninstantiated dxDateBox will always work with local time, whenever time is set at the server we also
		// interpret it as local, so 0:00 in local time. We always interpret local as utc when sending to the server, so
		// the devextreme components always see datetimes as 0:00 local, and the back-end as 0:00 UTC
		effectiveDate: data.policyVersion.effectiveDate ? utcAsLocal(new Date(data.policyVersion.effectiveDate)) : null,
		hasExpirationDate: Boolean(data.policyVersion.contractExpirationDate),
		expirationDate: expirationDate ? utcAsLocal(new Date(expirationDate)) : null,
		premiumPaymentTerm: data.policyVersion.premiumPaymentTerm,
	};

	const effectiveDateField = useMemo(() => {
		const startDate = startOfDay(data.policy.creationDate);
		const endDate = add(startOfToday(), { months: 3 });
		return (
			<SimpleItem
				dataField="effectiveDate"
				editorType="dxDateBox"
				label={{ text: Translate("policy.form.effectiveDate") }}
				editorOptions={{ displayFormat: "dd/MM/yyyy" }}
			>
				<RequiredRule message={Translate("policy.form.effectiveDate.required")} />
				<RangeRule min={startDate} max={endDate} message={Translate("policy.form.effectiveDate.out-of-range")} />
			</SimpleItem>
		);
	}, [data.policy]);

	const hasExpirationDateLabel = useMemo(() => ({ text: Translate("policy.form.hasExpirationDate") }), []);
	const expirationDateLabel = useMemo(() => ({ text: Translate("policy.form.expirationDate") }), []);

	const premiumPaymentTermField = useMemo(() => {
		return (
			<SimpleItem
				dataField="premiumPaymentTerm"
				editorType="dxSelectBox"
				label={{ text: Translate("policy.form.premiumPaymentTerm") }}
				editorOptions={{
					valueExpr: "value",
					displayExpr: "name",
					dataSource: [
						{
							value: 6,
							name: Translate("policy.form.premiumPaymentTerm.6-months"),
						},
						{
							value: 12,
							name: Translate("policy.form.premiumPaymentTerm.12-months"),
						},
					],
				}}
			/>);
	}, []);

	// Normally we'd expect the expiration date to be between a week and a year from the effective date
	// But when using a three yearly contract, we expect it to be between 2 years plus a week and 3 years from now
	const validateExpirationDate = useMemo(() => ({ value }: { value: any }): boolean => {
		if (!(value instanceof Date)) {
			throw new Error('Expected to get a date, but did not (needs any type because of devextreme wrong typing)');
		}
		const effectiveDate = data.policyVersion.effectiveDate;
		if (!effectiveDate || !value)
			return true;

		const startDate = add(effectiveDate, { years: yearOffset, weeks: 1 });
		const endDate = add(effectiveDate, { years: 1 + yearOffset });

		return value >= startDate && value <= endDate;

	}, [data, yearOffset]);

	const setFieldValue = useCallback(async (e: FieldDataChangedEvent): Promise<void> => {
		if (!e.dataField)
			return;
		try {
			switch (e.dataField) {
				case EFFECTIVE_DATE: {
					const date = e.value as Date;
					// See comment in formData why we do this interpretation
					const value = date ? localAsUtc(date).toISOString() : null;
					const result = await triggerPutPolicyVersion({
						policyGuid: data.policy.guid,
						updatePolicyVersionDto: {
							...data.policyVersion,
							effectiveDate: value,
						}
					}).unwrap();
					if (result.effectiveDate !== undefined)
						dispatch(setEffectiveDate(result.effectiveDate));
					break;
				}
				case HAS_EXPIRATION_DATA: {
					const useAlternative = e.value as boolean;
					let newExpirationDate = null;
					if (useAlternative) {
						if (data.policyVersion.effectiveDate)
							newExpirationDate = add(data.policyVersion.effectiveDate, { years: 1 }).toISOString();
						else
							newExpirationDate = add(startOfToday(), { years: 1 }).toISOString();
					}
					const result = await triggerPutPolicyVersion({
						policyGuid: data.policy.guid,
						updatePolicyVersionDto: {
							...data.policyVersion,
							contractExpirationDate: newExpirationDate,
						}
					}).unwrap();
					if (result.contractExpirationDate !== undefined)
						dispatch(setContractExpirationDate(result.contractExpirationDate));
					break;
				}
				case EXPIRATION_DATE: {
					const date = e.value as Date;
					const newExpirationDate = date ? localAsUtc(date).toISOString() : null;
					const result = await triggerPutPolicyVersion({
						policyGuid: data.policy.guid,
						updatePolicyVersionDto: {
							...data.policyVersion,
							contractExpirationDate: newExpirationDate,
						}
					}).unwrap();
					if (result.contractExpirationDate !== undefined)
						dispatch(setContractExpirationDate(result.contractExpirationDate));
					break;
				}
				case PREMIUM_PAYMENT_TERM: {
					const result = await triggerPutPolicyVersion({
						policyGuid: data.policy.guid,
						updatePolicyVersionDto: {
							...data.policyVersion,
							premiumPaymentTerm: e.value as number,
						}
					}).unwrap();
					if (result.premiumPaymentTerm !== undefined)
						dispatch(setPremiumPaymentTerm(result.premiumPaymentTerm));
				}
			}
			onFormChanged();
		} catch (err) {
			setSavingFailed(true);
		}
	}, [data, dispatch, triggerPutPolicyVersion, onFormChanged]);

	return (
		<BaseComponent captionKey="policy.form.title.terms" id="terms">
			<Form
				colCount={1}
				id="terms-form"
				formData={formData}
				readOnly={readOnly}
				onFieldDataChanged={setFieldValue}
				onOptionChanged={onFormChanged}
				validationGroup={POLICY_VALIDATION_GROUP}
				onContentReady={disableAutocompleteOnForm}
			>
				{effectiveDateField}
				<SimpleItem
					dataField="hasExpirationDate"
					editorType="dxCheckBox"
					label={hasExpirationDateLabel}
				/>
				<SimpleItem
					dataField="expirationDate"
					editorType="dxDateBox"
					label={expirationDateLabel}
					editorOptions={{ displayFormat: "dd/MM/yyyy", readOnly: !formData.hasExpirationDate || readOnly }}
				>
					<RequiredRule message={Translate("policy.form.expirationDate.required")} />
					<CustomRule
						message={Translate("policy.form.expirationDate.out-of-range")}
						validationCallback={validateExpirationDate}
					/>
				</SimpleItem>
				{premiumPaymentTermField}
			</Form>
			<Toast
				visible={savingFailed}
				message={Translate("policy.form.failure.saving")}
				type="error"
				onHiding={(): void => setSavingFailed(false)}
				closeOnClick
				displayTime={10000}
			/>
		</BaseComponent>
	);
};

export default React.memo(TermsComponent);
