import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { PolicyDto, PolicyVersionDto } from "../apis/PoliciesApi";
import { RelationDto } from "../apis/RelationsApi";
import { Guid } from "../utils/Guid";
import { RootState } from "./store";

export type PolicyPageData = {
	policy: PolicyDto;
	policyVersion: PolicyVersionDto;
	relation: RelationDto;
};

export type PolicyNavigationItem = {
	readonly hash: string;
	text: string;
	visible: boolean;
};

export type PolicyPageState = {
	data: PolicyPageData | null;
	navigationItems: PolicyNavigationItem[];
};

const initialState: PolicyPageState = {
	data: null,
	navigationItems: []
};

export type UpdatePolicyOccupancy = {
	destinationId: Guid;
	destinationDescription?: string;
	destinationRemark?: string;
};

export const policyPageStateSlice = createSlice({
	name: 'identity',
	initialState,
	reducers: {
		setData: (state, action: PayloadAction<PolicyPageData | null>) => {
			state.data = action.payload;
		},
		setPolicyEmail: (state, action: PayloadAction<string>) => {
			if (!state.data)
				return;
			state.data.policy.email = action.payload;
		},
		setPolicyNameInsured: (state, action: PayloadAction<string>) => {
			if (!state.data)
				return;
			state.data.policy.nameInsured = action.payload;
		},
		setProvisionPercentage: (state, action: PayloadAction<number>) => {
			if (!state.data)
				return;
			state.data.policyVersion.provisionPercentage = action.payload;
		},
		setCommercialDiscount: (state, action: PayloadAction<number>) => {
			if (!state.data)
				return;
			state.data.policyVersion.commercialDiscount = action.payload;
		},
		setEffectiveDate: (state, action: PayloadAction<string | null>) => {
			if (!state.data)
				return;
			state.data.policyVersion.effectiveDate = action.payload;
		},
		setContractExpirationDate: (state, action: PayloadAction<string | null>) => {
			if (!state.data)
				return;
			state.data.policyVersion.contractExpirationDate = action.payload;
		},
		setPremiumPaymentTerm: (state, action: PayloadAction<number>) => {
			if (!state.data)
				return;
			state.data.policyVersion.premiumPaymentTerm = action.payload;
		},
		setPolicyHolderAddressGuid: (state, action: PayloadAction<Guid>) => {
			if (!state.data)
				return;
			state.data.policyVersion.policyholderAddressGuid = action.payload;
		},
		setCorrespondenceAddressGuid: (state, action: PayloadAction<Guid>) => {
			if (!state.data)
				return;
			state.data.policyVersion.correspondenceAddressGuid = action.payload;
		},
		setInsuredAddressGuid: (state, action: PayloadAction<Guid>) => {
			if (!state.data)
				return;
			state.data.policyVersion.insuredAddressGuid = action.payload;
		},
		setPolicyOccupancy: (state, action: PayloadAction<UpdatePolicyOccupancy>) => {
			if (!state.data)
				return;
			state.data.policyVersion.destinationGuid = action.payload.destinationId;
			state.data.policyVersion.destinationDescription = action.payload.destinationDescription
				? action.payload.destinationDescription : null;
			state.data.policyVersion.destinationRemark = action.payload.destinationRemark ? action.payload.destinationRemark : null;
		},
		setDeductibleGuid: (state, action: PayloadAction<Guid>) => {
			if (!state.data)
				return;
			state.data.policyVersion.deductibleGuid = action.payload;
		},
		addNavigationItem: (state, action: PayloadAction<PolicyNavigationItem>) => {
			const newItem = action.payload;
			const existingItem = state.navigationItems.find(x => x.hash === newItem.hash);
			if (!existingItem) {
				state.navigationItems.push(newItem);
			} else {
				// Tried adding an item that already exists: Keep the existing element but adapt text, visibility
				existingItem.text = newItem.text;
				existingItem.visible = newItem.visible;
			}
		},
		removeNavigationItem: (state, action: PayloadAction<string>) => {
			const item = state.navigationItems.find(x => x.hash === action.payload);
			// Probably a duplicate call, so we just return
			if (!item)
				return;
			item.visible = false;
		}
	}
});

export const {
	setData,
	setPolicyEmail,
	setPolicyNameInsured,
	setEffectiveDate,
	setProvisionPercentage,
	setCommercialDiscount,
	setContractExpirationDate,
	setPremiumPaymentTerm,
	setPolicyHolderAddressGuid,
	setCorrespondenceAddressGuid,
	setInsuredAddressGuid,
	setPolicyOccupancy,
	setDeductibleGuid,
	addNavigationItem,
	removeNavigationItem
} = policyPageStateSlice.actions;
export const policyPageStateReducer = policyPageStateSlice.reducer;

export const selectPolicyPage = (state: RootState): PolicyPageState => state.policyPageState;

export default policyPageStateReducer;
