import { Guid } from "../utils/Guid";
import { befurstApiClient as api } from "./BefurstApi";
export const addTagTypes = ["Destinations"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      destinationsGetDestinations: build.query<
        DestinationsGetDestinationsApiResponse,
        DestinationsGetDestinationsApiArg
      >({
        query: () => ({ url: `/api/Destinations` }),
        providesTags: ["Destinations"],
      }),
      destinationsFetchDestination: build.query<
        DestinationsFetchDestinationApiResponse,
        DestinationsFetchDestinationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Destinations/${queryArg.destinationGuid}`,
        }),
        providesTags: ["Destinations"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as enhancedApi };
export type DestinationsGetDestinationsApiResponse =
  /** status 200 OK */ DestinationDto[];
export type DestinationsGetDestinationsApiArg = void;
export type DestinationsFetchDestinationApiResponse =
  /** status 200 OK */ DestinationDto;
export type DestinationsFetchDestinationApiArg = {
  destinationGuid: Guid;
};
export type DestinationDto = {
  guid: Guid;
  destinationCode: string | null;
  ceaCode: string | null;
  mainGroupCaption: string | null;
  clusterCaption: string | null;
  segmentCaption: string | null;
  destinationCaption: string | null;
};
export type CustomError = {
  status: number;
  key: string | null;
  message: string | null;
};
export const {
  useDestinationsGetDestinationsQuery,
  useLazyDestinationsGetDestinationsQuery,
  useDestinationsFetchDestinationQuery,
  useLazyDestinationsFetchDestinationQuery,
} = injectedRtkApi;
