import * as Interpreter from "js-interpreter";

export type EvaluatorInitFunc = {
    (interpreter: any, globalObject: any): void;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function AddNative(interpreter: any, globalObject: any, name: string, func: EvaluatorInitFunc): void {
    interpreter.setProperty(globalObject, name, interpreter.createNativeFunction(func));
}

function LocalInit(interpreter: any, globalObject: any): void {
    AddNative(interpreter, globalObject, "parseBool", (value: string): boolean => {
        if (typeof value === "boolean")
            return value;
        if (typeof value === "string")
            return value.trim().localeCompare("true", undefined, { sensitivity: "base" }) === 0;
        return false;
    });
}

export function Evaluate(condition: string, initFunc: EvaluatorInitFunc): boolean {
    const cleanedCondition = condition.replace("return ", "");
    const interp = new Interpreter(cleanedCondition, (interpreter: any, globalObject: any): void => {
        LocalInit(interpreter, globalObject);
        if (initFunc !== null) {
            initFunc(interpreter, globalObject);
        }
    });
    interp.run();
    return interp.value === true;
}
