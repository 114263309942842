import React, { memo, useMemo } from "react";

import { DataGrid } from "devextreme-react";
import { Column } from "devextreme-react/data-grid";

import { Translate } from "../../../utils/Translation";
import ComponentTitle from "../../titles/ComponentTitle";

type Props = {
	readonly failures: string[]
};

type Row = {
	failure: string,
	index: number;
};

const FailuresComponent = ({ failures }: Props): JSX.Element => {

	const tableRows = useMemo(() => failures.map((x, index) => {
		return {
			// Localisation not implemented for failures yet since they are like errors. But could work just like rejections.
			failure: x,
			index
		} as Row;
	}), [failures]);

	return (
		<>
			<ComponentTitle text={Translate("premium-calculation.failures")} id={"failures"} />
			<DataGrid
				dataSource={tableRows}
				showBorders
				columnAutoWidth
				columnHidingEnabled
				sorting={undefined}
				keyExpr={"index"}
			>
				<Column
					dataField="failure"
					caption={Translate("premium-calculation.failure")}
				/>
			</DataGrid>
		</>);
};

export default memo(FailuresComponent);