import { Guid } from "../utils/Guid";
import { befurstApiClient as api } from "./BefurstApi";
export const addTagTypes = ["Permissions"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      permissionsGetPermissions: build.query<
        PermissionsGetPermissionsApiResponse,
        PermissionsGetPermissionsApiArg
      >({
        query: () => ({ url: `/api/Permissions` }),
        providesTags: ["Permissions"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as enhancedApi };
export type PermissionsGetPermissionsApiResponse =
  /** status 200 OK */ string[];
export type PermissionsGetPermissionsApiArg = void;
export const {
  usePermissionsGetPermissionsQuery,
  useLazyPermissionsGetPermissionsQuery,
} = injectedRtkApi;
