import React, { memo, useCallback, useState } from "react";

import { CheckBox, Toast } from "devextreme-react";
import { ValueChangedEvent } from "devextreme/ui/check_box";

import { PolicyDto, usePoliciesUpdateFishAndUboMutation } from "../../apis/PoliciesApi";
import styles from "../../styles/Policy.module.scss";
import { Translate } from "../../utils/Translation";

type Props = {
	readonly policy: PolicyDto;
	readonly readonly?: boolean;
};

const FishAndUboComponent = ({ policy, readonly = false }: Props): JSX.Element => {

	const [fishAccepted, setFishAccepted] = useState(policy.fishAccepted);
	const [uboAccepted, setUboAccepted] = useState(policy.uboAccepted);
	const [savingFailed, setSavingFailed] = useState(false);

	const [triggerSetFishUbo] = usePoliciesUpdateFishAndUboMutation();

	const handleTestThing = useCallback(async (e: ValueChangedEvent, isFish: boolean): Promise<void> => {
		try {
			if (isFish)
				setFishAccepted(e.value);
			else
				setUboAccepted(e.value);
			await triggerSetFishUbo({
				policyGuid: policy.guid,
				updateFishAndUboDto: {
					fishAccepted: isFish ? e.value : fishAccepted,
					uboAccepted: isFish ? uboAccepted : e.value
				}
			}).unwrap();

		} catch (error) {
			setSavingFailed(true);
		}
	}, [fishAccepted, policy.guid, triggerSetFishUbo, uboAccepted]);

	return (
		<>

			<div className={styles["fish-and-ubo-wrapper"]}>
				<div className={styles["fish-and-ubo-item"]}>
					<p>{Translate("policy.checks.fish")}</p>
					<CheckBox
						value={fishAccepted}
						readOnly={readonly}
						onValueChanged={(e: ValueChangedEvent): Promise<void> => handleTestThing(e, true)}
					/>
				</div>
				<div className={styles["fish-and-ubo-item"]}>
					<p>{Translate("policy.checks.ubo")}</p>
					<CheckBox
						value={uboAccepted}
						readOnly={readonly}
						onValueChanged={(e: ValueChangedEvent): Promise<void> => handleTestThing(e, false)}
					/>
				</div>
			</div>
			<Toast
				visible={savingFailed}
				message={Translate("policy.form.failure.saving")}
				type="error"
				onHiding={(): void => setSavingFailed(false)}
				closeOnClick
				displayTime={10000}
			/>
		</>

	);
};

export default memo(FishAndUboComponent);