import { Translate } from "../utils/Translation";

export class Role {
    key: string;
    value: string;

    constructor(key: string, value: string) {
        this.key = key;
        this.value = value;
    }

    caption(): string {
        return Translate(this.key);
    }
}

function createRole(key: string, value: string): Role {
    return new Role(key, value);
}

export const roles: Role[] = [
    createRole("role.admin", "Admin"),
    createRole("role.intermediary", "Intermediary"),
    createRole("role.inspectionbureau", "InspectionBureau"),
    createRole("role.inspector", "Inspector"),
    createRole("role.acceptant", "Acceptant"),
    createRole("role.unauthorized", "Unauthorized"),
];