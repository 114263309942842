import { PreRiskAnswerDto } from "../apis/PoliciesApi";
import { PreRiskQuestionDto } from "../apis/PreRiskQuestionsApi";
import CEAFilters from "../models/policies/CEAFilters";
import { FieldValueRetrieval } from "../models/policies/FieldValueRetrieval";
import { PreRiskData } from "../store/PreRiskState";
import { PreRiskQuestionMatcher } from "../vm/PreRiskQuestionMatcher";
import { SimpleExpressionEvaluator } from "../vm/SimpleExpressionEvaluator";

export const isPreRiskQuestionVisible = (
	question: PreRiskQuestionDto,
	retrieval: FieldValueRetrieval,
	preRiskState: PreRiskData | null = null,
	answers: PreRiskAnswerDto[]
): boolean => {
	if (question.visibilityCondition) {
		if (preRiskState !== null) {
			const conditions = new PreRiskQuestionMatcher(preRiskState, answers);
			if (!conditions.Evaluate(question.visibilityCondition, retrieval)) return false;
		} else {
			const conditions = new SimpleExpressionEvaluator();
			if (!conditions.Evaluate(question.visibilityCondition, retrieval)) return false;
		}
	}

	const filters = new CEAFilters(question.ceaCodeFilter);
	if (!filters.evaluate(retrieval)) return false;

	return true;
};
