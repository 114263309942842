import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "./store";

export type StipulationsState = {
	hasAnyUnacceptableStipulations: boolean
	acceptantCanEditStipulationsFromInspection: boolean
};

const initialState: StipulationsState = {
	hasAnyUnacceptableStipulations: false,
	acceptantCanEditStipulationsFromInspection: false
};

export const stipulationSlice = createSlice({
	name: 'stipulations',
	initialState,
	reducers: {
		setHasAnyUnacceptableStipulations: (state, action: PayloadAction<boolean>) => {
			state.hasAnyUnacceptableStipulations = action.payload;
		},
		setAcceptantCanEditStipulationsFromInspection: (state, action: PayloadAction<boolean>) => {
			state.acceptantCanEditStipulationsFromInspection = action.payload;
		}
	}
});

export const {
	setHasAnyUnacceptableStipulations,
	setAcceptantCanEditStipulationsFromInspection
} = stipulationSlice.actions;
export const stipulationsReducer = stipulationSlice.reducer;

export const selectStipulations = (state: RootState): StipulationsState => state.stipulationState;

export default stipulationsReducer;
