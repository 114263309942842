import { Guid } from "../utils/Guid";
import { befurstApiClient as api } from "./BefurstApi";
export const addTagTypes = ["AppSettings"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      appSettingsFetchAuthenticationProviderSettings: build.query<
        AppSettingsFetchAuthenticationProviderSettingsApiResponse,
        AppSettingsFetchAuthenticationProviderSettingsApiArg
      >({
        query: () => ({ url: `/api/AppSettings/authentication-provider` }),
        providesTags: ["AppSettings"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as enhancedApi };
export type AppSettingsFetchAuthenticationProviderSettingsApiResponse =
  /** status 200 OK */ AuthenticationProviderDto;
export type AppSettingsFetchAuthenticationProviderSettingsApiArg = void;
export type AuthenticationProviderDto = {
  authority: string | null;
  audience: string | null;
  clientId: string | null;
  domain: string | null;
};
export const {
  useAppSettingsFetchAuthenticationProviderSettingsQuery,
  useLazyAppSettingsFetchAuthenticationProviderSettingsQuery,
} = injectedRtkApi;
