export type Navigation = {
  icon: string;
  localeId: string;
  path: string;
  text: string;
  allowedRoles?: string[];
  allowedPermission?: string;
};

export const NavigationList: Navigation[] = [
  {
    icon: "home",
    localeId: "navigation.home",
    text: "Home",
    path: "/",
  },
  {
    icon: "file",
    localeId: "navigation.policies",
    text: "Policies",
    path: "/policies",
    allowedPermission: "PolicyAccess"
  },
  {
    icon: "event",
    localeId: "navigation.inspection-schedule",
    text: "Inspection schedule",
    path: "/inspection-schedule",
    allowedRoles: ["Admin", "InspectionBureau"],
  },
  {
    icon: "todo",
    localeId: "navigation.inspections",
    text: "Inspections",
    path: "/inspections",
    allowedRoles: ["Admin", "Inspector"],
  },
  {
    icon: "chart",
    localeId: "navigation.dashboard",
    text: "Dashboard",
    path: "/dashboard",
    allowedRoles: ["Admin"],
  },
  {
    icon: "preferences",
    localeId: "navigation.admin",
    text: "Admin",
    path: "/admin",
    allowedRoles: ["Admin"],
  },
];
