/* eslint-disable react/forbid-elements */
import React from "react";

import {
	PolicyDto, usePoliciesGetCalculationResultsQuery, usePoliciesGetLatestPremiumQuery, usePoliciesHasLatestOutdatedPremiumQuery,
} from "../../apis/PoliciesApi";
import { useAppSelector } from "../../hooks/hooks";
import { selectIdentity } from "../../store/Identity";
import styles from "../../styles/Policy.module.scss";
import { canWritePremiumReviews, reviewIsReadonly } from "../../utils/PolicyUtil";
import { Translate } from "../../utils/Translation";
import PageTitle from "../titles/PageTitle";
import FishAndUboComponent from "./FishAndUboComponent";
import CalculationComponent from "./premium-calculation/CalculationComponent";
import CapacityFeedbackComponent from "./premium-calculation/CapacityFeedbackComponent";
import FailuresComponent from "./premium-calculation/FailuresComponent";
import PreRiskFeedbackComponent from "./premium-calculation/PreRiskFeedbackComponent";
import RejectionsComponent from "./premium-calculation/RejectionsComponent";
import ReviewRequestComponent from "./premium-calculation/ReviewRequestComponent";

type Props = {
	readonly policy: PolicyDto;
};

const FullCalculation = ({ policy }: Props): JSX.Element => {
	const { data, error } = usePoliciesGetCalculationResultsQuery({ policyGuid: policy.guid });
	const { data: outdated, error: outdatedError } = usePoliciesHasLatestOutdatedPremiumQuery({ policyGuid: policy.guid });
	const { permissions } = useAppSelector(selectIdentity);

	if (error)
		throw new Error(`Error getting calculation results: ${error}`);
	if (outdatedError)
		throw new Error(`Error getting has outdated premium: ${outdatedError}`);

	const {
		currentData: premium,
		error: errorPremium,
	} = usePoliciesGetLatestPremiumQuery({ policyGuid: policy.guid });
	if (errorPremium)
		throw new Error(`Error getting premium: ${error}`);

	if (!data || !premium || outdated === undefined)
		return <div />;

	if (!data.calculationVectors)
		throw new Error(`Error: No calculation vectors found`);

	return (
		<>
			<PageTitle text={Translate("premium-calculation.full-calculation")} />
			{data.feedback.failures && data.feedback.failures.length > 0 ? (
				<div className={styles["data"]}>
					<FailuresComponent failures={data.feedback.failures} />
				</div>) : null}
			{data.feedback.rejections && data.feedback.rejections.length > 0 ? (
				<div className={styles["data"]}>
					<RejectionsComponent rejections={data.feedback.rejections} />
				</div>) : null}
			<div className={styles["data"]}>
				<ReviewRequestComponent
					reviewRequestRows={data.feedback.reviewRequests ? data.feedback.reviewRequests : []}
					policyGuid={policy.guid}
					premiumStage={premium.stage}
					readonly={reviewIsReadonly(policy.status) || !canWritePremiumReviews(permissions)}
					premiumOutdated={outdated}
				/>
			</div>
			<FishAndUboComponent policy={policy} readonly />
			{data.calculationFactors ? (
				<div className={styles["data"]}>
					<CalculationComponent vectors={data.calculationVectors} factors={data.calculationFactors} />
				</div>) : null}
			{data.feedback.preRiskFeedback ? (
				<div className={styles["data"]}>
					<PreRiskFeedbackComponent feedback={data.feedback.preRiskFeedback} />
				</div>) : null}
			{data.feedback.capacityFeedback ? (
				<div className={styles["data"]}>
					<CapacityFeedbackComponent feedback={data.feedback.capacityFeedback} />
				</div>) : null}
		</>);
};

export default FullCalculation;
