import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import { LoadIndicator, LoadPanel, ValidationGroup, ValidationSummary } from "devextreme-react";
import validationEngine from "devextreme/ui/validation_engine";
import { useNavigate, useParams } from "react-router";

import { useDestinationsFetchDestinationQuery } from "../../../apis/DestinationsApi";
import {
  FieldType, PolicyStatus, PolicyType, usePoliciesFetchPreRiskAnswersQuery, usePoliciesFinishPreRiskQuestionnaireMutation,
  usePoliciesGetCurrentPolicyCoveragesChoicesQuery, usePoliciesGetCurrentPolicyFieldsValuesQuery, usePoliciesGetCurrentPolicyVersionQuery,
  usePoliciesGetPolicyQuery,
} from "../../../apis/PoliciesApi";
import { usePolicyTypeGetAllPolicyTypeFieldOptionsQuery } from "../../../apis/PolicyTypeApi";
import { usePreRiskQuestionsFetchPreRiskQuestionsQuery } from "../../../apis/PreRiskQuestionsApi";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import usePreRiskFieldRetrieval from "../../../hooks/usePreRiskFieldRetrieval";
import CEAFilters from "../../../models/policies/CEAFilters";
import { selectIdentity } from "../../../store/Identity";
import { selectPreRiskData, setData } from "../../../store/PreRiskState";
import styles from "../../../styles/PreRiskQuestionnaire.module.scss";
import { isPreRiskQuestionVisible } from "../../../utils/fieldsVisibility";
import { emptyGuid, Guid } from "../../../utils/Guid";
import { canWritePolicy } from "../../../utils/PolicyUtil";
import { PRE_RISK_VALIDATION_GROUP } from "../../../utils/PreRiskUtil";
import { Translate } from "../../../utils/Translation";
import Button from "../../buttons/Button";
import PageTitle from "../../titles/PageTitle";
import PreRiskCategoryComponent from "./PreRiskCategoryComponent";

const filterCeaCode = (ceaCode: string | null, filter: string | null): boolean => {
	const filters = new CEAFilters(filter);
	return filters.evaluateCode(ceaCode);
};

const PreRiskQuestionnaire = (): JSX.Element => {

	const params = useParams<{ guid: Guid }>();
	const [sendingQuestionnaire, setSendingQuestionnaire] = useState(false);
	const { data: preRiskState } = useAppSelector(selectPreRiskData);
	const dispatch = useAppDispatch();
	const { username, permissions } = useAppSelector(selectIdentity);

	if (!params.guid)
		throw new Error('No policy guid provided in parameters');

	const { data: policy, isLoading: isPolicyLoading, error: policyError } = usePoliciesGetPolicyQuery({ policyGuid: params.guid });
	const { data: policyVersion, isLoading: isPolicyVersionLoading, error: policyVersionError } =
		usePoliciesGetCurrentPolicyVersionQuery({ policyGuid: params.guid });
	const { data: policyFieldValues } = usePoliciesGetCurrentPolicyFieldsValuesQuery({ policyGuid: params.guid });
	const { data: destination } = useDestinationsFetchDestinationQuery({
		destinationGuid: policyVersion ? policyVersion.destinationGuid : emptyGuid()
	}, { skip: !policyVersion });
	const { data: coverage } = usePoliciesGetCurrentPolicyCoveragesChoicesQuery({ policyGuid: params.guid });

	const { data: policyFieldOptions } = usePolicyTypeGetAllPolicyTypeFieldOptionsQuery(
		{ type: policy ? policy.type : PolicyType.Fire },
		{ skip: !policy });
	const {
		isLoading: isLoadingQuestions,
		error: questionsError,
		data: questions,
	} = usePreRiskQuestionsFetchPreRiskQuestionsQuery();
	const { data: answers } = usePoliciesFetchPreRiskAnswersQuery({ policyGuid: params.guid });
	const fieldRetrieval = usePreRiskFieldRetrieval(answers);
	const [triggerFinishPreRiskQuestionnaire] = usePoliciesFinishPreRiskQuestionnaireMutation();
	const navigate = useNavigate();

	const userCanWritePolicy = policy ? canWritePolicy(username, permissions, policy) : false;

	useEffect(() => {

		if (policy && destination && questions && policyFieldValues && coverage && policyFieldOptions) {

			const ceaCode = destination.ceaCode;

			const sortedQuestions = [...questions.filter(x => filterCeaCode(ceaCode, x.ceaCodeFilter))]
				.sort((q1, q2) => q1.index - q2.index);

			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			const categories = Array.from(new Set(sortedQuestions.map((q) => q.category!)));

			dispatch(setData({
				policy,
				questions: sortedQuestions,
				categories,
				ceaCode,
				fieldValues: policyFieldValues,
				fieldOptions: policyFieldOptions,
				coverage
			}));
		}


	}, [destination, dispatch, policy, policyFieldValues, questions, coverage, policyFieldOptions]);

	const isLoading = isPolicyLoading || isLoadingQuestions || isPolicyVersionLoading;

	const missingDataMessage = useMemo(() => <div>{Translate("pre-risk-questionnaire.failure.message")}</div>, []);
	const notIntermediaryMessage = useMemo(() => <div>{Translate("pre-risk-questionnaire.intermediary-only")}</div>, []);
	const wrongStateMessage = useMemo(() => <div>{Translate("pre-risk-questionnaire.wrong-state")}</div>, []);

	const pageTitle = useMemo(() => {
		return preRiskState ? <PageTitle
			text={`${Translate("policy.form.policyNumber")}: ${preRiskState.policy.policyNumber
				}`}
		/> : null;
	}, [preRiskState]);

	const mainComponent = useMemo(() => {
		if (!preRiskState)
			return null;
		return (
			<div className={styles["page-content"]}>
				{preRiskState.categories.map((category: string): JSX.Element => {
					return <PreRiskCategoryComponent key={category} category={category} />;
				})}
			</div>
		);
	}, [preRiskState]);

	const isQuestionnaireComplete = useCallback((): boolean => {
		let foundNull = false;
		if (!questions || !answers || !fieldRetrieval)
			return false;

		if (validationEngine.getGroupConfig(PRE_RISK_VALIDATION_GROUP)) {
			const validationResult = validationEngine.validateGroup(PRE_RISK_VALIDATION_GROUP);
			if (!validationResult.isValid)
				return false;
		}

		for (const question of questions) {
			if (!isPreRiskQuestionVisible(question, fieldRetrieval, preRiskState, answers) || question.type === FieldType.Occupancy)
				continue;

			const answer = answers.find(x => x.questionName === question.name);

			if (answer === null || answer === undefined) {
				foundNull = true;
				break;
			}
		}

		return !foundNull;
	}, [questions, answers, fieldRetrieval, preRiskState]);

	const sendQuestionnaire = useCallback(async (): Promise<void> => {
		if (!params.guid)
			return;
		setSendingQuestionnaire(true);
		await triggerFinishPreRiskQuestionnaire({ policyGuid: params.guid });
		setSendingQuestionnaire(false);
		navigate(`/policy/${params.guid}`, { replace: true });
	}, [navigate, params.guid, triggerFinishPreRiskQuestionnaire]);

	const finalizeButton = useMemo(() => {
		return (
			<>
				<div className={styles["page-actions"]}>
					<Button
						text={Translate("pre-risk-questionnaire.complete.button")}
						isDisabled={!isQuestionnaireComplete()}
						onClick={sendQuestionnaire}
					/>
				</div>

				<LoadPanel
					visible={sendingQuestionnaire}
					position="center"
					hideOnOutsideClick={false}
					shadingColor="rgba(0,0,0,0.4)"
					message={Translate("pre-risk-questionnaire.sending-message")}
				/>
			</>);
	}, [isQuestionnaireComplete, sendQuestionnaire, sendingQuestionnaire]);

	const backButton = useMemo(() => {
		if (!policy)
			return null;
		return (
			<Button
				text={Translate("pre-risk-questionnaire.back.button")}
				autoWidth
				stylingMode="text"
				onClick={(): void => navigate(`/policy/${policy.guid}`)}
			/>
		);
	}, [navigate, policy]);

	if (policyError || questionsError || policyVersionError)
		return missingDataMessage;

	if (!userCanWritePolicy) {
		return (
			<div>
				{notIntermediaryMessage}
				{backButton}
			</div>
		);
	}

	if (policy && policy.status !== PolicyStatus.PreRiskRequired) {
		return (
			<div>
				{wrongStateMessage}
				{backButton}
			</div>
		);
	}

	if (isLoading || !preRiskState)
		return <LoadIndicator />;

	return (
		<div className={styles["page"]}>
			{pageTitle}
			{backButton}
			<ValidationGroup>
				{mainComponent}
				<ValidationSummary />
			</ValidationGroup>
			<ValidationSummary validationGroup={PRE_RISK_VALIDATION_GROUP} />
			{finalizeButton}
		</div>
	);
};

export default memo(PreRiskQuestionnaire);