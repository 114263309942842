import React from "react";

import styles from "../../styles/Header.module.scss";

const HeaderProductName = (): JSX.Element => {

	return (
		<div>
			<h4 className={`dx-theme-generic-typography ${styles.productName}`}>BeFurst - Online Insurance</h4>
			<p>Powered by Macomi</p>
		</div>
	);
};

export default HeaderProductName;
