import React from "react";

import GenericDataComponent from "../utility-components/GenericDataComponent";
import BaseComponent from "./BaseComponent";

type Props = {
	readonly onFormChanged: () => Promise<void>;
};

const PreventionInstallationsComponent = ({ onFormChanged }: Props): JSX.Element => {

	return (
		<BaseComponent id="prevention-installations" captionKey="policy.form.title.prevention-installations">
			<GenericDataComponent onFormChanged={onFormChanged} category="prevention-installations" />
		</BaseComponent>
	);
};

export default React.memo(PreventionInstallationsComponent);