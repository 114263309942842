import React from "react";

import styles from "../../styles/PolicyFormSubHeader.module.scss";

type Props = {
	readonly text: string;
};

const PolicyFormSubHeader = ({ text }: Props): JSX.Element => {
	return (
		<>
			<h4 className={styles["policy-form-sub-header"]}>{text}</h4>
			<hr className={styles["policy-form-sub-header-divider"]} />
		</>
	);
};

export default PolicyFormSubHeader;
