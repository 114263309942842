import React from "react";

import styles from "../../styles/PageTitle.module.scss";

type Props = {
  readonly text: string;
};

const PageTitle = ({ text }: Props): JSX.Element => {
  return <h1 className={styles["page-title"]}>{text}</h1>;
};

export default PageTitle;
