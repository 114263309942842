import { Translate } from "../utils/Translation";

// Source: https://stackoverflow.com/questions/49432350/how-to-represent-guid-in-typescript

export type Guid = string & { isGuid: true };

/** Specifies the RegExp necessary to validate **guid** values */
export const validator = new RegExp(
	'^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$',
	'i'
);

/** Generates a new **guid**, with the empty/least possible value
 * @returns {guid} 00000000-0000-0000-0000-000000000000
 */
export const emptyGuid = (): Guid =>
	'00000000-0000-0000-0000-000000000000' as Guid;

/** Generates a new **guid**, with the full/greatest possible value
 * @returns {Guid} ffffffff-ffff-ffff-ffffffffffff
 */
export const fullGuid = (): Guid =>
	'ffffffff-ffff-ffff-ffffffffffff' as Guid;

/** Evaluates whether the supplied **guid** is equal to the empty/least possible value */
export const isEmptyGuid = (value: Guid): boolean =>
	value === emptyGuid();

/** Evaluates whether the supplied *guid* is equal to the empty/greatest possible value */
export const isFullGuid = (value: Guid): boolean =>
	value === fullGuid();

/** Evaluates whether the supplied value is a valid **guid** */
export const isValidGuid = (value: string | Guid): boolean =>
	validator.test(value);

/** Validates a string is of type **guid** and returns the **guid** */
export function parseGuid(guid: string): Guid {
	if (guid === "")
		return emptyGuid();
	else if (isValidGuid(guid))
		return guid as Guid;
	else 
		throw new Error(Translate("exception.invalid.guid", guid));
}

/** Generates a specified number of double-byte segements for **guid** generation  */
function generateGuidSegment(count: number): string {
	let out = '';
	for (let i = 0; i < count; i++) {
		// eslint-disable-next-line no-bitwise
		out += (((1 + Math.random()) * 0x10000) | 0)
			.toString(16)
			.substring(1)
			.toLowerCase();
	}
	return out;
}

/** Generates a random, hyphenated **guid** value */
export function newGuid(): Guid {
	return parseGuid([
		generateGuidSegment(2),
		generateGuidSegment(1),
		generateGuidSegment(1),
		generateGuidSegment(1),
		generateGuidSegment(3),
	].join('-'));
}
