import React, { memo, useCallback } from "react";

import { DataGrid } from "devextreme-react";
import { Column } from "devextreme-react/data-grid";

import { CalculationRejectionDto, PremiumCalcRejectionType } from "../../../apis/PoliciesApi";
import { parseMessage } from "../../../utils/commonFunctions";
import { Translate } from "../../../utils/Translation";
import ComponentTitle from "../../titles/ComponentTitle";

type Props = {
	readonly rejections: CalculationRejectionDto[];
};

const RejectionsComponent = ({ rejections }: Props): JSX.Element => {

	const getMessage = useCallback((rejection: CalculationRejectionDto): string => {
		switch (rejection.type) {
			case PremiumCalcRejectionType.RiskClass:
				return Translate("premium-calculation.rejections.risk-class");
			case PremiumCalcRejectionType.InsufficientCapacity:
				return parseMessage("${", "}", Translate("premium-calculation.rejections.insufficient-capacity"),
					rejection.argument ? rejection.argument : "");
			case PremiumCalcRejectionType.ConstructionNature:
				return parseMessage("${", "}", Translate("premium-calculation.rejections.construction-nature"),
					rejection.argument ? rejection.argument : "");
			default:
				return rejection.message ? rejection.message : "Uknown";
		}
	}, []);

	return (
		<>
			<ComponentTitle text={Translate("premium-calculation.rejections")} id={"rejections"} />
			<DataGrid
				keyExpr="guid"
				dataSource={rejections}
				showBorders
				columnAutoWidth
				columnHidingEnabled
				sorting={undefined}
			>
				<Column
					dataField="type"
					caption={Translate("premium-calculation.rejections.rejection")}
					calculateDisplayValue={getMessage}
				/>
			</DataGrid>
		</>);
};

export default memo(RejectionsComponent);