/* eslint-disable complexity */
import React, { useCallback, useMemo } from "react";

import { LoadIndicator } from "devextreme-react";

import {
	PolicyDto, PolicyStatus, usePoliciesFlowFromQuoteRejectionMutation, usePoliciesGetLatestPremiumQuery,
	usePoliciesHasLatestOutdatedPremiumQuery,
} from "../../../apis/PoliciesApi";
import { PremiumStage, usePremiumGetResultsQuery } from "../../../apis/PremiumApi";
import { useAppSelector } from "../../../hooks/hooks";
import { selectIdentity } from "../../../store/Identity";
import { selectPolicyPage } from "../../../store/PolicyPageState";
import { selectStipulations } from "../../../store/StipulationsSlice";
import { emptyGuid } from "../../../utils/Guid";
import {
	canViewExtraStipulationColumns, canWritePolicy, canWriteRejectedQuote, canWriteStipulations, getPremiumCaptionKey,
} from "../../../utils/PolicyUtil";
import { Translate } from "../../../utils/Translation";
import ComponentSubTitle from "../../titles/ComponentSubTitle";
import ComponentTitle from "../../titles/ComponentTitle";
import PremiumDialogButtons from "./PremiumDialogButtons";
import PremiumResultWidget from "./PremiumResultWidget";
import PremiumStipulationsComponent from "./PremiumStipulationsComponent";
import RecalculateRejectedQuoteComponent from "./RecalculateRejectedQuoteComponent";
import RequestQuoteWidget from "./RequestQuoteWidget";
import RespondToQuoteWidget from "./RespondToQuoteWidget";
import Warning from "./Warning";

type PremiumWidgetProps = {
	readonly policy: PolicyDto;
};

const PremiumWidget = ({ policy }: PremiumWidgetProps): JSX.Element => {

	const { username, permissions } = useAppSelector(selectIdentity);
	const { data } = useAppSelector(selectPolicyPage);
	const { acceptantCanEditStipulationsFromInspection } = useAppSelector(selectStipulations);

	if (!data)
		throw new Error('No policy page data available');

	const userCanWritePolicy = canWritePolicy(username, permissions, policy);

	const {
		data: premium,
		isFetching: isFetchingPremium,
		error: errorPremium,
	} = usePoliciesGetLatestPremiumQuery({ policyGuid: policy.guid });

	const {
		data: results,
		isFetching: isFetchingPremiumResults,
		error: errorPremiumResults
	} = usePremiumGetResultsQuery({
		premiumGuid: premium ? premium.guid : emptyGuid()
	}, { skip: !premium });

	const { data: outdated, error: outdatedError } = usePoliciesHasLatestOutdatedPremiumQuery({ policyGuid: policy.guid });

	const [flowFromQuoteRejection] = usePoliciesFlowFromQuoteRejectionMutation();

	const allowStipulationReview = useMemo(() => canWriteStipulations(permissions) &&
		(policy.status === PolicyStatus.ReviewQuote || acceptantCanEditStipulationsFromInspection),
		[acceptantCanEditStipulationsFromInspection, policy.status, permissions]);

	if (errorPremium)
		throw new Error(`Error fetching premium: ${errorPremium}`);
	if (errorPremiumResults)
		throw new Error(`Error fetching premium results: ${errorPremiumResults}`);
	if (outdatedError)
		throw new Error(`Error getting has outdated premium: ${outdatedError}`);
	let key = 1;

	const getStateComponent = useCallback((status: PolicyStatus, stage: PremiumStage) => {
		switch (status) {
			case PolicyStatus.Rejected:
				return stage === PremiumStage.Indication
					? <ComponentSubTitle text={Translate("premium.indication.status.rejected")} />
					: <ComponentSubTitle text={Translate("premium.quote.status.rejected")} />;
			case PolicyStatus.PremiumIndicationRequested:
				return <ComponentSubTitle text={Translate("premium.indication.status.requested")} />;
			case PolicyStatus.PremiumIndicationProvided:
			case PolicyStatus.QuoteRequested:
			case PolicyStatus.FullInspectionRequired:
			case PolicyStatus.QuickScanInspectionRequired:
			case PolicyStatus.PreRiskRequired:
				return <ComponentSubTitle text={Translate("premium.indication.status.success")} />;
			case PolicyStatus.ReviewPremiumIndicationRequest:
				return <ComponentSubTitle text={Translate("premium.indication.status.review")} />;
			case PolicyStatus.QuoteProvided:
				return <ComponentSubTitle text={Translate("premium.quote.status.success")} />;
			case PolicyStatus.ReviewQuote:
				return <ComponentSubTitle text={Translate("premium.quote.status.review")} />;
			case PolicyStatus.QuoteAccepted:
				return <ComponentSubTitle text={Translate("premium.quote.status.accepted")} />;
			case PolicyStatus.QuoteRejected:
				// Different from normal rejected in that the requester(e.g. intermediary) has rejected
				return <ComponentSubTitle text={Translate("premium.quote.status.quote.rejected")} />;
			default:
				return null;
		}
	}, []);

	const handleOfferQuoteAgain = useCallback(async (newStatus: PolicyStatus): Promise<void> => {
		await flowFromQuoteRejection({
			policyGuid: data.policy.guid,
			newStatus
		});
	}, [flowFromQuoteRejection, data.policy.guid]);

	if (isFetchingPremium || isFetchingPremiumResults || !premium || outdated === undefined)
		return <LoadIndicator />;

	return (
		<>
			<ComponentTitle
				text={Translate(getPremiumCaptionKey(policy.status, premium.stage))}
				id={"premium"}
			/>
			{getStateComponent(policy.status, premium.stage)}
			{(results && results.warnings)
				?
				<div>
					{results.warnings.map(warning => {
						return (<Warning key={`${warning.type}-${key++}`} warning={warning} />);
					})
					}
				</div>
				: null}
			{canWriteRejectedQuote(permissions) && policy.status === PolicyStatus.QuoteRejected
				? <RecalculateRejectedQuoteComponent outdated={outdated} />
				: null}
			{(results && results.results)
				?
				<div>
					{results.results.map(res => {
						return (<PremiumResultWidget key={res.guid} result={res} />);
					})}
				</div>
				: null}
			{canWriteRejectedQuote(permissions) && policy.status === PolicyStatus.QuoteRejected
				? <PremiumDialogButtons
					acceptAction={(): Promise<void> => handleOfferQuoteAgain(PolicyStatus.QuoteProvided)}
					rejectAction={(): Promise<void> => handleOfferQuoteAgain(PolicyStatus.Rejected)}
					premiumStage="quote-reoffer"
					customLocalizeButton
					acceptEnabled={!outdated}
				/> : null}
			{(results && results.stipulations) ?
				<PremiumStipulationsComponent
					stipulations={results.stipulations}
					policy={policy}
					showResponseColumn={canViewExtraStipulationColumns(policy.status)}
					showIrrelevantColumn={canViewExtraStipulationColumns(policy.status) && canWriteStipulations(permissions)}
					showFinishStipulationReview={policy.status === PolicyStatus.ReviewStipulations && userCanWritePolicy}
					allowEditResponseColumn={policy.status === PolicyStatus.ReviewStipulations && userCanWritePolicy}
					allowQuoteReviewEdit={allowStipulationReview}
					allowQuoteReviewEditFromInspection={acceptantCanEditStipulationsFromInspection}
					premiumOutdated={outdated}
					premiumGuid={premium.guid}
					freeFieldStipulations={results.freeFieldStipulations}
				/>
				: null}
			{(userCanWritePolicy && policy.status === PolicyStatus.PremiumIndicationProvided && !outdated)
				? <RequestQuoteWidget policy={policy} premium={premium} premiumOutdated={outdated} />
				: null}
			{(userCanWritePolicy && policy.status === PolicyStatus.QuoteProvided)
				? <RespondToQuoteWidget policyGuid={policy.guid} />
				: null}

		</>);
};

export default PremiumWidget;