/* eslint-disable react/forbid-elements */
import React, { memo, useMemo } from "react";

import { DataGrid } from "devextreme-react";
import { Column } from "devextreme-react/data-grid";

import { PremiumCalcCapacityFeedbackDto } from "../../../apis/PoliciesApi";
import { euroFormat, formatEuros, formatPercentage, percentageFormat } from "../../../utils/formatUtils";
import { Translate } from "../../../utils/Translation";
import ComponentTitle from "../../titles/ComponentTitle";
import CapacityFeedbackItem from "./CapacityFeedbackItem";

type Props = {
	readonly feedback: PremiumCalcCapacityFeedbackDto;
};

type CapacityTableRow = {
	company: string | null,
	capacityPercentage: number,
	capacityEuro: number
};

const compareCapacity = (a: CapacityTableRow, b: CapacityTableRow): number => {
	if (a.capacityEuro === b.capacityEuro) {
		const partA = a.company ? a.company : "";
		const partB = b.company ? b.company : "";
		return partA.localeCompare(partB);
	}
	return b.capacityEuro - a.capacityEuro;
};

const CapacityFeedbackComponent = ({ feedback }: Props): JSX.Element => {

	const tableItems = useMemo(() => {
		const items: CapacityTableRow[] = [];
		if (feedback.items) {
			feedback.items.forEach(x => {
				items.push({
					company: x.participant,
					capacityPercentage: x.capacity / feedback.totalCapacity,
					capacityEuro: x.capacity
				});
			});
			items.sort(compareCapacity);
		}
		return items;
	}, [feedback]);

	return (
		<>
			<ComponentTitle text={Translate("premium-calculation.capacity-feedback")} id={"capacity"} />
			<div>
				<CapacityFeedbackItem
					itemKey={"premium-calculation.class"}
					itemValue={feedback.class.toString()}
				/>
				<CapacityFeedbackItem
					itemKey={"premium-calculation.total-capacity"}
					itemValue={formatEuros(feedback.totalCapacity)}
				/>
				<CapacityFeedbackItem
					itemKey={"premium-calculation.required-capacity"}
					itemValue={formatEuros(feedback.requiredCapacity)}
				/>
				<CapacityFeedbackItem
					itemKey={"premium-calculation.total-exposure"}
					itemValue={formatEuros(feedback.totalExposure)}
				/>
				<CapacityFeedbackItem
					itemKey={"premium-calculation.mpl"}
					itemValue={formatPercentage(feedback.mpl)}
				/>
			</div>

			<DataGrid
				keyExpr="company"
				dataSource={tableItems}
				showBorders
				columnAutoWidth
				columnHidingEnabled
				sorting={undefined}
				width={800}
			>
				<Column
					dataField="company"
					caption={Translate("premium-calculation.company")}
				/>
				<Column
					dataField="capacityPercentage"
					caption={Translate("premium-calculation.capacity-percentage")}
					format={percentageFormat}
				/>
				<Column
					dataField="capacityEuro"
					caption={Translate("premium-calculation.capacity-euro")}
					format={euroFormat}
				/>
			</DataGrid>
		</>);
};

export default memo(CapacityFeedbackComponent);