import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "./store";

export type FocusedFields = {
    policyFocusedField: string | null;
};

const initialState: FocusedFields = {
    policyFocusedField: null
};

export const focusedFieldsSlice = createSlice({
    name: 'focusedFields',
    initialState,
    reducers: {
        setFocusedField: (state, action: PayloadAction<string>) => {
            state.policyFocusedField = action.payload;
        },
    }
});

export const { setFocusedField } = focusedFieldsSlice.actions;
export const focusedFieldsReducer = focusedFieldsSlice.reducer;

export const selectFocusedFields = (state: RootState): FocusedFields => state.focusedFields;

export default focusedFieldsReducer;
