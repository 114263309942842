import React from "react";

import { useLocation } from "react-router";

type Props = {
	children: JSX.Element;
};

const ScrollRestoration = ({ children }: Props): JSX.Element => {
	const location = useLocation();

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, [location.pathname]);

	return children;
};

export default ScrollRestoration;
