import "devextreme-react/text-area";

import React, { useCallback } from "react";

import { UpdatePolicyVersionDestinationDto, usePoliciesUpdatePolicyDestinationMutation } from "../../../apis/PoliciesApi";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { selectIdentity } from "../../../store/Identity";
import { selectPolicyPage, setPolicyOccupancy } from "../../../store/PolicyPageState";
import { isPolicyComponentReadOnly } from "../../../utils/PolicyUtil";
import OccupancyComponent, {
	ChangeOccupancyDescription, ChangeOccupancyGuid, ChangeOccupancyRemarks, OccupancyChange, OccupancyState,
} from "../utility-components/OccupancyComponent";
import BaseComponent from "./BaseComponent";

type Props = {
	readonly onFormChanged: () => Promise<void>;
};

const RiskDestinationComponent = ({ onFormChanged }: Props): JSX.Element => {

	const dispatch = useAppDispatch();
	const { data } = useAppSelector(selectPolicyPage);
	const { username, permissions } = useAppSelector(selectIdentity);
	const [triggerUpdatePolicyOccupancy] = usePoliciesUpdatePolicyDestinationMutation();

	if (!data)
		throw new Error("No policy page data set");

	const readOnly = isPolicyComponentReadOnly(data, username, permissions);

	const fetchOccupancy = useCallback((): OccupancyState => {
		return {
			occupancyGuid: data.policyVersion.destinationGuid,
			description: data.policyVersion.destinationDescription,
			remarks: data.policyVersion.destinationRemark,
		};
	}, [data]);

	const updateOccupancy = useCallback(async (change: OccupancyChange): Promise<boolean> => {
		const updatePolicyVersionDestinationDto: UpdatePolicyVersionDestinationDto = {
			destinationGuid: change.change === "occupancy"
				? (change as ChangeOccupancyGuid).newOccupancyGuid
				: data.policyVersion.destinationGuid,
			destinationDescription: change.change === "description"
				? (change as ChangeOccupancyDescription).newDescription
				: data.policyVersion.destinationDescription,
			destinationRemark: change.change === "remarks"
				? (change as ChangeOccupancyRemarks).newRemarks
				: data.policyVersion.destinationRemark,
		};
		const updatedPolicyVersion = await triggerUpdatePolicyOccupancy({
			policyGuid: data.policy.guid,
			updatePolicyVersionDestinationDto
		}).unwrap();

		dispatch(setPolicyOccupancy({
			destinationId: updatedPolicyVersion.destinationGuid,
			destinationDescription: updatedPolicyVersion.destinationDescription ? updatedPolicyVersion.destinationDescription : undefined,
			destinationRemark: updatedPolicyVersion.destinationRemark ? updatedPolicyVersion.destinationRemark : undefined,
		}));

		return true;
	}, [data, dispatch, triggerUpdatePolicyOccupancy]);

	return (
		<BaseComponent captionKey="policy.form.title.risk-destination" id="risk-destination">
			<OccupancyComponent
				fetchOccupancy={fetchOccupancy}
				updateOccupancy={updateOccupancy}
				onFormChanged={onFormChanged}
				readOnly={readOnly}
				mode="default"
			/>
		</BaseComponent>
	);

};

export default React.memo(RiskDestinationComponent);
