import { useCallback } from "react";

import { PreRiskAnswerDto, usePoliciesCreatePreRiskAnswerMutation, usePoliciesUpdatePreRiskAnswerMutation } from "../apis/PoliciesApi";
import { selectPreRiskData } from "../store/PreRiskState";
import { useAppSelector } from "./hooks";

export const useCreateOrUpdatePreRiskAnswer = (
	answersByQuestionName: Map<string, PreRiskAnswerDto>,
	setAnswersByQuestionName: React.Dispatch<React.SetStateAction<Map<string, PreRiskAnswerDto>>>
): (question: string, value: any) => Promise<void> => {

	const { data } = useAppSelector(selectPreRiskData);
	const [triggerCreateAnswer] = usePoliciesCreatePreRiskAnswerMutation();
	const [triggerUpdateAnswer] = usePoliciesUpdatePreRiskAnswerMutation();

	const policyGuid = data ? data.policy.guid : null;

	return useCallback(async (question: string, value: any): Promise<void> => {
		if (!policyGuid)
			return;

		const answer = answersByQuestionName.get(question);
		if (answer) {
			await triggerUpdateAnswer({
				policyGuid, answerGuid: answer.guid, updatePreRiskAnswerDto: {
					value,
				}
			}).unwrap();
		} else {
			const newAnswer = await triggerCreateAnswer({
				policyGuid, createPreRiskAnswerDto: {
					questionName: question,
					value,
				}
			}).unwrap();
			setAnswersByQuestionName((prev: Map<string, PreRiskAnswerDto>): Map<string, PreRiskAnswerDto> => {
				prev.set(question, newAnswer);
				return prev;
			});
		}

	}, [answersByQuestionName, policyGuid, setAnswersByQuestionName, triggerCreateAnswer, triggerUpdateAnswer]);
};
