import CEAFilter from "./CEAFilter";
import { FieldValueRetrieval } from "./FieldValueRetrieval";

class CEAFilters {
	filters: CEAFilter[];
	type: "normal" | "negation";

	constructor(filter: string | null) {
		if (filter === null) {
			this.filters = [];
			this.type = "normal";
			return;
		}
		if (!filter.startsWith("!")) {
			this.type = "normal";
			this.filters = filter.split(",").map((f) => new CEAFilter(f, this.type));
		} else {
			this.type = "negation";
			this.filters = filter
				.replace("!", "")
				.replace("(", "")
				.replace(")", "")
				.split(",")
				.map((f) => new CEAFilter(f, this.type));
		}
	}

	evaluate(retrieval: FieldValueRetrieval): boolean {
		if (this.type === "normal") {
			let result = false;

			for (const filter of this.filters) {
				if (filter.evaluate(retrieval)) {
					result = true;
					break;
				}
			}

			return result;
		} else {
			let result = true;

			for (const filter of this.filters) {
				if (!filter.evaluate(retrieval)) {
					result = false;
					break;
				}
			}

			return result;
		}
	}

	evaluateCode(ceaCode: string | null): boolean {

		if (this.type === "normal") {
			let result = false;

			for (const filter of this.filters) {
				if (filter.evaluateCode(ceaCode)) {
					result = true;
					break;
				}
			}

			return result;
		} else {
			let result = true;

			for (const filter of this.filters) {
				if (!filter.evaluateCode(ceaCode)) {
					result = false;
					break;
				}
			}

			return result;
		}
	}
}

export default CEAFilters;
