/* eslint-disable max-lines */
import React, { memo, useCallback, useMemo } from "react";

import { DataGrid, LoadIndicator } from "devextreme-react";
import {
  AsyncRule, Column, Editing, FormItem, Item, Lookup, MasterDetail, RangeRule, RequiredRule, Sorting, Toolbar,
} from "devextreme-react/data-grid";
import Form, { SimpleItem } from "devextreme-react/form";

import {
  RiskType, SupplierDto, SupplierValidationWarning, useLazyPoliciesValidateCurrentPolicySuppliersQuery, usePoliciesCreateSupplierMutation,
  usePoliciesDeleteSupplierMutation, usePoliciesGetCurrentPolicySuppliersQuery, usePoliciesUpdateSupplierMutation,
} from "../../../apis/PoliciesApi";
import { useAppSelector } from "../../../hooks/hooks";
import { selectPolicyPage } from "../../../store/PolicyPageState";
import { ErrorBoundary } from "../../../utils/error-boundary";
import { Guid } from "../../../utils/Guid";
import { POLICY_VALIDATION_GROUP } from "../../../utils/PolicyUtil";
import { Translate } from "../../../utils/Translation";
import { disableAutocompleteOnForm } from "../../../utils/utils";
import SupplierDetailComponent from "./SupplierDetailComponent";

type RiskTypeWithTranslation = {
	readonly value: RiskType;
	readonly name: string;
};

const nullToZero = (value: number | undefined): number => value ? value : 0;

type Props = {
	readonly onFormChanged: () => Promise<void>;
};

const SuppliersTableComponent = ({ onFormChanged }: Props): JSX.Element => {

	const { data } = useAppSelector(selectPolicyPage);

	if (!data)
		throw new Error("Policy page data unavailable");

	const { data: supplierData, isLoading, error, refetch } =
		usePoliciesGetCurrentPolicySuppliersQuery({ policyGuid: data.policy.guid });
	const [triggerCreateSupplier] = usePoliciesCreateSupplierMutation();
	const [triggerUpdateSupplier] = usePoliciesUpdateSupplierMutation();
	const [triggerDeleteSupplier] = usePoliciesDeleteSupplierMutation();
	const [triggerSupplierValidation] = useLazyPoliciesValidateCurrentPolicySuppliersQuery();

	const suppliers: SupplierDto[] = useMemo(() => supplierData ? supplierData.map(e => ({ ...e })) : [], [supplierData]);

	if (error)
		throw new Error(`Error retrieving supplier data: ${error}`);

	// Labels
	const addRowButtonLabel = useMemo(() => ({ text: Translate("policy.suppliers-table.add") }), []);
	const companyNameCaption = useMemo(() => Translate("policy.suppliers-table.companyName"), []);
	const companyNameRequiredRule = useMemo(() => Translate("policy.suppliers-table.companyName.required"), []);
	const dependencyRatioLabel = useMemo(() => Translate("policy.suppliers-table.dependencyRatio"), []);
	const dependencyRatioRequiredRule = useMemo(() => Translate("policy.suppliers-table.dependencyRatio.required"), []);
	const dependencyRatioRangeRule = useMemo(() => Translate("policy.suppliers-table.dependencyRatio.invalid"), []);
	const typeLabel = useMemo(() => Translate("policy.suppliers-table.type"), []);
	const typeRequiredRule = useMemo(() => Translate("policy.suppliers-table.type-required"), []);

	const riskTypes: RiskTypeWithTranslation[] = useMemo(() => ([
		{
			value: RiskType.Supplier,
			name: Translate("policy.suppliers-table.supplier"),
		},
		{
			value: RiskType.Customer,
			name: Translate("policy.suppliers-table.customer"),
		},
	]), []);

	const addSupplier = useCallback(async ({ data: rowData, key }: { data: SupplierDto, key: Guid }): Promise<void> => {
		await triggerCreateSupplier({
			policyGuid: data.policy.guid,
			createSupplierDto: {
				guid: key,
				companyName: rowData.companyName,
				dependencyRatio: rowData.dependencyRatio,
				type: rowData.type,
			},
		});
		refetch();
	}, [data.policy.guid, refetch, triggerCreateSupplier]);

	const updateSupplier = useCallback(async ({ data: rowData }: { data: SupplierDto }): Promise<void> => {
		await triggerUpdateSupplier({
			policyGuid: data.policy.guid,
			supplierGuid: rowData.guid,
			updateSupplierDto: {
				companyName: rowData.companyName,
				dependencyRatio: rowData.dependencyRatio,
				destinationGuid: rowData.destinationGuid,
				type: rowData.type,
			},
		});
		refetch();
	}, [data.policy.guid, refetch, triggerUpdateSupplier]);

	const deleteSupplier = useCallback(async ({ data: rowData }: { data: SupplierDto }): Promise<void> => {
		await triggerDeleteSupplier({
			policyGuid: data.policy.guid,
			supplierGuid: rowData.guid,
		});
		refetch();
	}, [data.policy.guid, refetch, triggerDeleteSupplier]);

	const onRowValidating = useCallback((e: { isValid: boolean, newData: any, oldData: SupplierDto, errorText?: string }) => {
		// show error when required fields are empty
		const allFieldsRequiredError = Translate("policy.suppliers-table.required-fields");

		const errorOnAddingNewRow = e.oldData === undefined && (e.newData.type === undefined
			|| e.newData.companyName === undefined
			|| e.newData.dependencyRatio === undefined);

		const errorOnEditingCompanyName = e.oldData !== undefined
			&& e.newData.companyName !== undefined
			&& e.newData.companyName === "";

		const errorOnEditingDependencyRatio = e.oldData !== undefined
			&& e.newData.dependencyRatio !== undefined
			&& e.newData.dependencyRatio === null;

		if (errorOnAddingNewRow || errorOnEditingCompanyName || errorOnEditingDependencyRatio) {
			e.isValid = false;
			e.errorText = allFieldsRequiredError;
			return;
		}

		// get data from newData if they were changed
		// if we are adding data, oldData is undefined
		const hasOldData = e.oldData !== undefined;
		const hasNewData = e.newData !== undefined;
		const newType = hasOldData
			? e.oldData.type !== undefined && hasNewData && e.newData.type !== undefined && e.oldData.type !== e.newData.type
			: true;
		const newDependencyRatio = hasOldData
			? e.oldData.dependencyRatio !== undefined && hasNewData && e.newData.dependencyRatio !== undefined
			&& e.oldData.dependencyRatio !== e.newData.dependencyRatio
			: true;

		const currentType = newType ? e.newData.type : e.oldData.type;
		const currentId = hasOldData ? e.oldData.guid : null;
		const currentDependencyRatio = newDependencyRatio ? nullToZero(e.newData.dependencyRatio) : nullToZero(e.oldData.dependencyRatio);

		const otherRowsSameType = currentId ?
			suppliers.filter(x => x.type === currentType && x.guid !== currentId) :
			suppliers.filter(x => x.type === currentType);

		const type = currentType === 0 ? "policy.suppliers-table.suppliers" : "policy.suppliers-table.customers";
		const typeTranslated = Translate(type);
		e.errorText = Translate("policy.suppliers-table.exceed-ratio", typeTranslated);

		if (otherRowsSameType.length === 0) {
			e.isValid = currentDependencyRatio <= 100;
			return;
		}

		const dependencyOthers = otherRowsSameType.map(x => x.dependencyRatio);
		const totalDependencyOthers = dependencyOthers.reduce((prev, next) => nullToZero(prev) + nullToZero(next));
		e.isValid = nullToZero(totalDependencyOthers) + nullToZero(currentDependencyRatio) <= 100;
	}, [suppliers]);

	const getRiskTypeSortValue = useCallback((r: SupplierDto) => {
		const riskType = riskTypes.find(x => x.value === r.type);
		return riskType ? riskType.name : "";
	}, [riskTypes]);

	const renderSupplierDetail = useCallback((event: { data: SupplierDto }): JSX.Element => {
		return (
			<SupplierDetailComponent policyVersion={data.policyVersion} supplier={event.data} onFormChanged={onFormChanged} />
		);
	}, [data.policyVersion, onFormChanged]);

	const customValidationStep = useCallback(async (): Promise<any> => {

		const validation = await triggerSupplierValidation({ policyGuid: data.policy.guid }).unwrap();

		if (validation.isValid || !validation.warnings || validation.warnings.length === 0)
			return true;

		if (validation.warnings.includes(SupplierValidationWarning.MissingOrInvalidAddress))
			return Promise.reject(new Error(Translate("policy.suppliers-table.details.address.required")));
		if (validation.warnings.includes(SupplierValidationWarning.MissingOccupancy))
			return Promise.reject(new Error(Translate("policy.suppliers-table.details.occupancies.required")));
		return Promise.reject(new Error("Unknown supplier validation error"));
	}, [data.policy.guid, triggerSupplierValidation]);

	const customValidationItem = useMemo(() => {
		return (
			<SimpleItem
				label={{ text: " ", showColon: false }}
				dataField={"suppliers"}
				editorType="dxTextArea"
				editorOptions={{ width: '0%', height: '0%' }}
			>
				<AsyncRule
					validationCallback={customValidationStep}
					reevaluate
					type="async"
				/>
			</SimpleItem>
		);
	}, [customValidationStep]);

	if (isLoading || !suppliers)
		return <LoadIndicator />;

	return (
		<>
			<DataGrid
				dataSource={suppliers}
				showBorders
				onRowInserted={addSupplier}
				onRowUpdated={updateSupplier}
				onRowRemoved={deleteSupplier}
				onRowValidating={onRowValidating}
				keyExpr="guid"
			>
				<Editing mode="form" allowAdding allowUpdating allowDeleting useIcons />
				<Sorting mode="multiple" />

				<Toolbar>
					<Item
						name="addRowButton"
						showText="always"
						options={addRowButtonLabel}
						cssClass="data-grid-add-row-button"
					/>
				</Toolbar>

				<Column
					dataField="companyName"
					caption={companyNameCaption}
					alignment="left"
				>
					<FormItem editorType="dxTextBox">
						<RequiredRule
							message={companyNameRequiredRule}
						/>
					</FormItem>
				</Column>
				<Column
					dataField="dependencyRatio"
					caption={dependencyRatioLabel}
					alignment="left"
					sortOrder="desc"
					sortIndex={2}
				>
					<FormItem editorType="dxNumberBox">
						<RequiredRule
							message={dependencyRatioRequiredRule}
						/>
						<RangeRule
							min={0}
							max={100}
							message={dependencyRatioRangeRule}
						/>
					</FormItem>
				</Column>
				<Column
					dataField="type"
					caption={typeLabel}
					sortOrder="asc"
					calculateSortValue={getRiskTypeSortValue}
					sortIndex={1}
				>
					<Lookup dataSource={riskTypes} valueExpr="value" displayExpr="name" />
					<RequiredRule
						message={typeRequiredRule}
					/>
				</Column>
				<MasterDetail enabled render={renderSupplierDetail} />
			</DataGrid>
			<ErrorBoundary>
				<Form
					validationGroup={POLICY_VALIDATION_GROUP}
					formData={{ suppliers }}
					onContentReady={disableAutocompleteOnForm}
				>
					{customValidationItem}
				</Form>
			</ErrorBoundary>
		</>
	);

};

export default memo(SuppliersTableComponent);