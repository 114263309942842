import React, { useCallback, useMemo } from "react";

import { Form, Toast } from "devextreme-react";
import { FieldDataChangedEvent } from "devextreme/ui/form";

import { UpdatePolicyDto } from "../../../apis/PoliciesApi";
import { useAppSelector } from "../../../hooks/hooks";
import { selectIdentity } from "../../../store/Identity";
import { selectPolicyPage } from "../../../store/PolicyPageState";
import { isPolicyComponentReadOnly, POLICY_VALIDATION_GROUP } from "../../../utils/PolicyUtil";
import { Translate } from "../../../utils/Translation";
import { disableAutocompleteOnForm } from "../../../utils/utils";
import FieldInfo from "../utility-components/FieldInfo";
import FieldItem from "../utility-components/FieldItem";
import BaseComponent from "./BaseComponent";

type Props = {
	readonly updatePolicyMainData: (mainData: UpdatePolicyDto) => Promise<boolean>;
	readonly onFormChanged: () => Promise<void>;
};

const EMAIL = "email";
const NAME_INSURED = "nameInsured";

const fields: FieldInfo[] = [
	{ name: "kvk", readOnly: true, isRequired: true },
	{ name: "sbi", readOnly: true, isRequired: true },
	{ name: "legalStructure", readOnly: true },
	{ name: "policyHolder", readOnly: true },
	{ name: EMAIL, isRequired: true, isEmail: true },
	{ name: NAME_INSURED, isRequired: true }
];

type Data = {
	kvk: string | null;
	sbi: string | null;
	legalStructure: string | null;
	policyHolder: string | null;
	[EMAIL]: string | null;
	[NAME_INSURED]: string | null;
};

const MainDataComponent = ({ updatePolicyMainData: setMainData, onFormChanged }: Props): JSX.Element => {

	const { data: policyData } = useAppSelector(selectPolicyPage);
	const { username, permissions } = useAppSelector(selectIdentity);
	const [data, setData] = React.useState<Data | null>(null);
	const [savingFailed, setSavingFailed] = React.useState(false);

	if (!policyData)
		throw new Error("No policy page data available");

	const readOnly = isPolicyComponentReadOnly(policyData, username, permissions);

	React.useEffect(() => {
		if (data)
			return;
		setData({
			kvk: policyData.relation.kvkNumber,
			sbi: policyData.relation.sbiActivityCodes,
			legalStructure: policyData.relation.legalStructure,
			policyHolder: policyData.relation.companyName,
			[EMAIL]: policyData.policy.email,
			[NAME_INSURED]: policyData.policy.nameInsured,
		});
	}, [data, setData, policyData]);

	const setFieldValue = useCallback(async (e: FieldDataChangedEvent): Promise<void> => {
		if (!e.dataField)
			return;
		let success = true;
		switch (e.dataField) {
			case EMAIL: {
				success = await setMainData({
					...policyData.policy,
					email: e.value as string
				});
				break;
			}
			case NAME_INSURED: {
				success = await setMainData({
					...policyData.policy,
					nameInsured: e.value as string
				});
				break;
			}
		}
		if (success)
			return;
		setSavingFailed(true);
		onFormChanged();
	}, [setMainData, onFormChanged, policyData.policy]);

	const form = useMemo(() => {
		return (
			<Form
				colCount={1}
				id="main-data-form"
				formData={data}
				readOnly={readOnly}
				onFieldDataChanged={setFieldValue}
				validationGroup={POLICY_VALIDATION_GROUP}
				onOptionChanged={onFormChanged}
				onContentReady={disableAutocompleteOnForm}
			>
				{fields.map((field) => new FieldItem("main-data-form", field).getElement())}
			</Form>
		);
	}, [data, readOnly, onFormChanged, setFieldValue]);

	return (
		<BaseComponent id="main-data" captionKey="policy.form.title.main-data">
			<Toast
				visible={savingFailed}
				message={Translate("policy.form.failure.saving")}
				type="error"
				onHiding={(): void => setSavingFailed(false)}
				closeOnClick
				displayTime={10000}
			/>
			{form}
		</BaseComponent>
	);
};

export default React.memo(MainDataComponent);