import React, { useState } from "react";

import SelectBox from "devextreme-react/select-box";
import { NativeEventInfo } from "devextreme/events";
import { ValueChangedInfo } from "devextreme/ui/editor/editor";
import { SelectBoxInstance } from "devextreme/ui/select_box";

import { ChangeLanguage, locales, Translate } from "../../utils/Translation";

const selectBoxInputAttr = { id: "selectInput" };

type Props = {
  readonly locale: string;
};

const Settings = ({ locale }: Props): JSX.Element => {
  const [currentLanguage, setCurrentLanguage] = useState(locale);

  function translateLanguageNames(): void {
    for (const i in locales) {
      const code = locales[i].NameCode;
      locales[i].Name = Translate(code);
    }
  }

  translateLanguageNames();

  // https://js.devexpress.com/Demos/WidgetsGallery/Demo/Localization/UsingIntl/React/Light/
  const changeLocale = (
    e: NativeEventInfo<SelectBoxInstance, KeyboardEvent | Event | MouseEvent | PointerEvent> & ValueChangedInfo
  ): void => {
    setCurrentLanguage(e.value);
    ChangeLanguage(e.value);
  };

  return (
  <>
  <label htmlFor="selectInput">{Translate("changelanguage.title")}</label>
    &nbsp;
  <SelectBox
    items={locales}
    valueExpr="Value"
    displayExpr="Name"
    value={currentLanguage}
    onValueChanged={changeLocale}
    inputAttr={selectBoxInputAttr}
  />
  </>
  );
};

export default Settings;
