/* eslint-disable react/forbid-elements */
import React, { useCallback, useMemo } from "react";

import { DataGrid } from "devextreme-react";
import { Column } from "devextreme-react/data-grid";

import { PremiumResultComponentDto, PremiumResultDto } from "../../../apis/PremiumApi";
import { useAppSelector } from "../../../hooks/hooks";
import { selectIdentity } from "../../../store/Identity";
import styles from "../../../styles/Calculation.module.scss";
import { euroFormat, formatEuros, formatPercentage } from "../../../utils/formatUtils";
import { isAcceptant } from "../../../utils/PolicyUtil";
import { Translate } from "../../../utils/Translation";
import ComponentSubTitle from "../../titles/ComponentSubTitle";

type PremiumResultProps = {
	readonly result: PremiumResultDto;
};

const PremiumResultWidget = ({ result }: PremiumResultProps): JSX.Element => {

	const { role } = useAppSelector(selectIdentity);

	const cellPrepared = useCallback((e: any): void => {
		if (e.rowType === "data") {
			if (e.data.componentKey === "total") {
				e.cellElement.classList.add(styles["important-cell"]);
			}
		}
	}, []);

	if (result.usesMinimumPremium && !result.yearlyPremium)
		throw new Error("Yearly premium should be known when using minimum premium");

	const showCoolingDamageWarning = useMemo(() => {
		if (!result.components)
			return false;
		const coolingDamage = result.components.filter(x => x.componentKey === "coolingDamage");
		return coolingDamage.length !== 0;
	}, [result.components]);

	return (
		<div>
			<ComponentSubTitle text={result.participant ? result.participant : ""} />
			{isAcceptant(role) ? <p>{`${Translate("premium-result.signing-year")}: ${result.signingYear}`}</p> : null}
			<DataGrid
				dataSource={result.components}
				keyExpr="componentKey"
				showBorders
				columnAutoWidth
				columnHidingEnabled
				onCellPrepared={cellPrepared}
				sorting={undefined}
				cellHintEnabled
			>
				<Column
					dataField="componentKey"
					caption={Translate("premium-calculation.result.component")}
					calculateDisplayValue={(x: PremiumResultComponentDto): string =>
						Translate(`premium-result.component.${x.componentKey}`)}
				/>
				<Column
					dataField="insuredValue"
					caption={Translate("premium-calculation.result.insured-value")}
					format={euroFormat}
				/>
				<Column
					dataField="premiumRatio"
					caption={Translate("premium-calculation.result.premium-ratio")}
					format={"#0.####‰"}
					calculateDisplayValue={(x: PremiumResultComponentDto): number =>
						x.premiumRatio ? x.premiumRatio * 1000 : 0}
				/>
				<Column
					dataField="yearlyPremium"
					caption={Translate("premium-calculation.result.yearly-premium")}
					format={euroFormat}
				/>
			</DataGrid>
			<div>
				{result.usesMinimumPremium ?
					<p>
						<span>{Translate("premium-calculation.result.using-minimum-premium")}: </span>
						{/* Yearly premium should always be set here due to if statement earlier */}
						<span>{result.yearlyPremium ? formatEuros(result.yearlyPremium) : "Uknown"}</span>
					</p> : null
				}
				{showCoolingDamageWarning ?
					<p>
						{Translate("premium-calculation.result.cooling-warning")}
					</p> : null}

				<span>{Translate("premium-calculation.result.provision-intermediary")}: </span>
				<span>{formatEuros(result.intermediaryProvisionValue)} </span>
				<span>
					({formatPercentage(result.intermediaryProvisionPercentage / 100)})
				</span>
			</div>
		</div>
	);
};

export default PremiumResultWidget;