import React, { memo, useCallback, useMemo } from "react";

import { useNavigate } from "react-router";

import {
  InspectionType, PolicyDto, PolicyStatus, usePoliciesRejectPremiumIndicationMutation, usePoliciesRequestQuoteMutation,
} from "../../../apis/PoliciesApi";
import { PremiumDto } from "../../../apis/PremiumApi";
import PremiumDialogButtons from "./PremiumDialogButtons";

type RequestQuoteWidgetProps = {
	readonly policy: PolicyDto;
	readonly premium: PremiumDto;
	readonly premiumOutdated: boolean;
};

const RequestQuoteWidget = ({ policy, premium, premiumOutdated }: RequestQuoteWidgetProps): JSX.Element => {

	const [triggerRequestQuote] = usePoliciesRequestQuoteMutation();
	const [triggerRejectPremiumIndication] = usePoliciesRejectPremiumIndicationMutation();
	const navigate = useNavigate();

	const requestQuote = useCallback(async (): Promise<void> => {
		if (!premium)
			return;
		const result = await triggerRequestQuote({
			policyGuid: policy.guid, requestQuoteDto: {
				premiumGuid: premium.guid,
			}
		}).unwrap();
		if (result.inspection === InspectionType.PreRisk)
			navigate("prerisk");

	}, [navigate, policy.guid, premium, triggerRequestQuote]);

	const handleRejectPremiumIndication = useCallback(async (): Promise<void> => {
		await triggerRejectPremiumIndication({
			policyGuid: policy.guid
		});
	}, [policy, triggerRejectPremiumIndication]);

	const requestQuoteButton = useMemo(() => {
		return (
			<PremiumDialogButtons
				premiumStage="request-quote"
				acceptAction={requestQuote}
				acceptEnabled={!premiumOutdated}
				rejectAction={handleRejectPremiumIndication}
				customLocalizeButton
				redirect={false}
			/>
		);
	}, [handleRejectPremiumIndication, premiumOutdated, requestQuote]);

	if (policy.status !== PolicyStatus.PremiumIndicationProvided)
		return <div />;
	return requestQuoteButton;
};

export default memo(RequestQuoteWidget);