import { Guid } from "../utils/Guid";
import { befurstApiClient as api } from "./BefurstApi";
export const addTagTypes = ["StaticData"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      staticDataValidatePendingData: build.query<
        StaticDataValidatePendingDataApiResponse,
        StaticDataValidatePendingDataApiArg
      >({
        query: () => ({ url: `/api/StaticData/validate-pending` }),
        providesTags: ["StaticData"],
      }),
      staticDataValidateAndInsertPendingData: build.mutation<
        StaticDataValidateAndInsertPendingDataApiResponse,
        StaticDataValidateAndInsertPendingDataApiArg
      >({
        query: () => ({
          url: `/api/StaticData/validate-and-insert-pending`,
          method: "POST",
        }),
        invalidatesTags: ["StaticData"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as enhancedApi };
export type StaticDataValidatePendingDataApiResponse =
  /** status 200 OK */ StaticDataValidatonResultDto;
export type StaticDataValidatePendingDataApiArg = void;
export type StaticDataValidateAndInsertPendingDataApiResponse =
  /** status 200 OK */ StaticDataValidatonResultDto;
export type StaticDataValidateAndInsertPendingDataApiArg = void;
export type StaticDataValidatonResultDto = {
  isValid: boolean;
  messages: string[] | null;
};
export const {
  useStaticDataValidatePendingDataQuery,
  useLazyStaticDataValidatePendingDataQuery,
  useStaticDataValidateAndInsertPendingDataMutation,
} = injectedRtkApi;
