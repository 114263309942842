import React from "react";

import AddressComponent, { AddressType } from "../utility-components/AddressComponent";
import BaseComponent from "./BaseComponent";

type Props = {
	readonly onFormChanged: () => Promise<void>;
};

const CorrespondenceAddressComponent = ({ onFormChanged }: Props): JSX.Element => {

	return (
		<BaseComponent id="correspondence-address" captionKey="policy.correspondence-address.title">
			<AddressComponent onFormChanged={onFormChanged} addressType={AddressType.Correspondence} />
		</BaseComponent>
	);
};

export default React.memo(CorrespondenceAddressComponent);