import { Guid } from "../utils/Guid";
import { befurstApiClient as api } from "./BefurstApi";
export const addTagTypes = [
  "ControllerTagNotInUse-PremiumUpdateFreeFieldStipulations",
  "Policies",
  "Premium",
  "PremiumOutdated",
  "PremiumStipulations",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      premiumUpdateFreeFieldStipulations: build.mutation<
        PremiumUpdateFreeFieldStipulationsApiResponse,
        PremiumUpdateFreeFieldStipulationsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/premium-indications/${queryArg.premiumGuid}/free-stipulations`,
          method: "PUT",
          params: { freeFieldStipulations: queryArg.freeFieldStipulations },
        }),
        invalidatesTags: [
          "ControllerTagNotInUse-PremiumUpdateFreeFieldStipulations",
        ],
      }),
      policiesRejectPremiumIndication: build.mutation<
        PoliciesRejectPremiumIndicationApiResponse,
        PoliciesRejectPremiumIndicationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/reject-premium-indication`,
          method: "PUT",
        }),
        invalidatesTags: ["Policies"],
      }),
      policiesGetLatestPremium: build.query<
        PoliciesGetLatestPremiumApiResponse,
        PoliciesGetLatestPremiumApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/current-version/premium`,
        }),
        providesTags: ["Premium"],
      }),
      policiesCreatePremium: build.mutation<
        PoliciesCreatePremiumApiResponse,
        PoliciesCreatePremiumApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/current-version/premium`,
          method: "POST",
        }),
        invalidatesTags: ["Premium", "Policies"],
      }),
      policiesHasLatestPremium: build.query<
        PoliciesHasLatestPremiumApiResponse,
        PoliciesHasLatestPremiumApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/current-version/has-premium`,
        }),
        providesTags: ["Premium"],
      }),
      policiesHasLatestOutdatedPremium: build.query<
        PoliciesHasLatestOutdatedPremiumApiResponse,
        PoliciesHasLatestOutdatedPremiumApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/current-version/has-outdated-premium`,
        }),
        providesTags: ["Premium", "PremiumOutdated"],
      }),
      policiesCreatePremiumAfterRejectedQuote: build.mutation<
        PoliciesCreatePremiumAfterRejectedQuoteApiResponse,
        PoliciesCreatePremiumAfterRejectedQuoteApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/current-version/premium-after-quote-rejection`,
          method: "POST",
        }),
        invalidatesTags: ["Premium", "Policies"],
      }),
      policiesCreatePremiumAfterQuoteReview: build.mutation<
        PoliciesCreatePremiumAfterQuoteReviewApiResponse,
        PoliciesCreatePremiumAfterQuoteReviewApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/current-version/premium-after-quote-review`,
          method: "POST",
        }),
        invalidatesTags: ["Premium", "Policies"],
      }),
      policiesCreatePremiumAfterFillInspectionScore: build.mutation<
        PoliciesCreatePremiumAfterFillInspectionScoreApiResponse,
        PoliciesCreatePremiumAfterFillInspectionScoreApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/current-version/premium-after-fill-inspection-score`,
          method: "POST",
        }),
        invalidatesTags: ["Premium", "Policies"],
      }),
      policiesCreatePremiumDuringIndicationReview: build.mutation<
        PoliciesCreatePremiumDuringIndicationReviewApiResponse,
        PoliciesCreatePremiumDuringIndicationReviewApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Policies/${queryArg.policyGuid}/current-version/premium-during-indication-review`,
          method: "POST",
        }),
        invalidatesTags: ["Premium", "Policies"],
      }),
      premiumGetPremium: build.query<
        PremiumGetPremiumApiResponse,
        PremiumGetPremiumApiArg
      >({
        query: (queryArg) => ({
          url: `/api/premium-indications/${queryArg.premiumGuid}`,
        }),
        providesTags: ["Premium"],
      }),
      premiumGetResults: build.query<
        PremiumGetResultsApiResponse,
        PremiumGetResultsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/premium-indications/${queryArg.premiumGuid}/results`,
        }),
        providesTags: ["Premium"],
      }),
      premiumGetStipulationsNotInUse: build.query<
        PremiumGetStipulationsNotInUseApiResponse,
        PremiumGetStipulationsNotInUseApiArg
      >({
        query: (queryArg) => ({
          url: `/api/premium-indications/${queryArg.premiumGuid}/stipulations-not-in-use`,
        }),
        providesTags: ["Premium"],
      }),
      premiumStipulationsCreatePremiumStipulation: build.mutation<
        PremiumStipulationsCreatePremiumStipulationApiResponse,
        PremiumStipulationsCreatePremiumStipulationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/PremiumStipulations`,
          method: "POST",
          body: queryArg.createPremiumStipulationDto,
        }),
        invalidatesTags: ["Premium", "PremiumOutdated", "PremiumStipulations"],
      }),
      premiumStipulationsUpdatePremiumStipulationResponse: build.mutation<
        PremiumStipulationsUpdatePremiumStipulationResponseApiResponse,
        PremiumStipulationsUpdatePremiumStipulationResponseApiArg
      >({
        query: (queryArg) => ({
          url: `/api/PremiumStipulations/${queryArg.premiumStipulationGuid}`,
          method: "PUT",
          params: { response: queryArg.response },
        }),
        invalidatesTags: ["PremiumOutdated", "PremiumStipulations"],
      }),
      premiumStipulationsUpdateQuoteReviewPremiumStipulation: build.mutation<
        PremiumStipulationsUpdateQuoteReviewPremiumStipulationApiResponse,
        PremiumStipulationsUpdateQuoteReviewPremiumStipulationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/PremiumStipulations/${queryArg.premiumStipulationGuid}/quote-review`,
          method: "PUT",
          body: queryArg.updatePremiumStipulationDto,
        }),
        invalidatesTags: ["PremiumOutdated", "PremiumStipulations"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as enhancedApi };
export type PremiumUpdateFreeFieldStipulationsApiResponse =
  /** status 200 OK */ PremiumResultsDto;
export type PremiumUpdateFreeFieldStipulationsApiArg = {
  premiumGuid: Guid;
  freeFieldStipulations?: string;
};
export type PoliciesRejectPremiumIndicationApiResponse =
  /** status 200 OK */ PolicyDto;
export type PoliciesRejectPremiumIndicationApiArg = {
  policyGuid: Guid;
};
export type PoliciesGetLatestPremiumApiResponse =
  /** status 200 OK */ PremiumDto;
export type PoliciesGetLatestPremiumApiArg = {
  policyGuid: Guid;
};
export type PoliciesCreatePremiumApiResponse =
  /** status 201 Created */ PremiumDto;
export type PoliciesCreatePremiumApiArg = {
  policyGuid: Guid;
};
export type PoliciesHasLatestPremiumApiResponse = /** status 200 OK */ boolean;
export type PoliciesHasLatestPremiumApiArg = {
  policyGuid: Guid;
};
export type PoliciesHasLatestOutdatedPremiumApiResponse =
  /** status 200 OK */ boolean;
export type PoliciesHasLatestOutdatedPremiumApiArg = {
  policyGuid: Guid;
};
export type PoliciesCreatePremiumAfterRejectedQuoteApiResponse =
  /** status 201 Created */ PremiumDto;
export type PoliciesCreatePremiumAfterRejectedQuoteApiArg = {
  policyGuid: Guid;
};
export type PoliciesCreatePremiumAfterQuoteReviewApiResponse =
  /** status 201 Created */ PremiumDto;
export type PoliciesCreatePremiumAfterQuoteReviewApiArg = {
  policyGuid: Guid;
};
export type PoliciesCreatePremiumAfterFillInspectionScoreApiResponse =
  /** status 201 Created */ PremiumDto;
export type PoliciesCreatePremiumAfterFillInspectionScoreApiArg = {
  policyGuid: Guid;
};
export type PoliciesCreatePremiumDuringIndicationReviewApiResponse =
  /** status 201 Created */ PremiumDto;
export type PoliciesCreatePremiumDuringIndicationReviewApiArg = {
  policyGuid: Guid;
};
export type PremiumGetPremiumApiResponse = /** status 200 OK */ PremiumDto;
export type PremiumGetPremiumApiArg = {
  premiumGuid: Guid;
};
export type PremiumGetResultsApiResponse =
  /** status 200 OK */ PremiumResultsDto;
export type PremiumGetResultsApiArg = {
  premiumGuid: Guid;
};
export type PremiumGetStipulationsNotInUseApiResponse =
  /** status 200 OK */ StipulationDto[];
export type PremiumGetStipulationsNotInUseApiArg = {
  premiumGuid: Guid;
};
export type PremiumStipulationsCreatePremiumStipulationApiResponse =
  /** status 200 OK */ PremiumStipulationDto;
export type PremiumStipulationsCreatePremiumStipulationApiArg = {
  createPremiumStipulationDto: CreatePremiumStipulationDto;
};
export type PremiumStipulationsUpdatePremiumStipulationResponseApiResponse =
  /** status 200 OK */ PremiumStipulationDto;
export type PremiumStipulationsUpdatePremiumStipulationResponseApiArg = {
  premiumStipulationGuid: Guid;
  response?: PremiumStipulationResponse;
};
export type PremiumStipulationsUpdateQuoteReviewPremiumStipulationApiResponse =
  /** status 200 OK */ PremiumStipulationDto;
export type PremiumStipulationsUpdateQuoteReviewPremiumStipulationApiArg = {
  premiumStipulationGuid: Guid;
  updatePremiumStipulationDto: UpdatePremiumStipulationDto;
};
export type PremiumResultComponentDto = {
  componentKey: string | null;
  insuredValue: number | null;
  premiumRatio: number | null;
  yearlyPremium: number | null;
};
export type PremiumResultDto = {
  guid: Guid;
  participantGuid: Guid;
  participant: string | null;
  signingYear: number;
  yearlyPremium: number | null;
  intermediaryProvisionPercentage: number;
  intermediaryProvisionValue: number;
  usesMinimumPremium: boolean;
  components: PremiumResultComponentDto[] | null;
};
export type PremiumWarningDto = {
  type: PremiumWarningType;
};
export type PremiumStipulationDto = {
  guid: Guid;
  code: string | null;
  description: string | null;
  preventionText: string | null;
  label: string | null;
  type: PremiumStipulationType;
  resolutionTimeMonths: number | null;
  response: PremiumStipulationResponse;
  irrelevant: boolean;
};
export type PremiumResultsDto = {
  results: PremiumResultDto[] | null;
  warnings: PremiumWarningDto[] | null;
  stipulations: PremiumStipulationDto[] | null;
  freeFieldStipulations: string | null;
};
export type CustomError = {
  status: number;
  key: string | null;
  message: string | null;
};
export type PolicyDto = {
  policyNumber: string | null;
  ownerId: string | null;
  ownerName: string | null;
  guid: Guid;
  relationGuid: Guid;
  creationDate: string;
  status: PolicyStatus;
  nameInsured: string | null;
  currentVersionGuid: Guid;
  type: PolicyType;
  email: string | null;
  fishAccepted: boolean;
  uboAccepted: boolean;
  inspectionState: InspectionState;
};
export type PremiumDto = {
  guid: Guid;
  policyVersionGuid: Guid;
  stage: PremiumStage;
};
export type StipulationDto = {
  code: string | null;
  description: string | null;
};
export type CreatePremiumStipulationDto = {
  premiumGuid: Guid;
  stipulationCode: string | null;
  type: PremiumStipulationType;
};
export type UpdatePremiumStipulationDto = {
  type: PremiumStipulationType;
  irrelevant: boolean;
  resolutionTimeMonths: number | null;
};
export enum PremiumWarningType {
  UnknownOccupancy = "UnknownOccupancy",
  OccupancyZero = "OccupancyZero",
  UnknownError = "UnknownError",
  ConstructionNature = "ConstructionNature",
  NoOccupancyForMajorSupplier = "NoOccupancyForMajorSupplier",
  RiskClassTooHigh = "RiskClassTooHigh",
  HasRemarksForReview = "HasRemarksForReview",
  InsufficientCapacity = "InsufficientCapacity",
  DamageHistoryTooHigh = "DamageHistoryTooHigh",
  DamageFrequencyTooHigh = "DamageFrequencyTooHigh",
  PreRiskAnswer = "PreRiskAnswer",
}
export enum PremiumStipulationType {
  Demand = "Demand",
  Advice = "Advice",
}
export enum PremiumStipulationResponse {
  WillComply = "WillComply",
  WillNotComply = "WillNotComply",
  AlreadyComplying = "AlreadyComplying",
}
export enum PolicyStatus {
  ProvideInformation = "ProvideInformation",
  PremiumIndicationRequested = "PremiumIndicationRequested",
  ReviewPremiumIndicationRequest = "ReviewPremiumIndicationRequest",
  PremiumIndicationProvided = "PremiumIndicationProvided",
  ReviewStipulations = "ReviewStipulations",
  QuoteRequested = "QuoteRequested",
  QuoteProvided = "QuoteProvided",
  ReviewQuote = "ReviewQuote",
  QuoteAccepted = "QuoteAccepted",
  QuoteRejected = "QuoteRejected",
  Running = "Running",
  RequestProlongation = "RequestProlongation",
  Cancelled = "Cancelled",
  Expired = "Expired",
  FullInspectionRequired = "FullInspectionRequired",
  Inspected = "Inspected",
  Rejected = "Rejected",
  PreRiskRequired = "PreRiskRequired",
  QuickScanInspectionRequired = "QuickScanInspectionRequired",
}
export enum PolicyType {
  Fire = "Fire",
}
export enum InspectionState {
  RequestInspection = "RequestInspection",
  InspectionToBeDelivered = "InspectionToBeDelivered",
  ContractChoiceRequired = "ContractChoiceRequired",
  FillScore = "FillScore",
  ScheduleInspection = "ScheduleInspection",
  Accepted = "Accepted",
  Rejected = "Rejected",
  NoInspection = "NoInspection",
}
export enum PremiumStage {
  Indication = "Indication",
  Quote = "Quote",
}
export const {
  usePremiumUpdateFreeFieldStipulationsMutation,
  usePoliciesRejectPremiumIndicationMutation,
  usePoliciesGetLatestPremiumQuery,
  useLazyPoliciesGetLatestPremiumQuery,
  usePoliciesCreatePremiumMutation,
  usePoliciesHasLatestPremiumQuery,
  useLazyPoliciesHasLatestPremiumQuery,
  usePoliciesHasLatestOutdatedPremiumQuery,
  useLazyPoliciesHasLatestOutdatedPremiumQuery,
  usePoliciesCreatePremiumAfterRejectedQuoteMutation,
  usePoliciesCreatePremiumAfterQuoteReviewMutation,
  usePoliciesCreatePremiumAfterFillInspectionScoreMutation,
  usePoliciesCreatePremiumDuringIndicationReviewMutation,
  usePremiumGetPremiumQuery,
  useLazyPremiumGetPremiumQuery,
  usePremiumGetResultsQuery,
  useLazyPremiumGetResultsQuery,
  usePremiumGetStipulationsNotInUseQuery,
  useLazyPremiumGetStipulationsNotInUseQuery,
  usePremiumStipulationsCreatePremiumStipulationMutation,
  usePremiumStipulationsUpdatePremiumStipulationResponseMutation,
  usePremiumStipulationsUpdateQuoteReviewPremiumStipulationMutation,
} = injectedRtkApi;
