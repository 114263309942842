import React, { memo } from "react";

import { useAppSelector } from "../../hooks/hooks";
import { selectIdentity } from "../../store/Identity";
import { canReadAllPolicies, canWritePolicies, hasPolicyAccess, isAcceptant } from "../../utils/PolicyUtil";
import HomePage from "../home-page/HomePage";
import Policies from "./Policies";

type Props = {
	readonly applyTodoListFilter?: boolean;
};

const PoliciesWrapper = ({ applyTodoListFilter = false }: Props): JSX.Element => {

	const { permissions, role } = useAppSelector(selectIdentity);

	if (!hasPolicyAccess(permissions))
		return <HomePage />;

	return (
		<Policies
			showCreatePolicyButton={canWritePolicies(permissions)}
			// If the user can see the policies of other people, the owner name is useful
			showOwner={canReadAllPolicies(permissions)}
			// Role specific logic: Only the acceptant gets to see 
			showInsuredAddress={isAcceptant(role)}
			applyTodoListFilter={applyTodoListFilter}
		/>);
};

export default memo(PoliciesWrapper);