import React, { useCallback, useMemo } from "react";

import { DataGrid, LoadPanel, TextBox, Validator } from "devextreme-react";
import { Button as DataGridButton, Column, FilterBuilderPopup, FilterPanel, FilterRow, HeaderFilter } from "devextreme-react/data-grid";
import { CustomRule, RequiredRule, StringLengthRule } from "devextreme-react/validator";
import { useNavigate } from "react-router-dom";

import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";

import { usePoliciesCreatePolicyMutation, usePoliciesFetchPoliciesQuery } from "../../apis/PoliciesApi";
import { useAppSelector } from "../../hooks/hooks";
import { selectIdentity } from "../../store/Identity";
import styles from "../../styles/Policies.module.scss";
import { policyStatusesDesc, todoListFilter } from "../../utils/PolicyUtil";
import { Translate } from "../../utils/Translation";
import Button from "../buttons/Button";
import Dialog from "../dialog/Dialog";
import PageTitle from "../titles/PageTitle";
import { PositionConfig } from "devextreme/animation/position";

type Props = {
	readonly showCreatePolicyButton: boolean;
	readonly showOwner: boolean;
	readonly showInsuredAddress: boolean;
	readonly applyTodoListFilter: boolean;
};

const Policies = ({ showCreatePolicyButton, showOwner, showInsuredAddress, applyTodoListFilter }: Props): JSX.Element => {
	const navigate = useNavigate();

	const [policyDialogOpen, setPolicyDialogOpen] = React.useState(false);
	const [kvk, setKvk] = React.useState<string | undefined>(undefined);
	const [creatingPolicy, setCreatingPolicy] = React.useState(false);
	const [kvkNotFound, setKvkNotFound] = React.useState(false);

	const [triggerCreatePolicy] = usePoliciesCreatePolicyMutation();

	const { permissions, username } = useAppSelector(selectIdentity);

	const loadingMessage = <div>Fetching the policies. Please wait...</div>;
	const missingDataMessage = (
		<div>Fetching policies failed. Try refreshing the page.</div>
	);

	const toggleDialog = (): void => {
		setPolicyDialogOpen((prev: boolean) => !prev);
		setKvk(undefined);
	};

	const changeKvk = (value: string): void => {
		setKvk(value);
		setKvkNotFound(false);
	};

	const isKvkValid = (): boolean =>
		kvk !== undefined && kvk.length === 8;

	const createPolicy = useCallback(async (): Promise<void> => {
		setCreatingPolicy(true);

		try {
			const newPolicyResult = await triggerCreatePolicy({ kvkNumber: kvk }).unwrap();
			navigate(`/policy/${newPolicyResult.guid}`);
		} catch (error) {
			const fetchError = error as FetchBaseQueryError;
			if (fetchError && fetchError.status === 404) {
				setKvkNotFound(true);
			}
			setCreatingPolicy(false);
		}
	}, [kvk, navigate, triggerCreatePolicy]);

	const {
		isLoading,
		error,
		data: policies,
	} = usePoliciesFetchPoliciesQuery();

	const dataSource = useMemo(() => {
		if (!policies)
			return [];
		return policies
			.filter(x => applyTodoListFilter ? todoListFilter(x, username, permissions) : true)
			.map((p) => {
				const effectiveDate: Date | null = p.version.effectiveDate
					? new Date(p.version.effectiveDate)
					: null;
				let expiryDate: Date | null = p.version.contractExpirationDate
					? new Date(p.version.contractExpirationDate)
					: null;
				if (expiryDate === null && effectiveDate !== null) {
					expiryDate = new Date(effectiveDate);
					expiryDate.setFullYear(expiryDate.getFullYear() + 1);
				}
				const status = policyStatusesDesc.get(p.status);
				return {
					policyId: p.guid,
					companyName: p.relation.companyName,
					status: status ? Translate(status) : "Unknown status",
					policyNumber: p.policyNumber,
					expiryDate,
					creationDate: p.creationDate ? new Date(p.creationDate) : null,
					effectiveDate,
					requester: p.ownerName,
					insuredAddress: p.insuredAddress
				};
			});
	}, [applyTodoListFilter, permissions, policies, username]);

	if (error) throw error;
	if (isLoading) return loadingMessage;
	if (!policies) return missingDataMessage;

	const goToEdit = (event: any): void => {
		const id = event.row.data.policyId;
		navigate(`/policy/${id}`);
	};

	const filterBuilderPopupPosition: PositionConfig = {
		of: window,
		at: "center",
		my: "center",
	};

	return (
		<div className={styles["policies"]}>
			<div className={styles["policies-header"]}>
				<PageTitle text={applyTodoListFilter ? Translate("title.todo-list") : Translate("title.policies")} />
				{
					showCreatePolicyButton ?
						<Button
							text={Translate("policies.button-create")}
							stylingMode="outlined"
							onClick={toggleDialog}
							autoWidth
						/> : null}

			</div>

			{applyTodoListFilter && dataSource.length === 0 ?
				<p>{Translate("policies.no-open-tasks")}</p> :
				<DataGrid dataSource={dataSource} showBorders columnAutoWidth columnHidingEnabled>
					<FilterRow visible />
					<FilterPanel visible />
					<FilterBuilderPopup position={filterBuilderPopupPosition} />
					<HeaderFilter visible />

					{showOwner ?
						<Column
							dataField="requester"
							caption={Translate("role.intermediary")}
						/> : null}
					<Column
						dataField="companyName"
						caption={Translate("policies.table.company-name")}
					/>
					{showInsuredAddress ?
						<Column
							dataField="insuredAddress"
							caption={Translate("policy.insured-address.title")}
						/> : null}
					<Column
						dataField="policyNumber"
						caption={Translate("policies.table.policy-number")}
					/>
					<Column
						dataField="expiryDate"
						caption={Translate("policies.table.expiry-date")}
						dataType="date"
					/>
					<Column
						dataField="status"
						caption={Translate("policies.table.status")}
					/>
					<Column
						dataField="creationDate"
						caption={Translate("policies.table.creation-date")}
						dataType="date"
					/>
					<Column
						dataField="effectiveDate"
						caption={Translate("policies.table.effective-date")}
						dataType="date"
					/>
					<Column type="buttons">
						<DataGridButton hint="Edit" icon="edit" onClick={goToEdit} />
					</Column>
				</DataGrid>
			}

			<Dialog
				title={Translate("policy.dialog.title")}
				subTitle={Translate("policy.dialog.subtitle")}
				buttonText={Translate("policy.dialog.button.text")}
				isVisible={policyDialogOpen}
				cancelAction={toggleDialog}
				mainAction={createPolicy}
				cancelActionDisabled={creatingPolicy}
				mainActionDisabled={!isKvkValid() || creatingPolicy}
			>
				<>
					<div className={`dx-field ${styles["kvk-input-field"]}`}>
						<div className={`dx-field-label ${styles["kvk-input-label"]}`}>
							{Translate("policy.dialog.form.label")}
						</div>
						<div className={`dx-field-value ${styles["kvk-input-value"]}`}>
							<TextBox value={kvk} onValueChange={changeKvk} height={45}>
								<Validator>
									<RequiredRule
										message={Translate("policy.dialog.validation.message")}
									/>
									<StringLengthRule
										min={8}
										max={8}
										message={Translate("policy.dialog.validation.message")}
									/>
									<CustomRule
										type="custom"
										validationCallback={(): boolean => !kvkNotFound}
										message={Translate("policy.dialog.form.kvk-not-found")}
									/>
								</Validator>
							</TextBox>
						</div>
					</div>

					<div id="load-panel" className={styles["load-panel"]}>
						<LoadPanel
							visible={creatingPolicy}
							container="#load-panel"
							position="center"
							hideOnOutsideClick={false}
							message={Translate("policy.dialog.loading.message")}
						/>
					</div>
				</>
			</Dialog>
		</div>
	);
};

export default Policies;
