import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "./store";

export type IdentityState = {
    username: string | null;
    role: string | null;
    token: string | null;
    permissions: string[];
};

const initialState: IdentityState = {
    username: null,
    role: null,
    token: null,
    // Tried to use a Set here to get sublinear has() behavior, but Redux state doesn't like unserializables so went with array instead
    permissions: []
};

export const identitySlice = createSlice({
    name: 'identity',
    initialState,
    reducers: {
        setUsername: (state, action: PayloadAction<string>) => {
            state.username = action.payload;
        },
        setRole: (state, action: PayloadAction<string>) => {
            state.role = action.payload;
        },
        setToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload;
        },
        setPermissions: (state, action: PayloadAction<string[]>) => {
            state.permissions = action.payload;
        },
    }
});

export const { setUsername, setRole, setToken, setPermissions } = identitySlice.actions;
export const identityReducer = identitySlice.reducer;

export const selectIdentity = (state: RootState): IdentityState => state.identity;

export default identityReducer;
