import { Guid } from "../utils/Guid";
import { befurstApiClient as api } from "./BefurstApi";
export const addTagTypes = ["PreRiskQuestions"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      preRiskQuestionsFetchPreRiskQuestions: build.query<
        PreRiskQuestionsFetchPreRiskQuestionsApiResponse,
        PreRiskQuestionsFetchPreRiskQuestionsApiArg
      >({
        query: () => ({ url: `/api/pre-risk-questions` }),
        providesTags: ["PreRiskQuestions"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as enhancedApi };
export type PreRiskQuestionsFetchPreRiskQuestionsApiResponse =
  /** status 200 OK */ PreRiskQuestionDto[];
export type PreRiskQuestionsFetchPreRiskQuestionsApiArg = void;
export type PreRiskQuestionDto = {
  guid: Guid;
  name: string | null;
  visibilityCondition: string | null;
  ceaCodeFilter: string | null;
  category: string | null;
  subHeader: string | null;
  text: string | null;
  index: number;
  type: FieldType;
  answerCategory: string | null;
};
export enum FieldType {
  Number = "Number",
  Currency = "Currency",
  Boolean = "Boolean",
  String = "String",
  Date = "Date",
  Enum = "Enum",
  Percentage = "Percentage",
  Occupancy = "Occupancy",
}
export const {
  usePreRiskQuestionsFetchPreRiskQuestionsQuery,
  useLazyPreRiskQuestionsFetchPreRiskQuestionsQuery,
} = injectedRtkApi;
