import React, { memo, useCallback, useMemo } from "react";

import Form, { SimpleItem } from "devextreme-react/form";
import { FieldDataChangedEvent } from "devextreme/ui/form";

import { usePoliciesGetCurrentPolicyDamagesQuery } from "../../../apis/DamagesApi";
import { DamageOption, usePoliciesUpdatePolicyVersionMutation } from "../../../apis/PoliciesApi";
import { useAppSelector } from "../../../hooks/hooks";
import { selectIdentity } from "../../../store/Identity";
import { selectPolicyPage } from "../../../store/PolicyPageState";
import styles from "../../../styles/PolicyInput.module.scss";
import { emptyGuid } from "../../../utils/Guid";
import { isPolicyComponentReadOnly, POLICY_VALIDATION_GROUP } from "../../../utils/PolicyUtil";
import { Translate } from "../../../utils/Translation";
import { disableAutocompleteOnForm } from "../../../utils/utils";
import DamageHistoryTableComponent from "../utility-components/DamageHistoryTableComponent";
import BaseComponent from "./BaseComponent";

type Props = {
	readonly onFormChanged: () => Promise<void>;
};

const OPTION = "option";

type FormData = {
	[OPTION]?: DamageOption;
};

type Option = {
	readonly value: DamageOption;
	readonly text: string;
};

const DamageHistoryComponent = ({ onFormChanged }: Props): JSX.Element => {

	const { data } = useAppSelector(selectPolicyPage);
	const { username, permissions } = useAppSelector(selectIdentity);

	if (!data)
		throw new Error("Policy page data is unavailable");

	const readOnly = isPolicyComponentReadOnly(data, username, permissions);

	const formData: FormData = useMemo(() => ({ option: data.policyVersion.damageOption }), [data.policyVersion.damageOption]);
	const [triggerPutPolicyVersion] = usePoliciesUpdatePolicyVersionMutation();
	const { data: damages } = usePoliciesGetCurrentPolicyDamagesQuery({ policyGuid: data.policy.guid });

	const damageTable = useMemo(() => <DamageHistoryTableComponent supplierGuid={emptyGuid()} readOnly={readOnly} />, [readOnly]);

	const options = useMemo(() => {
		const opt: Option[] = [
			{ value: DamageOption.Unknown, text: Translate("policy.form.previousDamages.unknown") },
			{ value: DamageOption.Not, text: Translate("policy.form.previousDamages.not") },
			{ value: DamageOption.Yes, text: Translate("policy.form.previousDamages.add") },
		];
		return opt;
	}, []);

	const optionLabel = useMemo(() => ({
		text: Translate("policy.form.previousDamages"),
	}), []);

	const setFieldValue = useCallback(async (e: FieldDataChangedEvent): Promise<void> => {
		if (!e.dataField)
			return;

		switch (e.dataField) {
			case OPTION:
				await triggerPutPolicyVersion({
					policyGuid: data.policy.guid,
					updatePolicyVersionDto: {
						...data.policyVersion,
						damageOption: e.value as DamageOption,
					}
				}).unwrap();
				break;
		}
		onFormChanged();
	}, [onFormChanged, triggerPutPolicyVersion, data]);

	const tableNotEmpty = formData.option === DamageOption.Yes && damages && damages.length > 0;

	return (
		<BaseComponent id="damage-history" captionKey="policy.form.title.damage-history">
			<Form
				formData={formData}
				onOptionChanged={onFormChanged}
				onFieldDataChanged={setFieldValue}
				validationGroup={POLICY_VALIDATION_GROUP}
				readOnly={tableNotEmpty || readOnly}
				onContentReady={disableAutocompleteOnForm}
			>
				<SimpleItem
					dataField={OPTION}
					cssClass={styles["wide-editor"]}
					editorType="dxSelectBox"
					label={optionLabel}
					editorOptions={{
						valueExpr: "value",
						displayExpr: "text",
						dataSource: options,
					}}
				/>
			</Form>
			{formData.option === DamageOption.Yes ? damageTable : null}
		</BaseComponent>
	);

};

export default memo(DamageHistoryComponent);