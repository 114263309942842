/* eslint-disable complexity */
/* eslint-disable max-lines */
import React, { useCallback, useMemo, useState } from "react";

import { Form, Toast } from "devextreme-react";
import { ColCountByScreen, CustomRule, GroupItem, RangeRule, RequiredRule, SimpleItem } from "devextreme-react/form";
import { FieldDataChangedEvent } from "devextreme/ui/form";

import { AddressDto, useAddressesUpdateAddressMutation, useLazyAddressesGetAddressQuery } from "../../../apis/AddressesApi";
import {
  UpdatePolicyVersionDto, usePoliciesPatchSupplierMutation, usePoliciesUpdatePolicyVersionMutation,
} from "../../../apis/PoliciesApi";
import { useRelationsGetAddressesQuery } from "../../../apis/RelationsApi";
import { useAsyncEffect } from "../../../hooks/AsyncEffectHook";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { selectIdentity } from "../../../store/Identity";
import {
  selectPolicyPage, setCorrespondenceAddressGuid, setInsuredAddressGuid, setPolicyHolderAddressGuid,
} from "../../../store/PolicyPageState";
import styles from "../../../styles/PolicyInput.module.scss";
import { emptyGuid, Guid, parseGuid } from "../../../utils/Guid";
import { isPolicyComponentReadOnly, POLICY_VALIDATION_GROUP } from "../../../utils/PolicyUtil";
import { Translate } from "../../../utils/Translation";
import { disableAutocompleteOnForm } from "../../../utils/utils";

export enum AddressType {
	PolicyHolder = 0,
	Correspondence = 1,
	Insured = 2,
	Supplier = 3,
}

export type SupplierProps = {
	guid: Guid,
	addressGuid: Guid,
};

type Props = {
	readonly addressType: AddressType;
	readonly onFormChanged: () => Promise<void>;
	readonly supplier?: SupplierProps;
};

const SELECTED_RELATION_ADDRESS = "selectedRelationAddressGuid";
const SELECT_ADDRESS = "selectAddress";
const GUID = "guid";
const ADDRESS = "address";
const USE_POBOX = "usePoBox";
const PO_BOX = "poBox";
const POSTAL_CODE = "postalCode";
const STREET_NAME = "street";
const HOUSE_NUMBER = "houseNumber";
const HOUSE_LETTER = "houseLetter";
const CITY = "city";

const nullToZero = (value: number | null): number => value === null ? 0 : value;

type FormData = {
	[SELECTED_RELATION_ADDRESS]: Guid | null;
	[SELECT_ADDRESS]: boolean;
	[STREET_NAME]: string;
	[USE_POBOX]: boolean;
	[PO_BOX]: number | null;
	[POSTAL_CODE]: string | null;
	[HOUSE_NUMBER]: number | null;
	[HOUSE_LETTER]: string | null;
	[CITY]: string | null;
};

type AddressChange = {
	streetName?: string | null;
	city?: string | null;
	postalCode?: string | null;
	houseLetter?: string | null;
	houseNumber?: number | null;
	poBoxNumber?: number | null;
};

type RelationAddress = {
	readonly [GUID]: Guid;
	readonly [ADDRESS]: string;
};

const filterAddresses = (addresses: AddressDto[], addressType: AddressType): AddressDto[] => {
	return addresses.filter(x => !x.poBoxNumber || addressType === AddressType.Correspondence);
};

const EmptyAddress: AddressDto = {
	guid: emptyGuid(),
	city: null,
	country: null,
	houseLetter: null,
	houseNumber: 0,
	latitude: 0,
	longitude: 0,
	poBoxNumber: 0,
	postalCode: null,
	relationGuid: emptyGuid(),
	streetName: null,
};

const AddressComponent = ({ addressType, onFormChanged, supplier }: Props): JSX.Element => {

	const dispatch = useAppDispatch();
	const { data } = useAppSelector(selectPolicyPage);
	const { username, permissions } = useAppSelector(selectIdentity);
	const [selectAddress, setSelectAddress] = useState<boolean | null>(null);
	const [usePoBox, setUsePoBox] = useState<boolean | null>(null);
	const [savingFailed, setSavingFailed] = useState(false);
	const [address, setAddress] = useState<AddressDto | null>(null);
	const [customAddressGuid, setCustomAddressGuid] = useState(emptyGuid());
	const allowPoBox = addressType === AddressType.Correspondence;
	if (!data)
		throw new Error("Policy data not set");

	const readOnly = isPolicyComponentReadOnly(data, username, permissions);

	const relationGuid = data.policy.relationGuid;
	const { data: relationAddresses } = useRelationsGetAddressesQuery({ relationGuid });
	const [triggerUpdatePolicyVersion] = usePoliciesUpdatePolicyVersionMutation();
	const [triggerGetAddress] = useLazyAddressesGetAddressQuery();
	const [triggerUpdateAddress] = useAddressesUpdateAddressMutation();
	const [triggerPatchSupplier] = usePoliciesPatchSupplierMutation();

	let addressGuid: Guid | null = null;
	switch (addressType) {
		case AddressType.PolicyHolder:
			addressGuid = data.policyVersion.policyholderAddressGuid;
			break;
		case AddressType.Correspondence:
			addressGuid = data.policyVersion.correspondenceAddressGuid;
			break;
		case AddressType.Insured:
			addressGuid = data.policyVersion.insuredAddressGuid;
			break;
		case AddressType.Supplier:
			if (!supplier)
				throw new Error("Supplier address type not provided a supplier");
			addressGuid = supplier.addressGuid;
			break;
		default:
			throw new Error(`Unknown address type ${addressType}`);
	}

	if (!addressGuid) {
		throw new Error("Address not found");
	}

	useAsyncEffect(async (): Promise<void> => {
		if (!addressGuid)
			return;
		if (addressGuid === emptyGuid()) {
			setAddress({
				...EmptyAddress,
			});
			return;
		}
		const fetchedAddress = await triggerGetAddress({ addressGuid })
			.unwrap();
		setAddress(fetchedAddress);
		if (usePoBox === null)
			setUsePoBox(Boolean(fetchedAddress.poBoxNumber));
	}, [addressGuid, addressType, triggerGetAddress]);

	const updateAddressGuid = useCallback(async (newGuid: Guid): Promise<void> => {
		try {
			if (addressType === AddressType.Supplier) {
				if (!supplier)
					throw new Error('Cannot update supplier addresss without a supplier being provided');
				await triggerPatchSupplier({
					policyGuid: data.policy.guid,
					supplierGuid: supplier.guid,
					patchSupplierDto: {
						addressGuid: newGuid,
						occupancyGuid: null,
					},
				});
				return;
			}

			const update: UpdatePolicyVersionDto = {
				...data.policyVersion,
			};
			switch (addressType) {
				case AddressType.PolicyHolder:
					update.policyholderAddressGuid = newGuid;
					break;
				case AddressType.Correspondence:
					update.correspondenceAddressGuid = newGuid;
					break;
				case AddressType.Insured:
					update.insuredAddressGuid = newGuid;
					break;
				default:
					throw new Error(`Unknown address type ${addressType}`);
			}

			switch (addressType) {
				case AddressType.PolicyHolder:
					dispatch(setPolicyHolderAddressGuid(newGuid));
					break;
				case AddressType.Correspondence:
					dispatch(setCorrespondenceAddressGuid(newGuid));
					break;
				case AddressType.Insured:
					dispatch(setInsuredAddressGuid(newGuid));
					break;
			}

			await triggerUpdatePolicyVersion({ policyGuid: data.policy.guid, updatePolicyVersionDto: update });
		} catch (err) {
			setSavingFailed(true);
		}
	}, [addressType, data.policy.guid, data.policyVersion, dispatch, supplier, triggerPatchSupplier, triggerUpdatePolicyVersion]);

	const setFieldValue = useCallback(async (e: FieldDataChangedEvent): Promise<void> => {
		if (!e.dataField)
			return;

		const updateAddress = async (oldAddress: AddressDto | null, change: AddressChange): Promise<void> => {
			setAddress((prev: AddressDto | null): (AddressDto | null) => {
				if (!prev)
					return null;
				return {
					...prev,
					streetName: change.streetName !== undefined ? change.streetName : prev.streetName,
					city: change.city !== undefined ? change.city : prev.city,
					houseNumber: change.houseNumber !== undefined ? nullToZero(change.houseNumber) : prev.houseNumber,
					houseLetter: change.houseLetter !== undefined ? change.houseLetter : prev.houseLetter,
					poBoxNumber: change.poBoxNumber !== undefined ? nullToZero(change.poBoxNumber) : prev.poBoxNumber,
					postalCode: change.postalCode !== undefined ? change.postalCode : prev.postalCode,
				};
			});
			const searchPostalCode = change.houseNumber !== undefined
				|| change.postalCode !== undefined
				|| change.houseLetter !== undefined;
			const updatedAddress = await triggerUpdateAddress({
				addressGuid: customAddressGuid,
				updateAddressDto: {
					...EmptyAddress,
					policyVersionGuid: data.policyVersion.guid,
					searchPostalCode,
					...oldAddress,
					...change,
				}
			}).unwrap();
			if (updatedAddress.guid !== customAddressGuid)
				updateAddressGuid(updatedAddress.guid);
			if (searchPostalCode) {
				setAddress((prev: AddressDto | null): (AddressDto | null) => {
					if (!prev)
						return null;
					const newAddress: AddressDto = { ...prev };
					if (updatedAddress.city)
						newAddress.city = updatedAddress.city;
					if (updatedAddress.streetName)
						newAddress.streetName = updatedAddress.streetName;
					return newAddress;
				});
			}
		};

		try {
			switch (e.dataField) {
				case SELECTED_RELATION_ADDRESS: {
					await updateAddressGuid(e.value);
					break;
				}
				case SELECT_ADDRESS: {
					const select = e.value as boolean;
					setSelectAddress(select);

					const filteredAddresses = relationAddresses ? filterAddresses(relationAddresses, addressType) : null;
					if (!select)
						await updateAddressGuid(customAddressGuid);
					else if (filteredAddresses && filteredAddresses.length > 0)
						await updateAddressGuid(filteredAddresses[0].guid);
					break;
				}
				case STREET_NAME:
					await updateAddress(address, { streetName: e.value as string });
					break;
				case POSTAL_CODE:
					await updateAddress(address, { postalCode: e.value as string });
					break;
				case HOUSE_NUMBER:
					await updateAddress(address, { houseNumber: e.value as number });
					break;
				case HOUSE_LETTER:
					await updateAddress(address, { houseLetter: e.value as string });
					break;
				case CITY:
					await updateAddress(address, { city: e.value as string });
					break;
				case PO_BOX:
					await updateAddress(address, { poBoxNumber: e.value as number });
					break;
				case USE_POBOX: {
					const newUsePoBox = e.value as boolean;
					setUsePoBox(newUsePoBox);
					if (!newUsePoBox)
						await updateAddress(address, { poBoxNumber: undefined });
					break;
				}
				default:
					throw new Error(`Unknown data field: ${e.dataField}`);
			}
			onFormChanged();
		} catch (err) {
			setSavingFailed(true);
		}
	}, [address, addressType, customAddressGuid, relationAddresses, triggerUpdateAddress, updateAddressGuid, onFormChanged,
		data.policyVersion]);

	const relationAddressDataSource = useMemo(() => {
		if (!relationAddresses)
			return null;
		const dataSource = filterAddresses(relationAddresses, addressType)
			.map((add): RelationAddress => {
				const addressString = add.poBoxNumber
					? `Postbus ${add.poBoxNumber}, ${add.postalCode} ${add.city}`
					: `${add.streetName}, ${add.houseNumber}${add.houseLetter ? add.houseLetter : ""}, ${add.postalCode} ${add.city}`;
				return {
					guid: add.guid,
					address: addressString,
				};
			});
		return dataSource;
	}, [addressType, relationAddresses]);

	const selectAddressLabel = useMemo(() => ({ text: Translate("policy.form.addressSelection") }), []);
	const selectedAddressLabel = useMemo(() => ({ text: Translate("address.select") }), []);
	const usePoBoxLabel = useMemo(() => ({ text: Translate("policy.form.usePoBox") }), []);

	const streetNameItem = useMemo(() =>
	(
		<SimpleItem
			dataField={STREET_NAME}
			label={{ text: Translate("policy.form.streetName") }}
		>
			<RequiredRule message={Translate("policy.form.streetName.required")} />
		</SimpleItem>
	), []);

	const poBoxItem = useMemo(() =>
	(
		<SimpleItem
			dataField={PO_BOX}
			editorType="dxNumberBox"
			label={{ text: Translate("policy.form.poBoxNumber") }}
		>
			<RequiredRule message={Translate("policy.form.poBoxNumber.required")} />
			<RangeRule min={1} />
		</SimpleItem>
	), []);

	const postalCodeItem = useMemo(() =>
	(
		<SimpleItem
			dataField={POSTAL_CODE}
			label={{ text: Translate("policy.form.postalCode") }}
			editorOptions={{
				mask: "Dddd LL",
				maskRules: {
					D: /[1-9]/,
					d: /[0-9]/,
					L: /[A-Za-z]/,
				}
			}}
		>
			<RequiredRule message={Translate("policy.form.postalCode.required")} />
		</SimpleItem>
	), []);

	const houseNumberItem = useMemo(() =>
	(
		<SimpleItem
			dataField={HOUSE_NUMBER}
			editorType="dxNumberBox"
			label={{ text: Translate("policy.form.houseNumber") }}
		>
			<RequiredRule message={Translate("policy.form.houseNumber.required")} />
			<RangeRule min={1} />
		</SimpleItem>
	), []);

	const houseLetterItem = useMemo(() =>
	(
		<SimpleItem
			dataField={HOUSE_LETTER}
			label={{ text: Translate("policy.form.houseLetter") }}
		/>
	), []);

	const cityItem = useMemo(() =>
	(
		<SimpleItem
			dataField={CITY}
			label={{ text: Translate("policy.form.city") }}
		>
			<RequiredRule message={Translate("policy.form.city.required")} />
		</SimpleItem>
	), []);

	const requiredAddressRule = useMemo(() => {
		const validate = ({ value }: { value: string | number | null | undefined }): boolean => {
			if (typeof value === "number") {
				return false;
			} else if (value) {
				const guidValue = parseGuid(value);
				return guidValue !== emptyGuid();
			} else {
				return false;
			}
		};
		return (
			<CustomRule validationCallback={validate} message={Translate("policy.form.addressSelection.required")} />
		);
	}, []);

	const hideError = useCallback((): void => setSavingFailed(false), []);

	if (!relationAddresses)
		return (<div>{Translate("address.fetching.message")}</div>);

	const isRelationAddress = relationAddresses.find((x: AddressDto) => x.guid === addressGuid) !== undefined;
	if (selectAddress === null) {
		if (addressType === AddressType.Supplier) {
			setSelectAddress(false);
		} else {
			const filteredAddresses = filterAddresses(relationAddresses, addressType);
			setSelectAddress(isRelationAddress || (filteredAddresses.length > 0 && addressGuid === emptyGuid()));
		}
		if (!isRelationAddress)
			setCustomAddressGuid(addressGuid);
	}

	const formData: FormData = {
		[SELECTED_RELATION_ADDRESS]: isRelationAddress ? addressGuid : null,
		[SELECT_ADDRESS]: selectAddress ? selectAddress : false,
		[STREET_NAME]: address && address.streetName ? address.streetName : "",
		[USE_POBOX]: usePoBox ? usePoBox : false,
		[PO_BOX]: address && address.poBoxNumber ? address.poBoxNumber : null,
		[POSTAL_CODE]: address && address.postalCode ? address.postalCode : null,
		[HOUSE_NUMBER]: address && address.houseNumber ? address.houseNumber : null,
		[HOUSE_LETTER]: address && address.houseLetter ? address.houseLetter : null,
		[CITY]: address && address.city ? address.city : null,
	};

	const showPoBoxItem = allowPoBox && usePoBox;
	const showAddressSelection = Boolean(addressType !== AddressType.Supplier);

	return (
		<Form
			colCount={1}
			id="terms-form"
			formData={formData}
			readOnly={readOnly}
			onFieldDataChanged={setFieldValue}
			onOptionChanged={onFormChanged}
			validationGroup={POLICY_VALIDATION_GROUP}
			onContentReady={disableAutocompleteOnForm}
		>
			<GroupItem>
				<GroupItem>
					<SimpleItem
						dataField={SELECT_ADDRESS}
						editorType="dxCheckBox"
						label={selectAddressLabel}
						visible={showAddressSelection}
					/>
					<SimpleItem
						dataField={SELECTED_RELATION_ADDRESS}
						editorType="dxSelectBox"
						cssClass={styles["wide-editor"]}
						label={selectedAddressLabel}
						editorOptions={{
							valueExpr: "guid",
							displayExpr: "address",
							disabled: !selectAddress,
							dataSource: relationAddressDataSource,
						}}
						visible={showAddressSelection}
					>
						{requiredAddressRule}
					</SimpleItem>
				</GroupItem>
			</GroupItem>
			<GroupItem>
				<GroupItem visible={!selectAddress}>
					<SimpleItem
						dataField={USE_POBOX}
						editorType="dxCheckBox"
						label={usePoBoxLabel}
						visible={allowPoBox}
					/>
					<GroupItem>
						<GroupItem visible={!showPoBoxItem}>
							<ColCountByScreen lg={2} md={1} />
							{postalCodeItem}
							{houseNumberItem}
							{houseLetterItem}
							{streetNameItem}
							{cityItem}
						</GroupItem>
					</GroupItem>
					<GroupItem>
						<GroupItem visible={showPoBoxItem !== null && showPoBoxItem}>
							{postalCodeItem}
							{cityItem}
							{poBoxItem}
						</GroupItem>
					</GroupItem>
				</GroupItem>
			</GroupItem>
			<Toast
				visible={savingFailed}
				message={Translate("policy.form.failure.saving")}
				type="error"
				onHiding={hideError}
				closeOnClick
				displayTime={10000}
			/>
		</Form>
	);
};

export default React.memo(AddressComponent);