import "bootstrap/dist/css/bootstrap.css";
import "./styles/dx.generic.BeFurst-Dark-Red-Scheme.css";
import "./styles/globals.scss";

import React from "react";

import { createRoot } from "react-dom/client";

import App from "./App";
import { unregister } from "./registerServiceWorker";
import { LoadLanguages } from "./utils/Translation";

LoadLanguages();

const container = document.getElementById("root") as Element;
const root = createRoot(container);
root.render(<App />);

unregister();